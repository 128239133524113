import React from "react";
import ReactDOM from "react-dom";
import {ConsumerSearch} from "../../src/features/consumer_search/consumer_search";
import {ListingSearchProvider} from "../../src/components/listing_search/listing_search_context";

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    (
      <ListingSearchProvider>
        <ConsumerSearch/>
      </ListingSearchProvider>
    ),
    document.getElementById('react_content'),
  )
});