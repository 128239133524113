import React, { PropsWithChildren } from 'react'

interface IProps {
    height?: string
    width?: string
    viewBox?: string
    className?: string

    onClick?(): void
}

const SvgIcon: React.FC<IProps> = (props: PropsWithChildren<IProps>) => {
    const height: string =
        typeof props.height !== 'undefined' ? props.height : '24'
    const width: string =
        typeof props.width !== 'undefined' ? props.width : '24'
    const viewBox: string =
        typeof props.viewBox !== 'undefined' ? props.viewBox : '0 0 24 24'

    return (
        <svg
            className={`${
                typeof props.className !== 'undefined'
                    ? props.className
                    : 'fill-current'
            }`}
            xmlns="http://www.w3.org/2000/svg"
            height={height}
            width={width}
            viewBox={viewBox}
            preserveAspectRatio="none"
            onClick={() =>
                typeof props.onClick !== 'undefined'
                    ? props.onClick()
                    : () => {}
            }
        >
            {props.children}
        </svg>
    )
}

export default SvgIcon
