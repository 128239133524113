import React, {useContext, useState} from "react";
import "./styles/listing_tile.scss";
import {IListing} from "../../../services/api/listing";
import {ConsumerSearchContext} from "./consumer_search_context";
import {SliderInfoControls} from "../listing_tile/slider_info_controls";
import {InfoPanel} from "./info_panel";
import {savedListingApi} from "../../../services/api/saved_listing";

const isAtLeastOneFavorite = (listings) => {
    return listings.some((listing) => listing.is_saved_for_user);
};

export function ListingTile(props: IListingTile) {
    const {paddingPosition, listings, openSignUpModal, resultId} = props;
    const {highlightedResultId, userData, setMultiUnitModalOpen, setMultiUnitListings} = useContext(ConsumerSearchContext);
    const [isFavorite, setIsFavorite] = useState(isAtLeastOneFavorite(listings));
    const [isSavingFavorite, setIsSavingFavorite] = useState(false);
    const [isSavingFavoriteError, setIsSavingFavoriteError] = useState(false);
    const {isMobile} = useContext(ConsumerSearchContext);
    const firstListing = listings[0];

    const getTileStyles = (pos) => {
        return "right" === pos ? {paddingLeft:"6px"} : {paddingRight:"6px"};
    };

    const getTileWrapperStyles = () => {
        if(resultId === highlightedResultId) {
            return {border: "4px solid #e1e06e"};
        }
    };

    const handleOnMouseEnter = (id) => {
        if(isMobile) return;

        //highlight the corresponding flag on the map
        const flagEl = document.getElementById(`flag_${id}`);
        flagEl.className = "rb__flag rb__flag-selected";

        const zIndex = parseInt(flagEl.style.zIndex);
        const inflatedZIndex = isNaN(zIndex) ? 100 : zIndex + 100;
        flagEl.style.zIndex = inflatedZIndex.toString();
    };

    const handleOnMouseLeave = (id) => {
        if(isMobile) return;

        // remove flag highlight on the map
        const flagEl = document.getElementById(`flag_${id}`);
        flagEl.className = "rb__flag";

        const zIndex = parseInt(flagEl.style.zIndex);
        const deflatedZIndex = zIndex - 100;
        flagEl.style.zIndex = deflatedZIndex.toString();
    };

    const handleSaveToFavorites = async (userData, listings, isFavorite) => {

        if(userData.id) {
            if(listings.length > 1) {
                toggleMultipeListings(!isFavorite, listings)
            }
            else {
                toggleSavedStatus(!isFavorite, listings[0]);
            }
        }
        else {
            const formValues = {
                saveToFavorites: firstListing.apartment_id,
                listingId: firstListing.apartment_id,
                route: "/legacy-login",
                landingURI: location.origin + location.pathname, // add agentId params
            };
            openSignUpModal(formValues);
        }
    };

    const toggleMultipeListings = (value, listings) => {
        if(value) {
            toggleSavedStatus(true, listings[0]);
        }
        else {
            listings.forEach((listing) => {
                if(listing.is_saved_for_user) {
                    toggleSavedStatus(false, listing);
                }
            });
        }
    };

    const toggleSavedStatus = async (value, listing) => {

        let response;
        setIsSavingFavorite(true);

        if(value) {
            response = await savedListingApi.post({
                listingId: listing.apartment_id,
                liked: true,
                contextListings: [],
            }).catch(e => {
                console.error(e);
            });
        }
        else {
            response = await savedListingApi.delete({
                listingId: listing.apartment_id,
                contextListings: [],
            }).catch(e => {
                console.error(e);
            });
        }

        if(200 === response.data.code) {
            setIsFavorite(value);
            listing.is_saved_for_user = value;
            setIsSavingFavorite(false);
            setIsSavingFavoriteError(false);
            console.log(`Successfully set favorite listing (${listing.apartment_id}) to ${value}`);
        }
        else {
            setIsSavingFavoriteError(true);
            console.log(`There was an ERROR setting favorite listing (${listing.apartment_id}) to ${value}`);
        }
    };

    const getSavedListingIcon = () => {

        const className =  isFavorite ? "favorite-heart fas fa-heart saved-listing" : "favorite-heart far fa-heart";
        let icon = <i className={className} onClick={ ()=> { handleSaveToFavorites(userData, listings, isFavorite) } } />;

        if(isSavingFavorite) {
            icon = <div className="loading-favorite-icon" />;
        }

        if(isSavingFavoriteError) {
            icon = <i className="fas fa-exclamation-circle loading-favorite-icon-error" />;
        }

        return icon;
    };

    const getPicPaths = (listing) => {
        if(listing.pics) {
            return listing.pics.map(pic => pic.path);
        }
        else {
            return [];
        }
    };

    const getLowAndHighRent = (listings) => {
        const rentRange = [listings[0].rent, listings[0].rent];

        listings.forEach(function(listing){
            if(listing.rent < rentRange[0]) {
                rentRange[0] = listing.rent;
            }

            if(listing.rent > rentRange[1]) {
                rentRange[1] = listing.rent;
            }
        });

        return rentRange;
    };

    const getRentText = (firstListing, listings) => {
        if(listings.length > 1) {
            const arr = getLowAndHighRent(listings);
            return `$${arr[0]}-$${arr[1]}`;
        }
        else {
            return `$${firstListing.rent}`;
        }
    };

    const handleOpenModal = (listings: IListing[]) => {
        setMultiUnitModalOpen(true);
        setMultiUnitListings(listings);
    };

    const getMultiUnitButton = (listings) => {
        if(listings.length > 1) {
            return <span className={"multi-unit-btn"} onClick={ ()=> handleOpenModal(listings) }>more <i className="fas fa-arrow-right"/></span>
        }
    };

    return (
        <div className="listing-tile-container"
             style={getTileStyles(paddingPosition)}
             id={`tile_${resultId}`}
             onMouseEnter={ () => handleOnMouseEnter(resultId) }
             onMouseLeave={ () => handleOnMouseLeave(resultId) }
        >
            <div className="listing-tile-wrapper" style={getTileWrapperStyles()}>
                <SliderInfoControls pics={getPicPaths(firstListing)} height={260} priceRowClassName="tile-price-row" >
                    <span className={"price-text"}>{getRentText(firstListing, listings)}</span>
                    {getMultiUnitButton(listings)}
                    {getSavedListingIcon()}
                </SliderInfoControls>
                <InfoPanel listings={listings} firstListing={firstListing} />
            </div>
        </div>
    )
}

export interface IListingTile {
    paddingPosition?: string;
    listings: IListing[];
    contextListings?: any [];
    openSignUpModal?: (props) => void;
    resultId?: string;
}