import React, { useContext, useEffect } from "react";
import { sortTableHeaderConfig } from "./map_view_config/SortTableHeaderConfig";
import { viewSetting } from "./map_view_config/ViewSetting";
import { mapSetting } from "./map_view_config/MapSetting";
import { LayersDropdown } from "./layers_dropdown";
import { DrawDropdown } from "./draw_dropdown";
import "./map_view.scss";
import "../../../../../assets/icons/select_box.svg";
import { SelectedListingsContext } from "../../selected_listings_context";
import { SingleBox } from "../../../../../assets/icons/SingleBox";
import { SingleSelectedBox } from "../../../../../assets/icons/SingleSelectedBox";
import { MultiBox } from "../../../../../assets/icons/MultiBox";
import { MultiBoxSelected } from "../../../../../assets/icons/MultiBoxSelected";
import { ListingSearchContext } from "../../../../components/listing_search/listing_search_context";
import { isMobile } from '../../../../utils/device_info';

interface MapViewTableHeader {
    handleSortClick: (field: string) => void;
    selectedSortBy: string;
    handleViewSettingClick: (field: string) => void;
    handleMapSettingClick: (field: string) => void;
    isSingleCheck: boolean;
    isMultiCheck: boolean;
    toggleSingleCheck: () => void;
    toggleMultiCheck: () => void;
    handleSelectShape: (
        event: React.ChangeEvent<{
            name?: string;
            value: unknown;
        }>
    ) => void;
    selectedShapeController: string | null;
    listings: any[];
}
const MapViewTableHeader = ({
    handleSortClick,
    selectedSortBy,
    handleViewSettingClick,
    handleMapSettingClick,
    isSingleCheck,
    isMultiCheck,
    toggleSingleCheck,
    toggleMultiCheck,
    handleSelectShape,
    selectedShapeController,
    listings,
    onSetSelectedLayers,
    selectedLayers,
    schoolDistrictLayer,
    onSetSchoolDistrictLayer,
    // --- new layer: Subdivision ---
    subdivisionLayer,
    onSetSubdivisionLayer,
    // --- END ---
    selectedSchoolLayers,
    onSetSelectedSchoolLayers
}) => {
    const { selectedListings, clearSelectedListings, setSelectedListings } = useContext(
        SelectedListingsContext
    );

    const { drawShapes, isActionExcludeOrDeleteShape} = useContext(
        ListingSearchContext
    )

    useEffect(() => {
        if (drawShapes.length > 0) {
            const prevSelectedListings = [...selectedListings]
            const filteredSelectedListings = selectedListings.filter(listing => {
                return listings.some(f => {
                    return f.apartment_id === listing.listingId;
                });
            });
            const deduplicatedListings = filteredSelectedListings.concat(
            isActionExcludeOrDeleteShape == null
                ? prevSelectedListings.filter(p => !filteredSelectedListings.some(f => f.listingId === p.listingId))
                : []
            )
            setSelectedListings(deduplicatedListings)
        }
    }, [listings, drawShapes,isActionExcludeOrDeleteShape])

    return (
        <div className={`map-view-table-header ${isMobile() ? "map-mobile" : ""}`}>
            <div className='map-view-sort-by-section'>
                <div>
                    <select onChange={(e) => handleSortClick(e.target.value)}>
                        <option value={""} disabled selected hidden>
                            Sort By
                        </option>
                        {sortTableHeaderConfig.map((config) => {
                            return (
                                <option
                                    key={config.label}
                                    value={JSON.stringify(config)}
                                    selected={config.label === selectedSortBy}
                                >
                                    {config.label}
                                </option>
                            );
                        })}
                    </select>
                </div>
            </div>
            <div className='map-view-select-diselect'>
                <span>Selected</span>
                <span className='map-view-selected-count'>
                    {" "}
                    {selectedListings.length}
                </span>

                <span
                    onClick={clearSelectedListings}
                    className='action--header'
                    title={"Clear Selection"}
                >
                    Unselect
                </span>
            </div>
            <div className='map-view-toolbars'>
                <div className='map-view-select-diselect'>
                    <a
                        href='#'
                        className='svg-cursor'
                        onClick={(e) => {
                            e.preventDefault();
                            toggleSingleCheck();
                        }}
                        title={"Single Select"}
                    >
                        {isSingleCheck ? <SingleSelectedBox /> : <SingleBox />}
                    </a>
                    <a
                        href='#'
                        className='svg-cursor multi-select'
                        onClick={(e) => {
                            e.preventDefault();
                            toggleMultiCheck();
                        }}
                        title={"Area Select"}
                    >
                        {isMultiCheck ? <MultiBoxSelected /> : <MultiBox />}
                    </a>
                </div>
                <div className={`toolbar-list ${isMobile() ? "map-mobile" : ""}`}>
                    <DrawDropdown
                        handleSelectShape={handleSelectShape}
                        selectedShapeController={selectedShapeController}
                    />
                    <LayersDropdown
                        selectedLayers={selectedLayers}
                        onSetSelectedLayers={onSetSelectedLayers}
                        schoolDistrictLayer={schoolDistrictLayer}
                        onSetSchoolDistrictLayer={onSetSchoolDistrictLayer}
                        selectedSchoolLayers={selectedSchoolLayers}
                        onSetSelectedSchoolLayers={onSetSelectedSchoolLayers}
                        // --- new layer: Subdivision ---
                        subdivisionLayer={subdivisionLayer}
                        onSetSubdivisionLayer={onSetSubdivisionLayer}
                        // --- END ---
                    />
                    <select
                        onChange={(e) => handleMapSettingClick(e.target.value)}
                    >
                        <option value={""} disabled selected hidden>
                            Map
                        </option>
                        {mapSetting.map((setting) => {
                            return (
                                <option
                                    key={setting.label}
                                    value={JSON.stringify(setting)}
                                >
                                    {setting.label}
                                </option>
                            );
                        })}
                    </select>
                    {/*Permanently hide view dropdown on map view mobile*/}
                    {
                        !isMobile() &&
                        <select
                            onChange={(e) => handleViewSettingClick(e.target.value)}
                        >
                            <option value={""} disabled selected hidden>
                                View
                            </option>
                            {viewSetting.map((setting) => {
                                return (
                                    <option
                                        key={setting.label}
                                        value={JSON.stringify(setting)}
                                    >
                                        {setting.label}
                                    </option>
                                );
                            })}
                        </select>
                    }
                </div>
            </div>
        </div>
    );
};

export default React.memo(MapViewTableHeader);
