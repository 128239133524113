export function parseOwnerPayValue(payAmount = "") {
    const type = payAmount.includes("%") ? "%" : "$";
    return {
        ownerPayAmountType: type,
        ownerPayAmountValue: payAmount.replace(type, "")
    }
}

export function getCurrentListingIdByUrl() {
    const pathArray = window.location.pathname.split("/");
    return pathArray[pathArray.length - 1];
}

export const filterVisibleListings = (loadedListings, selectedOption = []) => {
    var options = [];
    selectedOption.forEach( option => {
        options.push(option.label);
    });

    if(!selectedOption.length) {
        return loadedListings;
    }
    else {
        return loadedListings.filter( listing => options.includes(listing.status) );
    }
};

export function getListingTypes() {
    return ([
        { value: 'active', label: 'Active' },
        { value: 'application-pending', label: 'Application Pending' },
        { value: 'application-only', label: 'Application Only' },
        { value: 'pending-new', label: 'Pending New' },
        // { value: 'problem-property', label: 'Problem Property' },
        // { value: 'rejected', label: 'Rejected' },
        { value: 'rented', label: 'Rented' },
        { value: 'draft', label: 'Draft' },
        { value: 'expired', label: 'Expired' },
        { value: 'withdrawn', label: 'Withdrawn' },
        { value: 'cancelled', label: 'Cancelled' },
    ]);
}

export const isBrokerPortal = () => {
    return window.location.pathname.includes('broker_portal');
}

export const isLandlordPortal = () => {
    return window.location.pathname.includes('landlord_portal');
}

export const isAgentSettings = () => {
    return window.location.pathname.includes('/agent/settings');
}
