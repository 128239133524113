import React from "react";

import { IListing } from "../../../../../services/api/listing";
import { Jss } from "jss";
import { ReportHeader, reportStyles } from "../report";
import { BasicFullReport } from "./basic_full_report";
import { RemarksFullReport } from "./remarks_full_report";
import { PropertyFullReport } from "./property_full_report";
import { UnitFullReport } from "./unit_full_report";
import { OtherInfoFullReport } from "./other_info_full_report";
import { AgentInfoFullReport } from "./agent_info_full_report";

export function FullReport(props: IReportInfo) {
  const {
    agentName,
    agentEmail,
    agentContact,
    listings,
    disclaimer,
    agentId,
    featuresList,
    agentBrokerLogo,
  } = props;
  const { classes } = props.jss.createStyleSheet(reportStyles).attach();

  return (
    <div
      className={classes.container}
      style={{ height: 2000, paddingBottom: 50 }}
    >
      <ReportHeader {...{ agentEmail, agentName, jss: props.jss }} />
      {listings.map((listing) => {
        return (
          <div
            key={listing.apartment_id}
            style={{ fontFamily: "Tahoma, Verdana, sans-serif", marginTop: 30 }}
          >
            <AgentInfoFullReport
              listing={listing}
              agentName={agentName}
              agentEmail={agentEmail}
              agentContact={agentContact}
              agentBrokerLogo={agentBrokerLogo}
            />
            <BasicFullReport listing={listing} />
            <RemarksFullReport listing={listing} />
            <PropertyFullReport listing={listing} featuresList={featuresList} />
            <UnitFullReport listing={listing} featuresList={featuresList} />
            <OtherInfoFullReport listing={listing} />
          </div>
        );
      })}
    </div>
  );
}

// listings: IListing[];
export interface IReportInfo {
  agentName: string;
  agentEmail: string;
  agentId?: string;
  agentContact?: string | number;
  agentBrokerLogo?: string;
  listings: IListing[];
  imgUrl: string;
  jss: Jss;
  disclaimer: string;
  featuresList?: any;
}
