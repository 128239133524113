import React, {useContext, useEffect, useState} from 'react';
import "./alerts.scss";
import {AgentUserContext} from "../../broker_search/agent_user_context";
import {alertsApi, IAlert} from "../../../../services/api/alerts";
import {Alert} from "./alert";
import {Drawer} from "../../../components/drawer/drawer";
import {Filters} from "../../../components/listing_search/search_filters/filters";
import {FiltersContext} from "../../../components/listing_search/search_filters/filters_provider";
import {getEmptyCriteria, ListingSearchContext} from "../../../components/listing_search/listing_search_context";
import {BrokerSearchingContext} from "../../broker_search/broker_search_context";
import {SweetAlertContext} from "../../../components/sweet_alert/sweet_alert";
import {mapParamsForUI} from "../../../../services/rails_api/broker_portal/shortcuts_alerts";
import {ListingType} from "../../../../services/api/listing";
import {sorter_sortDescending} from "../../../utils/sorter";
export function Alerts() {

    const {agentId} = useContext(AgentUserContext);
    const {criteria, setCriteria, partnerKey, initPartnerKey} = useContext(ListingSearchContext);
    const {validateSearchFilters} = useContext(FiltersContext);
    const {
        triggerAlert,
        setAlertVisible,
        setAlertType,
        setAlertContent,
        setSweetAlertProps,
        handleCloseSweetAlert,
    } = useContext(SweetAlertContext);
    const {purgeMultipleLocations, searching, setSearching} = useContext(BrokerSearchingContext);

    const [alerts, setAlerts] = useState([] as IAlert[]);
    const [selectedAlert, setSelectedAlert] = useState(getEmptyAlertObj(agentId));
    const [filtersOpen, setFiltersOpen] = useState(false);
    const handleClose = () => {
        setFiltersOpen(false);
    };

    const handleOpen = (alert) => {
        setFiltersOpen(true);

        const sP = alert.search_params;
        setSelectedAlert(alert);

        const selectedCriteria = mapParamsForUI(sP);
        setCriteria(selectedCriteria);
    };

    const handleOpenNew = () => {
        setFiltersOpen(true);
        setCriteria(getEmptyCriteria(partnerKey));

        const newAlert = getEmptyAlertObj(agentId);
        setSelectedAlert(newAlert);
    };

    const loadAlerts = async () => {
        if(agentId && agentId > 0) {
            const response = await alertsApi.index(agentId);

            sorter_sortDescending("modified_at", response);
            console.log(response);

            setAlerts(response);
            setSelectedAlert(getEmptyAlertObj(agentId));
            setSearching(false);
        }
    };

    const handleProcess = async () => {
        if(searching) {return} // prevent double/multiple user inputted clicking when saving alert

        if(!validateSearchFilters(criteria)) {
            triggerAlert("error", "{t('Please select an agent or change the MLS/Agency filter.')}");
            return
        }

        const alertToPersist = selectedAlert;

        // validate alert name to be present
        if(!alertToPersist.criteria_name || "" === alertToPersist.criteria_name) {
            triggerAlert("error", "Alert name must not be empty");
            return
        }

        setSearching(true);

        const searchParams = {...criteria, multiple_areas: purgeMultipleLocations(criteria)};
        alertToPersist.search_params = mapCriteriaToAlertParams(searchParams);

        const method = alertToPersist.id > 0 ? "put" : "post";
        await alertsApi[method](alertToPersist).catch(e => handleAPIError(e));

        handleClose();
        loadAlerts();
    };

    const handleAPIError = (e) => {
        triggerAlert("error");
    };

    const handleAlertNameChange = (e, alert) => {
        setSelectedAlert({...alert, criteria_name: e.currentTarget.value});
    };

    const handleDelete = (alert) => {
        setAlertVisible(true);
        setAlertType('warning');
        setAlertContent(`Are you sure that you want to delete "${alert.criteria_name}"?`);
        setSweetAlertProps({
            title: 'DELETE',
            showConfirm: true,
            showCancel: true,
            onConfirm: async () => {
                await alertsApi.delete(alert);
                handleCloseSweetAlert();
                loadAlerts();
            },
            onCancel: () => {
                handleCloseSweetAlert();
            }
        })
    };

    const handleAssociateClient = async (newClient, alert) => {
        const clients = alert.clients;
        clients.push(newClient);

        const response = await alertsApi.put(alert).catch(e => triggerAlert("error"));
        if(response) {
            triggerAlert("success");
            loadAlerts();
        }
    };

    const handleDissociateClient = async (alert, clients, index) => {
        setAlertVisible(true);
        setAlertType('warning');
        setAlertContent(`Are you sure that you want to dissociate this client from the alert?`);
        setSweetAlertProps({
            title: 'Confirm',
            showConfirm: true,
            showCancel: true,
            onConfirm: async () => {
                clients.splice(index, 1);
                const response = await alertsApi.put(alert).catch(e => triggerAlert("error"));
                if(response) {
                    triggerAlert("success");
                    loadAlerts();
                }
            },
            onCancel: () => {
                handleCloseSweetAlert();
            }
        });
    };

    useEffect(()=>{
        loadAlerts();
    }, [agentId]);

    useEffect(()=>{
        initPartnerKey(); // init partner key when page loads, this will affect the multiple location autocomplete response
    }, []);

    return (
        <section className="alerts-wrapper">
            <div className="alerts-container">
                <div className="container-header">
                    <div className="title">My Alerts</div>
                    <div className="icons" onClick={handleOpenNew}>
                        <span className="new-alert">NEW</span>
                        <i className="fa fa-bell" />
                    </div>
                </div>
                <div className="alerts-list-cont">
                    {   searching ?
                        <div className="loading">
                            <span>Loading</span>
                            <div className="loading-circle-icon" />
                        </div> :
                        alerts.length ?
                        alerts.map((alert, index) => {
                                return <Alert alert={alert}
                                              handleDrawerOpen={()=>handleOpen(alert)}
                                              handleDelete={()=>handleDelete(alert)}
                                              handleAssociateClient={handleAssociateClient}
                                              handleDissociateClient={handleDissociateClient}
                                              key={`alert_${index}`}/>
                        }) :
                        <div className="loading">
                            <span>No Alerts Available</span>
                        </div>
                    }
                </div>
            </div>
            <Drawer open={filtersOpen} handleClose={handleClose} width={80}>
                <Filters handleClose={handleClose}
                         filtersOpen={filtersOpen}
                         handleProcess={handleProcess}
                         alert={selectedAlert}
                         handleAlertNameChange={handleAlertNameChange}
                />
            </Drawer>
        </section>
    )
}

export const getEmptyAlertObj = (agentId) => {
    return (
        {
            search_params: {},
            user: agentId,
            criteria_name: "",
        } as IAlert
    )
};

const mapCriteriaToAlertParams = (params) => {

    // convert FE only value ("other_agent_listings") to API value ListingType.agent_listings
    params.listing_type = ListingType.other_agent_listings === params.listing_type ? ListingType.agent_listings : params.listing_type;

    const obj = {
        ...params,
        addresses: params.address,
        ac_type: params.cooling_type_list,
        building_type: params.building_type_list,
        heat_type: params.heat_type_list,
        max_price: params.rent_range.max_price,
        min_bathrooms: params.min_bathrooms < 0 ? null : params.min_bathrooms,
        min_price: "" === params.rent_range.min_price ? 0 : params.rent_range.min_price,
        owner_pay: params.owner_pay_list,
        ownership_type: params.ownership_type_list,
        parking_options: params.parking_options_list,
        pet_types: params.pet_types_list,
        statuses: params.statuses_list,
        type_contact: params.ownership_type_list,
        unit_features: params.unit_features_list,
    };

    // delete the members of the criteria object that are solely for FE use (UI state control)
    const fieldsToDelete = [
        "building_type_list",
        "cooling_type_list",
        "fields_to_include",
        "heat_type_list",
        "max_record_count",
        "owner_pay_list",
        "ownership_type_list",
        "page_number",
        "parking_options_list",
        "partner_key",
        "pet_types_list",
        "rent_range",
        "statuses_list",
        "unit_features_list",
    ];
    fieldsToDelete.forEach((key)=>{ delete(obj[key])});

    return obj
};