import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import {getFormattedDate} from "../../utilities";
import moment from "moment";
import React, {useContext, useEffect, useState} from "react";
import {ListingSearchContext} from "../../listing_search_context";

export function FieldsetLeaseDate(props) {
    const {criteria, setCriteria} = useContext(ListingSearchContext);
    const [dates, setDates] = useState([]);

    const setDatesCriteria = () => {
        if (dates === null || (dates[0] ==null && dates[1] === null)) {
            return
        }
        else {
            setCriteria({...criteria, lease_date: {...criteria.lease_date, min: getFormattedDate(dates[0]), max: getFormattedDate(dates[1])}})
        }
    };

    useEffect(() => {
        if (dates?.length > 0 && dates[0]?.getFullYear() > 1900 && dates[1]?.getFullYear() > 1900) {
            setDatesCriteria()
        } else {
            setCriteria({...criteria, lease_date: {...criteria.lease_date, min: null, max: null}})
        }
    }, [dates])

    useEffect(() => {
        setDates([
            criteria.lease_date.min ? moment(criteria.lease_date.min).toDate() : null,
            criteria.lease_date.max ? moment(criteria.lease_date.max).toDate() : null
        ])
    }, [criteria.lease_date.min, criteria.lease_date.max])

    return <fieldset className={props.className}>
        <div>
            <label>Lease Date</label>
        </div>
        <DateRangePicker
            onChange={setDates}
            value={dates}
            className="fieldset-date--picker"
            dayPlaceholder="dd"
            monthPlaceholder="mm"
            yearPlaceholder="yyyy"
            calendarType="US"
        />
    </fieldset>
}