import React, {useContext, useEffect, useState} from "react";
import {ListingSearchContext} from "../../listing_search_context";
import "./fieldset_square_footage_range.scss";

export function FieldsetSquareFootageRange (props: IFieldsetSqFtRange) {
    const {className} = props;
    const {criteria, setCriteria} = useContext(ListingSearchContext);
    const {focusedElId, setFocusedElId} = useContext(ListingSearchContext);
    const [inputError, setInputError] = useState(null);
    const errorLabel = `Input a max sq ft that is higher than the min sq ft`;

    const handleMinChange = (e) => {
        setCriteria({...criteria, sqft_range: {...criteria.sqft_range, min_sq_ft: e.currentTarget.value} });

        if(setFocusedElId) {
            setFocusedElId(props.minInputId);
        }
    };

    const handleMaxChange = (e) => {
        setCriteria({...criteria, sqft_range: {...criteria.sqft_range, max_sq_ft: e.currentTarget.value} });

        if(setFocusedElId) {
            setFocusedElId(props.maxInputId);
        }
    };

    const handleMinBlur = (e) => {
        const newMinValue = e.currentTarget.value;
        const newSqFt = parseInt(newMinValue);
        if (!(criteria.sqft_range.max_sq_ft === "") && newSqFt > criteria.sqft_range.max_sq_ft) {
            setInputError(errorLabel);
        } else {
            setInputError(null);
        }
    };

    const handleMaxBlur = (e) => {
        const newMaxValue = e.currentTarget.value;
        const newSqFt = parseInt(newMaxValue);
        if (newSqFt < criteria.sqft_range.min_sq_ft) {
            setInputError(errorLabel);
        } else {
            setInputError(null);
        }
    };

    useEffect(()=>{
        if(focusedElId) {
            document.getElementById(focusedElId).focus();
        }
    }, [focusedElId]);

    return <fieldset className={`square-footage--container ${className}`}>
        <div>
            <label className="wide-only">Total Square Footage</label>
        </div>
        <div className="sqft-input--container">
            <input id={props.minInputId || ""}
                   className={"criteria--input"}
                   onChange={e => handleMinChange(e)}
                   onBlur={e => handleMinBlur(e)}
                   value={criteria.sqft_range.min_sq_ft}
                   type="number"
            />
            <span className={"to"}>to</span>
            <input id={props.maxInputId || ""}
                   className={"criteria--input"}
                   onChange={e => handleMaxChange(e)}
                   onBlur={e => handleMaxBlur(e)}
                   value={criteria.sqft_range.max_sq_ft}
                   type="number"
                   min={1}
            />
            <label className={`errorLabel ${inputError ? 'active' : ''}`}>{inputError}</label>
        </div>
    </fieldset>
}


interface IFieldsetSqFtRange {
    className?: string;
    minInputId?: string;
    maxInputId?: string;
}