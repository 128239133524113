import React, {useContext, useEffect, useState} from "react";
import {ListingSearchContext} from "../../listing_search_context";
import "../square_footage_range/fieldset_square_footage_range.scss"

export function FieldsetFinishedSquareFootage (props: IFieldsetFinishedRange) {
    const {className} = props;
    const {criteria, setCriteria} = useContext(ListingSearchContext);
    const {focusedElId, setFocusedElId} = useContext(ListingSearchContext);
    const [inputError, setInputError] = useState(null);
    const errorLabel = `Input a max sq ft that is higher than the min sq ft`;

    const handleMinChange = (e) => {
        setCriteria({...criteria, finished_square_footage: {...criteria.finished_square_footage, min: e.currentTarget.value} });
        if(setFocusedElId) {
            setFocusedElId(props.minInputId);
        }
    };

    const handleMaxChange = (e) => {
        setCriteria({...criteria, finished_square_footage: {...criteria.finished_square_footage, max: e.currentTarget.value} });
        if(setFocusedElId) {
            setFocusedElId(props.maxInputId);
        }
    };

    const handleMinBlur = (e) => {
        const newMinValue = e.currentTarget.value;
        const newSqFt = parseInt(newMinValue);
        if (!(criteria.finished_square_footage.max === null) && newSqFt > criteria.finished_square_footage.max) {
            setInputError(errorLabel);
        } else {
            setInputError(null);
        }
    };

    const handleMaxBlur = (e) => {
        const newMaxValue = e.currentTarget.value;
        const newSqFt = parseInt(newMaxValue);
        if (newSqFt < criteria.finished_square_footage.min) {
            setInputError(errorLabel);
        } else {
            setInputError(null);
        }
    };

    useEffect(()=>{
        if(focusedElId) {
            document.getElementById(focusedElId).focus();
        }
    }, [focusedElId]);

    return <fieldset className={`square-footage--container ${className}`}>
        <div>
            <label className="wide-only">Finished Square Footage</label>
        </div>
        <div className="sqft-input--container">
            <input id={props.minInputId || ""}
                   className={"criteria--input"}
                   onChange={e => handleMinChange(e)}
                   onBlur={e => handleMinBlur(e)}
                   value={criteria.finished_square_footage.min}
                   type="number"
            />
            <span className="to">to</span>
            <input id={props.maxInputId || ""}
                   className={"criteria--input"}
                   onChange={e => handleMaxChange(e)}
                   onBlur={e => handleMaxBlur(e)}
                   value={criteria.finished_square_footage.max}
                   type="number"
                   min={1}
            />
            <label className={`errorLabel ${inputError ? 'active' : ''}`}>{inputError}</label>
        </div>
    </fieldset>
}

interface IFieldsetFinishedRange {
    className?: string;
    minInputId?: string;
    maxInputId?: string;
}