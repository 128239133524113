import {ImageSlider} from "./image_slider";
import "./slider_info.scss";
import React from "react";
import {imgUrl} from "../../../services/constants";

export function SliderInfoControls (props) {

    const {pics, height} = props;

    const getBackgroundImage = (pics) => {
        if(!pics.length) {
            return {backgroundImage: `url('${imgUrl}/no-listing.rbm')`}
        }
    };

    const getBottom = (pics) => {
        return (!pics.length) ? {bottom: 0} : {bottom: "6px"};
    };

    return <div className={"fl__carousel-cont"}  style={getBackgroundImage(pics)} >
        <ImageSlider pictures={pics} height={height}/>
        <div className={"fl__slider-shade fl__left-shade"}>
            <div className={"fl__arrow fl__back-arrow"} />
        </div>
        <div className={"fl__slider-shade  fl__right-shade"}>
            <div className={"fl__arrow fl__forward-arrow"} />
        </div>
        <div className={props.priceRowClassName || "fl__price-row"} style={getBottom(pics)}>
           {props.children}
        </div>
    </div>
}