import React from "react";
import {EFieldsToInclude} from "../../../services/api/listing";

export const tileFieldsToInclude = [
    EFieldsToInclude.building_name,
    EFieldsToInclude.zip,
    EFieldsToInclude.last_name,
    EFieldsToInclude.first_name,
    EFieldsToInclude.owner_pay_amount,
    EFieldsToInclude.owner_id,
    // EFieldsToInclude.type_contact,
    EFieldsToInclude.apartment_id,
    EFieldsToInclude.status,
    EFieldsToInclude.date_available,
    EFieldsToInclude.beds_count,
    EFieldsToInclude.bedrooms,
    EFieldsToInclude.bathrooms,
    EFieldsToInclude.rent,
    EFieldsToInclude.city,
    EFieldsToInclude.state,
    EFieldsToInclude.address,
    EFieldsToInclude.pics,
    EFieldsToInclude.long_term_commission,
    EFieldsToInclude.latitude,
    EFieldsToInclude.longitude,
    EFieldsToInclude.apt_number,
];

export const mapFieldsToInclude = [
    EFieldsToInclude.apartment_id,
    EFieldsToInclude.latitude,
    EFieldsToInclude.longitude,
];

export const getAreaString = (listing) => {
    return (!listing.area || "" === listing.area) ? listing.city : listing.area;
};

export const getAddressLineOne = (listing) => {
    const aptNum = listing.apt_number;
    const str = "" !== aptNum ? `${listing.address}, #${listing.apt_number}` : listing.address;

    if (listing.is_agent_listing) {
        return <div className="listing-address">{str}</div>
    }
};