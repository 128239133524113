import React from "react";
import uuidv4 from "uuid/v4";
import {IFilterGroup} from "./types";

interface IFilterGroupProps {
    filterData: any;
    filterGroups: [];
    setFilterGroups: ([]: IFilterGroup[]) => void;
    groupIndex: number;
    key: string;
}

export function FilterGroup(props: IFilterGroupProps) {

    const {filterData, groupIndex, filterGroups, setFilterGroups} = props;
    const isOpened = filterData.isOpened;

    const getFilterGroupHeight = (isOpened) => {
        const hasHeader = filterData.title;
        const height = hasHeader ? "170px" : "120px";
        return isOpened ? "auto" : height;
    };

    const toggleIsOpened = () => {
        const filterGroup = {...filterData, isOpened: !isOpened};
        let newGroups = [...filterGroups];
        newGroups.splice(groupIndex, 1, filterGroup);
        setFilterGroups(newGroups);
    };

    return (
        <div className="filter-group" >
            <div className="group-wrapper" style={{overflow: isOpened ? "" : "hidden", height: getFilterGroupHeight(isOpened)}} >
                <div style={{display: !filterData.title || "" === filterData.title ? "none" : "block"}}>
                    <div className="group-title">{filterData.title}</div>
                </div>
                <div className="filter-area">
                    {
                        filterData.filters.map(function(filter, index) {
                            return <div className="component-wrapper" key={filter.name}>{filter.module}</div>
                        })
                    }
                </div>
                {
                    filterData.isExpandable ?
                        <div className="see-more" onClick={toggleIsOpened}>
                            <span className={"more-filters"}>{isOpened ? "Less Filters" : "More Filters"}</span>
                            { isOpened ? <i className="fas fa-angle-up"/> : <i className="fas fa-angle-down"/> }
                        </div>
                        : null
                }

            </div>
        </div>
    )
}