import React from "react";
import {Toolbar} from "./toolbar";
import {ResultsColumn} from "./results_column";
import {MapColumn} from "./map_column";
import {IResult} from "../../components/listing_search/utilities";

export function Layout({tileResults, mapResults}: {tileResults: IResult[], mapResults: IResult[]}) {

    return (
        <section className="global-search-wrapper">
            <Toolbar />
            <section className="bottom-wrapper">
                <ResultsColumn results={tileResults}/>
                <MapColumn results={mapResults} tileResults={tileResults}/>
            </section>
        </section>
    )
}