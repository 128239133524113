import React, {useContext, useEffect, useState} from "react";
import {ListingSearchContext} from "../../listing_search_context";
import "./fieldset_rent_range.scss";

export function FieldsetRentRange(props: IFieldsetRentRange) {
    const {className} = props;
    const {criteria, setCriteria} = useContext(ListingSearchContext);
    const {focusedElId, setFocusedElId} = useContext(ListingSearchContext);
    const [inputError, setInputError] = useState(null);
    const errorLabel = `Input a max price that is higher than the min price`;

    const handleMinChange = (e) => {
        setCriteria({...criteria, rent_range: {...criteria.rent_range, min_price: e.currentTarget.value} });

        if(setFocusedElId) {
            setFocusedElId(props.minInputId);
        }
    };

    const handleMaxChange = (e) => {
        setCriteria({...criteria, rent_range: {...criteria.rent_range, max_price: e.currentTarget.value} });

        if(setFocusedElId) {
            setFocusedElId(props.maxInputId);
        }
    };

    const handleMinBlur = (e) => {
        const newMinValue = e.currentTarget.value;
        const newPrice = parseInt(newMinValue);
        if (!(criteria.rent_range.max_price === "") && newPrice > criteria.rent_range.max_price) {
            //TODO: ADD GLOBAL CONTEXT ERROR HANDLING
            setInputError(errorLabel);
        } else {
            setInputError(null);
        }
    };

    const handleMaxBlur = (e) => {
        const newMaxValue = e.currentTarget.value;

        const newPrice = parseInt(newMaxValue);
        if (newPrice < criteria.rent_range.min_price) {
            //TODO: ADD GLOBAL CONTEXT ERROR HANDLING
            setInputError(errorLabel);
        } else {
            setInputError(null);
        }
    };

    useEffect(()=>{
        if(focusedElId) {
            document.getElementById(focusedElId).focus();
        }
    }, [focusedElId]);

    return (
        <fieldset className={`rent-range--container ${className}`}>
            <div>
                <label className="wide-only">Rent</label>
            </div>
            <div className="rent-input--container">
                <span className="dollar-sign-label">$</span>
                <input id={props.minInputId || ""}
                       className={"criteria--input"}
                       onChange={e => handleMinChange(e)}
                       onBlur={e => handleMinBlur(e)}
                       value={criteria.rent_range.min_price}
                       type="number"
                       min={1}
                />
                <span className={"to"}>to</span>
                <span className="dollar-sign-label">$</span>
                <input id={props.maxInputId || ""}
                       className={"criteria--input"}
                       onChange={e => handleMaxChange(e)}
                       onBlur={e => handleMaxBlur(e)}
                       value={criteria.rent_range.max_price}
                       type="number"
                       min={1}
                />
                <label className={`errorLabel ${inputError ? 'active' : ''}`}>{inputError}</label>
            </div>
        </fieldset>
    )
}

interface IFieldsetRentRange {
    className?: string;
    minInputId?: string;
    maxInputId?: string;
}