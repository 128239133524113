import {useEffect, useMemo, useState} from "react";
import {IPartnerConfigSettings} from "../../../services/api/partner_config";
import {RB_FEATURES} from "../admin/types";

export function UsePartnerConfig () {
    const [partnerConfig, setPartnerConfig] = useState({features: []} as IPartnerConfigSettings)

    useEffect(() => {
        const partnerConfigRes = $("body").attr('data-partner-config');
        const jsonRes = JSON.parse(partnerConfigRes);
        setPartnerConfig(jsonRes);
    }, [])

    const hideClaim = useMemo(() => {
        return !partnerConfig.features.includes(RB_FEATURES.MY_LISTINGS) ||
        	(!partnerConfig.features.includes(RB_FEATURES.LIGHT_SEARCH) && !partnerConfig.features.includes(RB_FEATURES.FULL_SEARCH))
    }, [partnerConfig])

    const hideLeadManagement = useMemo(() => {
        return !partnerConfig.features.includes(RB_FEATURES.MY_LISTINGS)
    }, [partnerConfig])

    const hideClientEmailItems = useMemo(() => {
        return (!partnerConfig.features.includes(RB_FEATURES.LIGHT_SEARCH) && !partnerConfig.features.includes(RB_FEATURES.FULL_SEARCH))
    }, [partnerConfig])

    const hideRegionAndSignature = useMemo(() => {
        return (!partnerConfig.features.includes(RB_FEATURES.LIGHT_SEARCH) && !partnerConfig.features.includes(RB_FEATURES.FULL_SEARCH))
    }, [partnerConfig])

    const hideMapSearchView = useMemo(() => {
        return !partnerConfig.features.includes(RB_FEATURES.MAP_SEARCH_VIEW)
    }, [partnerConfig])

    const hideSchoolLayers = useMemo(() => {
        return !partnerConfig.features.includes(RB_FEATURES.SCHOOL_LAYER)
    }, [partnerConfig])

    const hidePOILayers = useMemo(() => {
        return !partnerConfig.features.includes(RB_FEATURES.POINTS_OF_INTEREST)
    }, [partnerConfig])

    const hideSchoolDistrictLayers = useMemo(() => {
        return !partnerConfig.features.includes(RB_FEATURES.SCHOOL_DISTRICT_LAYER)
    }, [partnerConfig])

    // --- new layer: Subdivision ---
    const hideSubdivisionLayers = useMemo(() => {
        return !partnerConfig.features.includes(RB_FEATURES.SUBDIVISION_LAYER)
    }, [partnerConfig])
    // --- END ---

    return {
        partnerConfig,
        hideClaim,
        hideLeadManagement,
        hideClientEmailItems,
        hideRegionAndSignature,
        hideMapSearchView,
        hideSchoolLayers,
        hidePOILayers,
        hideSchoolDistrictLayers,
        hideSubdivisionLayers
    }
}
