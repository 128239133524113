import React, {useContext, useEffect} from "react";
import {ListingSearchContext} from "../../listing_search_context";
import "./fieldset_rb_mls_id.scss";
import { useTranslation } from "react-i18next";

export function FieldsetRbMlsId  () {
    const {criteria, setCriteria} = useContext(ListingSearchContext);
    const { t } = useTranslation( 'translation',{useSuspense: false});
    const handleOnChange = e => {
        setCriteria({...criteria, rb_mls_id: e.target.value});
    };

    return <fieldset className="rb-mls-id--container">
        <label>{t('RB/MLS ID')}</label>
        <div>
            <input onChange={handleOnChange} value={criteria.rb_mls_id} className="criteria--input" />
        </div>
    </fieldset>
}