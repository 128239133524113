import React, {useContext} from "react";
import {ListingSearchContext} from "../../listing_search_context";
import "./fieldset_floor.scss";

export function FieldsetFloor  () {
    const {criteria, setCriteria} = useContext(ListingSearchContext);

    const handleOnChange = e => {
        setCriteria({...criteria, floor: e.target.value});
    };

    return <fieldset className="direct-phone--container">
        <label>Floor</label>
        <div>
            <input onChange={handleOnChange} value={criteria.floor} className="criteria--input" />
        </div>
    </fieldset>
}
