import React from 'react'
import ReactOnRails from "react-on-rails";
import './upgrade.scss';
import { useTranslation } from "react-i18next";
import {AgentUserProvider} from "../../../src/features/broker_search/agent_user_context";
import {SweetAlertsProvider} from "../../../src/components/sweet_alert/sweet_alert";
import {BrowserRouter as Router, Route} from "react-router-dom";


export function Upgrade ({ customClass }): any {
    const { t } = useTranslation( 'translation',{useSuspense: false});

    const demoAndProUpgradeButtons = () => (
        <>
            <button
                className='upgrade-pro-btn'
                onClick={() => window.open('/agent/subscriptions_and_payment', '_blank')}
            >
                UPGRADE TO PRO
            </button>
            <span>or</span>
            <button
                className='upgrade-demo-btn'
                onClick={() => window.open('https://go.rentalbeast.com/rbpdemorequest.html', '_blank')}
            >
                GET A DEMO
            </button>
        </>
    )

    return (
        <>
            <div className={`${customClass} upgrade-content`}>
                <span className='upgrade-txt'>Upgrade to Pro to unlock all the details and gain access to thousands of co-broke-free owner-sourced listings</span>
                <div className='upgrade-access-features'>
                    <span className='access-txt'>You’ll also get access to:</span>
                    <div className='access-features'>
                        <li><span>&#10003;</span>Additional leads exclusive to Pro subscribers</li>
                        <li><span>&#10003;</span>Customizable rental website with your personal branding</li>
                        <li><span>&#10003;</span>Premium third-party syndication tools</li>
                    </div>
                </div>
            </div>
            <div className='upgrade-buttons'>{demoAndProUpgradeButtons()}</div>
            <div className='rbg-vs-rbp-chart-wrapper'>
                <div className="w-layout-grid grid-20 snipcss-dkCvR">
                    <div className="text-block-rbg rows-grid">
                        Feature
                    </div>
                    <div className="text-block-rbg rows-grid">
                        Rental Beast Gateway (Free)
                    </div>
                    <div className="text-block-rbg rows-grid">
                        Rental Beast Pro (Premium)
                    </div>
                    <div className="text-block-lg-feature rows-grid">
                        <strong className='strong-texts'>
                        {t('MLS Rental Search')}
                        </strong>
                    </div>
                    <div className="text-block-rbp rows-grid">
                        {t('MLS Listings: All Details')}
                    </div>
                    <div className="text-block-rbp rows-grid">
                        {t('MLS Listings: All Details')}
                    </div>
                    <div className="text-block-83 rows-grid">
                        <strong className='strong-texts'>
                        Owner Sourced Rental Listings
                        </strong>
                    </div>
                    <div className="text-block-rbp-dg rows-grid">
                        Rental Beast Listings: Basic Info
                    </div>
                    <div className="text-block-rbp-dg rows-grid">
                        <strong className='strong-texts'>
                        Rental Beast Listings: All Details
                        </strong>
                    </div>
                    <div className="text-block-lg-feature rows-grid">
                        <strong className='strong-texts'>
                        Rental Comparative Market Analysis (CMA)
                        </strong>
                    </div>
                    <div className="text-block-rbp rows-grid">
                        {t('MLS Listings Only')}
                    </div>
                    <div className="text-block-rbp-block-element rows-grid">
                        {t('MLS')} &amp;
                        <strong className="bold-text-3 strong-texts">
                        Rental Beast
                        </strong>
                        Listings
                    </div>
                    <div className="text-block-features-dg-large rows-grid">
                        <strong className='strong-texts'>
                        Rental-Centric Add/Edit
                        </strong>
                    </div>
                    <div className="text-block-rbp-dg-large-copy one-line rows-grid">
                        √
                    </div>
                    <div className="text-block-rbp-dg-large-copy one-line rows-grid">
                        √
                    </div>
                    <div className="text-block-lg-feature rows-grid">
                        <strong className='strong-texts'>
                        Property Management Software Listings Sync (Claim Rental Listings)
                        </strong>
                    </div>
                    <div className="text-block-rbp rows-grid">
                        √
                    </div>
                    <div className="text-block-rbp rows-grid">
                        √
                    </div>
                    <div className="text-block-83 rows-grid">
                        <strong className='strong-texts'>
                        Rental Leads
                        </strong>
                    </div>
                    <div className="text-block-rbp-dg rows-grid">
                        Your {t('MLS')} Listing, Your Lead
                    </div>
                    <div className="text-block-rbp-dg-copy rows-grid">
                        Your {t('MLS')} Listing, Your Lead -
                        <strong className='strong-texts'>
                        PLUS additional leads generated from Rental Beast listings
                        </strong>
                    </div>
                    <div className="text-block-feature-g-large rows-grid">
                        <strong className='strong-texts'>
                        Rental Lead Qualification
                        </strong>
                    </div>
                    <div className="text-block-rbp-gray-large-checkmark rows-grid">
                        √
                    </div>
                    <div className="text-block-rbp-gray-large rows-grid">
                        √
                    </div>
                    <div className="text-block-83 rows-grid">
                        <strong className='strong-texts'>
                        Rental Lead Filtering
                        </strong>
                    </div>
                    <div className="text-block-rbp-dg rows-grid">
                    </div>
                    <div className="text-block-rbp-dg rows-grid">
                        √
                    </div>
                    <div className="text-block-lg-feature rows-grid">
                        <strong className='strong-texts'>
                        Listing Agent Tools
                        </strong>
                    </div>
                    <div className="text-block-lg rows-grid">
                    </div>
                    <div className="text-block-rbp-partner-upgrade rows-grid">
                        √
                    </div>
                    <div className="text-block-83 rows-grid">
                        <strong className='strong-texts'>
                        Rental Website
                        </strong>
                    </div>
                    <div className="text-block-rbp-dg rows-grid">
                    </div>
                    <div className="text-block-rbp-dg rows-grid">
                        <strong className='strong-texts'>
                        Your own custom rental website
                        </strong>
                    </div>
                    <div className="text-block-lg-feature rows-grid">
                        <strong className='strong-texts'>
                        Third-Party Rental Listing Syndication
                        </strong>
                    </div>
                    <div className="text-block-lg rows-grid">
                        Existing {t('MLS')} Syndication and IDX Feeds, your {t('MLS')}'s consumer-facing website, and
                        <a href="https://www.rentalbeast.com" target="_blank" className="link-6 rental_links">
                        RentalBeast.com
                        </a>
                    </div>
                    <div className="text-block-rbp-upgrade-partner rows-grid">
                        Existing {t('MLS')} Syndication and IDX Feeds, your {t('MLS')}'s consumer-facing website, and
                        <a href="https://www.rentalbeast.com" target="_blank" className="link-6 rental_links">
                        RentalBeast.com
                        </a>
                        <strong className='strong-texts'>
                        , your custom rental website, premium 3rd-party syndication, including RentPath, Zumper, and more
                        </strong>
                    </div>
                    <div className="text-block-83 rows-grid">
                        <strong className='strong-texts'>
                        Online Rental Application and Tenant Screening Service
                        </strong>
                    </div>
                    <div className="text-block-rbp-dg rows-grid">
                        √
                    </div>
                    <div className="text-block-rbp-dg rows-grid">
                        √
                    </div>
                    <div className="text-block-lg-feature rows-grid">
                        <strong className='strong-texts'>
                        Client Management Tools
                        </strong>
                    </div>
                    <div className="text-block-lg rows-grid">
                        √
                    </div>
                    <div className="text-block-lg rows-grid">
                        √
                    </div>
                    <div className="text-block-83 rows-grid">
                        <strong className='strong-texts'>
                        Renter-to-Buyer Conversion Tools
                        </strong>
                    </div>
                    <div className="text-block-rbp-dg rows-grid">
                    </div>
                    <div className="text-block-rbp-dg-partner-upgrade rows-grid">
                        <strong className='strong-texts'>
                        √
                        </strong>
                    </div>
                    <div className="text-block-lg-feature rows-grid">
                        <strong className='strong-texts'>
                        Rental Beast University
                        </strong>
                    </div>
                    <div className="text-block-lg rows-grid">
                        Unlimited Access
                    </div>
                    <div className="text-block-lg rows-grid">
                        Unlimited Access
                        <strong className='strong-texts'>
                        PLUS direct feedback from industry experts and coaches
                        </strong>
                    </div>
                    <div className="text-block-83 rows-grid">
                        <strong className='strong-texts'>
                        Resources &amp; Support
                        </strong>
                    </div>
                    <div className="text-block-rbp-dg rows-grid">
                        Live Chat, Email, Tutorial Center, Help Center
                    </div>
                    <div className="text-block-rbp-dg-upgrade-partner2 rows-grid">
                        <strong className='strong-texts'>
                        Phone
                        </strong>
                        , Live Chat, Email, Tutorial Center, Help Center,
                        <strong className='strong-texts'>
                        Dedicated Client Success Specialist
                        </strong>
                    </div>
                </div>
            </div>
            <div className='upgrade-buttons'>{demoAndProUpgradeButtons()}</div>
        </>
    )
}

export function UpgradeUser() {
    return (
        <AgentUserProvider>
            <SweetAlertsProvider>
                <Router>
                    <Route exact path="/broker_portal/upgrade">
                        <Upgrade customClass='upgrade-content-ldp' />
                    </Route>
                </Router>
            </SweetAlertsProvider>
        </AgentUserProvider>
    );
}

// This is how react_on_rails can see the HelloWorld in the browser.
ReactOnRails.register({
    UpgradeUser
});
