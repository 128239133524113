import React, {useContext, useEffect, useState} from "react";
import {FieldsetMultipleSelect} from "../multiple_select/fieldset_multiple_select";
import {ListingSearchContext} from "../../listing_search_context";
import {Checkbox, FormControlLabel} from "@material-ui/core";
import "./fieldset_pets_allowed.scss"

export function FieldsetPetsAllowed() {
    const {getFilterOptions, filterConfig, criteria, setCriteria} = useContext(ListingSearchContext);
    const [options, setOptions] = useState([]);
    const [optionArr, setOptionArr] = useState(criteria.pet_types_list);

    useEffect(()=>{
        setOptions(getFilterOptions("pet_type"));
    },[filterConfig]);

    useEffect(()=>{
        setOptionArr(criteria.pet_types_list);
    }, [criteria.pet_types_list]);

    const handlePetsChange = (e) => {
        setCriteria({...criteria, pets: !criteria.pets});
    };

    return <fieldset className="fieldset--select-container pets--container">
        <label className="wide-only">Pets</label>
        <span className="separator" />
        <div className="allowed-filter-cont">
            <FormControlLabel
                control={<Checkbox checked={criteria.pets} onChange={handlePetsChange}/>}
                label={`Pets Allowed`}
            />
        </div>
        <div className="types-filter-cont">
            <FieldsetMultipleSelect
                options={options}
                optionArr={optionArr}
                setOptionArr={setOptionArr}
                title="Pet Types"
                criteriaFieldName="pet_types_list"
            />
        </div>
    </fieldset>
}