import {REST} from "../rest";
import {sorter_sortDescending} from "../../src/utils/sorter";
import {getUserId} from "../session";

export const showingsApi = {
    getList: async (agentId: string, clientId = null): Promise<any> => {
        const url = `/api/agent/${agentId}/showings`;
        return await REST.get(url, {
            params: {
                client_id: clientId
            }
        }).then(res => {
            return res;
        });
    },

    post: async (params: IShowingsApiPostParams) => {
        const url = `/api/agent/${params.agentId}/showings`;
        return await REST.post(url, params.payload).then(res => {
            return res
        });
    },

    put: async (params: IShowingsApiPutParams) => {
        const url = `/api/agent/${params.agentId}/showings/${params.showingId}`;
        return await REST.put(url, params.payload).then(res => {
            return res
        });
    },

    delete : async (showing: IShowing) => {
        const url = `/api/agent/${showing.agent_id}/showings/${showing.id}`;
        return await REST.delete(url).then(res => res);
    },
};

interface IShowingsApiPostParams {
    agentId: string,
    payload: {
        listing_ids: number [];
        showing_name: string;
        client_ids: number [];
    }
}

interface IShowingsApiPutParams {
    agentId: string,
    showingId: string,
    payload: {
        listing_ids: number [];
        showing_name: string;
        client_ids: number [];
    }
}

export interface IShowing {
    id: number;
    listingIds: string [];
    showing_name: string;
    agent_id: number;
    showing_clients: IShowingClient [];

}

export interface IShowingClient {
    id?: number;
    client_id: number;
    client_name: string;
    is_activated?: boolean;
    other_info?: string;
    owner?: number;
    created_at?: string;
    created_by?: string;
    updated_at?: string;
}

export const handleShowingPut = async (showing: IShowing, agentId: number) => {
    const clientIds = showing.showing_clients.map((client) => client.client_id)
    const showingParams = {
        agentId: `${agentId}`,
        showingId: `${showing.id}`,
        payload: {
            client_ids: clientIds,
            showing_name: showing.showing_name,
            listing_ids: showing.listingIds.map(id => parseInt(id))
        }
    };
    return await showingsApi.put(showingParams);
};

export const getShowingsList = async () => {
    const agentId = await getUserId()
    const res = await showingsApi.getList(agentId)
    const data = res.data;
    sorter_sortDescending("updated_at", data);
    return data;
}