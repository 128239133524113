import React, {useContext, useEffect, useMemo, useState} from "react";
import {ListingSearchContext} from "../../listing_search_context";
import {Select} from "@material-ui/core";
import {Furnished} from "../../../../../services/api/listing";

export function FieldsetFurnished() {
    const {criteria, setCriteria} = useContext(ListingSearchContext);
    const {getFilterOptions, filterConfig} = useContext(ListingSearchContext);
    const [options, setOptions] = useState([]);

    useEffect(()=>{
        setOptions(getFilterOptions("furnished_type"));
    },[filterConfig]);

    const handleChange = (e) => {
        const selected = e.currentTarget.value;
        setCriteria({...criteria, furnished: selected});
    };

    const getFurnishedOptions = useMemo(() => {
        return [
            {value: Furnished.furnished, label: "Furnished"},
            {value: Furnished.furnished_with_fee, label: "Furnished with Fee"},
            {value: Furnished.partially_furnished, label: "Partially Furnished"},
            {value: Furnished.not_furnished, label: "Not Furnished"}
        ];
    }, [])

    return (
        <fieldset className="fieldset--select-container">
            <label>Furnished</label>
            <Select
                native
                value={criteria.furnished}
                onChange={handleChange}
            >
                <option aria-label="None" value="">Any</option>
                {
                    options.map((option) => (
                        <option value={option.label} key={option.value}>{option.label}</option>
                    ))
                }
            </Select>
        </fieldset>
    )
}