import {REST} from "../../rest";
import {getUserId} from "../../session";

export const landlordClientSearchAutocompleteApi = {
    /**
     * @param nameStr string inputted by user
     */
    get : async (nameStr) => {

        const userId = await getUserId();
        const url = `/api/autocomplete/agent/${userId}/clients`;
        return await REST.get(url,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                params: {
                    search: nameStr
                }
            }).then(res => {
                return res.data;
            });
    }
};