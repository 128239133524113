import React, {useContext, useEffect, useState} from "react";
import "../fieldsets.scss";
import {FieldsetMultipleSelect} from "../multiple_select/fieldset_multiple_select";
import {ListingSearchContext} from "../../listing_search_context";
import {Checkbox, FormControlLabel} from "@material-ui/core";
import "./fieldset_parking.scss";

export function FieldsetParking() {
    const {getFilterOptions, filterConfig, criteria, setCriteria} = useContext(ListingSearchContext);
    const [options, setOptions] = useState([]);
    const [optionArr, setOptionArr] = useState(criteria.parking_options_list);

    const handleParkingChange = () => {
        setOptionArr([]);
        setCriteria({...criteria, garage: !criteria.garage, parking_options_list: []});
    }

    useEffect(()=>{
        setOptions(getFilterOptions("parking_option", true));
    },[filterConfig]);

    useEffect(()=> {
        setOptionArr(criteria.parking_options_list);
    }, [criteria.parking_options_list]);

    return <div className={`parking-fields__container`}>
        <FormControlLabel
            control={<Checkbox checked={criteria.garage || criteria.parking_options_list.length > 0} onChange={handleParkingChange}/>}
            label={`Parking`}
        />
        <FieldsetMultipleSelect
                options={options}
                optionArr={optionArr}
                setOptionArr={setOptionArr}
                title="Parking Options"
                criteriaFieldName="parking_options_list"
                disabled={!criteria.garage}
            />
    </div>
}