import React, { useState, useEffect } from 'react'
import Select from 'react-select';

const customStyles = {
    control: (provided, _state) => ({
      ...provided,
      borderRadius: 0,
      color: '#5B626E',
      fontSize: '16px',
      fontFamily: 'Work Sans',
      minWidth: '200px',
      maxWidth: '300px',
    }),
    option: (provided, _state) => ({
      ...provided,
      color: '#5B626E',
      fontSize: '16px',
      fontFamily: 'Work Sans',
    }),
}

const ClientDropDown = ({
    clients,
    selectClient,
    selectedClient,
    openNewClientModal,
    index,
    containerRef,
    applicants
}) => {

    const filteredApplicants = applicants.map(applicant => applicant.id)

    const clientOptions = clients
        .filter(client => !filteredApplicants.includes(client.id))
        .map((client) => {
            return {
                label: client.name, 
                value: client
            }
        })

    const [dropdownVisible, setDropdownVisible] = useState(false);

    const handleChange = (selectedOption) => {
        selectClient(index, selectedOption)
    }

    const handleDropdownVisibility = (visible) => {
        setDropdownVisible(visible);
    };

    useEffect(() => {
        if (containerRef.current && dropdownVisible) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
    }, [dropdownVisible]);

  return (
    <div style={{display: 'flex', alignItems: 'center', gap: 30}}>
        <Select
            value={selectedClient ? selectedClient.label : selectedClient}
            // defaultInputValue={props.defaultValue}
            onChange={handleChange}
            options={
                clientOptions
            }
            placeholder="Select Clients"
            onMenuOpen={() => handleDropdownVisibility(true)}
            styles={customStyles}
        />

        <div style={{
            fontSize: 18,
            cursor: 'pointer',
            color: '#0f6cf6'
        }}
        onClick={() => openNewClientModal(index)}
        >
            + New Client
        </div>
    </div>

  )
}

export default ClientDropDown
