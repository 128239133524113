import {REST} from "../rest";
import {rbApiUrl} from "../constants";
import {getUserId} from "../session";

export const agentFeaturedAreasApi = {
    getList: async (agentId: string): Promise<any> => {
        if (agentId !== null || agentId !== 'null') {
            const url = `${rbApiUrl}/featured_areas/agent/${agentId}`;
            return await REST.get(url, {}).then(res => {
                return res.data;
            });
        }
    },
};

export const fetchFeaturedAreas = async () => {
    const userId =  await getUserId();
    return await agentFeaturedAreasApi.getList(userId)
}