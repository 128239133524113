import {REST} from "../../rest";
import {IListingApiGetParams} from "../../api/listing";
import {getEmptyCriteria} from "../../../src/components/listing_search/listing_search_context";

export const shortcutsAlertsApi = {

    get: async (params: IShortcutsAlertsParams) => {
        const url = `/api/shortcuts_alerts/${params.agent_user_id}`;

        return await REST.get(url,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            }).then(res => {
            return res;
        });
    },

    post: async (params:IShortcutsAlertsParams) => {
        const searchParams = mapCriteriaToShortcutsPostParams(params.search_params);

        const payload = {...params, search_params: searchParams};
        const formData = new FormData();

        Object.keys(payload).forEach(key => {
            formData.append(key, params[key]);
        });

        return await REST.post("/api/shortcuts_alerts",
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                params: payload,
            }, true).then(res => {
                console.log(`Shortcuts (POST) response: ${JSON.stringify(res)}`);
            return res
        });
    },

    put: async (params:IShortcutsAlertsParams) => {
        let payload = {...params};
        if(params.search_params) {
            const searchParams = mapCriteriaToShortcutsPostParams(params.search_params);
            payload = {...params, search_params: searchParams};
        }

        const formData = new FormData();
        Object.keys(payload).forEach(key => {
            formData.append(key, params[key]);
        });

        return await REST.put(`/api/shortcuts_alerts/${params.shortcut_id}`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                params: payload,
            }, true).then(res => {
                console.log(`Shortcuts (PUT) response: ${JSON.stringify(res)}`);
            return res
        });
    },

    delete: async (params: IShortcutsAlertsParams) => {
        const url = `/api/shortcuts_alerts/${params.shortcut_id}`;
        return await REST.delete(url,
            {
                params: {
                    "agent_user_id": params.agent_user_id
                }
            }
        ).then(res => {
            return res;
        });
    },
};

export interface IShortcutsAlertsParams {
    agent_user_id: number;
    criteria_name?: string;
    search_params?: any;
    shortcut_id?: number;
}

const mapCriteriaToShortcutsPostParams = (params: IListingApiGetParams) => {
    const payload = {
        ...params,
        ac_type: params["cooling_type_list"],
        building_type: params["building_type_list"],
        heat_type: params["heat_type_list"],
        laundry: params["laundry_type_list"],
        min_pic_count: 1 === params.min_pic_count ? 1 : null,
        min_price: params["rent_range"]["min_price"],
        max_price: params["rent_range"]["max_price"],
        min_sq_ft: params["sqft_range"]["min_sq_ft"],
        max_sq_ft: params["sqft_range"]["max_sq_ft"],
        owner_pay: params["owner_pay_list"],
        ownership_type: params["ownership_type_list"],
        parking_options: params["parking_options_list"],
        pet_types: params["pet_types_list"],
        public_transit: getPublicTransitParam(params),
        room_types: params["room_types"],
        statuses: params["statuses_list"],
        type_contact: params["ownership_type_list"],
        unit_features: params["unit_features_list"],
    };

    const fieldsToDelete = [
        "building_type_list",
        "cooling_type_list",
        "fields_to_include",
        "heat_type_list",
        "laundry_type_list",
        "max_record_count",
        "owner_ids",
        "owner_pay_list",
        "ownership_type_list",
        "page_number",
        "parking_options_list",
        "pet_types_list",
        "rent_range",
        "sqft_range",
        "statuses_list",
        "type_contact",
        "unit_features_list",
    ];
    fieldsToDelete.forEach((key)=>{ delete(payload[key])});

    return payload
};

const getPublicTransitParam = (params: IListingApiGetParams) => {
    const types = params.public_transit?.types;
    const distance = params.public_transit?.distance;

    if(!types?.length || distance <= 0) {
        return null
    }

    return {
        distance: distance,
        types: types,
    }
};

export const mapParamsForUI = (params) => {

    const rentRangeFx = (criteria, key, value) => {
        criteria.rent_range[key] = value || criteria.rent_range[key];
    };
    const sqFtRangeFx = (criteria, key, value) => {
        criteria.sqft_range[key] = value || criteria.sqft_range[key];
    };

    const multipleAreasFx = (criteria, key, value) => {
        criteria.multiple_areas = value;
    };

    const convertCoolingType = (criteria, key, value) => {
        criteria.cooling_type_list = value || criteria.cooling_type_list;
    };

    const convertLaundryType = (criteria, key, value) => {
        criteria.laundry_type_list = value || criteria.laundry_type_list;
    };

    const convertListFx = (criteria, key, value) => {
        criteria[`${key}_list`] = value || criteria[`${key}_list`];
    };

    const conversionMap = {
        ac_type: convertCoolingType,
        building_type: convertListFx,
        heat_type: convertListFx,
        laundry: convertLaundryType,
        max_price: rentRangeFx,
        max_sq_ft: sqFtRangeFx,
        multiple_areas: multipleAreasFx,
        min_price: rentRangeFx,
        min_sq_ft: sqFtRangeFx,
        owner_pay: convertListFx,
        ownership_type: convertListFx,
        parking_options: convertListFx,
        pet_types: convertListFx,
        statuses: convertListFx,
        unit_features: convertListFx,
    };

    const criteria = getEmptyCriteria(params.partner_key);

    for (const [key, value] of Object.entries(params)) {
        const convertFx = conversionMap[key];
        if(convertFx) {
            convertFx(criteria, key, value, params);
        }
        else {
            criteria[key] = params[key] || criteria[key];
        }
    }

    return criteria
};
