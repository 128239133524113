import React, {useContext} from "react";
import Collapsible from 'react-collapsible';
import {ClientAssociation} from "./client_association";
import {ListingType, OwnershipType} from "../../../../services/api/listing";
import {ListingSearchContext} from "../../../components/listing_search/listing_search_context";
export function Alert(props) {
    const {
        alert,
        handleDrawerOpen,
        handleDelete,
        handleAssociateClient,
        handleDissociateClient
    } = props;

    return (
        <div className="alert-wrapper zebra-stripes">
            <div className="alert-title">{alert.criteria_name}</div>
            <ClientAssociation alert={alert}
                               handleAssociateClient={handleAssociateClient}
                               handleDissociateClient={handleDissociateClient}
            />
            <CriteriaSummary alert={alert} />
            <div className="actions">
                <span className="action" onClick={handleDrawerOpen}>EDIT</span>
                <span className="divider">|</span>
                <span className="action" onClick={() => handleDelete(alert) }>DELETE</span>
            </div>
        </div>
    )
}

function CriteriaSummary(params) {
    const {alert} = params;
    const {getFilterOptions} = useContext(ListingSearchContext);

    const getStatuses = () => {
        return alert.search_params.statuses.join(', ');
    };

    const getLocations = () => {
        const fields = ["address", "city", "metro", "neighborhood", "sub-neighborhood", "state", "zip"];
        const locations = [];
        const multipleAreas = alert.search_params.multiple_areas;

        multipleAreas.forEach( area => {
            const location = [];

            fields.forEach(field => {
                const val = area[field];
                if (val) {
                    location.push(val);
                }
            });

            if(location.length) {
                locations.push(location);
            }
        });

        return locations.length ?
            <ul className="sub-list">
                {
                    locations.map( location => {
                        return <li key={location}>{location.join(", ")}</li>
                    })
                }
            </ul> : null
    };

    const getRentRange = () => {
        const sP = alert.search_params;
        const min = sP.min_price;
        const max = sP.max_price;
        if(0 === min && max >= 29999) {
            return false;
        }
        const maxStr = max && max < 29999 ? max : "";
        const minStr = 0 === min ? "" : min;
        return ("" !== minStr && "" !== maxStr) ? `${minStr}-${maxStr}` : null;
    };

    const getLeaseRateRange = () => {
        const sP = alert.search_params.lease_rate;
        const min = sP?.min;
        const max = sP?.max;
        if(0 === min && max >= 29999) {
            return false;
        }
        const maxStr = max && max < 29999 ? max : "";
        const minStr = 0 === min ? "" : min;
        return ("" !== minStr && "" !== maxStr) ? `${minStr}-${maxStr}` : null;
    };

    const getTotalSqFtRange = () => {
        const sP = alert.search_params.sqft_range;
        const min = sP?.min_sq_ft;
        const max = sP?.max_sq_ft;
        if(0 === min && max >= 29999) {
            return false;
        }
        const maxStr = max && max < 29999 ? max : "";
        const minStr = 0 === min ? "" : min;
        return ("" !== minStr && "" !== maxStr) ? `${minStr}-${maxStr}` : null;
    };

    const getFinishedSqFtRange = () => {
        const sP = alert.search_params.finished_square_footage;
        const min = sP?.min;
        const max = sP?.max;
        if(0 === min && max >= 29999) {
            return false;
        }
        const maxStr = max && max < 29999 ? max : "";
        const minStr = 0 === min ? "" : min;
        return ("" !== minStr && "" !== maxStr) ? `${minStr}-${maxStr}` : null;
    };

    const getBedsRange = () => {
        const sP = alert.search_params.bedroom_range;
        const min = sP?.min_bedrooms;
        const max = sP?.max_bedrooms;
        let minStr = (typeof min == 'undefined' || min === null) ? 0 : (min === '0' ? 'Studio' : min)
        let maxStr = (typeof max == 'undefined' || max === null) ? '' : (max === '0' ? 'Studio' : max)
        return minStr === maxStr || maxStr === '' ? `${minStr}+` : `${minStr}-${maxStr}`
    };

    const getBathsRange = () => {
        const sP = alert.search_params.bathroom_range;
        const min = sP?.min_bathrooms;
        const max = sP?.max_bathrooms;
        let minStr = (typeof min == 'undefined' || min === null) ? '0' : min
        let maxStr = (typeof max == 'undefined' || max === null) ? '0' : max
        return minStr === maxStr || maxStr === '0' ? `${minStr}+` : `${minStr}-${maxStr}`
    };

    const getListingType = () => {
        const labelMap = {};
        labelMap[ListingType.mls] = "MLS Only";
        labelMap[ListingType.rb] =  "Exclude MLS";
        labelMap[ListingType.agency_exclusive] = "Agency Exclusive";
        labelMap[ListingType.agent_listings] =  "My Listings";
        labelMap[ListingType.other_agent_listings] = "Another Agent's Listings";

        return labelMap[alert.search_params.listing_type] || null;
    };

    const getAvailableByStr = () => {
        const sP = alert.search_params;
        const from = sP.available_from_date;
        const by = sP.available_by_date;
        return from && by ? `${from} to ${by}` : null
    };

    const getDateRangeByStr = (fieldName) => {
        const sP = alert.search_params[fieldName];
        const from = sP?.min;
        const by = sP?.max;
        return from && by ? `${from} to ${by}` : null
    };

    const getPublicTransitStr = () => {
        const obj = alert.search_params.public_transit;
        if (!obj) {return}
        if (obj.distance === -1 || obj.types.size === 0) {return}

        const distanceMap = {
            .01: "50 feet",
            .05: "250 feet",
            .1: "500 feet",
            .25: "Quarter mile",
            .5: "Half mile",
            .75: "Three-quarter mile",
            1: "One mile",
            1.5: "One-and-a-half miles",
            2: "Two miles",
            5: "Five miles",
            10: "Ten miles",
        };

        const options = getFilterOptions("public_transit");
        const labelMap = {};
        options.forEach(option => labelMap[option.value] = option.label);

        return `${distanceMap[obj.distance]}, ${labelMap[obj.types]}`
    };

    const getOwnershipType = () => {
        const labelMap = {};
        labelMap[OwnershipType.MANAGEMENT_COMPANY] = "Management Company";
        labelMap[OwnershipType.PRIVATE_OWNER] = "Private Owner";
        labelMap[OwnershipType.AGENT] = "Agent";

        return alert.search_params.ownership_type.map(option => labelMap[option]).join(", ") || null;
    };

    const getPhotos = () => {
        return alert.search_params.min_pic_count ? "Yes" : null // returns null if value is 0 in order to not display the filter under the "search filters" list for the alert
    };

    const getValue = (fieldName) => {
        const param = alert.search_params[fieldName];
        const isBoolean = "boolean" === typeof(param);
        if(isBoolean) {
            return param ? "Yes" : null // returns null if boolean is false in order to not display the filter under the "search filters" list for the alert
        }
        const isArray = Array.isArray(param);
        return (isArray || "number" === typeof(param) && param < 0) ? null : param // disregard array and negative numbers
    };

    const getListingFeaturesList = (fieldName, filterName, useLabelAsValue = false) => {
        const alertFilter = alert.search_params[fieldName];
        if(!alertFilter) {return}

        const options = getFilterOptions(filterName, useLabelAsValue);

        const labelMap = {};
        options.forEach(option => labelMap[option.value] = option.label);

        const optionListItems = alertFilter.map( alertOption => {
            return <li key={`option_${alertOption}`}>{labelMap[alertOption]}</li>
        });

        return <ul className="sub-list">{optionListItems}</ul>
    };

    const filters = [
        {label: "Location(s)", valueFx: () => getLocations() },
        {label: "Rent", valueFx: () => getRentRange() },
        {label: "Bedrooms", valueFx: () => getBedsRange()},
        {label: "Bathrooms", valueFx: () => getBathsRange()},
        {label: "Status", valueFx: () => getStatuses()},
        {label: "Date available", valueFx: () => getAvailableByStr()},
        {label: "Date active", valueFx: () => getDateRangeByStr("date_active")},
        {label: "Agent", valueFx: () => getValue("agent_name")},
        {label: "Attached Dwelling", valueFx: () => getValue("attached_to_another_dwelling")},
        {label: "Building features", valueFx: () => getListingFeaturesList("property_features", "property_feature")},
        {label: "Building type", valueFx: () => getListingFeaturesList("building_type", "property_type", true)},
        {label: "Contact Phone", valueFx: () => getValue("direct_phone")},
        {label: "Cooling type", valueFx: () => getListingFeaturesList("ac_type", "ac_type", true)},
        {label: "External structures", valueFx: () => getListingFeaturesList("external_structures", "external_structure")},
        {label: "Finished Square footage", valueFx: () => getFinishedSqFtRange()},
        {label: "Floors in Unit", valueFx: () => getListingFeaturesList("floors_in_unit_list", "floors_in_unit", false)},
        {label: "Heat type", valueFx: () => getListingFeaturesList("heat_type", "heat_type", true)},
        {label: "Lease Date", valueFx: () => getDateRangeByStr("lease_date")},
        {label: "Lease Rate", valueFx: () => getLeaseRateRange()},
        {label: "Min. commission", valueFx: () => getValue("min_commission")},
        {label: "MLS/agency", valueFx: () => getListingType()},
        {label: "Other unit features", valueFx: () => getListingFeaturesList("unit_features", "unit_feature")},
        {label: "Owner pays fee", valueFx: () => getListingFeaturesList("owner_pay", "owner_pay", true)},
        {label: "Ownership type", valueFx: () => getOwnershipType()},
        {label: "Parking", valueFx: () => getListingFeaturesList("parking_options", "parking_option", true)},
        {label: "Pet Types", valueFx: () => getListingFeaturesList("pet_types", "pet_type")},
        {label: "Photos", valueFx: () => getPhotos()},
        {label: "Public transit", valueFx: () => getPublicTransitStr()},
        {label: "RB/MLS ID", valueFx: () => getValue("rb_mls_id")},
        {label: "Renters insurance", valueFx: () => getValue("renter_insurance")},
        {label: "Room Type", valueFx: () => getListingFeaturesList("room_types", "room_types")},
        {label: "Services & Utilities", valueFx: () => getListingFeaturesList("utilities_available", "utility")},
        {label: "Square footage", valueFx: () => getValue("min_sq_ft")},
        {label: "Total Square footage", valueFx: () => getTotalSqFtRange()},
        {label: "Virtual showings", valueFx: () => getValue("virtual_showings_allowed")},
        {label: "Virtual tour", valueFx: () => getValue("virtual_tour_url")},
    ];

    const getTriggerMarkup = (isOpen = false) => {
        return (
            <div className="search-filters-row">
                <i className={`fas ${isOpen ? "fa-caret-down" : "fa-caret-right"}`} />
                <div className="title">Search filters</div>
            </div>
        )
    };

    return (
        <div>
            <Collapsible trigger={getTriggerMarkup()} triggerWhenOpen={getTriggerMarkup(true)}>
                <ul className="filters-list">
                    {
                        filters.map( filter => {
                            const value = filter.valueFx();
                            return value ?
                            <li key={`filter_${filter.label}`} className="search_param">
                                <label>{filter.label}:</label>
                                <span>{value}</span>
                            </li> : null
                        })
                    }
                </ul>
            </Collapsible>
        </div>
    )
}
