import {REST} from "../rest";
import {apiURL} from "../constants";
import {getArrayOrStringParamValue} from "./listing";

const API_URL = apiURL + '/v1';

export const listingStatsApi = {
    get: async (params: IListingStatsApiGetParams) => {
        return await REST.get(`${API_URL}/listing_stats.json`,
            {
                headers: {
                    'Content-Type': 'application/json'
                },
            params: {
                    ...params,
                city: getArrayOrStringParamValue(params, "city"),
                gen_neighborhood: getArrayOrStringParamValue(params, "gen_neighborhood"),
                state: getArrayOrStringParamValue(params, "state"),
                }
            }).then(res => {
            return res
        });
    }
};

interface IListingStatsApiGetParams{
    state?: string;
    city?: string | string [];
    zip_codes?: number;
    gen_neighborhood?: string | string [];
    unitNumber?: string;
    min_price?: number;
    max_price?: number;
    min_bedrooms?: number;
    min_bathrooms?: number;
}