import React, {useContext, useState} from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { ListingSearchContext } from '../../../../components/listing_search/listing_search_context';
import { RowsPerPagePagination } from './map_view_config/MapRowsPerPage';
import { isMobile } from '../../../../utils/device_info';

function Alert(props : any) {
    return <MuiAlert variant="filled" {...props} />;
}

export const MapToast = React.memo(
    function MapToastComponent() {
        const [open, setOpen] = useState(true);
        const {
            tabChosen,
            allCount,
            rbCount,
            mlsCount,
            isPaginatedFromMapListingArea,
            isPaginatedFromMapArea
        } = useContext(ListingSearchContext);
        let alertListingCount = 0;
        switch (tabChosen) {
            case 'all':
                alertListingCount = allCount;
                break;
            case 'mls':
                alertListingCount = mlsCount;
                break;
            case 'rb':
                alertListingCount = rbCount;
                break;
        }

        const handleClose = () => {
            setOpen(false);
        };

        return (
            <>
                {
                    ((tabChosen === 'all' && allCount > RowsPerPagePagination.LIMIT ) ||
                    (tabChosen === 'mls' && mlsCount > RowsPerPagePagination.LIMIT) ||
                    (tabChosen === 'rb' && rbCount > RowsPerPagePagination.LIMIT))
                    && !isPaginatedFromMapListingArea
                    && !isPaginatedFromMapArea
                    && (
                        <div style={{ transform: isMobile() ? "translate(0px, -780px)" : "translate(-410px, -780px)" }}>
                            <Snackbar open={open} autoHideDuration={12000} onClose={handleClose}>
                                <Alert onClose={handleClose} severity="info" className="map-alert" style={{ backgroundColor: "#337ab7" }}>
                                  Showing {RowsPerPagePagination.LIMIT} of {alertListingCount} listings
                                </Alert>
                            </Snackbar>
                        </div>
                    )
                }
            </>
        );
    }
)
