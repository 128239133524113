import React, {useContext} from "react";
import {Checkbox, FormControlLabel} from "@material-ui/core";
import {ListingSearchContext} from "../../listing_search_context";

export function FieldsetOnlyPhotos () {
    const {criteria, setCriteria} = useContext(ListingSearchContext);

    const handleChange = () => {
        setCriteria({...criteria, min_pic_count: criteria.min_pic_count > 0 ? 0 : 1});
    };

    return (
        <fieldset className="only-photos--container">
            <label className="wide-only">Photos</label>
            <span className="separator" />
            <FormControlLabel
                control={<Checkbox checked={criteria.min_pic_count > 0} onChange={handleChange}/>}
                label={`Only include listings with pictures`}
            />
        </fieldset>
    )
}