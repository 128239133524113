import React, {useContext} from "react";
import {ListingSearchContext} from "../../listing_search_context";
import "./fieldset_garage_spaces.scss";

export function FieldsetGarageSpaces () {
    const {criteria, setCriteria} = useContext(ListingSearchContext);

    const handleChange = (event) => {
        const select = event.currentTarget;
        const index = select.selectedIndex;
        const val = parseInt(select.options[index].value);
        setCriteria({...criteria, garage_spaces: val});
    };

    return (
        <fieldset className="fieldset--garage-spaces">
            <label>Number of Garage spaces</label>
            <select onChange={handleChange} value={criteria.garage_spaces} className="full-span">
                <option value={-1} >Select</option>
                <option value={1} >1</option>
                <option value={2} >2</option>
                <option value={3} >3</option>
                <option value={4} >4</option>
                <option value={5} >5</option>
                <option value={6} >6</option>
                <option value={7} >7</option>
                <option value={8} >8</option>
                <option value={9} >9</option>
            </select>
        </fieldset>
    )
}