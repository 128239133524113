import React, {useContext, useEffect, useState} from "react";
import {FieldsetMultipleSelect} from "../multiple_select/fieldset_multiple_select";
import {ListingSearchContext} from "../../listing_search_context";

export function FieldSetLaundryType() {
    const {getFilterOptions, filterConfig, criteria} = useContext(ListingSearchContext);
    const [options, setOptions] = useState([]);
    const [optionArr, setOptionArr] = useState(criteria.laundry_type_list);

    useEffect(()=>{
        setOptions(getFilterOptions("laundry", true));
    },[filterConfig]);

    useEffect(()=>{
        setOptionArr(criteria.laundry_type_list);
    }, [criteria.laundry_type_list]);

    return <FieldsetMultipleSelect
        options={options}
        optionArr={optionArr}
        setOptionArr={setOptionArr}
        title="Laundry"
        criteriaFieldName="laundry_type_list"
    />
}