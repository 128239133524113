import React, {useContext} from "react";
import "./listings_controls.scss";
import {Button, ButtonGroup} from "@material-ui/core";
import {BrokerSearchingContext} from "../../../features/broker_search/broker_search_context";
import {BookmarksContext} from "../../../features/broker_search/bookmarks_context";
import {ViewType} from "../../../utils/device_info";
import ViewListIcon from '@material-ui/icons/ViewList';
import BookmarksIcon from '@material-ui/icons/Bookmarks';
import { ListingSearchContext } from "../listing_search_context";
import {FilterPills} from "../search_filters/filter_pills";
import {SelectedListingsContext} from "../../../features/broker_search/selected_listings_context";
import { UsePartnerConfig } from '../../hooks/use_partner_config';
import { AgentUserContext } from '../../../features/broker_search/agent_user_context';
import { MapIconMobile } from '../../../../assets/MapIconMobile';

export function ListingsControls () {
    const { criteria, setCriteria, setIsListingControlClicked } = useContext(ListingSearchContext)
    const {bookmarksCount} = useContext(BookmarksContext);
    const {viewType, setViewType, isMobileRes, setSearching} = useContext(BrokerSearchingContext);
    const {setRowPerPageRenderer} = useContext(SelectedListingsContext);
    const {hideMapSearchView} = UsePartnerConfig();
    const {isFullAgent, isMlsAgent} = useContext(AgentUserContext);

    return (
        <div className="listings-controls--container listings-controls-config--container">
            {
                !isMobileRes ?
                <ButtonGroup disableElevation variant="contained" color="primary">
                    <Button
                        onClick={() => {
                            setViewType(ViewType.LIST)
                            setSearching(true);
                        }}
                        className={`button-view ${viewType === ViewType.LIST ? "active" : ""}`}
                    >
                        {ViewType.LIST}
                    </Button>
                    <Button
                        onClick={() => {
                            setViewType(ViewType.TILE)

                            // temporary solution for limiting the page per items
                            setCriteria({...criteria, page_number: 1, max_record_count: criteria?.max_record_count > 25 ? 10 : criteria?.max_record_count});
                            console.log("Searching");
                            setSearching(true);
                            setRowPerPageRenderer((Math.random() + 1).toString(36).substring(7))
                            // end
                        }}
                        className={`button-view ${viewType === ViewType.TILE ? "active" : ""}`}
                    >
                        {ViewType.TILE}
                    </Button>
                    {
                        ((isFullAgent() && !hideMapSearchView) ||
                        (isMlsAgent() && !hideMapSearchView)) &&
                        <Button onClick={
                            () => {
                                setViewType(ViewType.MAP);
                                setSearching(true);
                            }
                        } className={`button-view ${viewType === ViewType.MAP ? "active" : ""}`}>
                            Map
                        </Button>
                    }
                    <Button onClick={() => setViewType(ViewType.BOOKMARKS)} className={`button-view ${viewType === ViewType.BOOKMARKS ? "active" : ""}`}>Bookmarks ({bookmarksCount > 0 ? bookmarksCount : 0})</Button>
                </ButtonGroup>
                :
                <ButtonGroup disableElevation variant="contained" color="primary">
                    {
                        ((isFullAgent() && !hideMapSearchView) ||
                        (isMlsAgent() && !hideMapSearchView)) &&
                        <Button onClick={() => {
                            setViewType(ViewType.MAP);
                            setIsListingControlClicked(true);
                        }} className={`button-view ${viewType === ViewType.MAP ? "active" : ""}`}>
                            <MapIconMobile/>
                        </Button>
                    }
                    <Button onClick={() => {
                        setViewType(ViewType.TILE);
                        setIsListingControlClicked(true);
                    }} className={`button-view ${viewType === ViewType.TILE ? "active" : ""}`}>
                        <ViewListIcon/>
                    </Button>
                    <Button onClick={() => {
                        setViewType(ViewType.BOOKMARKS);
                        setIsListingControlClicked(true);
                    }} className={`button-view ${viewType === ViewType.BOOKMARKS ? "active" : ""}`}>
                        <BookmarksIcon/>
                    </Button>
                </ButtonGroup>
            }
            <FilterPills/>
        </div>
    )
}
