import {REST} from "../rest";
import {rbApiUrl} from "../constants";
import {getAuthToken} from "../session";

export const agentAutoCompleteApi = {
    get : async (matchString, count = 10, partnerKey = '', agentId = null) => {
        const authToken = await getAuthToken();
        const endPoint = `${rbApiUrl}/autocomplete/agent`
        const searchFilters = `?search=${matchString}&limit=${count}&partner_key=${partnerKey}`
        let url = [endPoint, agentId].filter(Boolean).join("/")
        url = [url, searchFilters].filter(Boolean).join("")
        return await REST.get(url,
            {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Token token=${authToken}`,
                }
            }).then(res => {
                return res.data?.suggestions;
        });
    }
};
