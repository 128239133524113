import React, {useContext, useEffect, useState} from "react";
import {Checkbox, FormControlLabel, Select} from "@material-ui/core";
import {ListingSearchContext} from "../../listing_search_context";
import "./fieldset_virtual_showings_tour.scss";

export function FieldsetVirtualShowingsTour () {
    const {criteria, setCriteria} = useContext(ListingSearchContext);
    const [selection, setSelection] = useState(criteria.virtual_showing_allowed);
    const {getFilterOptions, filterConfig} = useContext(ListingSearchContext);
    const [options, setOptions] = useState([]);

    useEffect(()=>{
        setOptions(getFilterOptions("virtual_showing_allowed"));
    },[filterConfig]);

    useEffect(()=>{
        setSelection(criteria.virtual_showing_allowed);
    },[criteria.virtual_showing_allowed]);

    const handleShowingsChange = (e) => {
        const selected = e.currentTarget.value;
        setSelection(selected);
        setCriteria({...criteria, virtual_showing_allowed: selected});
    };

    const handleToursChange = (e) => {
        setCriteria({...criteria, virtual_tour_url: !criteria.virtual_tour_url});
    };

    return (
        <fieldset className="fieldset--select-container virtual-showings-tour--container">
            <label className="wide-only">Virtual Showings & Tour</label>
            <span className="separator" />
            <div className="showings-filter-cont">
                <label>Virtual Showings</label>
                <Select
                    native
                    value={selection}
                    onChange={handleShowingsChange}
                >
                    <option aria-label="None" value="" >Any</option>
                    {
                        options.map((option) => (
                            <option value={option.value} key={option.value}>{option.label}</option>
                        ))
                    }
                </Select>
            </div>
            <div className="tour-filter-cont">
                <FormControlLabel
                    control={<Checkbox checked={criteria.virtual_tour_url} onChange={handleToursChange}/>}
                    label={`Has a virtual tour URL`}
                />
            </div>
        </fieldset>
    )
}