import {REST} from "../rest";
import { rbApiUrl } from "../constants";

export const schoolAutoCompleteApi = {
    get: async (props: ISchoolAutoCompleteGet) => {

        return await REST.get(`/api/schools`,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                params: {
                    state: props.state,
                    city: props.city,
                    search: props.matchString,
                    schoolLevel: props.schoolLevel,
                }
            }).then(res => {
                if(res.data && res.data.length) {
                    return res.data.map(({ id, schName, schoolYear }) => ({ id, schName, schoolYear }));
                }
                else {
                    return [];
                }
        });
    }
};

export const schoolDistrictApi = {
    post: async (geolocationData: ISchoolDistrictGet) => {
        return await REST.post(
            `${rbApiUrl}/schooldistrict/coordinates`,
            geolocationData, 
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        ).then(res => {
            return res.data
        }).catch(err => console.error(err))
    }
}

export const subdivisionApi = {
    post: async (geolocationData: ISubdivisionGet) => {
        return await REST.post(
            `${rbApiUrl}/subdivision/coordinates`,
            geolocationData,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        ).then(res => {
            return res.data
        }).catch(err => console.error(err))
    }
}

export const schoolsApi = {
    post: async (payload: ISchoolsGet) => {
        return await REST.post(
            `${rbApiUrl}/schools`,
            payload, 
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        ).then(res => {
            return res.data
        }).catch(err => console.error(err))
    }
}

interface ISchoolAutoCompleteGet {
    matchString: string;
    state?: string;
    city?: string;
    schoolLevel?: string;
}

interface Coordinates {
    latitude: number;
    longitude: number;
}

interface ISchoolDistrictGet {
    point1?: Coordinates;
    point2?: Coordinates
}

interface ISubdivisionGet {
    point1?: Coordinates;
    point2?: Coordinates
}

export type ISchoolSelectors = 'elementary' | 'middle' | 'high' | 'private' | 'public' | 'charter'

interface ISchoolsGet extends ISchoolDistrictGet {
    schoolSelectors?: ISchoolSelectors[]
}

export interface ISchoolLevel {
    id: number,
    schName: string,
    schoolYear?: string
}

export const emptySchoolOptionObj = {
    id: null,
    schName: null,
    schoolYear: null,
};
