import React, { useContext } from "react";
import { getStateAbbr } from "../../../../../utils/get_state_abbr";
import { apiURL, imgUrl } from "../../../../../../services/constants";
import "../../detail_search_results/listing_details/listing_details.scss";
import { IListing } from "../../../../../../services/api/listing";
import { SelectedListingsContext } from "../../../selected_listings_context";
import { AgentUserContext } from "../../../agent_user_context";
import { RBCheckbox } from "../../../../../components/rb_checkbox/rb_checkbox";
import { BookmarksContext } from "../../../bookmarks_context";

export function BookmarksTileView(props: IListingDetails) {
  const { isFullAgent, countryCode } = useContext(AgentUserContext);
  const { handleSelectedListing, isMlsAgentAndNonMlsListing } = useContext(SelectedListingsContext);
  const { toggleBookmarks } = useContext(BookmarksContext);

  const { listing } = props;

  let url = "";

  if (listing?.pics?.length > 0) {
    url = `url('${imgUrl}/${listing.pics[0].path}?w=500')`;
  }

  const handleClick = (listing) => {
    if (isFullAgent()) {
      const listingUrl = `${apiURL}/broker-apt-details?objectid=${listing.apartment_id}`;
      window.open(listingUrl, "_blank");
    }
  };

  const handleRowCheck = () => {
    handleSelectedListing(
      listing.apartment_id,
      !props.checked,
      listing.apartment_id,
      props.bookmarks
    );
  };

  const showOrHideAddress = (listing) => {
    if (isMlsAgentAndNonMlsListing(listing)) {
      return (
        <div>
          <div>
            {listing.city}, {getStateAbbr(listing.state, countryCode)} {listing.zip}
            <i
              onClick={(e) => toggleBookmarks(e, [listing.apartment_id])}
              className="fas fa-bookmark title--bookmark"
            />
          </div>
          <div className="upgrade--text">
            <a href="/agent/subscriptions_and_payment">Upgrade</a> to see
            address
          </div>
        </div>
      );
    } else {
      return (
        <div>
          {listing.address}, {listing.city}, {getStateAbbr(listing.state, countryCode)}{" "}
          {listing.zip}
          <i
            onClick={(e) => toggleBookmarks(e, [listing.apartment_id])}
            className="fas fa-bookmark title--bookmark"
          />
        </div>
      );
    }
  };

  return (
    <div key={listing.apartment_id} className="detail--row col-lg-6">
      <div
        className="col-12 tile-box pl-0 pr-0"
        onClick={() => {
          handleClick(listing);
        }}
      >
        <div className="col-12 col-md-4 col-lg-12 col-xl-4 pl-0 pr-0">
          <div className="pictures__module">
            <div className="picture__container">
              <div
                className="picture__container--bg"
                style={{ backgroundImage: url }}
              >
                <div className="picture__container--counter">
                  <i className="fas fa-camera" />
                  <span>{listing.pics.length}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-12 col-lg-12 col-xl-8 pl-4">
          <div className="row mb-0">
            <div
              className="address__container col-12"
              style={{ cursor: "pointer" }}
            >
              <RBCheckbox
                name={listing.apartment_id.toString()}
                checked={props.checked}
                handleChange={(changed) => handleRowCheck()}
              />
              {showOrHideAddress(listing)}
            </div>
          </div>
          <div className="row">
            <div className="col-6 col-md-3 col-lg-6">
              <div className="feature--label">Beds: {listing.beds_count}</div>
              <div className="feature--label">Baths: {listing.bathrooms}</div>
              <div className="feature--label">Rent: {listing.rent}</div>
            </div>
            <div className="col-6 col-md-4 col-lg-6">
              <div className="feature--label">Status: {listing.status}</div>
              <div className="feature--label">
                Sq. Footage: {listing.square_footage}
              </div>
              <div className="feature--label">
                Available: {listing.date_available}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export interface IListingDetails {
  listing: IListing;
  bookmarks?: any,
  checked: boolean;
}
