export enum ViewType {
    TILE = "tile",
    LIST = "list",
    BOOKMARKS = "bookmarks",
    MAP = "map"
}

export const isMobile = () => {
    return 768 >= document.body.clientWidth;
};
