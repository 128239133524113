import React, {useContext, useEffect, useState} from "react";
import {Pagination} from "./pagination";
import "./styles/results.scss";
import {ListingTile} from "./listing_tile";
import {SearchingIcon} from "./searching_icon";
import {ConsumerSearchContext} from "./consumer_search_context";
import {SignUpModal} from "../../components/sign_up_modal/sign_up_modal";
import {getResultId, IResult} from "../../components/listing_search/utilities";

export function ResultsColumn({results}: {results: IResult[]}) {
    const [signUpModalParams, setSignUpModalParams] = useState({});
    const [openSignUpModal, setOpenSignUpModal] = useState(false);
    const {tileSearching, setTileSearching, submitForm} = useContext(ConsumerSearchContext);

    useEffect(() => {
    }, []);

    const openSignUpModalOpenWithParams = (formValues) => {
        setSignUpModalParams(formValues);
        setOpenSignUpModal(true);
    };

    const getResultsContent = (results, tileSearching) => {

        let content = results.map((result, index) => {
            const paddingPos = (0 === index % 2) ? "left" : "right";
            const resultId = getResultId(result);
            return <ListingTile paddingPosition={paddingPos}
                                key={resultId}
                                contextListings={result.listings}
                                listings={result.listings}
                                resultId={resultId}
                                openSignUpModal={openSignUpModalOpenWithParams}
            />
        });

        if (!content.length && !tileSearching) {
            content = <div className="no-listings-found">No listings matched your search criteria.</div>
        }

        return content;
    };

    const handleSignUpSuccess = () => {
        setTileSearching(true);
    };

    const landingUri = `${location.pathname + location.search}`;

    return (
        <section className="results-column-wrapper results-column-shown" id={"resultsCol"} >
            <SearchingIcon />
            <Pagination />
            <section className="scroller">{getResultsContent(results, tileSearching)}</section>
            <SignUpModal {...signUpModalParams}
                         open={openSignUpModal}
                         handleClose={() => setOpenSignUpModal(false)}
                         handleSuccess={handleSignUpSuccess}
                         landingURI={landingUri}
                         submitSignInForm={submitForm}
            />
        </section>
    )
}