import React from "react";

export const renderDataUi = (data: string[] | string) => {
  if (Array.isArray(data)) {
    return data.length > 0
      ? data.map((util, index) => {
          return (
            <span key={util} style={{ marginRight: 2 }}>
              {util + `${index === data.length - 1 ? "" : ", "}`}
            </span>
          );
        })
      : data[0];
  }
  return data;
};
