import React, {useContext, useEffect, useState} from "react";
import "../fieldsets.scss";
import {FieldsetMultipleSelect} from "../multiple_select/fieldset_multiple_select";
import {ListingSearchContext} from "../../listing_search_context";

export function FieldsetBuildingType() {
    const {getFilterOptions, filterConfig, criteria} = useContext(ListingSearchContext);
    const [options, setOptions] = useState([]);
    const [optionArr, setOptionArr] = useState(criteria.building_type_list);

    useEffect(()=>{
        setOptions(getFilterOptions("property_type", true));
    },[filterConfig]);

    useEffect(()=> {
        setOptionArr(criteria.building_type_list);
    }, [criteria.building_type_list]);

    return <FieldsetMultipleSelect
        options={options}
        optionArr={optionArr}
        setOptionArr={setOptionArr}
        title={"Property Type"}
        criteriaFieldName={"building_type_list"}
    />
}