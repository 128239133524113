import React, { useState } from 'react';
import applyNowLogo from "../../../../../assets/images/broker_portal/apply_now_logo.png";
import { DialogTitle, IconButton, DialogContent } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import {
  requiredPhoneValidation,
  requiredEmailValidation,
  requiredLastNameValidation,
  requiredFirstNameValidation
} from '../../../utils/validations';
import { IPostPutClientParams, agentClientsApi } from '../../../../services/rails_api/agent/clients';
import { getUserId } from '../../../../services/session';
import { Button } from "react-bootstrap";


const NewClientModal = ({ dialogClassess, closeModal, clientContactInfo, selectClient, getClients, newClientIndex}) => {
  const [email, setEmail] = useState('')
  const [confirmEmail, setConfirmEmail] = useState('')

  const quickAddClientValidationSchema = Yup.object().shape({
    first_name: requiredFirstNameValidation
      .min(2, 'First Name should have at least 2-50 characters long')
      .max(50, 'First Name should have at least 2-50 characters long')
      .matches(/^[\.a-zA-Z,'\-\ ]*$/, 'First Name should contain valid characters'),
    last_name: requiredLastNameValidation
      .min(2, 'Last Name should have at least 2-50 characters long')
      .max(50, 'Last Name should have at least 2-50 characters long')
      .matches(/^[\.a-zA-Z,'\-\ ]*$/, 'Last Name should contain valid characters'),
    phone: requiredPhoneValidation,
    email: requiredEmailValidation,
    confirm_email: Yup.string()
      .oneOf([Yup.ref('email'), email.toLowerCase(), confirmEmail.toLowerCase() === email.toLowerCase() && confirmEmail].filter(Boolean), 'Emails must match')
      .required('Confirm Email is required')
      // .test(
      //   'comfirmEmailCheck',
      //   'Emails must match',
      //   (value) => {
      //     console.log('>>>>>>', { value: value.toLowerCase(), email: email.toLowerCase() })
      //     if (value && email)
      //       return value?.toLowerCase() === email?.toLowerCase()
      //     else
      //       return true
      //   }
      // ),
  });

  const initialValues = {
    ...clientContactInfo,
    confirm_email: "",
  };

  const handleSubmit = async (values) => {
    const clientId = await getUserId();
    const payload: IPostPutClientParams = {
      client: {
        owner: parseInt(clientId),
        client_type: values.client_type,
        first_name: values.first_name,
        last_name: values.last_name,
        main_email: values.email,
        main_phone: values.phone,
        alternate_phone: "",
        street_address: "",
        city: "",
        state: "",
        zip_code: "",
        unit_count: 0,
        general_notes: "",
        status: 'Active'
      },
      agentId: parseInt(clientId)
    };

    const newClient = await agentClientsApi.post(payload);
    if (newClient) {
      payload.client.name = `${values.first_name} ${values.last_name}`,
      selectClient(newClientIndex, {value: {...payload.client, id: newClient?.data?.data?.id }})
      closeModal()
      getClients()
    }
  };

  const handleChangeEmail = (email, setFieldValue) => {
    setFieldValue('email', email.target.value)
    setEmail(email.target.value)
  }

  const handleConfirmChangeEmail = (confirmEmailVal, setFieldValue) => {
    setFieldValue('confirm_email', confirmEmailVal.target.value)
    setConfirmEmail(confirmEmailVal.target.value)
  }

  return (
    <React.Fragment>
      <DialogTitle className={dialogClassess.dialogTitle}>
        <div style={{position: 'absolute', right: 5, cursor: 'pointer', zIndex: 50}}>
              <IconButton aria-label="close" onClick={(e)=>{ closeModal() }}>
                <CloseIcon fontSize="large"/>
              </IconButton>
          </div>
        <div style={{ display: 'flex', position: 'relative', alignItems: 'center', gap: 5, padding: '20px 10px 0px' }}>
          <img src={applyNowLogo} width={'80'} />
          <div style={{ flex: 2 }}>
            <p style={{ fontSize: 20, color: '#0f6cf6', fontWeight: 'bold' }}>
              Client Contact Information
            </p>
          </div>
        </div>
        <hr style={{ margin: '10px 0px 5px', border: 0, borderTop: '5px solid #eeeeee' }} />
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={quickAddClientValidationSchema}
          onSubmit={(values, { setSubmitting }) => {
            const { confirm_email, ...submitValues } = values; // Exclude confirm_email from submission
            handleSubmit(submitValues);
            setSubmitting(false);
          }}
        >
          {({ handleSubmit, errors, touched, setFieldValue }) => (
            <form onSubmit={handleSubmit} className='add-client-form'>
              <Form.Row>
                <Form.Group as={Col}>
                  <label htmlFor="first_name" className='text-secondary'>
                    First Name <span style={{ color: '#ED4444' }}>*</span>
                  </label>
                  <Field type="text" name="first_name" className="form-control mb-3" />
                  {errors.first_name && touched.first_name && (
                    <div className="alert alert-danger">{errors.first_name}</div>
                  )}

                  <label htmlFor="last_name" className='text-secondary'>
                    Last Name <span style={{ color: '#ED4444' }}>*</span>
                  </label>
                  <Field type="text" name="last_name" className="form-control mb-3" />
                  {errors.last_name && touched.last_name && (
                    <div className="alert alert-danger">{errors.last_name}</div>
                  )}

                  <label htmlFor="phone" className='text-secondary'>
                    Phone <span style={{ color: '#ED4444' }}>*</span>
                  </label>
                  <Field type="text" name="phone" className="form-control mb-3" />
                  {errors.phone && touched.phone && (
                    <div className="alert alert-danger">{errors.phone}</div>
                  )}

                  <label htmlFor="email" className='text-secondary'>
                    Email <span style={{ color: '#ED4444' }}>*</span>
                  </label>
                  <Field onChange={value => handleChangeEmail(value, setFieldValue)} type="text" name="email" className="form-control mb-3" />
                  {errors.email && touched.email && (
                    <div className="alert alert-danger">{errors.email}</div>
                  )}

                  <label htmlFor="confirm_email" className='text-secondary'>
                    Confirm Email <span style={{ color: '#ED4444' }}>*</span>
                  </label>
                  <Field onChange={value => handleConfirmChangeEmail(value, setFieldValue)} type="text" name="confirm_email" className="form-control mb-3" />
                  {errors.confirm_email && touched.confirm_email && (
                    <div className="alert alert-danger">{errors.confirm_email}</div>
                  )}
                </Form.Group>
              </Form.Row>
              <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 5,
                marginBottom: 5
              }}>
                <button className='invite-applicant-btn' type="submit">Add Client</button>
              </div>
            </form>
          )}
        </Formik>
      </DialogContent>
    </React.Fragment>
  )
}

export default NewClientModal;
