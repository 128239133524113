import React, {useContext, useEffect, useState} from "react";
import {ListingSearchContext} from "../listing_search_context";
import _ from "lodash";
import "./filter_pills.scss";
import {getClearedFilters} from "../fieldsets/clear_filters/fieldset_clear_filters";

interface IPill {
    filterKeys: string[];
    label: string;
    isActive: boolean;
    inhibitRemove?: boolean;
}

export function FilterPills() {

    const [pills, setPills] = useState([]);
    const {criteria, setCriteria} = useContext(ListingSearchContext);
    const initialFilterState = getClearedFilters(criteria);
    const clearedFilters =  getClearedFilters(criteria);

    const getPills: () => IPill[] = () => {
        const locationFields = ["city", "metro", "neighborhood", "state", "sub-neighborhood", "zip"];
        const locationStringFields = ["metro", "sub-neighborhood", "zip"];
        const locationArrayFields = ["city", "neighborhood", "state"];
        return [
            {label: "Active Date", filterKeys: ["date_active"], isActive: checkDeepInequality("date_active")},
            {label: "Attached Dwelling", filterKeys: ["attached_to_another_dwelling"], isActive: comparePrimitiveInequality("attached_to_another_dwelling")},
            {label: "Agent", filterKeys: ["agent_id"], isActive: comparePrimitiveInequality("agent_id")},
            {label: "Baths", filterKeys: ["bathroom_range"], isActive: checkObjectInequality("bathroom_range")},
            {label: "Beds", filterKeys: ["bedroom_range"], isActive: checkObjectInequality("bedroom_range")},
            {label: "Building feat.", filterKeys: ["property_features"], isActive: compareArrayInequality("property_features")},
            {label: "Building type", filterKeys: ["building_type_list"], isActive: compareArrayInequality("building_type_list")},
            // {label: "Cooling type", filterKeys: ["ac_type_list"], isActive: compareArrayInequality("ac_type_list")},
            {label: "Cooling type", filterKeys: ["cooling_type_list"], isActive: compareArrayInequality("cooling_type_list")},
            {label: "Contact Phone", filterKeys: ["direct_phone"], isActive: comparePrimitiveInequality("direct_phone")},
            {label: "Date avail.", filterKeys: ["available_by_date", "available_from_date"], isActive: comparePrimitiveInequality("available_by_date") && comparePrimitiveInequality("available_from_date")},
            {label: "Deleaded", filterKeys: ["deleaded"], isActive: comparePrimitiveInequality("deleaded")},
            {label: "DOM", filterKeys: ["days_on_market"], isActive: checkDeepInequality("days_on_market")},
            {label: "Elementary School", filterKeys: ["elementary_school"], isActive: checkIfUndefined("elementary_school")},
            {label: "External structures", filterKeys: ["external_structures"], isActive: compareArrayInequality("external_structures")},
            {label: "Finished SqFt", filterKeys: ["finished_square_footage"], isActive: checkObjectInequality("finished_square_footage")},
            {label: "Floor", filterKeys: ["floor"], isActive: comparePrimitiveInequality("floor")},
            {label: "Floor type", filterKeys: ["floor_type"], isActive: compareArrayInequality("floor_type")},
            {label: "Floors In Unit", filterKeys: ["floors_in_unit_list"], isActive: compareArrayInequality("floors_in_unit_list")},
            {label: "Furnished", filterKeys: ["furnished"], isActive: comparePrimitiveInequality("furnished")},
            {label: "Garage Spaces", filterKeys: ["garage_spaces"], isActive: comparePrimitiveInequality("garage_spaces")},
            {label: "Heat type", filterKeys: ["heat_type_list"], isActive: compareArrayInequality("heat_type_list")},
            {label: "High School", filterKeys: ["high_school"], isActive: checkIfUndefined("high_school")},
            {label: "Laundry", filterKeys: ["laundry_type_list"], isActive: compareArrayInequality("laundry_type_list")},
            {label: "Lease Date", filterKeys: ["lease_date"], isActive: checkDeepInequality("lease_date")},
            {label: "Lease Rate", filterKeys: ["lease_rate"], isActive: checkDeepInequality("lease_rate")},
            {label: "Lease Term", filterKeys: ["sublet_term"], isActive: comparePrimitiveInequality("sublet_term")},
            {label: "Location", filterKeys: locationFields, isActive: checkMultipleArrayFieldInequiality(locationArrayFields) && checkMultipleStringFieldInequality(locationStringFields)},
            {label: "Lot Sq ft.", filterKeys: ["lot_square_footage"], isActive: checkObjectInequality("lot_square_footage")},
            {label: "Middle School", filterKeys: ["middle_school"], isActive: checkIfUndefined("middle_school")},
            {label: "Min. commission", filterKeys: ["min_commission"], isActive: comparePrimitiveInequality("min_commission")},
            {label: "MLS/agency", filterKeys: ["listing_type"], isActive: comparePrimitiveInequality("listing_type")},
            {label: "Ownership type", filterKeys: ["ownership_type_list"], isActive: compareArrayInequality("ownership_type_list")},
            {label: "Other unit feat.", filterKeys: ["unit_features_list"], isActive: compareArrayInequality("unit_features_list")},
            {label: "Owner pays fee.", filterKeys: ["owner_pay_list"], isActive: compareArrayInequality("owner_pay_list")},
            {label: "Parking", filterKeys: ["parking_options_list", "garage"], isActive: compareArrayInequality("parking_options_list") || comparePrimitiveInequality("garage")},
            {label: "Pet types", filterKeys: ["pet_types_list"], isActive: compareArrayInequality("pet_types_list")},
            {label: "Pets allowed", filterKeys: ["pets"], isActive: comparePrimitiveInequality("pets")},
            {label: "Photos", filterKeys: ["min_pic_count"], isActive: comparePrimitiveInequality("min_pic_count")},
            {label: "Property Structure", filterKeys: ["property_structure"], isActive: comparePrimitiveInequality("property_structure")},
            {label: "Public transit", filterKeys: ["public_transit"], isActive: checkDeepInequality("public_transit")},
            {label: "RB/MLS ID", filterKeys: ["rb_mls_id"], isActive: comparePrimitiveInequality("rb_mls_id")},
            {label: "Rent", filterKeys: ["rent_range"], isActive: checkDeepInequality("rent_range")},
            {label: "Renters ins.", filterKeys: ["renter_insurance"], isActive: comparePrimitiveInequality("renter_insurance")},
            {label: "Room Type", filterKeys: ["room_types"], isActive: compareArrayInequality("room_types")},
            {label: "School District", filterKeys: ["school_district"], isActive: checkIfUndefined("school_district")},
            {label: "Services & Utils.", filterKeys: ["utilities_available"], isActive: compareArrayInequality("utilities_available")},
            {label: "Status", filterKeys: ["statuses_list"], isActive: true, inhibitRemove: true}, // all searches are subject to at least one status search param
            {label: "Square ft.", filterKeys: ["sqft_range"], isActive: checkDeepInequality("sqft_range")},
            {label: "Virtual showings", filterKeys: ["virtual_showing_allowed"], isActive: comparePrimitiveInequality("virtual_showing_allowed")},
            {label: "Virtual tour", filterKeys: ["virtual_tour_url"], isActive: comparePrimitiveInequality("virtual_tour_url")},
            {label: "Year Built", filterKeys: ["year_built"], isActive: comparePrimitiveInequality("year_built")},
        ];

    };

    /**
     * @description: This function compares the criteria field by key (primitive) against the cleared criteria object in order to
     * determine if the filter is pristine or has been touched.
     * @param key: name of the primitive criteria field that holds the value state of the UI filter
     * @returns boolean
     */
    const comparePrimitiveInequality = (key) => {
        const criteriaField = criteria[key];
        const clearedField = clearedFilters[key];
        if(criteriaField !== clearedField) {
            return true;
        }
    };

    /**
     * @description: This function compares the length of the criteria field by key (array) against the length of the cleared
     * criteria field in order to determine if the filter is pristine or has been touched.
     * @param key: name of the criteria field that holds the value state of the UI filter
     * @returns boolean
     */
    const compareArrayInequality = (key: string) => {
        const criteriaField = criteria[key];
        const clearedField = clearedFilters[key];
        const isArray = Array.isArray(criteriaField) && Array.isArray(clearedField);

        if(isArray && criteriaField.length !== clearedField.length) {
            return true;
        }
    };

    /**
     * @description: This function evaluates if any of the fields passed (in the fields (array) param) are different than the matching criteria fields
     * @param fields: an array of fields (strings) that match between the UI filter and the criteria fields
     * @returns boolean
     */
    const checkMultipleStringFieldInequality = (fields: string[]) => {
        for(let i=0; i<fields.length; i++) {
            const key = fields[i];
            if(criteria[key] !== clearedFilters[key]) {
                return true;
            }
        }
    };

    const checkMultipleArrayFieldInequiality = (fields: string[]) => {
        for(let i=0; i<fields.length; i++) {
            const key = fields[i];
            if (compareArrayInequality(key)) {
                return true;
            }
        }
    };

    /**
     * @description: This function does a deep object comparison of the criteria field by key against the cleared criteria object in order to
     * determine if the filter is pristine or has been touched.
     * @param key: name of the criteria field that holds the value state of the UI filter
     * @returns boolean
     */
    const checkDeepInequality = (key: string) => {
        return !_.isEqual(criteria[key], clearedFilters[key]);
    };

    /**
     * @description: This function replaces nulls with empty string and does deep object comparison of the criteria field by key against the cleared criteria object in order to
     * determine if the filter is pristine or has been touched.
     * @param key: name of the criteria field that holds the value state of the UI filter
     * @returns boolean
     */
    const checkObjectInequality = (key: string) => {
        let obj = criteria[key];
        Object.keys(obj).forEach(function(key) {
            if(obj[key] === null) {
                obj[key] = '';
            }
        })
        return !_.isEqual(criteria[key], clearedFilters[key]);
    };

    /**
     * @description: This function does a object comparison of the criteria field by key against the cleared criteria object in order to
     * determine even if the filter is undefined.
     * @param key: name of the criteria field that holds the value state of the UI filter
     * @returns boolean
     */
    const checkIfUndefined = (key: string) => {
        const criteriaField = criteria[key];
        const clearedField = clearedFilters[key];
        if(criteriaField === undefined && clearedField === "") {
            return false;
        }
        else {
            return comparePrimitiveInequality(key)
        }
    };

    const getPillCssClassName = (pill) => {
        return !pill.inhibitRemove ? "closable" : ""
    };

    useEffect(()=>{
        setPills(getPills());
    }, [criteria]);

    const handleClick = (e, pill) => {
        pill.filterKeys.forEach((key) => {
            criteria[key] = initialFilterState[key];
        });

        setPills(getPills());
        setCriteria({...criteria});
    };

    return (
        <div className="filter-pills--container">
            <span className="filters-title">Filters: </span>
            {
                pills.map((pill)=>{
                    if(pill.isActive) {
                        return <span className={`pill-container ${getPillCssClassName(pill)}`} key={pill.label} onClick={e => handleClick(e, pill)}>
                            {pill.label}
                            { !pill.inhibitRemove ? <i className="fas fa-times close-pill" /> : null }
                        </span>
                    }
                })
            }
        </div>
    )
}
