import React, {useContext, useEffect, useState} from "react";
import {ListingSearchContext} from "../../listing_search_context";
import "./fieldset_public_transit.scss";
import {Checkbox, Input, ListItemText, MenuItem, Select} from "@material-ui/core";

export function FieldsetPublicTransit () {

    const {getFilterOptions} = useContext(ListingSearchContext);
    const {criteria, setCriteria, filterConfig} = useContext(ListingSearchContext);

    const [distance, setDistance] = useState(criteria.public_transit?.distance);
    const [transitTypes, setTransitTypes] = useState(criteria.public_transit?.types);

    const [labelMap, setLabelMap] = useState({});
    const [showError, setShowError] = useState(false);
    const [typeChoices, setTypeChoices] = useState([]);

    const errorLabel = `Make sure both, distance and transit type are selected`;
    useEffect(()=>{
        setTypeChoices(getFilterOptions("public_transit"));
    },[filterConfig]);

    const handleDistanceChange = (event) => {
        const newVal = parseFloat(event.target.value);
        setCriteria({...criteria, public_transit: {distance: newVal, types: transitTypes}});
        setDistance(newVal);
    };

    const handleTypeChange = (event) => {
        setTransitTypes(event.target.value);
    };

    const handleTypeClose = () => {
        setCriteria({...criteria, public_transit: {distance: distance, types: transitTypes}});
    };

    const handleValidation = () => {
        const isPristine = -1 === distance && !transitTypes?.length;
        const bool = (-1 === distance || !transitTypes?.length) && !isPristine;
        setShowError(bool);
    };

    const getCheckedType = (option) => {
        return transitTypes &&  Boolean(transitTypes.includes(option))
    };

    const renderValueFx = (selected) => {
        if ((selected as string[]).length === 0) {
            return <em>Select</em>;
        }
        return getSelectLabel(selected);
    };

    const getSelectLabel = (selected) => {
        let arr = [];
        selected.forEach((key) => {
            arr.push(labelMap[key]);
        });
        return arr.join(", ");
    };

    const initLabelMap = () => {
        const map = {};
        typeChoices.forEach((option)=>{
            map[option.value] = option.label;
        });
        setLabelMap(map);
    };

    useEffect(() => {
        initLabelMap();
    }, [typeChoices]);

    useEffect(()=>{
        handleValidation();
    }, [transitTypes, distance]);

    useEffect(()=>{
        setTransitTypes(criteria.public_transit?.types);
        setDistance(criteria.public_transit?.distance);
    },[criteria.public_transit]);

    const distanceChoices = [
        {label: "50 feet", value: .01},
        {label: "250 feet", value: .05},
        {label: "500 feet", value: .1},
        {label: "Quarter mile", value: .25},
        {label: "Half mile", value: .5},
        {label: "Three-quarter mile", value: .75},
        {label: "One mile", value: 1},
        {label: "One-and-a-half miles", value: 1.5},
        {label: "Two miles", value: 2},
        {label: "Five miles", value: 5},
        {label: "Ten miles", value: 10},
    ];

    return (
        <fieldset className="fieldset--select-container public-transit--container">
            <label>Proximity to Public Transit</label>
            <div className="select-row">
                <div className="transit-distance-cont">
                    <Select
                        native
                        value={distance}
                        onChange={handleDistanceChange}
                    >
                        <option aria-label="None" value="-1" >Select</option>
                        {
                            distanceChoices.map((option) => (
                                <option value={option.value} key={option.value}>{option.label}</option>
                            ))
                        }
                    </Select>
                </div>
                <div className="transit-type-cont">
                    <Select
                        multiple
                        displayEmpty
                        value={transitTypes || []}
                        onChange={handleTypeChange}
                        onClose={() => handleTypeClose() }
                        renderValue={renderValueFx}
                        input={<Input />}
                        MenuProps={{
                            getContentAnchorEl: () => null,
                        }}
                        className="transit-type-select"
                    >
                    {
                        typeChoices.map((option) => (
                            <MenuItem key={option.label} value={option.value}>
                                <Checkbox checked={getCheckedType(option.value)} />
                                <ListItemText primary={option.label} />
                            </MenuItem>
                        ))
                    }
                    </Select>
                </div>
            </div>
            <label className={`errorLabel ${showError ? 'active' : ''}`}>{errorLabel}</label>
        </fieldset>
    )
}