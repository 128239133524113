import React, {useContext, useEffect, useState} from "react";
import {ListingSearchContext} from "../../listing_search_context";
import {Select} from "@material-ui/core";
import {ListingType} from "../../../../../services/api/listing";
import { useTranslation } from "react-i18next";

export function FieldsetListingType({ hideAgentSearch }) {
    const {criteria, setCriteria} = useContext(ListingSearchContext);
    const [selection, setSelection] = useState(criteria.listing_type);
    const { t } = useTranslation();
    const handleChange = (e) => {
        const selected = e.currentTarget.value;
        setSelection(selected);
        setCriteria({...criteria, listing_type: selected});
    };

    useEffect(()=>{
        setSelection(criteria.listing_type);
    }, [criteria.listing_type]);

    const listingTypeOptions = () => {
        const optionChoices = [
            {value: ListingType.mls, label: t('fieldset_listing_type.mls_only') },
            {value: ListingType.rb, label: t('fieldset_listing_type.exclude_mls')},
            {value: ListingType.agency_exclusive, label: "Agency Exclusive"},
            {value: ListingType.agent_listings, label: "My Listings"}
        ];
        if (!hideAgentSearch) {
            optionChoices.push({value: ListingType.other_agent_listings, label: "Another Agent's Listings"}); // this listing type is for FE purposes and gets translated to ListingType.agent_listings when submitting search query
        }
        return optionChoices;
    }

    return (
        <fieldset className="fieldset--select-container">
            <label>{t('MLS/Agency')}</label>
            <Select
                native
                value={selection}
                onChange={handleChange}
            >
                <option aria-label="None" value="">Any</option>
                {
                    listingTypeOptions().map((option) => (
                        <option value={option.value} key={option.value}>{option.label}</option>
                    ))
                }
            </Select>
        </fieldset>
    )
}