import React, {useContext} from "react";
import "./fieldset_search_button.scss";
import {ListingSearchContext} from "../../listing_search_context";
import {BrokerSearchingContext} from "../../../../features/broker_search/broker_search_context";

export function FieldsetSearchButton(props) {
    const {searching, setSearching, isMobileRes, handleValidateSearchFilters, noListingsFound} = props;
    const {criteria, setCriteria, setDrawShapePayload, setPaginatedFromMapListingArea} = useContext(ListingSearchContext);
    const {setPersistLastSearch} = useContext(BrokerSearchingContext);

    const handleSearchAction = () => {
        if(handleValidateSearchFilters) {
            if( !handleValidateSearchFilters() ) {
                return;
            }
        }
        setPersistLastSearch(true); // This flag is to avoid unnecessarily persisting the freshly loaded last_search (shortcut) when loaded and executed on page init
        setCriteria({...criteria, page_number: 1});
        if (noListingsFound) {
            setPaginatedFromMapListingArea(false)
            setDrawShapePayload({
                geometry: []
            })
        }
        setSearching(true);
    };

    return <div className={props.className}>
        <div className={"search-btn"} onClick={handleSearchAction} >
            <span id={"search-label"} style={{display: searching ? "none" : "inline-block"}}>
                <span style={{display: isMobileRes ? "none" : ""}} className="search-text" >SEARCH</span>
                <i className="fas fa-search"/>
            </span>
            <div className="loading-icon-wrapper" style={{display: searching ? "inline-block" : "none"}} >
                <div className="loading-circle-icon" />
            </div>
        </div>
    </div>
}