import React, {useContext, useEffect, useState} from "react";
import "./listing_action_buttons.scss";
import {SendMailAction} from "./send_email_action/send_mail_action";
import {AddShowingAction} from "./add_showing_action/add_showing_action";
import {ShareAction} from "./share_action/share_action";
import {Dialog} from "@material-ui/core";
import {imgUrl} from "../../../services/constants";
import {SweetAlertContext} from "../../components/sweet_alert/sweet_alert";
import {formatSettingsToLocal, IListing, IListingApiGetParams, listingApi} from "../../../services/api/listing";
import {ClaimListing} from "../broker_portal/claim_listing/claim_listing";
import {Drawer} from "../../components/drawer/drawer";
import {EFieldsToInclude} from "../../../services/api/listing";
import {handleShowingPut, getShowingsList} from "../../../services/api/showings";
import {ConsumerAgentInfo} from "../../../services/api/consumer";
import {isFullAgent, isMlsAgentAndMlsListing, isMLSListing, isRBListing} from "../../utils/listings";
import {isMlsAgentAndNonMlsListing} from "../../utils/listings";
import ReactDOM from "react-dom";
import {ListingsPrint} from "./print_listings_action/print_listings_action";
import {fetchListings} from "./action_utils";
import {create} from "jss";
import extend from "jss-plugin-extend";
import { BrokerSearchingContext } from '../broker_search/broker_search_context';
import { ViewType } from '../../utils/device_info';
import { browserName } from "react-device-detect";
import {AgentUserContext} from "../broker_search/agent_user_context";
import applyNowLogo from "../../../../assets/images/broker_portal/apply_now_logo.png"

const fieldsInclude = [
    EFieldsToInclude.type_contact,
    EFieldsToInclude.listing_agent_id,
    EFieldsToInclude.email,
    EFieldsToInclude.direct_phone
];

export function ListingActionButtons (props: IListingActionButtons) {

    const {selectedListingIds, isDisabled, listing, removeExcludeDeleteModal, actionFromMap = false} = props;
    const [isOpen, setIsOpen] = React.useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [listingToClaim, setListingToClaim] = useState(null);
    const [agentConfig, setAgentConfig] = useState({} as ConsumerAgentInfo);
    const {
        triggerAlert,
        setAlertVisible,
        setAlertType,
        setAlertContent,
        setSweetAlertProps,
        handleCloseSweetAlert
    } = useContext(SweetAlertContext);
    const { viewType } = useContext(BrokerSearchingContext);
    const {agentId, agentInfo, consumerInfo} = useContext(AgentUserContext);

    const fetchAgentInfo = async () => {
        if(consumerInfo != null && Object.keys(consumerInfo).length > 0){
            setAgentConfig({
                cc_settings: consumerInfo.agent_info.cc_settings,
            })
        }
    }

    useEffect(() => {
        fetchAgentInfo();
    }, [consumerInfo]);

    const createOrUpdateBookmarks = async (e, listingIds) => {
        await props.toggleBookmarks(e, listingIds);
    };

    const onSuccess = () => {
        setIsOpen(false);
        props.onSuccess()
    };

    const printListings = (listingIds) => {
        const win = window.open("", `Listings Report ${listingIds}`, "_blank, width=825, height=910");
        win.document.head.innerHTML = `<div id='style-container'>`;
        win.document.body.innerHTML = `<div id='report-container${listingIds}'>`;

        const jss = create();
        jss.use(extend());
        jss.setup({insertionPoint: win.document.getElementById("style-container")});


        fetchListings(listingIds).then(async (listingsRes) => {
            const listings = listingsRes.data.data.map(listing => formatSettingsToLocal(listing));

            ReactDOM.render(
                <ListingsPrint {...{selectedListings: listings, agentInfo: agentInfo, jss: jss}}/>,
                win.document.getElementById(`report-container${listingIds}`)
            )
        });
    };

    const openModalWithContent = (e, action: string) => {
        console.log('@@@@@ action: ', action);
        e.preventDefault();
        e.stopPropagation();
        if (selectedListingIds.length < 1) {
            return;
        }

        switch (action) {
            case 'email':
                setModalContent(<SendMailAction {...{selectedListingIds: props.selectedListingIds, onSuccess, ccDefault: agentConfig.cc_settings?.share_email?.is_on}} />);
                break;
            case 'print':
                // setModalContent(<PrintReportsAction {...{selectedListingIds: props.selectedListingIds}}/>);
                printListings(props.selectedListingIds)
                break;
            case 'showing':
                setModalContent(<AddShowingAction {...{selectedListingIds: props.selectedListingIds, onSuccess}}/>);
                break;
            case 'share':
                setModalContent(<ShareAction {...{selectedListingIds: props.selectedListingIds, onSuccess}}/>);
                break;
        }

        if (action !== 'print') setIsOpen(true);
    };

    const openNewTabWithListing = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (selectedListingIds.length < 1) {
            return;
        }
        window.open(`/broker_portal/agent/${agentId}/cma_tool/new?comparison_listing_ids=${selectedListingIds}`)
    };

    const getClaimImage = () => {
        return isDisabled ? `${imgUrl}/icons/claim_icon_gray.png` : `${imgUrl}/icons/claim_icon_blue.png`
    };

    const handleCMARequireText = () => {
        triggerAlert('info', 'Please select comparable listings to use the CMA tool', 'Select Comparables')
    }

    const handleActionClick = (action, e) => {
        removeExcludeDeleteModal && removeExcludeDeleteModal()
        if (action === 'cma' && isDisabled) {
            handleCMARequireText()
        }
        if(isDisabled) {return}

        switch (action) {
            case 'bookmark':
                createOrUpdateBookmarks(e, props.selectedListingIds)
                // formatPlans();
                break;
            case 'claim':
                e.stopPropagation();
                handleClaim();
                break;
            case 'email':
                openModalWithContent(e, "email");
                break;
            case 'print':
                openModalWithContent(e, "print");
                break;
            case 'showing':
                openModalWithContent(e, "showing");
                break;
            case 'share':
                openModalWithContent(e, "share");
                break;
            case 'cma':
                openNewTabWithListing(e);
                break;
            case 'remove':
                handleRemoveShowingListings(e);
                break;
        }
    };

    const showClaimErrorAlert = (content) => {
        triggerAlert("error", content);
    };

    const handleClaim = async () => {
        if (selectedListingIds.length > 1) {
            showClaimErrorAlert("You can only claim one listing at a time. Please choose a single listing to continue.");
        } else {
            const id = selectedListingIds[0];
            const listingGetParams: IListingApiGetParams = {
                listing_ids: selectedListingIds,
                fields_to_include: fieldsInclude,
                include_disclaimer: true
            };
            await listingApi.get(listingGetParams).then(async (listingsRes) => {
                const listings = listingsRes.data.data.map(listing => formatSettingsToLocal(listing));
                const filtered = listings.filter(listing => listing.apartment_id === id);
                const listing = filtered[0];
                setListingToClaim(listing);

                // TODO Update once the requirements are clear
                // if (!(listing.type_contact !== 3 && !listing.listing_agent_id )) {
                //     showClaimErrorAlert("Please select a Rental Beast verified listing");
                // } else
                if (listing?.email === "" && listing?.direct_phone === "" ) {
                    showClaimErrorAlert("Sorry, the property contact for the selected listing has not provided any contact information. You need to contact this owner directly and request that the owner provides Rental Beast with a contact email address.");
                } else {
                    setDrawerOpen(true);
                }
            });
        }
    };

    const handleRemoveShowingListings = (e) => {
        if (selectedListingIds.length < 1) {
            return;
        }
        const showingId = location.pathname.split('/')[3]
        getShowingsList().then(agentShowings => {
            const currentShowing = agentShowings.filter((showing) => showing.id === parseInt(showingId))[0];
            setAlertVisible(true);
            setAlertType('warning');
            setAlertContent(`Are you sure that you want to dissociate this listing from the Showing?`);
            setSweetAlertProps({
                title: 'Confirm',
                showConfirm: true,
                showCancel: true,
                onConfirm: async () => {
                    let listingIds = currentShowing.listingIds;
                    currentShowing.listingIds = listingIds.filter(item => !selectedListingIds.includes(parseInt(item)))
                    await handleShowingPut(currentShowing, agentId).then(() => {
                        triggerAlert("success");
                        window.location.reload();
                    }).catch(() => {
                        triggerAlert("error")
                    });
                },
                onCancel: () => {
                    handleCloseSweetAlert();
                }
            });
        })
    };

    const handleCloseClaimDrawer = (e) => {
        e.stopPropagation();
        setDrawerOpen(false);
    };

    const handleCloseClaimModal = (e) => {
        e.stopPropagation();
        setDrawerOpen(false);
    };

    const handleClickClaimDrawer = (e) => {
        e.stopPropagation();
    };

    const getClaimMarkup = () => {

        const markup = (
            <>
                <span className={`icon-div-cont claim-icon-cont ${isDisabled ? "claim-icon-cont-disabled" : ""}`} onClick={(e) => handleActionClick('claim', e)}>
                    <img
                        title="Claim"
                        src={getClaimImage()}
                        className="claim-icon"
                        style={{height: viewType === ViewType.MAP && browserName === 'Mobile Safari' ? "27px" : "18px"}}
                        alt="Claim Listing"
                    />
                </span>
                <div
                    className="claim-drawer "
                    style={{
                        zoom: (
                            viewType === ViewType.MAP &&
                            !actionFromMap &&
                            listing.apt_count === 1
                        ) ? "150%" : "unset"
                }}
                >
                    <Drawer
                        open={drawerOpen}
                        handleClose={(e) => handleCloseClaimModal(e)}
                        width={80}
                        handleClick={(e) => handleClickClaimDrawer(e)}
                        actionFromMap={actionFromMap}
                    >
                        <ClaimListing listing={listingToClaim} agentInfo={agentInfo} cancelHandler={(e) => handleCloseClaimDrawer(e)} actionFromMap={actionFromMap}/>
                    </Drawer>
                </div>
            </>
        );
        // 1. To show claim listing button on listings not owned by an agent (type_contact 3 (agent)), and not belonging to a listing agent (listing_agent_id is 0 or empty)
        // 2. MLS Only users can claim Rental Beast Reviewed and Non-RB Reviewed listings
        // 3. MLS Only users cannot claim MLS Listings.
        // 4. Full Agent users can claim Rental Beast Reviewed and Non-RB Reviewed listings
        if (
            (listing.type_contact !== 3 && !listing.listing_agent_id && !isMLSListing(listing)) ||
            (isMlsAgentAndMlsListing(listing, agentInfo) && listing.is_agent_listing) ||
            (isMlsAgentAndNonMlsListing(listing, agentInfo) && listing.is_agent_listing) ||
            (isFullAgent(agentInfo) && isRBListing(listing) && listing.is_listing_free || listing.is_agent_listing) ||
            (isFullAgent(agentInfo) && isMLSListing(listing) && listing.is_listing_free || listing.is_agent_listing)
        ) {
            return markup;
        } else {
            return null;
        }
    };

    return (
        <div className={`action-buttons--container ${isDisabled ? "disabled" : ""}`}>
            {
                props.excludeEmail ?
                null : <span className="icon-div-cont" onClick={ (e) => handleActionClick("email", e) } >
                    <i title="Email" className="fas fa-envelope-open"/>
                </span>
            }
            {/*{   props.includeFacebookShare ?*/}
            {/*    <span className="icon-div-cont">*/}
            {/*        <FacebookShareButton*/}
            {/*            url={listingFacebookUrl()}*/}
            {/*            quote={""}*/}
            {/*            hashtag=""*/}
            {/*        >*/}
            {/*            <i title="Facebook" className="fab fa-facebook" onClick={ () => handleMultipleIds() }/>*/}
            {/*        </FacebookShareButton>*/}
            {/*    </span> : null*/}
            {/*}*/}
            {
                props.excludePrint ?
                null : <span className="icon-div-cont" onClick={ (e) => handleActionClick("print", e) } >
                    <i title="Print" className="fas fa-print"/>
                </span>
            }
            {
                isFullAgent(agentInfo) ?
                    <span className="icon-div-cont" onClick={ (e) => handleActionClick("showing", e) } >
                        <i title="Showing" className="fas fa-key"/>
                    </span> : null
            }
            {   props.excludeBookmark ?
                null : <span className="icon-div-cont"  onClick={ (e) => handleActionClick("bookmark", e) } >
                    <i title="Bookmark" className="fas fa-bookmark"/>
                </span>
            }
            {/* {
                props.excludeShare ?
                null : <span className="icon-div-cont" onClick={ (e) => handleActionClick("share", e) } >
                    <i title="Share" className="fas fa-link"/>
                </span>
            } */}
            {
                props.excludeCMA ?
                    null : <span className="icon-div-cont" onClick={ (e) => handleActionClick("cma", e) } >
                        <i title="CMA Tool" className="fas fa-chart-bar"/>
                </span>
            }
            { props.excludeClaim || (props?.isLDPOnly && (listing || {}).listing_source_type === 'MLS') ? null : getClaimMarkup() }
            {
                !props.includeRemoveListing ?
                    null : <span className="icon-div-cont" onClick={ (e) => handleActionClick("remove", e) } >
                <i title="Remove Listings" className="fas fa-trash"/>
            </span>
            }
            {props.hasApplyNow && props.listing &&
                <span className="icon-div-cont" onClick={ (e) => handleActionClick("remove", e) } >
                <div 
                    onClick={() => {
                        props.openInviteApplicationModal(props.listing)}}
                >
                        <img src={applyNowLogo} width="30" />
                    </div>
                </span>
            }
            {/* {props.listing && <span>Apply Now</span>} */}
            <Dialog
                open={isOpen}
                onClose={(e: any) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setIsOpen(false);
                }}
            >
                {modalContent}
            </Dialog>
        </div>
    )
}

interface IListingActionButtons {
    selectedListingIds: number [];
    withText?: boolean;
    excludeBookmark?: boolean;
    excludeCMA?: boolean;
    excludeClaim?: boolean;
    includeRemoveListing?: boolean;
    includeFacebookShare?: boolean;
    onSuccess?: (content?: string) => void;
    onError?: (content?: string) => void;
    toggleBookmarks?: (e?: any, props?: any) => void;
    isDisabled?: boolean;
    listing?: IListing;
    excludeEmail?: boolean;
    excludePrint?: boolean;
    excludeShare?: boolean;
    isLDPOnly?: boolean;
    removeExcludeDeleteModal? :() => void
    isListingInGroup? : boolean
    actionFromMap?: boolean;
    hasApplyNow?: boolean;
    openInviteApplicationModal?: (listingId) => void
}
