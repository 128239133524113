import React from "react";
import ChipInput from "material-ui-chip-input";
import "./chips_input.scss";

export function ChipsInput ({emails, setFieldValue}: {emails: string, setFieldValue}) {
    let emailsArr = [];
    if (emails !== '') {
        emailsArr = emails.split(',');
    }
    const addChip = value => {
        const chips = emails.slice();
        emailsArr.push(value);
        setFieldValue('emails_comma', emailsArr.join(','));
    };
    const removeChip = (chip, index) => {
        const chips = emails.slice();
        emailsArr.splice(index, 1);
        setFieldValue('emails_comma', emailsArr.join(','));
    };
    return <ChipInput
        value={emailsArr}
        onAdd={(chip) => addChip(chip)}
        onDelete={(chip, index) => removeChip(chip, index)}
        newChipKeyCodes={[188, 32]}
        variant={"outlined"}
        className={"chips-input"}
    />
}