import React, {useContext, useEffect, useState} from "react";
import {ListingSearchContext} from "../../listing_search_context";
import "./fieldset_default_region.scss";
import {agentFeaturedAreasApi, fetchFeaturedAreas} from "../../../../../services/api/agent_featured_areas";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import Form from "react-bootstrap/Form";
import {Field, Formik} from "formik";
import * as Yup from "yup";
import {AgentUserContext} from "../../../../features/broker_search/agent_user_context";

export function FieldsetDefaultRegion() {

    const {criteria, setCriteria} = useContext(ListingSearchContext);
    const {agentId, consumerInfo} = useContext(AgentUserContext);
    const [areas, setAreas] = useState([])
    const [defaultRegionId, setDefaultRegionId] = useState(null)
    const [defaultRegion, setDefaultRegion] = useState('')
    const [showChangeRegionModal, setShowChangeRegionModal] = useState(false);

    const regionFormValues = {
        super_region: defaultRegionId
    };

    const regionValidationSchema = Yup.object().shape({
        super_region: Yup.number()
            .typeError('must be a number')
    });

    const fetchUserInfo = async () => {
        if(consumerInfo != null && agentId){
            const regions = await agentFeaturedAreasApi.getList(`${agentId}`)
            setAreas(regions);
            const region = regions.find(o => o.id === consumerInfo.agent_info.super_region)
            setDefaultRegion(region.nameValue);
            setDefaultRegionId(region.id)
            setCriteria({...criteria, super_region: consumerInfo.agent_info.super_region});
        }
    }

    const handleOpen = () => {
        setShowChangeRegionModal(true)
    }

    const handleChangeRegion = (vals) => {
        setCriteria({...criteria, super_region: vals.super_region});
        setShowChangeRegionModal(false);
    }

    const changeDefaultRegion = () => {
        const area = areas.find(o => o.id == criteria.super_region)
        if (area !== undefined){
            setDefaultRegion(area?.nameValue);
            setDefaultRegionId(criteria?.super_region)
        }
    }

    const handleClose = () => {
        setShowChangeRegionModal(false);
    };

    useEffect(() => {
        fetchUserInfo()
    }, [consumerInfo])

    useEffect(() => {
        changeDefaultRegion()
    }, [criteria.super_region])

    return  <>
        {
        areas.length > 1 ? 
            <div className="super-region-container">
                    <label>
                        Your region is set to {defaultRegion} (
                        <Button variant="text" onClick={handleOpen} className="change-link">change</Button>)
                    </label>
                    <Dialog open={showChangeRegionModal} onClose={handleClose}>
                        <Formik
                            initialValues={regionFormValues}
                            enableReinitialize={true}
                            validationSchema={regionValidationSchema}
                            onSubmit={handleChangeRegion}
                            render={({handleSubmit}) => (
                                <form onSubmit={handleSubmit}>
                                    <DialogTitle>Default Region</DialogTitle>
                                    <DialogContent>
                                        <Form.Row>
                                            <Field name="super_region" component="select" className="form-control">
                                                {
                                                    areas && areas.map(area => {
                                                        return <option value={area.id}>{area.nameValue}</option>
                                                    })
                                                }
                                            </Field>
                                        </Form.Row>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button type="submit">Save</Button>
                                        <Button onClick={handleClose}>Cancel</Button>
                                    </DialogActions>
                                </form>

                            )}
                        />
                    </Dialog>
                </div>
        : null
        }
    </>
}