import React, {useContext} from "react";
import {ListingSearchContext} from "../../components/listing_search/listing_search_context";
import ReactPaginate from "react-paginate";
import {BrokerSearchingContext} from "./broker_search_context";
import { SelectedListingsContext } from "./selected_listings_context";
import { ViewType } from "../../utils/device_info";

export function PaginationModule(props: {totalPages: number, totalResults: number }) {

    const {criteria, setCriteria} = useContext(ListingSearchContext);
    const {isMobileRes, setSearching, viewType} = useContext(BrokerSearchingContext);
    const {setRowPerPageRenderer} = useContext(SelectedListingsContext)
    const {totalPages, totalResults} = props;

    const handlePageChange = (props) => {
        setCriteria({...criteria, page_number: props.selected+1});
        console.log("Searching");
        setSearching(true);
    };

    const handleResultsPerPageChange = (newVal) => {
        setCriteria({...criteria, page_number: 1, max_record_count: parseInt(newVal.currentTarget.value)});
        console.log("Searching");
        setSearching(true);
        setRowPerPageRenderer((Math.random() + 1).toString(36).substring(7))
    };

    const getPagination = () => {
        return (
            <div className="pagination-container" style={{display: isMobileRes ? "none" : ""}}>
                <ReactPaginate
                    previousLabel={'< '}
                    nextLabel={' >'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={totalPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={ (props) => {handlePageChange(props)} }
                    forcePage={criteria.page_number-1}
                    containerClassName={'pagination'}
                    activeClassName={'active'}
                />
                <div className="per-page-wrapper">
                    <label htmlFor="">Rows per page:</label>
                    <select className="per-page-select" value={criteria.max_record_count} onChange={newVal => handleResultsPerPageChange(newVal)}>
                        <option>10</option>
                        <option>25</option>
                        {
                            // temporary solution for limiting the page per items
                            viewType === ViewType.LIST && (<>
                                <option>50</option>
                                <option>100</option>
                                <option>250</option>
                            </>)
                            // END
                        }
                    </select>
                </div>
            </div>
        )
    };

    return totalPages > 0 ? getPagination() : null
}
