import React from "react";
import { IComponentProps } from "./full_report_type/full_report_component_type";
import { renderDataUi } from "./full_report_helpers/renderDataUi";
import { ListingFeature } from "../../../../../services/rails_api/listing_features";

interface UnitFullReport extends IComponentProps {
  featuresList?: any;
}
export const PropertyFullReport: React.FC<UnitFullReport> = ({
  listing,
  featuresList,
}) => {
  const getLabels = (keysArr, featureName: ListingFeature) => {
    if (Object.keys(featuresList).length && keysArr) {
      return keysArr.map((key) => {
        return featuresList[featureName][key];
      });
    } else {
      return [];
    }
  };

  return (
    <div style={{ marginTop: 10 }}>
      <h5 style={{ fontSize: 15, fontWeight: "bold" }}>Property</h5>
      <table
        style={{
          background: "#ececec",
          borderTop: "1px solid black",
          padding: "10px 20px",
          fontSize: 13,
          width: "100%",
        }}
      >
        <tr>
          <td>
            <table>
              <tr>
                <td>
                  <div style={{ width: 200 }}>Year Built:</div>{" "}
                </td>
                <td>
                  <span style={{ fontWeight: "bold" }}>
                    {listing.year_built}
                  </span>
                </td>
              </tr>

              <tr>
                <td>
                  <div style={{ width: 200 }}>Property Type:</div>{" "}
                </td>
                <td>
                  <span style={{ fontWeight: "bold" }}>
                    {listing.building_type}
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <div style={{ width: 200 }}>Property Structure:</div>{" "}
                </td>
                <td>
                  <span style={{ fontWeight: "bold" }}>
                    {listing.property_structure}
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <div style={{ width: 200 }}>Parking Options:</div>
                </td>
                <td>
                  <span style={{ fontWeight: "bold" }}>
                    {listing.parking_options}{" "}
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <div style={{ width: 200 }}> Other Parking Info: </div>
                </td>
                <td>
                  <span style={{ fontWeight: "bold" }}>
                    {listing.other_parking}{" "}
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <div style={{ width: 200 }}> Parking Fee: </div>
                </td>
                <td>
                  <span style={{ fontWeight: "bold" }}>
                    {listing.parking_fee !== "" && `$ ${listing.parking_fee}`}
                  </span>
                </td>
              </tr>
            </table>
          </td>
          <td style={{ verticalAlign: "top" }}>
            <table style={{ marginLeft: 20 }}>
              <tr>
                <td>
                  <div style={{ width: 200 }}> Pets Allowed:</div>
                </td>
                <td>
                  <span style={{ fontWeight: "bold" }}>
                    {listing.pets ? "Yes" : "No"}
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <div style={{ width: 200 }}>Pet Type:</div>
                </td>
                <td>
                  <span style={{ fontWeight: "bold", display: "inherit" }}>
                    {console.log(
                      "lets pet types",
                      listing.pet_types,
                      ListingFeature.pet_type
                    )}
                    {renderDataUi(
                      getLabels(listing.pet_types, ListingFeature.pet_type)
                    )}
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <div style={{ width: 200 }}>Pet Specs:</div>
                </td>
                <td>
                  <span style={{ fontWeight: "bold" }}>
                    {listing.pet_specs}
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <div style={{ width: 200 }}>Pet Fee:</div>
                </td>
                <td>
                  <span style={{ fontWeight: "bold" }}>
                    $ {listing.pet_fee}
                  </span>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </div>
  );
};
