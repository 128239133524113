import {OwnershipType} from "../../../../../services/api/listing";
import {FieldsetMultipleSelect} from "../multiple_select/fieldset_multiple_select";
import React, {useContext, useEffect, useState} from "react";
import {ListingSearchContext} from "../../listing_search_context";

export function FieldsetOwnershipType() {

    const {criteria} = useContext(ListingSearchContext);
    const [optionArr, setOptionArr] = useState(criteria.ownership_type_list);

    const options = [
        {value: "Management Company", label: "Management Company"},
        {value: "Private Owner", label: "Private Owner"},
        {value: "Agent", label: "Agent"},
    ];

    useEffect(()=>{
        setOptionArr(criteria.ownership_type_list);
    }, [criteria.ownership_type_list]);

    return <FieldsetMultipleSelect
        options={options}
        optionArr={optionArr}
        setOptionArr={setOptionArr}
        title="Ownership Type"
        criteriaFieldName="ownership_type_list"
    />
}