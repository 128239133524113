import React from "react";

export enum TabNames {
  CONTACT = "contact",
  LEASING_AGENT = "leasing_agent",
  LISTING_INFO = "listing",
  PROPERTY_INFO = "property",
  UNIT_INFO = "unit",
  SHOWINGS = "showings",
  NOTES_SETTINGS = "notes",
}

export const general_info_tabs = {
  [TabNames.CONTACT]: { label: "Address and Listing Contact" },
  [TabNames.LEASING_AGENT]: { label: "Leasing/Tenant Agent" },
  [TabNames.LISTING_INFO]: { label: "Listing" },
  [TabNames.PROPERTY_INFO]: { label: "Property" },
  [TabNames.UNIT_INFO]: { label: "Unit" },
  [TabNames.SHOWINGS]: { label: "Showings" },
  [TabNames.NOTES_SETTINGS]: { label: "Notes & Settings" },
};
