import React, {useContext, useEffect, useState} from "react";
import "../fieldsets.scss";
import {FieldsetMultipleSelect} from "../multiple_select/fieldset_multiple_select";
import {ListingSearchContext} from "../../listing_search_context";

export function FieldsetFloorType() {
    const {getFilterOptions, filterConfig, criteria} = useContext(ListingSearchContext);
    const [options, setOptions] = useState([]);
    const [optionArr, setOptionArr] = useState(criteria.floor_type);

    useEffect(()=>{
        setOptions(getFilterOptions("floor_type"));
    },[filterConfig]);

    useEffect(()=> {
        setOptionArr(criteria.floor_type);
    }, [criteria.floor_type]);

    return <FieldsetMultipleSelect
        options={options}
        optionArr={optionArr}
        setOptionArr={setOptionArr}
        title={"Floor Type"}
        criteriaFieldName={"floor_type"}
    />
}