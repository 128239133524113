import React, {useEffect, useRef, useState} from "react";

export function ViewModeSelector({className}) {

    const [mapOnly, setMapOnly] = useState(false);

    useEffect(()=> {
        setView();
    }, [mapOnly]);

    const getIconClassName = (mapOnly) => {
        return mapOnly ? "fas fa-map-marked map-view-icon" : "fas fa-columns map-view-icon";
    };

    const setFullMap = () => {
        const resultsCol = document.getElementById("resultsCol");
        resultsCol.className = "results-column-wrapper results-column results-column-shrunk";

        const mapCol = document.getElementById("map-container");
        mapCol.className = "map-wrapper map-full";
    };

    const setSplitView = () => {
        const resultsCol = document.getElementById("resultsCol");
        resultsCol.className = "results-column-wrapper results-column results-column-shown";

        const mapCol = document.getElementById("map-container");
        mapCol.className = "map-wrapper map-split";
    };

    const handleOnClick = () => {
        setMapOnly(!mapOnly);
    };

    const setView = () => {
        if(mapOnly) {
            setFullMap()
        }
        else {
            setSplitView();
        }
    };

    return (
        <div className={className} onClick={()=>{ handleOnClick() }}>
            <div className="view-mode-label">View Mode</div>
            <div className="icon-wrapper">
                <i className={ getIconClassName(mapOnly) } />
            </div>
        </div>
    )
}