/**
 * @param fetchedListings
 * @returns {{ne: {lat: *, lng: *}, sw: {lat: *, lng: *}}}
 */
export const getMapBounds = (fetchedListings = []) => {
    let southernMostLat;
    let northernMostLat;
    let westernMostLon;
    let easternMostLon;
    
    for( let i=0; i<fetchedListings.length; i++) {
        let listing = fetchedListings[i];
        let lat = listing.latitude;
        let lon = listing.longitude;

        southernMostLat = (lat < southernMostLat || !southernMostLat) ? lat : southernMostLat;
        northernMostLat = (lat > northernMostLat || !northernMostLat) ? lat : northernMostLat;
        westernMostLon = (lon < westernMostLon || !westernMostLon) ? lon : westernMostLon;
        easternMostLon = (lon > easternMostLon || !easternMostLon) ? lon : easternMostLon;

    }

    let bounds = {ne: {lat:0, lng:0}, sw:{lat:0, lng:0} };

    if(undefined !== southernMostLat && undefined !== northernMostLat && undefined !== westernMostLon && undefined !== easternMostLon) {
        const mod = .01;

        if(southernMostLat === northernMostLat) {
            southernMostLat = southernMostLat - mod;
            northernMostLat = northernMostLat + mod;
        }

        if(westernMostLon === easternMostLon) {
            westernMostLon = westernMostLon - mod;
            easternMostLon = easternMostLon + mod;
        }

        bounds = {
            ne: { lat: northernMostLat, lng: easternMostLon },
            sw: { lat: southernMostLat, lng: westernMostLon }
        };
    }

    return bounds;
};

export interface MapBoundsProps {
    location: {
        lat: number,
        lon: number
    }
}

interface MapBounds {
    ne: { lat: number, lng: number },
    sw: { lat: number, lng: number }
}

export const getMapBoundsForPackagedResults = (results: MapBoundsProps [] = []): MapBounds => {
    let southernMostLat;
    let northernMostLat;
    let westernMostLon;
    let easternMostLon;

    for( let i=0; i<results.length; i++) {
        let result = results[i];
        let lat = result.location.lat;
        let lon = result.location.lon;

        southernMostLat = (lat < southernMostLat || !southernMostLat) ? lat : southernMostLat;
        northernMostLat = (lat > northernMostLat || !northernMostLat) ? lat : northernMostLat;
        westernMostLon = (lon < westernMostLon || !westernMostLon) ? lon : westernMostLon;
        easternMostLon = (lon > easternMostLon || !easternMostLon) ? lon : easternMostLon;
    }

    let bounds = {ne: {lat:0, lng:0}, sw:{lat:0, lng:0} };

    if(undefined !== southernMostLat && undefined !== northernMostLat && undefined !== westernMostLon && undefined !== easternMostLon) {
        const mod = .01;

        if(southernMostLat === northernMostLat) {
            southernMostLat = southernMostLat - mod;
            northernMostLat = northernMostLat + mod;
        }

        if(westernMostLon === easternMostLon) {
            westernMostLon = westernMostLon - mod;
            easternMostLon = easternMostLon + mod;
        }        
      
        bounds = {
            ne: { lat: northernMostLat, lng: easternMostLon },
            sw: { lat: southernMostLat, lng: westernMostLon }
        };
    }

    return bounds;
};