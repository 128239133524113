import React, {useContext, useEffect, useState} from "react";
import "./styles/layout.scss";
import {Layout} from "./layout";
import {ResultsColumnMobile} from "./results_column_mobile";
import {
    IListing,
    IListingApiGetParams,
    listingJsonApi, ListingType, StatusType
} from "../../../services/api/listing";
import {areCoordsValid, removeDuplicates, getPackagedResults, IResult} from "../../components/listing_search/utilities";
import { ConsumerSearchContext } from "./consumer_search_context";
import {useAgentLink} from "../../components/hooks/get_agent_link";
import {useUserData} from "../../components/hooks/use_user_data";
import {useSignInForm} from "../../components/hooks/use_sign_in_form";
import {IConsumerData} from "../../../services/api";
import {MultiUnitModal} from "./multi_unit_modal";
import {mapFieldsToInclude, tileFieldsToInclude} from "./util";
import {FiltersProvider} from "../../components/listing_search/search_filters/filters_provider";

export function ConsumerSearch() {

    const {agentLinkId} = useAgentLink();

    //Tile search
    const [baseTileCriteria, setBaseTileCriteria] = useState({
        page_number: 1,
        max_record_count: 20,
        fields_to_include: tileFieldsToInclude,
        is_for_consumer: false,
        rb_portal: "bp_listings",
        statuses_list: ["Active"] as StatusType[],
        listing_type: "agent_branded" as ListingType,
        agent_id: 0
    } as IListingApiGetParams );
    const [tileSearching, setTileSearching] = useState(false);
    const [tileResults, setTileResults] = useState([] as IResult[]);

    //Map search
    const baseMapCriteria = {
        max_record_count: 10000,
        fields_to_include: mapFieldsToInclude,
        is_for_consumer: false,
        rb_portal: "bp_listings",
        statuses_list: ["Active"] as StatusType[],
        listing_type: "agent_branded" as ListingType,
        agent_id: 0
    };
    const [mapSearching, setMapSearching] = useState(false);
    const [mapResults, setMapResults] = useState([] as IResult[]);

    const [isMobile, setIsMobile] = useState(false);
    const [isInitialized, setIsInitialized] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [highlightedResultId, setHighlightedResultId] = useState("");
    const {userData, initUserData} = useUserData();
    const {SignInForm, submitForm} = useSignInForm();

    // Multi-unit modal
    const [multiUnitListings, setMultiUnitListings] = useState([] as IListing[]);
    const [multiUnitModalOpen, setMultiUnitModalOpen] = useState(false);

    const setMobileViewMode = () => {
        const documentWidth = document.body.clientWidth;
        setIsMobile(768 >= documentWidth);
    };

    useEffect(() => {
        if (agentLinkId) {
            setMapSearching(true);
            setTileSearching(true);
        }
    }, [agentLinkId]);

    const handleTileSearch = async () => {
        sessionStorage.setItem('validate-token', 'false');
        setTileResults([]);
        const response = await listingJsonApi.get({
            ...baseTileCriteria,
            agent_id: agentLinkId,
            listing_type: agentLinkId ? ListingType.agent_branded : undefined,
            is_for_consumer: true,
            include_disclaimer: true
        });

        let results = response.data.data.filter(areCoordsValid);
        results = removeDuplicates(results);

        const packagedListings = getPackagedResults(results);
        setTileResults(packagedListings);

        setTotalPages(response.data.meta.total_pages);
        setTileSearching(false);
    };

    const handleMapSearch = async () => {
        sessionStorage.setItem('validate-token', 'false');
        setMapResults([]);
        const mapResponse = await listingJsonApi.get({
            ...baseMapCriteria,
            agent_id: agentLinkId,
            listing_type: agentLinkId ? ListingType.agent_branded : undefined,
            is_for_consumer: true,
            include_disclaimer: true
        });

        let results = mapResponse.data.data.filter(areCoordsValid);
        results = removeDuplicates(results);

        const packagedResults = getPackagedResults(results);
        setMapResults(packagedResults);
        setMapSearching(false);
    };

    const initFx = () => {
        setMobileViewMode();
        window.addEventListener("resize", setMobileViewMode);
        setIsInitialized(true);
    };

    const handleUserSessionUpdate = (e: CustomEvent<IConsumerData>) => {
        initUserData()
    };

    useEffect(() => {
        window.addEventListener('session-update', handleUserSessionUpdate);
        if(!isInitialized) {
            initFx();
        }
    }, []);

    useEffect(() => {
        if (tileSearching) {
            console.log('Searching Tiles', {...baseTileCriteria});
            handleTileSearch();
        }
    }, [tileSearching]);

    useEffect(() => {
        if (mapSearching) {
            console.log("searching map", {...baseMapCriteria});
            handleMapSearch();
        }
    }, [mapSearching]);

    useEffect(() => {
        setTileSearching(true)
    }, [baseTileCriteria.page_number]);

    return (
        <ConsumerSearchContext.Provider value={
            {
                tileSearching,
                setTileSearching,
                mapSearching,
                setMapSearching,
                highlightedResultId,
                setHighlightedResultId,
                userData,
                submitForm,
                isMobile,
                agentLinkId,
                baseTileCriteria,
                setBaseTileCriteria,
                totalPages,
                setMultiUnitListings,
                setMultiUnitModalOpen,
            }
        }>
            <FiltersProvider isConsumerPortal={true}>
                <MultiUnitModal multiUnitListings={multiUnitListings} multiUnitModalOpen={multiUnitModalOpen} />
                {
                    isMobile ?
                        <ResultsColumnMobile tileResults={tileResults} />
                    :
                        <Layout tileResults={tileResults} mapResults={mapResults}/>
                }
                <SignInForm />
            </FiltersProvider>
        </ConsumerSearchContext.Provider>
    )
}