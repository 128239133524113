// generic collation procedure
// reduces an array based on a check and returns both sides of the condition
import {states,canadian_provinces} from "../../../utils/states" ;
export const collateBy = f => g => xs => {
    return xs.reduce((m,x) => {
        let v = f(x);
        return m.set(v, g(m.get(v), x))
    }, new Map())
};

export const collateByArrInclude = (arr2) => collateBy (x => arr2.includes(x)) ((a=[],b)=> [...a,b]);

export const getOwnerLabel = (listing) => {
    let ownerLabel: string;
    const isExclusiveAgent = listing.listing_agent_id && listing.listing_agent_id > 0;
/*
    if (listing.type_contact === 2) {
        ownerLabel = "Individual Name"
    }
    else
*/
    if (listing.type_contact === 3 || isExclusiveAgent ) {
        ownerLabel = "Agent"
    }
    else {
        ownerLabel = 'Owner/Manager'
    }
    return ownerLabel;
};

export const getOwnerName = (listing) => {
    const isExclusiveAgent = listing.listing_agent_id && listing.listing_agent_id > 0;
    const agentInfo = listing.agent_info;
    return isExclusiveAgent && agentInfo ? agentInfo.full_name : listing.management_co
};

export const stateListByUser = (agentInfo) => {

    const languageCode = (agentInfo?.locale_settings?.language + "-"+  agentInfo?.locale_settings?.country_code);
    if(languageCode === 'en-CA' || navigator.language === 'en-CA'){
        return canadian_provinces
    }
    else{
        return states
    }

}

export const stateListByCountry = (countryCode = 'US') => {
    return countryCode === 'CA' ? canadian_provinces : states
}

