import {REST} from "../rest";

interface applyNowComplianceProps {
    partnerKey?: string;
    state?: string;
    location?: string;
    rule_type?: string;
    listing_id?: string;
    partnerId?: string;
}

//RB-3496:  Apply Now Compliance API
export const applyNowComplianceApi = {
    get : async (props : applyNowComplianceProps) => {
        return await REST.get(`/api/apply_now_compliance`,
            {
                headers: {
                    'Accept': 'application/json',
                },
                params: {
                    'partnerKey': props?.partnerKey,
                    'state': props?.state,
                    'location': props?.location,
                    'rule_type': props?.rule_type,
                    'listing_id': props?.listing_id,
                    'partnerId': props?.partnerId,
                }
            }).then(res => {
            return res.data;
        });
    }
};
