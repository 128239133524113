import React, {useContext} from "react";
import {IListing} from "../../../services/api/listing";
import Pluralize from 'react-pluralize'
import {imgUrl} from "../../../services/constants";
import {ConsumerSearchContext} from "./consumer_search_context";
import {getAddressLineOne, getAreaString} from "./util";

export function InfoPanel(props: {listings:IListing[], firstListing: IListing}) {

    const {listings, firstListing} = props;
    const {agentLinkId} = useContext(ConsumerSearchContext);
    const {setMultiUnitModalOpen, setMultiUnitListings} = useContext(ConsumerSearchContext);

    const getDisclaimer = (disclaimer) => {
        //RB-1246
        if(disclaimer.tile_disclaimer) {
            return (
                <div className="disclaimer-row">
                 {firstListing.listing_source_type =="RB"?
                    (<div className="review-checkbox">
                        <img src={`${imgUrl}/icon-reviewed.png`} className="rb-check" alt="rental beast reviewed" />
                        <span className="rb-verified ">{disclaimer.tile_disclaimer}</span>
                    </div>)
                 :(<div className="verified-false">{disclaimer.tile_disclaimer}</div>)}   
                </div>       
            );
        }
    };

    const handleOpenModal = (listings: IListing[]) => {
        setMultiUnitModalOpen(true);
        setMultiUnitListings(listings);
    };
    const handleOnClick = (listings) => {
        if(listings.length > 1) {
            handleOpenModal(listings)
        }
        else {
            let path = `/listings/show/${listings[0].apartment_id}?agentId=${agentLinkId}&isAgentBranded=true`;
            open(path);
        }
    };

    return (
        <div className="info-panel" onClick={ () => { handleOnClick(listings) }}>
            <div className="listing-area">{getAreaString(firstListing)}</div>
                { getAddressLineOne(firstListing) }
            <div className="listing-address">{`${firstListing.city}, ${firstListing.state}`}</div>
            <div className="panel-row">
                <div className="bedrooms">
                    {listings.length > 1 ?
                        <span>Multiple units: See more <i className="fas fa-arrow-right"/></span> :
                        <span>
                          <Pluralize singular="bed" count={firstListing.bedrooms} zero={'Studio'}/>
                          <span className="bed-bath-slash">/</span>
                          <Pluralize singular="bath" count={firstListing.bathrooms}/>
                        </span>
                    }
                </div>
            </div>
            { getDisclaimer(firstListing.disclaimer) }
        </div>
    )
}