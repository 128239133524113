import React, {useContext, useState} from "react";
import "./claim_listing.scss";
import DatePicker from 'react-date-picker';
import {Button, IconButton} from "@material-ui/core";
import {claimListingApi} from "../../../../services/rails_api/broker_portal/claim_listing";
import {AgentUserContext} from "../../broker_search/agent_user_context";
import Moment from 'moment';
import {SweetAlertContext} from "../../../components/sweet_alert/sweet_alert";
import { isMobile } from '../../../utils/device_info';

export function ClaimListing(props: IClaimListingProps) {
    const {listing, cancelHandler, actionFromMap = false} = props;
    const [confirmed, setConfirmed] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [expirationDate, setExpirationDate] = useState(null);
    const [email, setEmail] = useState("");
    const {agentId, agentInfo} = useContext(AgentUserContext);
    const {triggerAlert} = useContext(SweetAlertContext);
    const oneDayAhead = Moment(new Date()).add(1, 'days').toDate();

    const handleDateChange = (datePicked: any) => {
        setExpirationDate(datePicked);
    };

    const handleSubmit = async () => {
        if(!confirmed || submitting) {return}
        setSubmitting(true);

        const params = {
            user_id: agentId,
            listing_id: listing.apartment_id,
            expiration_date: Moment(expirationDate).format('YYYY-MM-DDTHH:mm:ss.SSSZZ'),
            id: null,
            request_action: "",
            date_requested: "",
            date_approved: "",
            request_key: "",
            create_stamp: "",
            modify_stamp: "",
            listing_address: "",
            first_name: "",
            last_name: "",
            full_name: "",
            agent_email: "",
            owner_email: email,
            owner_phone: listing?.direct_phone,
        };

        const resPost = await claimListingApi.post(params).catch(() => {
            // TODO: Correct error text with response message
            triggerAlert("error", "This listing cannot be claimed. It already has a listing agent or the expiration date field is empty.");
            setSubmitting(false);
        });

        if (resPost) {
            triggerAlert("success", "Your request has been submitted.");
        }
    };
    const disableSubmitVerification = () => {
        return listing?.email === "" ? email === "" || !confirmed || submitting : !confirmed || submitting
    }

    return (
        <div className="claim-cont"
            style={{width: actionFromMap ? "55%" : "100%"}}
        >
            <div className="close-x-cont">
                <IconButton aria-label="close" className="close-x" onClick={(e) => cancelHandler(e)}>
                    <i className="fas fa-times"/>
                </IconButton>
            </div>
            <p className="dear-agent">{agentInfo ? agentInfo.full_name : ""},</p>
            <p>You’ve indicated that you’re the exclusive listing agent for the listing located at:</p>
            <p>{listing ? listing.address : ""}</p>
            <p>Please confirm your representation:</p>
            <div className="agreement-row">
                <input type="checkbox" checked={confirmed} onChange={() => setConfirmed(!confirmed)} style={{zoom: actionFromMap && isMobile() ? "150%" : "100%"}}/>
                <span> I certify that I am the exclusive listing agent for the property listed above, and that I have a listing contract or other agreement with the property owner or manager in place.</span>
            </div>
            <div className="picker-row">
                <span className="expiration-text">My agreement or contract related to this property expires on:</span>
                <div className="picker-cont">
                    <DatePicker
                        minDate={oneDayAhead}
                        onChange={handleDateChange}
                        value={expirationDate}
                    />
                </div>
            </div>
            {
                listing?.email === "" ?
                    <div>
                        <p>
                            <span className="expiration-text">
                                Rental Beast does not have an email address for the property manager on file. However, Rental Beast does have the phone number: {listing?.direct_phone}
                            </span>
                        </p>
                        <div className="contact-row">
                            <span className="expiration-text">
                                Please contact the property manager and enter the contact email address for this property so that we can contact the property manager and confirm your agreement (1):
                            </span>
                            <div>
                                <input placeholder="Enter property contact email address" onChange={(e) => setEmail(e.target.value)}/>
                            </div>
                        </div>
                    </div> : null
            }
            <p>To complete this process, Rental Beast will verify your exclusive listing representation with the property manager or owner. You will be notified when the verification process is complete.</p>
            <div className="buttons-cont">
                <Button variant="contained" color="primary" onClick={handleSubmit} disabled={disableSubmitVerification()}>SUBMIT VERIFICATION REQUEST</Button>
                <Button variant="contained" color="default" onClick={cancelHandler}>Cancel</Button>
            </div>
        </div>
    )
}

interface IClaimListingProps {
    agentInfo?: any;
    listing: any;
    cancelHandler: (e) => void;
    actionFromMap?: boolean;
}
