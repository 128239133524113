import React, { useContext } from 'react'
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import { pointsOfInterestSetting } from './map_view_config/PointsOfInterestSetting';
import { schoolSetting } from './map_view_config/SchoolSetting';
import { UsePartnerConfig } from '../../../../components/hooks/use_partner_config';
import { AgentUserContext } from '../../agent_user_context';
import { ListingSearchContext } from '../../../../components/listing_search/listing_search_context';
import { isMobile } from '../../../../utils/device_info';


interface ILayersDropdown {
    selectedLayers: string[];
    // --- new layer: Subdivision ---
    subdivisionLayer: boolean;
    // --- END ---
    schoolDistrictLayer: boolean;
    selectedSchoolLayers: string[];
    onSetSelectedLayers: (selectedLayers: string[]) => void | null;
    // --- new layer: Subdivision ---
    onSetSubdivisionLayer: (subdivisionLayer: boolean) => void | null;
    // --- END ---
    onSetSchoolDistrictLayer: (schoolDistrictLayer: boolean) => void | null;
    onSetSelectedSchoolLayers: (selectedLayers: string[]) => void | null;
}

const MenuProps = (menuLevel: string) => ({
    getContentAnchorEl: null,
    PaperProps: {
        style: {
            maxHeight: 'auto',
            width: 250,
            color: '#337ab7',
            ...(menuLevel === 'secondary' ? {
                ...( isMobile() ? {
                    marginLeft: -77.5,
                    marginTop: 40
                } : {
                    marginLeft: -277.5,
                    marginTop: -15
                })
            } : {
                marginTop: 35
            })
        }
    }
})

const layersMockUp = (optionConfigSettings) => {
    const {
        hideSchoolDistrictLayers,
        hideSubdivisionLayers
    } = optionConfigSettings
    return [
        {
            id: 'school',
            name: 'school',
            value: 'school',
            text: 'Schools',
            data: schoolSetting
        },
        {
            id: 'poi',
            name: 'poi',
            value: 'points_of_interest',
            text: 'Points of Interest',
            data: pointsOfInterestSetting
        },
        {
            id: 'boundaries',
            name: 'boundaries',
            value: 'boundaries',
            text: 'Boundaries',
            data: [
                { label: "School District", value: "school-district", isHidden: hideSchoolDistrictLayers },
                // --- new layer: Subdivision ---
                { label: "Subdvision", value: "subdivision", isHidden: hideSubdivisionLayers }
                // --- END ---
            ]
        }
    ]
}

export const LayersDropdown = React.memo(
    function LayersDropdownComponent(props: ILayersDropdown) {
        const {
            selectedLayers,
            // --- new layer: Subdivision ---
            subdivisionLayer,
            // --- END ---
            schoolDistrictLayer,
            selectedSchoolLayers,
            onSetSelectedLayers,
            // --- new layer: Subdivision ---
            onSetSubdivisionLayer,
            // --- END ---
            onSetSchoolDistrictLayer,
            onSetSelectedSchoolLayers
        } = props
        const {
            hideSchoolLayers,
            hidePOILayers,
            hideSchoolDistrictLayers,
            hideSubdivisionLayers
        } = UsePartnerConfig()
        const { isFullAgent, isMlsAgent } = useContext(AgentUserContext)
        const { loadingMapFilterSearch } = useContext(ListingSearchContext)

        // --- can be expanded for future layers ---
        const showLayersDropdown = !hideSchoolLayers || !hidePOILayers || !hideSchoolDistrictLayers || !hideSubdivisionLayers
        // --- END ---

        const handleSelectedLayers = (value, field?: string) => {

            if (loadingMapFilterSearch) {
                return
            }
            if (((value !== 'school-district') && (value !== 'subdivision')) && !field) {
                if (selectedLayers.includes(value)) {
                    const filteredSelectedLayers = selectedLayers.filter(item => item !== value)
                    onSetSelectedLayers(filteredSelectedLayers)
                } else {
                    onSetSelectedLayers([ ...selectedLayers, value ])
                }
            } else if (field) {
                if (selectedSchoolLayers.includes(value)) {
                    const filteredSelectedSchoolLayers = selectedSchoolLayers.filter(item => item !== value)
                    onSetSelectedSchoolLayers(filteredSelectedSchoolLayers)
                } else {
                    onSetSelectedSchoolLayers([ ...selectedSchoolLayers, value ])
                }
            } else {
                if (value === 'school-district') onSetSchoolDistrictLayer(!schoolDistrictLayer)
                // --- new layer: Subdivision ---
                else if (value === 'subdivision') onSetSubdivisionLayer(!subdivisionLayer)
                // --- END ---
            }

        }

        return ((isFullAgent() || isMlsAgent()) && showLayersDropdown) && (
            <Select
                className="layers-toolbar"
                multiple
                displayEmpty
                value={[]}
                renderValue={() => <em className="layers-toolbar-em">Layers</em>}
                MenuProps={MenuProps('main')}
            >
                {layersMockUp({ hideSchoolDistrictLayers, hideSubdivisionLayers }).map((layer: any) => {
                    let elemementToBeRendered = (
                        <MenuItem key={layer.id}>
                            <Select
                                multiple
                                displayEmpty
                                value={[]}
                                className='secondary-layers-toolbar'
                                disableUnderline
                                renderValue={() => <em className="layers-toolbar-em">{layer.text}</em>}
                                MenuProps={MenuProps('secondary')}
                            >
                                {layer.data
                                    .filter(({ isHidden }) => !isHidden)
                                    .map(({ label, value, field }) => (
                                        <MenuItem key={label} value={label}>
                                            <Checkbox
                                                checked={selectedSchoolLayers.includes(value) || selectedLayers.includes(value) || (schoolDistrictLayer && value === 'school-district') || (subdivisionLayer && value === 'subdivision')}
                                                onChange={() => handleSelectedLayers(value, field)}
                                            />
                                            <ListItemText primary={label} />
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </MenuItem>
                    )

                    if ((layer.id === 'school' && hideSchoolLayers) || (layer.id === 'poi' && hidePOILayers) || (layer.id === 'boundaries' && (hideSchoolDistrictLayers && hideSubdivisionLayers))) {
                        elemementToBeRendered = null
                    }

                    return elemementToBeRendered
                }).filter(Boolean)}
            </Select>
        )
    }
)
