import React, {useEffect, useState} from "react";
import _ from "lodash";
import {agentAutoCompleteApi} from "../../../../services/api/agent_auto_complete";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";


function updateOptions(loadOptions, value) {
    loadOptions(value);
}

const debounced = _.debounce(updateOptions, 300);

interface IListingAgentObj {
    user_id: number;
    agent_id: number;
    name: string;
    email: string;
}
interface IAutocompleteProps {
    handleAgentSelect: (params: any) => void;
    inputValue: number;
    selectedAgent: any;
    setSelectedAgent: (val) => void;
    disabled: boolean;
    criteria: any;
    setCriteria: any;
    currentLoggedInUser?: string;
    placeHolder?: string;
}

export function ListingAgentAutoComplete (props: IAutocompleteProps) {
    const [open, setOpen] = useState(false);
    const {handleAgentSelect, inputValue, selectedAgent, setSelectedAgent, criteria, setCriteria, currentLoggedInUser, placeHolder} = props;
    const [options, setOptions] = useState([] as IListingAgentObj []);
    
    // RB-2354 - update partner_key for listing criteria from session (otherwise, it is always `default`)
    // and AutoComplete API will not work unless partney_key is set
    criteria.partner_key = localStorage.getItem('partner_key');
    
    useEffect(() => {
        if ( typeof inputValue !== 'undefined' && inputValue === null) {
            setSelectedAgent(null);
            clearAgent();
        }
    }, [inputValue]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    const clearAgent = () => {
        setCriteria({...criteria, agent_id: null});
    };

    const autoCompleteWithValue = (value) => {
        debounced(loadOptions, value);
    };
    const loadOptions = async (string) => {
        const autocompleteRes = await agentAutoCompleteApi.get(string, 10, criteria.partner_key, currentLoggedInUser);
        setOptions(autocompleteRes);
    };
    const handleOnInputChange = (value) => {
        autoCompleteWithValue(value);
    };

    const handleOnChange = (event, value) => {
        setSelectedAgent(value);
        handleAgentSelect(value);
    };

    return <Autocomplete
        disabled={props.disabled}
        id="fieldset-listing-agent"
        size="small"
        fullWidth={true}
        open={open}
        onOpen={() => {
            setOpen(true);
        }}
        onClose={() => {
            setOpen(false);
        }}
        getOptionSelected={(option, value) => option.name === value.name}
        options={options}
        value={selectedAgent}
        onChange={(e, val) => handleOnChange(e, val)}
        getOptionLabel={(option) => "" === option ? "" : option.name}
        style={{ backgroundColor: "white", outlineColor: "#337ab7", color: "#5B626E"}}
        disableClearable={true}
        renderInput={(params) => (
            <TextField
                {...params}
                variant="outlined"
                size="small"
                onChange={ev => {
                    if (ev.target.value === "" || ev.target.value === null) {
                        setSelectedAgent(ev.target.value);
                        clearAgent();
                    }
                    else {
                        handleOnInputChange(ev.target.value);
                    }
                }}
                style={{ border: "none" }}
                placeholder={placeHolder}
            />
        )}
    />
}