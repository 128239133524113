import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { imgUrl } from '../../../../../services/constants'
import { ListingActionButtons } from '../../../listing_action_buttons/listing_action_buttons'
import { BookmarksContext } from '../../bookmarks_context'
import { RBCheckbox } from '../../../../components/rb_checkbox/rb_checkbox'
import { ISelectedListing, SelectedListingsContext } from '../../selected_listings_context'
import { SweetAlertContext } from '../../../../components/sweet_alert/sweet_alert'
import { ListingSearchContext } from '../../../../components/listing_search/listing_search_context'
import { AgentUserContext } from '../../agent_user_context'
import { isMLSListing, isRbgListing } from '../../../../utils/listings'
import { UpgradePricingModal } from '../detail_search_results/upgrade_pricing_modal/upgrade_pricing_modal'
import { listingApi, IListing, EFieldsToInclude } from '../../../../../services/api/listing'
import { getStateAbbr } from '../../../../utils/get_state_abbr'
import { BrokerSearchingContext } from '../../broker_search_context';
import { browserName } from "react-device-detect";
import { ViewType } from '../../../../utils/device_info';
import { useTranslation } from "react-i18next";

interface IExtendedListing extends IListing {
    pageNumber: number;
}
interface IModalSummary {
    restListingProps: IListing;
    isChecked: boolean;
    onSelectedListing: (apartmentId: any) => void | null;
    onActiveModalId: (val: number) => void | null;
    onActiveTooltipId: (val: number) => void | null;
    onHoveredListingId: (val: number | null) => void | null;
    getActionButtons: (listing: IListing) => any;
    onClickOpenLDP: (apartmentId: any) => void | null;
    rentRange?: string;
    isAddressDisplay?: boolean;
    onSetCurrentPage?: (currentPage: number) => void | null;
    isMlsListing?: boolean;
    isRBGListing?: boolean;
    dom?: any;
    rentChange?: any
}


const ModalSummary: React.FC<IModalSummary> = ({
    isChecked,
    onSelectedListing,
    getActionButtons,
    onClickOpenLDP,
    restListingProps,
    rentRange,
    isAddressDisplay,
    isMlsListing,
    isRBGListing,
    dom,
    rentChange
}) => {
    const {
        rent, square_footage, bedrooms, bathrooms, city, address,
        state, zip, apartment_id, status, date_available, pics,
        building_name, disclaimer
    } = restListingProps

    const { isMlsAgent, isFullAgent, countryCode } = useContext(AgentUserContext)
    const { setShapeOptionLatLng } = useContext(ListingSearchContext)
    const { isMobileRes, viewType } = useContext( BrokerSearchingContext );
    const { t } = useTranslation( 'translation',{useSuspense: false});

    const removeExcludeDeleteModal = (e) => {
        e.stopPropagation()
        setShapeOptionLatLng({
            lat: null,
            lng: null,
        })
    }

    const getImage = () => {
        return (pics[0] || {})?.path || null
    }

    const handleDisplayFullAddress = () => {
        if (isFullAgent() || (isMlsAgent() && restListingProps.listing_source_type === "MLS")) {
            return (<>
                <span className='address'>{address} {city}</span>
                <span className='city'>{state}, {zip}</span>
            </>)
        }

        const addressString = isAddressDisplay
            ? { full_address: `${address}, ${city}`, state_zip: `${getStateAbbr(state, countryCode)} ${zip}` }
            : { full_address: city, state_zip: `${getStateAbbr(state, countryCode)} ${zip}` }

        return (<>
            {building_name && <span>{building_name}</span>}
            <span className='address'>{addressString.full_address}</span>
            <span className='city'>{addressString.state_zip}</span>
        </>)
    }

    const truncateStatus = (status) => {
        let truncatedStatus = status

        if (status.includes('App')) truncatedStatus = 'App Pending'

        return truncatedStatus
    }

    const listingType = () => {
        if (isMlsListing) {
            return (
                <div className="disclaimer-row row-mls">
                    <span>{t('MLS')}</span>
                </div>
            )
        } else if (disclaimer.rb_verified) {
            return <>
                {
                    <img
                        src={`${imgUrl}/icons/rb-verified.png`}
                        className="rb-check"
                        alt="rental beast reviewed"
                    />
                }
            </>
        } else if (isRBGListing) {
            return (
                <div className="disclaimer-row">
                    <span className="row-rbg">RBG</span>
                </div>
            )
        }
    }

    return (<div onClick={removeExcludeDeleteModal}>
        <div className='header'>
            <div style={{ width: '100%' }}>
                {((isMlsAgent() && restListingProps.listing_source_type === "MLS") || isFullAgent()) && (
                    <div className='action-section'>
                        <RBCheckbox name='' checked={isChecked}
                        handleClick={(e) => {
                            onSelectedListing(apartment_id)
                            removeExcludeDeleteModal(e)
                        }}
                        handleChange={(e) =>  {

                            onSelectedListing(apartment_id)
                            removeExcludeDeleteModal(e)
                        }} />
                    </div>
                )}
                <div className='address-section'>{handleDisplayFullAddress()}</div>
            </div>
            <div className='listing-type'>
                {listingType()}
            </div>
        </div>
        <div className='content'>
            <div className='image'>
            {rentRange && (<div className={`rent-range ${viewType === ViewType.MAP && browserName === 'Mobile Safari' ? "mobile-safari" : ""}`}>{rentRange}</div>)}
            {
                getImage()
                    ? <img src={`${imgUrl}/${getImage()}`}/>
                    : <img src={require('../../../../../../assets/images/no-image.png')}/>
            }
            </div>
            <div className='fields'>
                <span>Rental Beast ID:</span>
                <span>Status:</span>
                <span>Rent:</span>
                <span>Total SqFt:</span>
                <span>Beds:</span>
                <span>Baths:</span>
                <span>Available:</span>
                <span>DOM:</span>
            </div>
            <div className={`values ${viewType === ViewType.MAP && browserName === 'Mobile Safari' ? "mobile-safari" : ""}`}>
                <span>{apartment_id}</span>
                <span>{truncateStatus(status).toUpperCase()}</span>
                <span>${rent}{rentChange}</span>
                <span>{square_footage}</span>
                <span>{bedrooms}</span>
                <span>{bathrooms}</span>
                <span>{moment(date_available).format('l')}</span>
                <span>{dom}</span>
            </div>
        </div>
        <div className='divider'></div>
        <div className='actions'
            style={{flexDirection: isMobileRes ? "column" : "row"}}
        >
            <div className='action-buttons'>{getActionButtons(restListingProps)}</div>
            <div className='view-button'>
                <span onClick={() => onClickOpenLDP(apartment_id)}>View Listing</span>
            </div>
        </div>
    </div>)
}

export default ({
    bookmarks,
    handleBookmark,
    activeTooltipId,
    onActiveTooltipId,
    hoveredListingId,
    onHoveredListingId,
    activeModalId,
    onActiveModalId,
    isAddressDisplay,
    totalListingCount,
    onPreventZoomIn,
    isMlsListing,
    isRBGListing,
    coords,
    onCenterFocus,
    currentPage,
    onSetCurrentPage,
    openInviteApplicationModal,
    ...restListingProps
}) => {
    const {
        rent, square_footage, bedrooms, bathrooms, city, address,
        state, apartment_id, listing_source_type, apt_count, dom,
        rentChanges, 
    } = restListingProps

    const getRentChange = () => {
        const rentChangeVaue = ((rentChanges || [])[0] || {})
        const rentChangeAbsolute = rentChangeVaue?.absolute > 0
        let rentChange: any = null
        const arrowStyle: any = {
            fontWeight: 'bold',
            fontSize: '140%',
            margin: "-5px 0px 0px 0.5px",
            position: 'absolute'
        }

        if (rentChangeVaue?.absolute) {
            if (rentChangeAbsolute) rentChange = <span style={{ ...arrowStyle, color: '#81C784' }}>&uarr;</span>
            else rentChange = <span style={{ ...arrowStyle, color: 'red' }}>&darr;</span>
        }

        return rentChange
    }
    const {
        selectedListings,
        setSelectedListings,
        getSelectedListingIds,
        isMlsAgentAndNonMlsListing,
        isMlsAgentAndEmptyDisclaimer
    } = useContext(SelectedListingsContext)
    const { isMobileRes } = useContext( BrokerSearchingContext );

    const { isFullAgent, isMlsAgent , agentId, agentInfo} = useContext(AgentUserContext)
    const { toggleBookmarks } = useContext(BookmarksContext)
    const { setAlertVisible, setAlertType } = useContext(SweetAlertContext)
    const { criteria, setShapeOptionLatLng, getGroupListingUnits } = useContext(ListingSearchContext)

    const [multipleListings, setMultipleListings] = useState<IExtendedListing[]>([])
    const [isChecked, setIsChecked] = useState<boolean>(false)
    const [showShowUpgradeModal, setShowUpgradePageModal] = useState<boolean>(false)
    const [errorFetchedChecker, setErrorFetchedChecker] = useState<boolean>(false)
    const checkCurrPageAndListingPageNumber = (listing: IExtendedListing) => listing?.pageNumber === currentPage

    const removeExcludeDeleteModal = () => {
        setShapeOptionLatLng({
            lat: null,
            lng: null,
        })
    }

    useEffect(() => {
        const currentListing = multipleListings.find(checkCurrPageAndListingPageNumber)
        const isFound = selectedListings.some(({ listingId }: ISelectedListing) => listingId === (multipleListings.length ? currentListing?.apartment_id : apartment_id))

        setIsChecked(isFound)
    }, [selectedListings])

    useEffect(() => {
        checkSelectedListingAndCurrentListingId()
    }, [currentPage])

    useEffect(() => {
        if (!showShowUpgradeModal) {
          document.body.style.overflow = 'visible'
          document.body.style.paddingRight = '0px'
        }
    }, [showShowUpgradeModal])

    useEffect(() => {
        if (apt_count > 1 && (activeModalId === apartment_id)) {
            try {
                ;(async () => {
                    const  data = await getGroupListingUnits({
                        city,
                        state,
                        page_number: 1,
                        addresses: address,
                        sort_ascending: true,
                        is_for_consumer: true,
                        partner_key: 'default',
                        include_disclaimer: true,
                        mls_toggle_value: 'mixed',
                        sort_field: 'dateavailable',
                        include_near_close_matches: true,
                        include_neighborhood_filter_list: true,
                        statuses: criteria.statuses_list,
                        fields_to_include: [
                            EFieldsToInclude.square_footage,
                            EFieldsToInclude.city,
                            EFieldsToInclude.address,
                            EFieldsToInclude.bedrooms,
                            EFieldsToInclude.bathrooms,
                            EFieldsToInclude.status,
                            EFieldsToInclude.state,
                            EFieldsToInclude.zip,
                            EFieldsToInclude.apartment_id,
                            EFieldsToInclude.rent,
                            EFieldsToInclude.date_available,
                            EFieldsToInclude.pics,
                            EFieldsToInclude.building_name,
                            EFieldsToInclude.dom,
                            EFieldsToInclude.rent_changes,
                            EFieldsToInclude.use_apply_now
                        ]
                    })
                    if (data.length) {
                        const filteredMultipleListingData = data
                            .filter((listing: IListing) => listing.apartment_id !== apartment_id)

                        const newMultipleListingData = [restListingProps, ...filteredMultipleListingData]
                            .map((listing: IListing, index: number) => ({ ...listing, pageNumber: index + 1 }))
                        setMultipleListings(newMultipleListingData)
                    }
                })()
                setErrorFetchedChecker(false)
            } catch (err) {
                setErrorFetchedChecker(true)
            }
        }

        // cleanup function
        return () => {
            setMultipleListings([])
        }
    }, [activeModalId, errorFetchedChecker])

    const toggleUpgradeModal = () => {
        setShowUpgradePageModal(!showShowUpgradeModal)
    }

    const handleClickOpenLDP = (apartment_id: any) => {
        const ldpUrl = `/broker_portal/listing_details/${apartment_id}`
        const subscriptionsAndPaymentUrl = '/agent/subscriptions_and_payment'

        if (isFullAgent()) {
            window.open(ldpUrl, '_blank')
            return
        }

        if (isMlsAgent()) {
            if (isMLSListing(restListingProps) || isRbgListing(restListingProps)) {
                window.open(ldpUrl, '_blank');
            } else {
                if (isAddressDisplay) {
                    toggleUpgradeModal()
                } else {
                    window.open(subscriptionsAndPaymentUrl, '_blank')
                }
            }
        }

    }

    const isBookmarked = (listingId) => {
        return (
            bookmarks.findIndex((bookmark) => bookmark.address == address) > -1
        );
    }

    const onSuccess = () => {
        setAlertType("success");
        setAlertVisible(true);
    }

    const handleSelectedListing = (apartmentId: any) => {
        const isFound = selectedListings.some(({ listingId }: ISelectedListing) => listingId === apartmentId)

        const filteredSelectedListings = selectedListings.filter(({ listingId }: ISelectedListing) => listingId !== apartmentId)

        const newSelectedListings = [
            ...selectedListings,
            {
                listingId: apartmentId,
                groupListingId: apartmentId,
                pageNumber: criteria.page_number,
                listing_source_type
            }
        ]

        setSelectedListings(isFound ? filteredSelectedListings : newSelectedListings)
    }

    const getActionButtons = (listing) => {
        if (isMlsAgentAndNonMlsListing(listing) || isMlsAgentAndEmptyDisclaimer(listing)) {
            return (
                <ListingActionButtons
                    selectedListingIds={[listing.apartment_id]}
                    onSuccess={onSuccess}
                    excludeBookmark={true}
                    toggleBookmarks={toggleBookmarks}
                    isDisabled={false}
                    listing={listing}
                    includeFacebookShare={false}
                    excludeCMA={true}
                    excludeEmail={true}
                    excludePrint={true}
                    excludeShare={true}
                    removeExcludeDeleteModal={removeExcludeDeleteModal}
                    actionFromMap={true}
                    hasApplyNow={listing.use_apply_now && (listing?.status === 'Active' || listing?.status === 'Application Pending')}
                    openInviteApplicationModal={openInviteApplicationModal}
                />
            )
        } else {
            const selectedListingIds = getSelectedListingIds();
            return (
                <ListingActionButtons
                    selectedListingIds={selectedListingIds}
                    onSuccess={onSuccess}
                    excludeBookmark={isBookmarked(listing.apartment_id)}
                    toggleBookmarks={toggleBookmarks}
                    isDisabled={false}
                    listing={listing}
                    includeFacebookShare={true}
                    excludeClaim={isRBGListing}
                    excludeCMA={false}
                    excludeEmail={false}
                    excludePrint={false}
                    excludeShare={false}
                    removeExcludeDeleteModal={removeExcludeDeleteModal}
                    actionFromMap={true}
                    hasApplyNow={listing.use_apply_now && (listing?.status === 'Active' || listing?.status === 'Application Pending')}
                    openInviteApplicationModal={openInviteApplicationModal}
                />
            );
        }
    }

    const getRentRange = () => {
        const sortedRentPrices = multipleListings
            .map((listing: IExtendedListing) => listing.rent)
            .sort((a, b) => a - b)

        return `$${sortedRentPrices[0]} - $${sortedRentPrices[sortedRentPrices.length - 1]}`
    }

    const getPaginateCondition = () => ({ prev: currentPage === 1, next: currentPage === apt_count })

    const checkSelectedListingAndCurrentListingId = () => {
        const currentListing = multipleListings.find((listing: IExtendedListing) => checkCurrPageAndListingPageNumber(listing))
        setIsChecked(selectedListings.some(({ listingId }: ISelectedListing) => listingId === currentListing?.apartment_id))
    }

    return (<>
        {showShowUpgradeModal && (
          <UpgradePricingModal
            showModal={showShowUpgradeModal}
            modalAction={toggleUpgradeModal}
            totalListingCount={totalListingCount}
          />
        )}
        {((activeTooltipId === hoveredListingId) && (activeTooltipId === apartment_id)) && (
            <span
                id='pin-tooltip'
                className='listing_floating-tooltip'
                onMouseOut={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    onActiveTooltipId(0)
                    onHoveredListingId(null)
                }}
                onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    onCenterFocus(coords)
                    onActiveTooltipId(0)
                    onActiveModalId(apartment_id)
                    onSetCurrentPage(1)
                    removeExcludeDeleteModal()
                }}
            >
                ${rent}{apt_count <= 1 && getRentChange()}
                <span style={{ marginLeft: apt_count <= 1 && getRentChange() ? '10px' : '5px' }}>{square_footage}SqFt {bedrooms}Beds {bathrooms}Baths</span>
            </span>
        )}
        {(activeModalId === apartment_id) && (
            <div
                className={`listing_floating-modal-${apt_count > 1 ? `multiple-${isMlsListing ? 'mls' : 'rb'}` : 'single'}`}
                style={{zoom: isMobileRes ? "65%" : "unset"}}
            >
                {!multipleListings.length
                    ? (
                        <>
                        <ModalSummary
                            isChecked={isChecked}
                            onSelectedListing={handleSelectedListing}
                            onActiveModalId={onActiveModalId}
                            onActiveTooltipId={onActiveTooltipId}
                            onHoveredListingId={onHoveredListingId}
                            getActionButtons={getActionButtons}
                            onClickOpenLDP={handleClickOpenLDP}
                            restListingProps={restListingProps}
                            isAddressDisplay={isAddressDisplay}
                            isMlsListing={isMlsListing}
                            isRBGListing={isRBGListing}
                            dom={dom}
                            rentChange={getRentChange()}
                        />
                        </>
                    ) : multipleListings
                            .filter((listing: IExtendedListing) => checkCurrPageAndListingPageNumber(listing))
                            .map((listing: IExtendedListing) => {
                                const getDOM = () => (((listing || {}).dom || [])[0] || {})?.calculated_days_on_market || 'N/A'

                                const getRentChange = () => {
                                    const rentChangeVaue = (((listing || {}).rent_changes || [])[0] || {})
                                    const rentChangeAbsolute = rentChangeVaue?.absolute > 0
                                    let rentChange: any = null
                                    const arrowStyle: any = {
                                        fontWeight: 'bold',
                                        fontSize: '140%',
                                        margin: "-5px 0px 0px 0.5px",
                                        position: 'absolute'
                                    }

                                    if (rentChangeVaue?.absolute) {
                                        if (rentChangeAbsolute) rentChange = <span style={{ ...arrowStyle, color: '#81C784' }}>&uarr;</span>
                                        else rentChange = <span style={{ ...arrowStyle, color: 'red' }}>&darr;</span>
                                    }

                                    return rentChange
                                }
                                return (
                                    <ModalSummary
                                        key={listing.apartment_id}
                                        isChecked={isChecked}
                                        onSelectedListing={handleSelectedListing}
                                        onActiveModalId={onActiveModalId}
                                        onActiveTooltipId={onActiveTooltipId}
                                        onHoveredListingId={onHoveredListingId}
                                        getActionButtons={getActionButtons}
                                        onClickOpenLDP={handleClickOpenLDP}
                                        restListingProps={listing}
                                        rentRange={getRentRange()}
                                        isAddressDisplay={isAddressDisplay}
                                        onSetCurrentPage={onSetCurrentPage}
                                        isMlsListing={isMlsListing}
                                        isRBGListing={isRBGListing}
                                        dom={getDOM()}
                                        rentChange={getRentChange()}
                                    />
                                )
                            })
                }
                {apt_count > 1 && (
                    <div className='pagination'>
                        <span
                            onClick={(e) => {
                                removeExcludeDeleteModal()
                                e.stopPropagation()
                                e.preventDefault()
                                onPreventZoomIn(true)
                                getPaginateCondition().prev ? null : onSetCurrentPage(currentPage - 1)
                            }}
                            onMouseLeave={(e) => {
                                e.stopPropagation()
                                e.preventDefault()
                                onPreventZoomIn(false)
                            }}
                            className={getPaginateCondition().prev ? 'greyed-out' : ''}
                        >
                            &#x3C;
                        </span>
                        <span>{currentPage} of {apt_count} units</span>
                        <span
                            onClick={(e) => {
                                removeExcludeDeleteModal()
                                e.stopPropagation()
                                e.preventDefault()
                                onPreventZoomIn(true)
                                getPaginateCondition().next ? null : onSetCurrentPage(currentPage + 1)
                            }}
                            onMouseLeave={(e) => {
                                e.stopPropagation()
                                e.preventDefault()
                                onPreventZoomIn(false)
                            }}
                            className={getPaginateCondition().next ? 'greyed-out' : ''}
                        >
                            &#x3E;
                        </span>
                    </div>
                )}
            </div>
        )}
    </>)
}
