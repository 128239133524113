import React from "react";
import { IListing } from "../../../../../../services/api/listing";
import { SingleSelectedBox } from '../../../../../../assets/icons/SingleSelectedBox';

interface ISelectedRentPins {
    listingId: number;
    hoverListingAction: (listingId : number) => void;
    isSingleCheck: boolean;
    onActiveTooltipId: (val: number) => void | null;
    isMlsListing?: boolean;
    isMlsAgent?: () => boolean;
    onOpen?: () => void | null;
    isBubbleVisible: boolean;
    removeExcludeDeleteModal: () => void;
    coords: { lat: number, lng: number };
    onCenterFocus?: (coords?: { lat: number, lng: number }) => void | null;
    onActiveModalId?: (listingId?: number) => void | null;
    onSetCurrentPage?: (pageNumber: number) => void | null;
    groupedListings?: any[];
    onIndividualListing?: (individualListing : IListing) => void;
    listing?: IListing;
}
export const SelectedRentPins: React.FC<ISelectedRentPins> = ({
    listingId,
    hoverListingAction,
    isSingleCheck,
    onActiveTooltipId,
    isMlsListing,
    isMlsAgent,
    onOpen,
    removeExcludeDeleteModal,
    coords,
    onCenterFocus,
    onActiveModalId,
    onSetCurrentPage,
    onIndividualListing,
    listing,
}) => {
    const handleClickMarker = (e) => {
        e.stopPropagation()
        e.preventDefault()

        removeExcludeDeleteModal()
        if (isSingleCheck && (isMlsAgent() && !isMlsListing)) {
            onOpen()
            return
        }

        if (!isSingleCheck) {
            onCenterFocus(coords)
            onActiveTooltipId(0)
            onActiveModalId(listingId)
            onSetCurrentPage(1)
        } else {
            onIndividualListing(listing);
        }
    }

    return (
      <div
          style={{ cursor: "pointer" }}
          onClick={handleClickMarker}
          onTouchStart={handleClickMarker}
          onMouseEnter={(e) => {
              e.preventDefault()
              e.stopPropagation()
              hoverListingAction(listingId);
              onActiveTooltipId(listingId);
          }}
      >
          <SingleSelectedBox />
      </div>
    );
};
