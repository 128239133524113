import React, {useContext, useEffect, useState} from "react";
import "../fieldsets.scss";
import {FieldsetMultipleSelect} from "../multiple_select/fieldset_multiple_select";
import {ListingSearchContext} from "../../listing_search_context";

export function FieldsetBuildingFeatures() {
    const {getFilterOptions, filterConfig, criteria} = useContext(ListingSearchContext);
    const [options, setOptions] = useState([]);
    const [optionArr, setOptionArr] = useState(criteria.property_features);

    useEffect(()=>{
        setOptions(getFilterOptions("property_feature"));
    },[filterConfig]);

    useEffect(()=>{
        setOptionArr(criteria.property_features);
    }, [criteria.property_features]);

    return <FieldsetMultipleSelect
        options={options}
        optionArr={optionArr}
        setOptionArr={setOptionArr}
        title="Building Features"
        criteriaFieldName="property_features"
    />
}