import React, {useContext, useState, useEffect} from "react";
import {isMobile, ViewType} from "../../utils/device_info";
import {getEmptyLocationObj, ListingSearchContext} from "../../components/listing_search/listing_search_context";
import {shortcutsAlertsApi} from "../../../services/rails_api/broker_portal/shortcuts_alerts";
import {AgentUserContext} from "./agent_user_context";
import { UsePartnerConfig } from '../../components/hooks/use_partner_config';

interface IBrokerSearchContext {
    searching: boolean;
    setSearching: (search: boolean) => any;
    viewType: ViewType;
    setViewType: (newType: ViewType) => any;
    isMobileRes: boolean;
    setIsMobileRes: (boolean) => void;
    drawerWidth: number;
    listings: any[];
    setListings: (listings: any[]) => void;
    loadedShortcut: boolean;
    setLoadedShortcut: (val: boolean) => void;
    purgeMultipleLocations: (criteria) => any[];
    handlePersistLastSearch: () => void;
    persistLastSearch: boolean; // This flag is to avoid unnecessarily persisting the freshly loaded last_search (shortcut) when loaded and executed on page init
    setPersistLastSearch: (val: boolean) => void;
    lastSearchId: number;
    setLastSearchId: (id: number) => void;
    handleDeleteLastSearch: () => void;
    orderBy: string;
    setOrderBy: (id: string) => void;
    order?: 'asc' | 'desc';
    setOrder?: (order: 'asc' | 'desc') => void;
}

export const BrokerSearchingContext = React.createContext<IBrokerSearchContext>(null);

export function BrokerSearchingProvider(props) {

    const initialViewType = isMobile() ? ViewType.TILE : ViewType.LIST;
    const [searching, setSearching] = useState(null);
    const [viewType, setViewType] = useState(initialViewType as ViewType);
    const [isMobileRes, setIsMobileRes] = useState(isMobile());
    const [listings, setListings] = useState([]);
    const [loadedShortcut, setLoadedShortcut] = useState(false);
    const [persistLastSearch, setPersistLastSearch] = useState(false);
    const [lastSearchId, setLastSearchId] = useState(0);
    const [orderBy, setOrderBy] = useState(null);
    const [order, setOrder] = useState<'asc' | 'desc'>('asc');

    const {agentId, isFullAgent, isMlsAgent} = useContext(AgentUserContext);
    const {hideMapSearchView} = UsePartnerConfig();
    const {criteria, isListingControlClicked} = useContext(ListingSearchContext);
    const drawerWidth = 80;

    const handlePersistLastSearch = async () => {
        const searchParams = {...criteria, multiple_areas: purgeMultipleLocations(criteria)};

        const params = {
            criteria_name: "last_search",
            search_params: searchParams,
            agent_user_id: agentId,
        };

        let res;
        if(lastSearchId > 0) {
            params["shortcut_id"] = lastSearchId;
            res = await shortcutsAlertsApi.put(params);
        }
        else {
            res = await shortcutsAlertsApi.post(params);
        }

        if(200 === res.status) {
            console.log(`Last Search Persisted with method ${lastSearchId? "PUT" : "POST"}`);
            setLastSearchId(res.data.id);
        }
    };

    /**
     * Clears the following fields from the ILocationObj: matched_field, rbId, total
     * @returns and array of ILocationObj objects without the cleared fields above
     * @param criteria
     */
    const purgeMultipleLocations = (criteria) => {
        const multipleAreas = criteria.multiple_areas;
        const purgedMultipleAreas = [];
        const fields = ["address", "city", "metro", "neighborhood", "sub_neighborhood", "neighborhood_override", "state", "zip"];

        multipleAreas.forEach((location) => {
            const newLocation = getEmptyLocationObj();

            fields.forEach((fieldName) => {
                if(fields.includes(fieldName)) {
                    newLocation[fieldName] = location[fieldName];
                }
            });

            purgedMultipleAreas.push(newLocation);
        });

        return purgedMultipleAreas;
    };

    const handleDeleteLastSearch = () => {
        console.log("handleDeleteLastSearch executed from broker_searching_context");
        const params = {
            agent_user_id: agentId,
            shortcut_id: lastSearchId,
        };
        shortcutsAlertsApi.delete(params).then(r => r);
        setLastSearchId(0);
    };

    useEffect(() => {
        if (isMobile() && ((isFullAgent() && !hideMapSearchView) || (isMlsAgent() && !hideMapSearchView)) && !isListingControlClicked) {
            setViewType(ViewType.MAP);
        }
    }, [isMobile, isFullAgent, isMlsAgent, hideMapSearchView, isListingControlClicked]);

    return (
        <BrokerSearchingContext.Provider
            value={{
                searching,
                setSearching,
                viewType,
                setViewType,
                isMobileRes,
                setIsMobileRes,
                drawerWidth,
                listings,
                setListings,
                loadedShortcut,
                setLoadedShortcut,
                purgeMultipleLocations,
                handlePersistLastSearch,
                persistLastSearch,
                setPersistLastSearch,
                lastSearchId,
                setLastSearchId,
                handleDeleteLastSearch,
                orderBy,
                setOrderBy,
                order,
                setOrder,
            }}
        >
            {props.children}
        </BrokerSearchingContext.Provider>
    )
}
