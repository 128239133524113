import React, {useContext, useEffect, useState} from "react";
import {ListingSearchContext} from "../../listing_search_context";
import {MultipleLocationAutocomplete} from "../../autocomplete/multiple_location_autocomplete";
import "./fieldset_location.scss";

export function FieldsetLocation(props) {

    const {criteria, setCriteria, selectedLocations, setSelectedLocations} = useContext(ListingSearchContext);
    const partnerKey = sessionStorage.getItem('partner_key');
    
    const [locationInputValue, setLocationInputValue] = useState([]);

    useEffect(() => {
        setLocationInputValue(criteria.multiple_areas);
    }, [criteria.multiple_areas]);

    const handleSelectionChange = (locations) => {
        if (props.handleSearchLocation) {
            props.handleSearchLocation(locations)
            return
        }
        setCriteria({...criteria, multiple_areas: locations});
    };

    return <fieldset className={props.className}>
        <div>
            <label className="">{props.label ? props.label  : "Find Properties:"}</label>
        </div>
        <div className="fieldset--location-container">
            <MultipleLocationAutocomplete
                partnerKey={partnerKey}
                includeStatuses={criteria.statuses_list}
                inputValue={locationInputValue}
                selectedLocations={selectedLocations}
                setSelectedLocations={setSelectedLocations}
                onSelect={handleSelectionChange}
            />
        </div>
    </fieldset>
}