import React from "react";
import {IFilterGroup} from "./types";
import {FieldsetRentRange} from "../fieldsets/rent_range/fieldset_rent_range";
import {FieldsetAvailableDate} from "../fieldsets/available_date/fieldset_available_date";
import {FieldsetBuildingFeatures} from "../fieldsets/building_features/fieldset_building_features";
import {FieldsetStatus} from "../fieldsets/status/fieldset_status";
import {FieldsetLocation} from "../fieldsets/location/fieldset_location";
import {FieldsetServicesAndUtilities} from "../fieldsets/services_and_utilities/fieldset_services_utilities";
import {FieldsetOtherUnitFeatures} from "../fieldsets/other_unit_features/other_unit_features";
import {FieldsetExternalStructures} from "../fieldsets/external_structures/fieldset_external_structures";
import {FieldsetParking} from "../fieldsets/parking/fieldset_parking";
import {FieldsetBuildingType} from "../fieldsets/building_type/fieldset_building_type";
import {FieldsetOwnerPays} from "../fieldsets/owner_pays/fieldset_owner_pays";
import {FieldsetListingType} from "../fieldsets/listing_type/fieldset_listing_type";
import {FieldsetHeatType} from "../fieldsets/heat_type/fieldtype_heat_type";
import {FieldsetCoolingType} from "../fieldsets/cooling_type/cooling_type";
import {FieldsetOwnershipType} from "../fieldsets/ownership_type/fieldset_ownership_type";
import {FieldsetOnlyPhotos} from "../fieldsets/only_photos/fieldset_only_photos";
import {FieldsetVirtualShowingsTour} from "../fieldsets/virtual_showings_tour/fieldset_virtual_showings_tour";
import {FieldsetRbMlsId} from "../fieldsets/rb_mls_id/fieldset_rb_mls_id";
import {FieldsetDirectPhone} from "../fieldsets/direct_phone/fieldset_direct_phone";
import {FieldSetListingAgent} from "../fieldsets/listing_agent/fieldset_listing_agent";
import {FieldsetRoomTypes} from "../fieldsets/room_type/fieldset_room_types";
import {FieldsetAttachedDwelling} from "../fieldsets/attached_to_dwelling/fieldset_attached_dwelling";
import {FieldsetPetsAllowed} from "../fieldsets/pets/fieldset_pets_allowed";
import {FieldSetLaundryType} from "../fieldsets/laundry/laundry_types";
import {FieldsetGarageSpaces} from "../fieldsets/garage_spaces/fieldset_garage_spaces";
import {FieldsetYearBuilt} from "../fieldsets/year_built/fieldset_year_built";
import {FieldsetLotSquareFootage} from "../fieldsets/lot_square_footage/fieldset_lot_square_footage";
import {FieldsetFurnished} from "../fieldsets/furnished/fieldset_furnished";
import {FieldsetDeleaded} from "../fieldsets/deleaded/fieldset_deleaded";
import {FieldsetLeaseTerm} from "../fieldsets/lease_term/fieldset_lease_term";
import {FieldsetPropertyStructure} from "../fieldsets/property_structure/fieldset_property_structure";
import {FieldsetFloorType} from "../fieldsets/floor_type/fieldset_floor_type";
import {FieldsetFloorsInUnit} from "../fieldsets/floors_in_unit/fieldset_floors_in_unit";
import {FieldsetSquareFootageRange} from "../fieldsets/square_footage_range/fieldset_square_footage_range";
import {FieldsetFloor} from "../fieldsets/floor/fieldset_floor";
import {FieldsetSchools} from "../fieldsets/schools/fieldset_schools";
import {FieldsetDateActive} from "../fieldsets/date_active/fieldset_date_active";
import {FieldsetDaysOnMarket} from "../fieldsets/days_on_market/fieldset_days_on_market";
import {FieldsetFinishedSquareFootage} from "../fieldsets/finished_square_footage/fieldset_finished_square_footage";
import {FieldsetLeaseDate} from "../fieldsets/lease_date/fieldset_lease_date";
import {FieldsetLeaseRate} from "../fieldsets/lease_rate/fieldset_lease_rate";
import { FieldsetBedroomRange } from "../fieldsets/bedroom_range/fieldset_bedroom_range";
import { FieldsetBathroomRange } from "../fieldsets/bathroom_range/fieldset_bathroom_range";

type Filter = {
    module: any;
    name: string;
}

const getAllowedFilters = (filtersObjs: Filter [], exludeFilters: string []) => {
    if (!exludeFilters.length) {
        return filtersObjs;
    }
    return filtersObjs.filter(filterObj => {
        return !exludeFilters.includes(filterObj.name);
    })
}

export const getBpFilterConfig = (excludeFilters: string [] = [], hideAgentSearch: boolean = false, customStatusesFilters : any[], compensationFields: any[]): IFilterGroup[] => {

    return [
        {
            title: "General",
            isOpened: true,
            filters: getAllowedFilters([
                {module: <FieldsetLocation/>, name: "fieldset_location"},
                {module: <FieldsetRentRange minInputId="minRent1" maxInputId="maxRent1"  className={"advanced--rent-range"}/>, name: "fieldset_rent_range"},
                {module: <FieldsetBedroomRange minBedInputId="minBed1" maxBedInputId="maxBed1" className={"advanced--bedroom-range"}/>, name: "fieldset_bedroom_range"},
                {module: <FieldsetStatus customStatusesFilters={customStatusesFilters}/>, name: "fieldset_status"},
                {module: <FieldsetAvailableDate />, name: "fieldset_available_date"},
            ], excludeFilters)
        },
        {
            title: "Listing Criteria",
            isOpened: false,
            isExpandable: true,
            filters: getAllowedFilters([
                {module: <FieldsetRbMlsId />, name: "fieldset_rb_mls_id"},
                {module: <FieldsetListingType hideAgentSearch={hideAgentSearch} />, name: "fieldset_listing_type"},
                {module: <FieldsetLeaseTerm />, name: "fieldset_lease_term"},
                {module: <FieldSetListingAgent />, name: "fieldset_listing_agent"},
                {module: <FieldsetDirectPhone />, name: "fieldset_direct_phone"},
                (compensationFields.length && {module: <FieldsetOwnerPays />, name: "fieldset_owner_pays"}),
                {module: <FieldsetOnlyPhotos/>, name: "fieldset_only_photos"},
                {module: <FieldsetOwnershipType />, name: "fieldset_ownership_type"},
                {module: <FieldsetDateActive />, name: "fieldset_date_active"},
                // {module: <FieldsetDaysOnMarket minInputId="minDOM1" maxInputId="maxDOM1"  className={"advanced--rent-range"}/>, name: "fieldset_dom"},
                {module: <FieldsetVirtualShowingsTour/>, name: "fieldset_virtual_showings_tour"},
            ].filter(Boolean), excludeFilters)
        },
        {
            title: "Property Info",
            isOpened: false,
            isExpandable: true,
            filters: getAllowedFilters([
                {module: <FieldsetSchools/>, name: "fieldset_schools"},
                {module: <FieldsetBuildingType/>, name: "fieldset_building_type"},
                {module: <FieldsetPropertyStructure/>, name: "fieldset_property_structure"},
                {module: <FieldsetYearBuilt />, name: "fieldset_year_built"},
                {module: <FieldsetExternalStructures />, name: "fieldset_external_structures"},
                {module: <FieldsetBuildingFeatures />, name: "fieldset_building_features"},
                {module: <FieldsetParking/>, name: "fieldset_parking"},
                {module: <FieldsetGarageSpaces/>, name: "fieldset_garage_spaces"},
                {module: <FieldsetLotSquareFootage minInputId="minLSqFt1" maxInputId="maxLSqFt1"  className={"advanced--rent-range"}/>, name: "fieldset_lot_square_footage"},
                {module: <FieldsetAttachedDwelling />, name: "fieldset_attached_dwelling"},
            ], excludeFilters)
        },
        {
            title: "Unit Info",
            isOpened: false,
            isExpandable: true,
            filters: getAllowedFilters([
                {module: <FieldsetBathroomRange minBathInputId="minBath1" maxBathInputId="maxBath1"/>, name: "fieldset_bathoom_range"},
                {module: <FieldsetPetsAllowed/>, name: "fieldset_pets_allowed"},
                {module: <FieldSetLaundryType />, name: "fieldset_laundry_type"},
                {module: <FieldsetOtherUnitFeatures />, name: "fieldset_other_unit_features"},
                {module: <FieldsetHeatType />, name: "fieldset_heat_type"},
                {module: <FieldsetCoolingType />, name: "fieldset_cooling_type"},
                {module: <FieldsetServicesAndUtilities />, name: "fieldset_services_and_utils"},
                {module: <FieldsetFurnished />, name: "fieldset_furnished"},
                {module: <FieldsetFloorType />, name: "fieldset_floor_type"},
                {module: <FieldsetRoomTypes />, name: "fieldset_room_types"},
                {module: <FieldsetDeleaded />, name: "fieldset_deleaded"},
            ], excludeFilters)
        },
        {
            title: "Size & Levels",
            isOpened: true,
            isExpandable: false,
            filters: getAllowedFilters([
                {module: <FieldsetSquareFootageRange minInputId="minSqFt1" maxInputId="maxSqFt1"  className={"advanced--rent-range"}/>, name: "fieldset_square_footage"},
                {module: <FieldsetFinishedSquareFootage minInputId="minFSqFt1" maxInputId="maxFSqFt1"  className={"advanced--rent-range"}/>, name: "fieldset_finished_square_footage"},
                {module: <FieldsetFloor />, name: "fieldset_floor"},
                {module: <FieldsetFloorsInUnit />, name: "fieldset_floors_in_unit"},
            ], excludeFilters)
        },
        {
            title: "Leased Info",
            isOpened: true,
            isExpandable: false,
            filters: getAllowedFilters([
                {module: <FieldsetLeaseDate />, name: "fieldset_lease_date"},
                // Rented By   --- Phase 2
                {module: <FieldsetLeaseRate minInputId="minLR1" maxInputId="maxLR1"  className={"advanced--rent-range"}/>, name: "fieldset_lease_rate"},
            ], excludeFilters)
        },
    ];
}
