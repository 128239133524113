import React, {useContext, useEffect, useState} from "react";
import {Checkbox, FormControlLabel, Select} from "@material-ui/core";
import {ListingSearchContext} from "../../listing_search_context";
import "./fieldset_attached_dwelling.scss"

export function FieldsetAttachedDwelling () {
    const {criteria, setCriteria} = useContext(ListingSearchContext);
    const [selection, setSelection] = useState(criteria.attached_to_another_dwelling);

    const optionChoices = [
        {label: "Any", value: ""},
        {label: "Yes", value: "true"},
        {label: "No", value: "false"},
    ];

    // const handleChange = () => {
    //     setCriteria({...criteria, attached_to_another_dwelling: !criteria.attached_to_another_dwelling});
    // };

    const handleChange = (e) => {
        const selected = e.currentTarget.value;
        setSelection(selected);
        setCriteria({...criteria, attached_to_another_dwelling: selected});
    };

    useEffect(()=>{
        setSelection(criteria.attached_to_another_dwelling);
    },[criteria.attached_to_another_dwelling]);

    return (
        <fieldset className="fieldset--select-container">
            <label className="wide-only">Building Structure</label>
            <span className="separator" />
            {/*<FormControlLabel*/}
            {/*    control={<Checkbox checked={criteria.attached_to_another_dwelling} onChange={handleChange}/>}*/}
            {/*    label={`Is attached to another dwelling`}*/}
            {/*/>*/}
            <div>
                <label>Is attached to another dwelling</label>
            </div>
            <Select
                native
                value={selection}
                onChange={handleChange}
            >
                {/*<option aria-label="None" value="" >Any</option>*/}
                {
                    optionChoices.map((option) => (
                        <option value={option.value} key={option.value}>{option.label}</option>
                    ))
                }
            </Select>
        </fieldset>
    )
}