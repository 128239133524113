import React, {useContext, useEffect, useState} from "react";
import "./shortcuts_alerts.scss";
import {FiltersContext} from "../search_filters/filters_provider";
import {BootstrapTooltip, getNumOfCols} from "../search_filters/filters";
import {mapParamsForUI, shortcutsAlertsApi} from "../../../../services/rails_api/broker_portal/shortcuts_alerts";
import {AgentUserContext} from "../../../features/broker_search/agent_user_context";
import {ListingSearchContext} from "../listing_search_context";
import {IListingApiGetParams} from "../../../../services/api/listing";
import {SweetAlertContext} from "../../sweet_alert/sweet_alert";
import {BrokerSearchingContext} from "../../../features/broker_search/broker_search_context";
import {alertsApi} from "../../../../services/api/alerts";
import {getEmptyAlertObj} from "../../../features/broker_portal/alerts/alerts";

export function ShortcutsAlerts(props: IShortcutsProps) {

    const {show, setShow, shortcuts, setShortcuts, saveCallback} = props;
    const {colWidth, contWidth} = useContext(FiltersContext);
    const {agentId} = useContext(AgentUserContext);
    const {criteria, setCriteria} = useContext(ListingSearchContext);
    const {
        setAlertVisible,
        setAlertType,
        setAlertContent,
        setSweetAlertProps,
        handleCloseSweetAlert,
        triggerAlert
    } = useContext(SweetAlertContext);
    const {setLoadedShortcut, purgeMultipleLocations} = useContext(BrokerSearchingContext);

    const [selectedShortcutId, setSelectedShortcutId] = useState(null);
    const [newSelectedShortcut, setNewSelectedShortcut] = useState(false);
    const [newShortcutName, setNewShortcutName] = useState("");
    const [selectedNameEditId, setSelectedNameEditId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleNewSearchOnChange = (e) => {
        setNewShortcutName(e.currentTarget.value);
    };

    const handleNewSearchKeyPress = (e) => {
        if(e.key === 'Enter'){
            handleSave(e.currentTarget.value);
        }
    };

    const handleEditName = (shortcut) => {
        setSelectedNameEditId(shortcut.id);
    };

    const handleNameEditOnChange = async (e, shortcut) => {
        shortcut.criteria_name = e.currentTarget.value;

        const params = {
            shortcut_id: shortcut.id,
            agent_user_id: agentId,
            criteria_name: shortcut.criteria_name,
        };

        const res = await shortcutsAlertsApi.put(params);
        if(200 === res.status) {
            setSelectedNameEditId(null);
        }
    };

    const handleSave = async (newName=null) => {
        const searchParams = {...criteria, multiple_areas: purgeMultipleLocations(criteria)};

        const params = {
            criteria_name: newName || newShortcutName,
            search_params: searchParams,
            agent_user_id: agentId,
        };

        const res = await shortcutsAlertsApi.post(params);
        if(200 === res.status) {
            setNewShortcutName("");
            setSelectedShortcutId(res.data.id);
            saveCallback();
        }
    };

    const handleDelete = (shortcut) => {

        setAlertVisible(true);
        setAlertType('warning');
        setAlertContent(`Please confirm that you want to delete this search shortcut`);
        setSweetAlertProps({
            title: `Delete '${shortcut.criteria_name}'`,
            onConfirm: () => {
                setAlertVisible(false);
                deleteShortcut(shortcut);
            },
            showCancel: true,
            onCancel: () => {
                setAlertVisible(false);
            }
        })

    };

    const deleteShortcut = (shortcut) => {
        const params = {
            agent_user_id: agentId,
            shortcut_id: shortcut.id,
        };
        shortcutsAlertsApi.delete(params);
        setShortcuts( shortcuts.filter((obj) => obj.id !== shortcut.id) );
    };

    const handleSelect = (shortcut) => {
        setNewSelectedShortcut(true);
        setSelectedShortcutId(shortcut.id);
        setLoadedShortcut(true); // flag to indicate that fieldset_listing_agent needs to initialize agent

        const newCriteria = mapParamsForUI(shortcut.search_params);
        setCriteria(newCriteria);
        setTimeout( ()=>{setShow(false)}, 1000);
    };

    const handleEdit = async (shortcut) => {
        setIsLoading(true);
        const searchParams = {...criteria, multiple_areas: purgeMultipleLocations(criteria)};
        const params = {
            criteria_name: shortcut.criteria_name,
            search_params: searchParams,
            agent_user_id: agentId,
            shortcut_id: shortcut.id,
        };

        const res = await shortcutsAlertsApi.put(params);
        if(200 === res.status) {
            setIsLoading(false);
            saveCallback();
        }
    };

    const handleNameEditKeyPress = (e, shortcut) => {
        if(e.key === 'Enter'){
            handleNameEditOnChange(e, shortcut);
        }
    };

    const handleCreateAlert = (shortcut) => {
        setAlertVisible(true);
        setAlertType('info');
        setAlertContent(`You are about to create a new Alert based on this shortcut's search criteria.`);

        setSweetAlertProps({
            title: 'Confirm',
            showConfirm: true,
            showCancel: true,
            onConfirm: async () => {
                const alert = getEmptyAlertObj(agentId);
                alert.search_params = shortcut.search_params;
                alert.criteria_name = shortcut.criteria_name;
                createAlertFromShortcut(alert);
            },
            onCancel: () => {
                handleCloseSweetAlert();
            }
        });
    };

    const createAlertFromShortcut = async (alert) => {
        const response = await alertsApi.post(alert).catch(e => handleAPIError(e));
        if(response) {
            triggerAlert("success", <div>Your alert has been created and can be seen in the <a href='/broker_portal/alerts'>Alerts</a> page</div>);
        }
    };

    const handleAPIError = (e) => {
        triggerAlert("error");
    };

    const getSaveButton = (shortcut) => {

        if(shortcut.id === selectedShortcutId) {
            if(isLoading) {
                return <div className="loading-circle-icon" />
            }
            else {
                return <BootstrapTooltip title="Save search" onClick={() => handleEdit(shortcut)}>
                    <i className="far fa-save"/>
                </BootstrapTooltip>;
            }
        }
    };

    const getCheckmark = (shortcut) => {
        const selected = <i className="fas fa-check-circle selected-shortcut" />;
        const select = <BootstrapTooltip title="Use this search" onClick={() => handleSelect(shortcut)}>
            <i className="far fa-check-square"/>
        </BootstrapTooltip>;

        return shortcut.id === selectedShortcutId ? selected : select
    };

    const getNameInputClass = (shortcut) => {
      return shortcut.id === selectedNameEditId ? "shortcut-name-input show-el" : "shortcut-name-input hide-el"
    };

    const getNameLabelClass = (shortcut) => {
      return shortcut.id !== selectedNameEditId ? "show-el" : "hide-el"
    };

    const getNameEditDisplay = (shortcut) => {
        return shortcut.id === selectedNameEditId ? "none" : "" // hide the pencil icon when it's already on edit mode
    };

    useEffect(()=>{
        if(!selectedNameEditId) {return}
        const input = document.getElementById(`name-input_${selectedNameEditId}`);
        input.focus();
    }, [selectedNameEditId]);

    useEffect(()=>{
        if (!newSelectedShortcut) {
            setSelectedShortcutId(null);
        } else {
            setNewSelectedShortcut(false);
        }
    }, [criteria]);

    return (
        <div className="shortcuts-cont" style={{display: show ? "" : "none"}}>
            <div className="shortcut-input">
                <div className="shortcut-row main-input">
                    <input type="text" value={newShortcutName}
                           onChange={handleNewSearchOnChange}
                           onKeyPress={handleNewSearchKeyPress}
                           placeholder="Type shortcut name"
                           className="criteria--input"
                    />
                    <div className="add-a-shortcut" onClick={()=>{handleSave()}}>
                        <i className="fas fa-plus"/>
                        <div className="label">New search</div>
                    </div>
                </div>
            </div>
            <div className="shortcuts-columns column" style={{columnCount: getNumOfCols(contWidth, colWidth)}}>
                {
                    shortcuts.map((shortcut: IShortcut)=>{
                        return (
                            <div className="shortcut-row view-edit" key={`shortcut_${shortcut.id}`}>
                                <div className="shortcut-name">
                                    <span className={getNameLabelClass(shortcut)} onClick={()=>{handleEditName(shortcut)}}>{shortcut.criteria_name}</span>
                                    <input className={getNameInputClass(shortcut)}
                                           id={`name-input_${shortcut.id}`}
                                           onBlur={(e)=>handleNameEditOnChange(e, shortcut)}
                                           defaultValue={shortcut.criteria_name}
                                           onKeyPress={(e)=>handleNameEditKeyPress(e, shortcut)}
                                    />
                                    <BootstrapTooltip title="Change shortcut name" onClick={()=>{handleEditName(shortcut)}} style={{display: getNameEditDisplay(shortcut)}}>
                                        <i className="fas fa-pencil-alt"/>
                                    </BootstrapTooltip>
                                </div>
                                <div className="cta-cont">
                                    {getSaveButton(shortcut)}
                                    {getCheckmark(shortcut)}
                                    <BootstrapTooltip title="Create alert" onClick={()=>handleCreateAlert(shortcut)}>
                                        <i className="fa fa-bell" />
                                    </BootstrapTooltip>
                                    <BootstrapTooltip title="Delete search" onClick={()=>handleDelete(shortcut)}>
                                        <i className="far fa-trash-alt"/>
                                    </BootstrapTooltip>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>

    )
}

interface IShortcutsProps {
    shortcuts: IShortcut[],
    setShortcuts: (shortcuts: IShortcut[]) => void,
    show: boolean,
    setShow: (boolean) => void,
    saveCallback: () => void,
}

export interface IShortcut {
    criteria_name: string,
    id: number,
    modified_at: string,
    modified_by: string,
    search_params: IListingApiGetParams,
    user: number,
}