import React, {useContext, useEffect} from "react";
import "./styles/toolbar.scss";
import {ViewModeSelector} from "./view_mode";
import {FieldsetMinBedsArrows} from "../../components/listing_search/fieldsets/bedrooms_arrows/fieldset_min_beds_arrows";
import {ConsumerSearchContext} from "./consumer_search_context";
import {FieldsetMaxRent} from "../../components/listing_search/fieldsets/max_rent/fieldset_max_rent";
import {AdvancedSearch} from "./filters/advanced_search";

export function Toolbar() {
    const {setMapSearching, setTileSearching, tileSearching, mapSearching} = useContext(ConsumerSearchContext);

    useEffect(() => {
    }, []);

    const handleInputsChange = () => {
        setTileSearching(true);
        setMapSearching(true);
    };

    return (
        <section className="toolbar-wrapper">
            <div className="right-floated-tools">
                <FieldsetMinBedsArrows
                    className={"search-filter min-beds"}
                    onChange={handleInputsChange}
                    disabled={tileSearching || mapSearching}
                    inputProps={{
                        onBlur: handleInputsChange
                    }}
                />
                <FieldsetMaxRent
                    className="filter--max-rent"
                    inputProps={{
                        disabled: tileSearching || mapSearching,
                        placeholder: "Max Price",
                        onBlur: handleInputsChange
                    }}
                    />
                <AdvancedSearch className={"search-filter refine-search"} />
                <ViewModeSelector className={"search-filter view-mode-selector"} />
            </div>
        </section>
    )
}