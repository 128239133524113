import {REST} from "../rest";
import {apiURL} from "../constants";
import * as Querystring from "querystring";

export const bpEmailSender = {
    // post : async (props: IBpEmailSenderPost) => {
    post : async (props: any) => {
        // const formData = Querystring.stringify(props as any);
        const formData = props

        // return await REST.post(`${apiURL}/v1/bp_email_sender.json`,
        return await REST.post(`${apiURL}/rb_api/rest/client/received/email`,
            formData, {
                headers: {
                    // 'Content-Type': 'application/x-www-form-urlencoded'
                    'Content-Type': 'application/json'
                }
            }).then(res => {
            return res
        });
    }
};

interface IBpEmailSenderPost {
    user_id: number;
    subject: string;
    content: string;
    recipients: string [];
    cc_list: string [];
    bcc_list: string [];
}