import React from "react";
import {IFilterGroup} from "./types";
import {FieldsetAvailableDate} from "../fieldsets/available_date/fieldset_available_date";
import {FieldsetBuildingFeatures} from "../fieldsets/building_features/fieldset_building_features";
import {FieldsetLocation} from "../fieldsets/location/fieldset_location";
import {FieldsetServicesAndUtilities} from "../fieldsets/services_and_utilities/fieldset_services_utilities";
import {FieldsetOtherUnitFeatures} from "../fieldsets/other_unit_features/other_unit_features";
import {FieldsetExternalStructures} from "../fieldsets/external_structures/fieldset_external_structures";
import {FieldsetParking} from "../fieldsets/parking/fieldset_parking";
import {FieldsetBuildingType} from "../fieldsets/building_type/fieldset_building_type";
import {FieldsetPets} from "../fieldsets/pets/fieldset_pets";
import {FieldsetPublicTransit} from "../fieldsets/public_transit/fieldset_public_transit";
import {FieldsetHeatType} from "../fieldsets/heat_type/fieldtype_heat_type";
import {FieldsetCoolingType} from "../fieldsets/cooling_type/cooling_type";
import {FieldsetOnlyPhotos} from "../fieldsets/only_photos/fieldset_only_photos";
import {FieldsetRentRange} from "../fieldsets/rent_range/fieldset_rent_range";
import { FieldsetBedroomRange } from "../fieldsets/bedroom_range/fieldset_bedroom_range";
import { FieldsetBathroomRange } from "../fieldsets/bathroom_range/fieldset_bathroom_range";

export const ConsumerFilterConfig: IFilterGroup[] = [
    {
        title: "BASIC",
        isOpened: true,
        filters: [
            {module: <FieldsetLocation/>, name: "fieldset_location"},
            {module: <FieldsetRentRange minInputId="minRent1" maxInputId="maxRent1"  className={"advanced--rent-range"}/>, name: "fieldset_rent_range"},
            {module: <FieldsetBedroomRange minBedInputId="minBed1" maxBedInputId="maxBed1" className={"advanced--bedroom-range"}/>, name: "fieldset_bedroom_range"},
            {module: <FieldsetAvailableDate />, name: "fieldset_available_dates"},
        ]
    },
    {
        title: "UNIT & PROPERTY",
        isOpened: true,
        isExpandable: true,
        filters: [
            {module: <FieldsetBathroomRange minBathInputId="minBath1" maxBathInputId="maxBath1"/>, name: "fieldset_bathoom_range"},
            {module: <FieldsetServicesAndUtilities />, name: "fieldset_services_and_utilities"},
            {module: <FieldsetBuildingFeatures />, name: "fieldset_building_features"},
            {module: <FieldsetExternalStructures />, name: "fieldset_external_structures"},
            {module: <FieldsetHeatType />, name: "fieldset_heat_type"},
            {module: <FieldsetCoolingType />, name: "fieldset_cooling_type"},
            {module: <FieldsetPublicTransit />, name: "fieldset_public_transit"},
            {module: <FieldsetOtherUnitFeatures />, name: "fieldset_other_unit_features"},
        ]
    },
    {
        title: "OTHER",
        isOpened: true,
        isExpandable: true,
        filters: [
            {module: <FieldsetPets/>, name: "fieldset_pets"},
            {module: <FieldsetParking/>, name: "fieldset_parking"},
            {module: <FieldsetBuildingType/>, name: "fieldset_building_type"},
            {module: <FieldsetOnlyPhotos/>, name: "fieldset_only_photos"},
        ]
    },
];