import React, {useEffect, useState} from "react";
import {searchAutocompleteApi, searchAutocompleteApiV2} from "../../../../services/api/search_autocomplete";
import "./multiple_location_autocomplete.scss";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import _ from "lodash";
import {ILocationObj} from "../listing_search_context";
import {sorter_sortDescending} from "../../../utils/sorter";
import {searchAutocompletionApi} from "../../../../services/api/search_autocompletion";
import {useTranslation} from "react-i18next";
import { getConsumerData } from "../../../../services/api";
import { agentInfoApi } from "../../../../services/api/agent_info";

function updateOptions(loadOptions, value) {
    loadOptions(value);
}

const debounced = _.debounce(updateOptions, 300);

export const getOptionString = (data) => {
    if(!data) {return}

    const fields = ["address","neighborhood","sub_neighborhood","neighborhood_override","city","state","metro","zip"];
    let str = "";
    let skipComma = true;

    fields.forEach(function(fName, _index) {
        if(data[fName]) {
            if(skipComma) {
                str += data[fName];
                skipComma = false;
            }
            else {
                str +=`, ${data[fName]}`;
            }
        }
    });

    return str;
};

export function MultipleLocationAutocomplete (props: IAutocomplete) {
    const {partnerKey, inputValue, selectedLocations, setSelectedLocations, onSelect} = props;
    const [options, setOptions] = useState([]);
    const { t } = useTranslation( 'translation',{useSuspense: false});

    
    const loadOptions = async (string = '') => {
        const currentPath = sessionStorage.getItem('active_tab')
        const consumerData = await getConsumerData();
        const userId = consumerData["id"];
        const agentInfo = await agentInfoApi.get(userId);

        const isWhiteLabeled = $("body").attr('data-white-label') === "true";
        const includeMatches = isWhiteLabeled ? "address,state,metro,city,neighborhood,sub_neighborhood,neighborhood_override,zip" : "state,metro,city,neighborhood,sub_neighborhood,neighborhood_override,zip";
        const includeStatuses = ["Active", "Application Pending", "Rented", "Withdrawn", "Cancelled", "Expired", "Draft", "Application Only", "Pending New"];

        let autocompleteRes: any

        if (currentPath === 'listings') {
            autocompleteRes = await searchAutocompleteApiV2.get(agentInfo?.agent_user_id, string, partnerKey, includeStatuses.toString(), includeMatches);
        } else {
            autocompleteRes = await searchAutocompleteApi.get(string, partnerKey, includeStatuses.toString(), includeMatches);
        }

        const locationsIdentified = autocompleteRes.map((location, index) => {
            location['rbId'] = index;
            return location
        });
        sorter_sortDescending("total", locationsIdentified);
        setOptions(locationsIdentified.slice(0,10));
    };

    useEffect(() => {
        if ( typeof inputValue !== 'undefined') {
            setSelectedLocations(inputValue)
        }
    }, [inputValue]);

    const handleOnChange = (e, val) => {
        autoCompleteWithValue(val);
    };

    const autoCompleteWithValue = (value) => {
        debounced(loadOptions, value);
    };

    const handleOnSelect = (e, val) => {
        const filtereredValues = val.filter(item => typeof item === "object");
        setSelectedLocations(filtereredValues);
        onSelect(filtereredValues);
    };

    return (
        <Autocomplete
            multiple
            freeSolo={true}
            clearOnBlur
            limitTags={1}
            id="multiple-limit-tags"
            size="small"
            fullWidth={true}
            value={selectedLocations}
            options={options}
            onInputChange={(e, val) => handleOnChange(e, val)}
            onChange={(e, val) => handleOnSelect(e, val)}
            getOptionLabel={(option) => getOptionString(option)}
            style={{ backgroundColor: "white", outlineColor: "#337ab7", color: "#5B626E"}}
            renderInput={(params) => (
                <TextField {...params} label="" variant="outlined" placeholder={t('locationAutoCompletePlaceHolder')} size="small"/>
            )}
        />
    )
}
export function MultipleLocationAutocompletion (props: IAutocomplete) {
    const {partnerKey, inputValue, selectedLocations, setSelectedLocations, onSelect, includeMatches} = props;
    const [options, setOptions] = useState([]);

    const matches = () => {
        const isWhiteLabeled = $("body").attr('data-white-label') === "true";
        if (includeMatches) {
            return includeMatches;
        }
        else {
            return isWhiteLabeled ? "address,state,metro,city,neighborhood,sub_neighborhood,neighborhood_override,zip" : "state,metro,city,neighborhood,sub_neighborhood,neighborhood_override,zip";
        }
    }

    const loadOptions = async (string = '') => {
        const autocompleteRes = await searchAutocompletionApi.get(string, partnerKey, matches());

        const locationsIdentified = autocompleteRes.map((location, index) => {
            location['rbId'] = index;
            return location
        });
        sorter_sortDescending("total", locationsIdentified);
        setOptions(locationsIdentified.slice(0,10));
    };

    useEffect(() => {
        if ( typeof inputValue !== 'undefined') {
            setSelectedLocations(inputValue)
        }
    }, [inputValue]);

    const handleOnChange = (e, val) => {
        debounced(loadOptions, val);
    };

    const handleOnSelect = (e, val) => {
        const filtereredValues = val.filter(item => typeof item === "object");
        setSelectedLocations(filtereredValues);
        onSelect(filtereredValues);
    };

    return (
        <Autocomplete
            multiple
            freeSolo={true}
            clearOnBlur
            limitTags={1}
            id="multiple-limit-tags"
            size="small"
            fullWidth={true}
            value={selectedLocations}
            options={options}
            onInputChange={(e, val) => handleOnChange(e, val)}
            onChange={(e, val) => handleOnSelect(e, val)}
            getOptionLabel={(option) => getOptionString(option)}
            style={{ backgroundColor: "white", outlineColor: "#337ab7", color: "#5B626E"}}
            renderInput={(params) => (
                <TextField {...params} label="" variant="outlined" placeholder="City or Neighborhood" size="small"/>
            )}
        />
    )
}

export interface IAutocomplete {
    partnerKey: string;
    includeStatuses?: string[];
    inputValue: ILocationObj [];
    selectedLocations: ILocationObj [];
    setSelectedLocations: (locations: ILocationObj []) => void;
    onSelect: (locations: ILocationObj []) => void;
    includeMatches?: string;
}