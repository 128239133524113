import { apiURL } from "../../constants";
import {REST} from "../../rest";
import moment from "moment";

export const agentClientsApi = {
    getList : async (params: IClientGetParams): Promise<IClientRes []> => {

        const url = `/api/agent/${params.agentId}/clients`;
        return await REST.get(url,
            {
                params: params
            }).then(res => {
                return res.data.client_list;
            });
    },

    searchClient: async (params) => {
        const url = `${apiURL}/rb_api/rest/applynow/landlord/summary/${params.listingId}/search/${params.name}`;
        return await REST.get(url).then(res => {
                return res;
        });
    },

    get : async (clientId: number, agentId: number) => {
        const url = `/api/agent/${agentId}/clients/${clientId}`;
        return await REST.get(url, {}).then(res => {
            return res.data;
        });
    },

    post: async (params:IPostPutClientParams) => {
        const url = `/api/agent/${params.agentId}/clients`;
        return await REST.post(url, params.client).then(res => {
            return res
        });
    },

    put : async (params:IPostPutClientParams) => {
        const client = params.client;
        const clientId = client.id;

        const url = `/api/agent/${params.agentId}/clients/${clientId}`;
        return await REST.put(url, params.client).then(res => {
            return res
        });
    },

    delete : async (clientId: number, agentId: number) => {
        return await REST.delete(`/api/agent/${agentId}/clients/${clientId}`,
            {}, true).then(res => {
            return res
        });

    },
};

export enum clientUser {
    Landlord,
    Agent
}

export interface IClientRes {
    id: number;
    modifiedByValue: string;
    createdAtValue: Date;
    modifiedAtValue: Date;
    next_lease_end_date: Date;
    clientPropertyCards: any;
    communicationLogs: any;
    created_by: string;
    status: "Client" | "Prospect" | "Dormant" | "Inactive" | "Active"; //Need to add the rest of possible client status
    email: string;
    phone: string;
    address: string;
    street_address: string;
    city: string;
    state: string;
    zip_code: string;
    client_type: string;
    education_type: string;
    unit_count: number;
    general_notes: string;
    name: string;
    first_name: string;
    last_name: string;
    owner: number
    name_ln: string;
    client_type_detail: string;
    client_user: clientUser;
    main_email: string;
    main_phone: string;
}

interface IClientGetParams {
    agentId: string;
    clientId?: number;
    type?: string;
    order_by_desc?: string;
    offset?: number;
    limit?: number;
}

export interface IPostPutClientParams {
    client: IClient;
    agentId: number;
}

export interface IDeleteClientParams {
    client_id: number;
}

interface IClient {
    owner: number;
    client_type?: "Renter" | "Landlord";
    first_name: string;
    last_name: string;
    main_email?: string;
    main_phone?: string;
    alternate_phone?: string;
    street_address?: string;
    city?: string;
    state?: string;
    zip_code?: string;
    unit_count?: number;
    general_notes?: string;
    client_type_detail?: string;
    phones?: string,
    created_at?: string,
    modified_by?: string,
    modified_at?: string,
    created_by?: string,
    address?: string,
    id?: number;
    client_user?: number;
    name_ln?: string;
    emails?: string;
    education_type?: string;
    birth_date?: string;
    lead_source?: string;
    status: "Client" | "Prospect" | "Dormant" | "Inactive" | "Active";
}

interface IClientFeeDeposits {
    broker_fee?: boolean;
    first_month_rent?: boolean;
    security_deposit?: boolean;
    last_month_rent?: boolean;
    fee_comments?: string;

}
export interface IClientCommitment {
    id?: number;
    client_id?: number;
    birth_date?: string;
    college_graduation?: string;
    lease_expiration?: string;
    income?: string;
    want_to_buy?: boolean;
    buy_scale?: string;
    buy_note?: string;
    other_note?: string;
}

export interface IClientListingPreference {
    move_in_start?: string;
    move_in_end?: string;
    lease_length_months?: number;
    min_bedrooms?: number;
    basement?: boolean;
    rent_min?: number;
    rent_max?: number;
    locations?: IClientLocation [];
    is_focused: boolean;
    focused_areas: string;
    must_have?: string;
    nice_to_have?: string;
    near_transportation: boolean;
    near_transportation_text?: string;
    commute?: boolean;
    commute_text?: string;
    parking?: boolean;
    parking_spots?: string;
    pets?: boolean;
    pets_text?: string;
    seen_props?: boolean;
    seen_props_text?: string;
    showing_times?: string;
    client_fees_deposits: IClientFeeDeposits;
    moving: string [];
    other_moving: string;
}

export interface IClientLocation {
    name: string;
    city?: string;
    state: string;
    type: string;
}

export interface IRenterClient extends IClient {
    client_commitment: IClientCommitment;
    client_listing_preference: IClientListingPreference;
}

export interface ILandlordClient extends IClient {}

const defaultClientShape: IClient = {
    owner: 0,
    client_type: "Landlord",
    first_name: "",
    last_name: "",
    main_email: "",
    main_phone: "",
    alternate_phone: "",
    phones: "",
    street_address: "",
    city: "",
    state: "",
    zip_code: "",
    general_notes: "",
    status: "Active",
};

export const defaultRenterClientShape: IRenterClient = {
    ...defaultClientShape,
    client_listing_preference: {
        move_in_start: null,
        move_in_end: null,
        lease_length_months: 12,
        min_bedrooms: 1,
        basement: true,
        rent_min: 0,
        rent_max: 0,
        is_focused: false,
        focused_areas: "",
        must_have: "",
        nice_to_have: "",
        near_transportation: false,
        near_transportation_text: "",
        commute: false,
        commute_text: "",
        parking: false,
        parking_spots: "1",
        pets: false,
        pets_text: "",
        seen_props: false,
        seen_props_text: "",
        showing_times: "",
        moving: [],
        other_moving: "",
        locations: [],
        client_fees_deposits: {
            broker_fee: false,
            first_month_rent: false,
            security_deposit: false,
            last_month_rent: false,
            fee_comments: "",
        }
    },
    client_commitment: {
        birth_date: "",
        college_graduation: "",
        lease_expiration: "",
        income: "",
        want_to_buy: false,
        buy_scale: "",
        buy_note: "",
        other_note: "",
    },
};

export const defaultLandlordClientShape: ILandlordClient = {
  ...defaultClientShape,
    unit_count: 1,
};

export const formatClientListingPreferenceData = (values: IClientListingPreference) => {
    const endValues = {
        ...values,
        focused_areas: values.is_focused === true ? values.focused_areas : "",
        near_transportation_text: values.near_transportation === true ? values.near_transportation_text : "",
        commute_text: values.commute === true ? values.commute_text : "",
        parking_spots: values.parking === true ? values.parking_spots : "",
        pets_text: values.pets === true ? values.pets_text : "",
        seen_props_text: values.seen_props === true ? values.seen_props_text : "",
        move_in_start: values.move_in_start ? moment(values.move_in_start).format('MM-DD-YYYY') : null,
        move_in_end: values.move_in_end ? moment(values.move_in_end).format('MM-DD-YYYY') : null,
        moving: values.moving.filter(String)
    }
    return endValues;
};

export const formatClientCommitmentData = (values: IClientCommitment) => {
    return {
        ...values,
        birth_date: values.birth_date ? moment(values.birth_date).format('MM-DD-YYYY') : "",
        lease_expiration: values.lease_expiration ? moment(values.lease_expiration).format('MM-DD-YYYY') : "",
    }
};