export const getContactInfo = (listing) => {
  const isExclusiveAgent =
    listing.listing_agent_id && listing.listing_agent_id > 0;
  const agentInfo = listing.agent_info;
  const ownerInfo = listing?.owner_info;

  let ownerLabel: string;
  const propertyOwnerTypeAgent = "Agent";
  const propertyOwnerTypePrivate = "Private Owner";

  if (listing?.property_owner_type === propertyOwnerTypeAgent) {
    ownerLabel = "Listing Agent";
  } else if (listing?.property_owner_type === propertyOwnerTypePrivate) {
    ownerLabel = "Individual Name";
  } else {
    ownerLabel = "Management Company";
  }

  const hasCoAgent =
    listing.listing_agent_id_other && listing.listing_agent_id_other > 0;
  const coAgentInfo = listing.co_agent_info;
  return hasCoAgent
    ? [
        listing.listing_source_type != "MLS" && {
          label: "Type of Contact",
          value: listing?.property_owner_type,
        },
        {
          label:
            listing.listing_source_type == "MLS" ? "Listing Agent" : ownerLabel,
          value:
            isExclusiveAgent && ownerInfo
              ? ownerInfo?.full_name
              : listing.management_co,
        },
        {
          label: "Agent Phone",
          value:
            isExclusiveAgent && ownerInfo
              ? ownerInfo?.phone
              : listing.direct_phone,
        },
        {
          label: "Agent Email",
          value:
            isExclusiveAgent && ownerInfo ? ownerInfo?.email : listing.email,
        },
        { label: "Co-agent", value: coAgentInfo?.full_name },
        { label: "Co-agent Phone", value: coAgentInfo?.phone },
        { label: "Co-agent Email", value: coAgentInfo?.email },
      ]
    : [
        (listing.listing_source_type != "MLS" && { label: "Type of Contact", value: listing?.property_owner_type }),
        {
          label: ownerLabel,
          value:
            isExclusiveAgent && ownerInfo
              ? ownerInfo?.full_name
              : listing.management_co,
          showFull: true,
        },
        {
          label: "Contact Phone",
          value: ownerInfo?.phone || listing.direct_phone,
          showFull: true,
        },
        {
          label: "Contact Email",
          value:
            isExclusiveAgent && ownerInfo ? ownerInfo?.email : listing.email,
          showFull: true,
        },
        { label: "Broker Office Name", value: agentInfo?.brokerage_name },
        { label: "Broker Office Phone", value: agentInfo?.office_phone },
        {
          label: "Broker Office Address",
          value: `${agentInfo?.office_address?.address ?? ''} ${agentInfo?.office_address?.city ?? ''} ${agentInfo?.office_address?.state ?? ''} ${agentInfo?.office_address?.zip ?? ''}`,
        },
      ];
};
