import React, {useContext, useEffect, useState} from "react";
import {ListingSearchContext} from "../../listing_search_context";
import {ListingAgentAutoComplete} from "../../autocomplete/listing_agent_autocomplete";
import {agentInfoApi} from "../../../../../services/api/agent_info";
import {ListingType} from "../../../../../services/api/listing";
import {BrokerSearchingContext} from "../../../../features/broker_search/broker_search_context";

export function FieldSetListingAgent (props) {
    const {criteria, setCriteria} = useContext(ListingSearchContext);
    const {loadedShortcut, setLoadedShortcut} = useContext(BrokerSearchingContext);
    const [selectedAgent, setSelectedAgent] = useState(null);
    const [isActive, setIsActive] = useState(false);
    const listingType = ListingType;

    const handleAutocompleteSelect = (newAgent) => {
        setCriteria({...criteria, agent_id: newAgent.user_id});
    };

    const loadListingAgent = async (agentId) => {
        const agentInfoRes = await agentInfoApi.get(agentId);
        const agentObj = {
            agent_id: agentInfoRes.ext_agent_id,
            name: agentInfoRes.full_name,
            email: agentInfoRes.email,
            user_id: agentInfoRes.agent_user_id,
        };
        setSelectedAgent(agentObj);
        setLoadedShortcut(false);
    };

    useEffect(()=>{
        const val = criteria.listing_type === (ListingType.other_agent_listings || listingType.agent_listings);
        setIsActive(val);
    },[loadedShortcut, criteria.listing_type]);

    useEffect(()=>{
        if(criteria.agent_id) {
            loadListingAgent(criteria.agent_id);
        }
    }, [criteria.agent_id]);

    return (
        <fieldset className={props.className}>
            <div>
                <label className={!isActive ? "disabled-label" : ""}>Agent</label>
            </div>
            <div>
                <ListingAgentAutoComplete
                    disabled={!isActive}
                    handleAgentSelect={handleAutocompleteSelect}
                    inputValue={criteria.agent_id}
                    selectedAgent={selectedAgent}
                    setSelectedAgent={setSelectedAgent}
                    criteria={criteria}
                    setCriteria={setCriteria}
                />
            </div>
        </fieldset>
    );
}