import React from "react";
import { imgUrl } from "../../../../../services/constants";
import { IComponentProps } from "./full_report_type/full_report_component_type";

interface IAgentInfoProps extends IComponentProps {
  agentName?: string;
  agentEmail?: string;
  agentContact?: string | number;
  agentBrokerLogo?: string;
}

export const AgentInfoFullReport: React.FC<IAgentInfoProps> = ({
  listing,
  agentName,
  agentEmail,
  agentContact,
  agentBrokerLogo,
}) => {
  const getPictureUrl = () => {
    const picPath = agentBrokerLogo;
    const pic = `${imgUrl}/home/rb-pictures${picPath}`.split(".");
    pic.pop();
    return pic.join(".");
  };
  return (
    <div style={{ display: "table" }}>
      <div
        style={{
          height: 300,
          width: 500,
          display: "inline-block",
          marginRight: 20,
        }}
      >
        {listing.pic_count > 0 ? (
          <img
            src={imgUrl + "/" + listing.pics[0].path}
            alt=""
            height={"100%"}
            width={"100%"}
            style={{ objectFit: "cover" }}
          />
        ) : (
          <img
            src={imgUrl + "/no-listing.rbm"}
            alt=""
            height={"100%"}
            width={"100%"}
            style={{ objectFit: "cover" }}
          />
        )}
      </div>
      <div
        style={{
          display: "inline-block",
          border: "1px solid black",
          verticalAlign: "top",
          padding: 20,
          boxSizing: "border-box",
          height: 300,
        }}
      >
        <div style={{ display: "flex", height: "100%", width: "100%" }}>
          <div style={{ display: "inline-block", height: "100%", width: 250 }}>
            <img
              src={getPictureUrl()}
              alt=""
              height={"100%"}
              width={"100%"}
              style={{ objectFit: "cover" }}
            />
          </div>
          <div
            style={{
              display: "inline-block",
              verticalAlign: "top",
              paddingLeft: 20,
            }}
          >
            <p>
              <b>{agentName}</b>
            </p>
            <p>{agentContact}</p>
            <p>{agentEmail}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
