import React from "react";
import { IComponentProps } from "./full_report_type/full_report_component_type";

export const RemarksFullReport: React.FC<IComponentProps> = ({ listing }) => {
  return (
    <div style={{ marginTop: 10 }}>
      <h5 style={{ fontSize: 15, fontWeight: "bold" }}>Remarks</h5>
      <table
        style={{
          background: "#ececec",
          borderTop: "1px solid black",
          padding: "10px 20px",
          fontSize: "small",
          width: "100%",
        }}
      >
        <tr>
          <td style={{ width: "30%" }}>
            <div>Description:</div>{" "}
          </td>
          <td style={{ paddingLeft: 10 }}>
            <div style={{ width: "100%", fontWeight: "bold" }}>
              <div dangerouslySetInnerHTML={{ __html: listing.comments }} />
            </div>
          </td>
        </tr>
      </table>
    </div>
  );
};
