import React, { useState, useContext, useEffect, useMemo, useRef } from 'react'
import styles from '../../../../broker_portal/listing_details/listing_details.module.scss'
import { PhotosSlider } from '../../../../broker_portal/listing_details/components/photos_slider/photos_slider'
import { MainInfo } from '../../../../broker_portal/listing_details/components/main_info/main_info'
import { GeneralInfo } from '../../../../broker_portal/listing_details/components/general_info/general_info'
import {
    EFieldsToInclude,
    formatSettingsToLocalV2,
    IListingLocal,
    listingApi,
} from '../../../../../../services/api/listing'
import { AgentUserContext } from '../../../agent_user_context'
import { applyNowAvailableListingApi } from '../../../../../../services/api/applynow_available_listing'
import { updateSessionInfo } from '../../../../../../services/session'
import LocalLogicSDK from "@local-logic/sdks-js";
import { localLogicSdkKey } from '../../../../../../services/constants';
import { convertToUnderscoreLowerCase } from '../../../../../components/listing_search/utilities'
import { partnerConfigApi } from '../../../../../../services/api/partner_config'

const fieldsInclude = [
    EFieldsToInclude.address,
    EFieldsToInclude.agent_info,
    EFieldsToInclude.co_agent_info,
    EFieldsToInclude.apartment_id,
    EFieldsToInclude.state,
    EFieldsToInclude.feed_building_name,
    EFieldsToInclude.city,
    EFieldsToInclude.zip,
    EFieldsToInclude.apt_number,
    EFieldsToInclude.type_contact,
    EFieldsToInclude.management_co,
    EFieldsToInclude.first_name,
    EFieldsToInclude.last_name,
    EFieldsToInclude.latitude,
    EFieldsToInclude.longitude,
    EFieldsToInclude.listing_agent_id,
    EFieldsToInclude.listing_agent_id_other,
    EFieldsToInclude.status,
    EFieldsToInclude.mls_id,
    //
    EFieldsToInclude.date_available,
    EFieldsToInclude.rent,
    EFieldsToInclude.financial_reqs,
    EFieldsToInclude.showing_instructions_listing,
    EFieldsToInclude.move_in_costs,
    EFieldsToInclude.move_in_specials_tenant,
    EFieldsToInclude.compensation_remarks,
    EFieldsToInclude.owner_pay,
    EFieldsToInclude.owner_pay_amount,
    EFieldsToInclude.compensation_data,
    EFieldsToInclude.renter_insurance,
    //
    EFieldsToInclude.broker_notes,
    EFieldsToInclude.application_procedure,
    EFieldsToInclude.partner_private_notes,
    EFieldsToInclude.agent_listing_notes,
    EFieldsToInclude.hide_partner_cp,
    EFieldsToInclude.hide_rb_cp,
    EFieldsToInclude.hide_for_market_bp,
    EFieldsToInclude.allow_syndication,
    EFieldsToInclude.la_reviewer,
    //
    EFieldsToInclude.pics,
    EFieldsToInclude.docs,
    //
    EFieldsToInclude.external_structures,
    EFieldsToInclude.property_structure,
    EFieldsToInclude.property_features,
    EFieldsToInclude.property_owner_type,
    EFieldsToInclude.contract_type,
    EFieldsToInclude.owner_info,
    EFieldsToInclude.middle_school,
    EFieldsToInclude.high_school,
    EFieldsToInclude.elementary_school,
    EFieldsToInclude.school_district,
    EFieldsToInclude.condo_association_name,
    EFieldsToInclude.condo_association_address,
    EFieldsToInclude.parking_options,
    EFieldsToInclude.other_parking,
    EFieldsToInclude.parking_fee,
    EFieldsToInclude.pet_fee,
    EFieldsToInclude.hoa_fee,
    EFieldsToInclude.lot_square_footage,
    EFieldsToInclude.restrictions,
    EFieldsToInclude.year_built,
    //
    EFieldsToInclude.virtual_showing_allowed,
    EFieldsToInclude.virtual_tour_url,
    EFieldsToInclude.virtual_showing_notes,
    EFieldsToInclude.disclosure,
    EFieldsToInclude.showing_instructions,
    EFieldsToInclude.key_info,
    EFieldsToInclude.key_code,
    EFieldsToInclude.unit_occupied,
    EFieldsToInclude.open_house_schedule,
    //
    EFieldsToInclude.building_type,
    EFieldsToInclude.square_footage,
    EFieldsToInclude.bedrooms,
    EFieldsToInclude.bathrooms,
    EFieldsToInclude.three_quarter_bathrooms,
    EFieldsToInclude.full_bathrooms,
    EFieldsToInclude.half_bathrooms,
    EFieldsToInclude.floor,
    EFieldsToInclude.floor_types,
    EFieldsToInclude.furnished,
    EFieldsToInclude.ac_type,
    EFieldsToInclude.heat_type,
    EFieldsToInclude.utilities_available,
    EFieldsToInclude.utilities_included,
    EFieldsToInclude.comments,
    EFieldsToInclude.other_utility,
    EFieldsToInclude.unit_features,
    EFieldsToInclude.laundry,
    EFieldsToInclude.pet_types,
    EFieldsToInclude.pet_specs,
    EFieldsToInclude.pets,
    EFieldsToInclude.direct_phone,
    EFieldsToInclude.email,
    EFieldsToInclude.disclaimer,
    EFieldsToInclude.long_term_commission,
    EFieldsToInclude.neighborhood,
    EFieldsToInclude.sublet,
    EFieldsToInclude.sublet_term,
    EFieldsToInclude.lease_term_details,
    EFieldsToInclude.deleaded,
    EFieldsToInclude.building_name,
    EFieldsToInclude.public_transit,
    EFieldsToInclude.finished_square_footage,
    EFieldsToInclude.building_ownership,
    EFieldsToInclude.service_level,
    EFieldsToInclude.additional_monthly_charge,
    EFieldsToInclude.additional_monthly_charge_comments,
    EFieldsToInclude.attached_to_another_dwelling,
    EFieldsToInclude.hoa_covenant,
    EFieldsToInclude.rented_equipment,
    EFieldsToInclude.floors_in_unit,
    EFieldsToInclude.room_types,
    EFieldsToInclude.mls_entry_date,
    EFieldsToInclude.county,
    // Agent fields
    EFieldsToInclude.is_listing_free,
    EFieldsToInclude.is_agent_listing,
    EFieldsToInclude.days_on_market,
    EFieldsToInclude.garage_spaces,
    EFieldsToInclude.third_party_application_url,
    EFieldsToInclude.other_application_instructions,
    EFieldsToInclude.use_apply_now,
    EFieldsToInclude.use_paper,
    EFieldsToInclude.use_third_party,
    // Showing info
    EFieldsToInclude.showing_time,
    // Days on Market
    EFieldsToInclude.dom,
    EFieldsToInclude.rent_changes
  ]

  //RB-2010: LocalLogicSDK initialization plus key from constants
  const localContent = LocalLogicSDK(localLogicSdkKey, { locale: "en" });

export const PaywallWrapper = (props) => {
    const [listing, setListing] = useState({} as IListingLocal);
    const [leaseData, setLeaseData] = useState(null);
    const { agentId, agentInfo } = useContext(AgentUserContext);
    const [applyNowEnabled, setApplyNowEnabled] = useState(false);
    const containerRef = useRef(null);
    const [userDefinedFields, setUserDefinedFields] = useState([]);

    const getUDFs = async () => {
      const partnerConfigRes = await partnerConfigApi.get(agentInfo.partner_key, ["udf"], 'broker_portal', 'listing_details');
      setUserDefinedFields(partnerConfigRes["udf"]);
    };

    const getListing = async () => {
        const partnerKey = agentInfo.partner_key;
        const listingResApi = await listingApi.get({
          partner_key: partnerKey,
          fields_to_include: fieldsInclude,
          listing_ids: [parseInt(props.listingId)],
          max_record_count: 10,
          include_disclaimer: true,
          rb_portal: "bp_search",
          agent_id: agentId,
        })
        const listingRes = listingResApi.data.data[0];
        const localFormattedListing = formatSettingsToLocalV2({
          listing: listingRes,
          usedKeys: fieldsInclude,
        })
        setListing(localFormattedListing);
    }

    const getListingLease = async () => {
        try {
          const { data } = await listingApi.getRentalLeaseDetails({
            listing_id: parseInt(props.listingId),
          })
          setLeaseData(data);
        } catch (error) {
          console.log("[error_get_listing_lease", error);
        }
    }

    function initLocallogic() {
          //RB-2010: Assigning of listings longitude and latitude
          const location = {
              lat: listing.latitude,
              lng: listing.longitude
          };

          //RB-2010: Creating instance of the new local content SDK
          localContent.create("local-content", containerRef.current, {
              ...location,
              marker: location
          });
      }

      const handleUserSessionUpdate = () => {};

      const initApplyNow = async () => {
        const applyNowAvailableParams = {
          listingId: listing.apartment_id,
          portal: "broker",
          partnerKey: agentInfo.partner_key,
        };
        let isOptedOut = await applyNowAvailableListingApi.get(applyNowAvailableParams);
        setApplyNowEnabled(isOptedOut);
      };

      useEffect(() => {
        updateSessionInfo();
        window.addEventListener("session-update", handleUserSessionUpdate);
      }, []);

      useEffect(() => {
        if (agentId) {
          getListing();
          getUDFs();
        }
      }, [agentId]);

      useEffect(() => {
        if (listing && listing.apartment_id) {
            initLocallogic();
        }
        initApplyNow();
      }, [listing]);

      useEffect(() => {
        getListingLease();
      }, []);

      useMemo(() => {
        console.log("Leasing/Tenant Agent data", leaseData);
      }, [leaseData]);

      const constructListingDetailUDFs = (anchorName, listing) => {
        // TODO: This name is temperory, ask BE if the name can be sent in field values
        let name = '';
        let fields = []
    
        udfFieldsByTab(anchorName).map(field => {
          name = convertToUnderscoreLowerCase(field["label"])
    
          if (field.renders_on === 0 || (field.renders_on > 0 && listing[`udf_${field.renders_on}`] === 'true')) {
            const udfValue = listing.udf?.find(udfField => udfField.id === field.id)?.value
      
            fields.push( {
              label: field.label,
              field_type: field.field_config?.field_type,
              value: field.field_config?.field_type === 'url' ? <a href={udfValue} target="_blank">{udfValue}</a> : udfValue,
              summaryLinkValue: udfValue
            })
          }
        })
    
        return fields
      }

      const udfFieldsByTab = (anchorName) => {
        let fields = []
        let sortedFields = []
        if(userDefinedFields.length > 0){
          userDefinedFields.map((field) => {
            if(field.field_config?.anchor_id === anchorName && field.field_config?.show === true){
              fields.push(field)
            }
          })
        }
        sortedFields = fields.sort((a, b) => {
          // If render_order is missing or 0, consider it as -1 (to keep them first)
          const renderOrderA = a.render_order === undefined || a.render_order === 0 ? -1 : a.render_order;
          const renderOrderB = b.render_order === undefined || b.render_order === 0 ? -1 : b.render_order;
    
          // Compare render_order values
          return renderOrderA - renderOrderB;
        });
        return sortedFields;
        // return fields;
      }

    return (
        <div className="see-pricing-wrapper-v2">
            {props.children}
            <div className={styles.listingMain}>
                {listing.apartment_id ? (
                    <div>
                        <PhotosSlider listing={listing} />
                    <div className={styles.listingInfoCont}>
                        <div className={styles.mainInfoWrapper}>
                            <MainInfo
                                leaseData={leaseData}
                                listing={listing}
                                applyNowEnabled={applyNowEnabled}
                                constructListingDetailUDFs={constructListingDetailUDFs}
                            />
                        </div>
                        <div className={styles.generalInfoWrapper} style={{ filter: 'blur(7px)' }}>
                            <GeneralInfo listing={listing} leaseData={leaseData} constructListingDetailUDFs={constructListingDetailUDFs}/>
                        </div>
                        <div className="container">
                          <div
                              ref={containerRef}
                              style={{
                                height: "60vh",
                                marginBottom: "20px"
                              }}
                          />
                        </div>
                    </div>
                    </div>
                ) : (
                    <div className={styles.loadingListingText}>Loading Listing...</div>
                )}
            </div>
        </div>
    )
}
