import {ShareDialog} from "../share_dialog/ShareDialog";
import React, {useState} from "react";
import { IListing } from "../../../services/api/listing";

interface ShareDialogProps {
    shareListing: IListing;
}

export function useAgentShareLink() {
    const [shareDialogOpen, setShareDialogOpen] = useState(false);

    const handleDialogClose = () => {
        setShareDialogOpen(false);
    };

    const Dialog = (props: ShareDialogProps) => {
        return React.useMemo(() => <ShareDialog
            shareDialogOpen={shareDialogOpen}
            handleDialogClose={handleDialogClose}
            shareListing={props.shareListing}
        />, [shareDialogOpen])

    }

    return {
        Dialog,
        setShareDialogOpen
    }
}
