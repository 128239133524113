import React from "react";

export const ShapeOptions = ({
    lat,
    lng,
    hoveredShapeId,
    handleExcludeListing,
    handleDeleteListing,
    shapeMouseOut,
    retrieveDrawings,
}) => {
    return (
        <div
            style={{
                color: "#0361c0",
                width: 150,
                height: 75,
                display: "flex",
                background: "#f5f5f5",
                zIndex: 99999,
                fontSize: 16,
                position: "relative",
                flexDirection: "column",
                padding: "3px 10px",
                borderRadius: '10px',
                boxShadow: '0px 0px 25px -4px rgb(0 0 0 / 75%)',
                marginTop: "-60px"
            }}
        >
            <div
                className='close-icon'
                style={{marginLeft: 'auto', color: 'black', height: 15, cursor: 'pointer'}}
                onClick={shapeMouseOut}
                onTouchStart={shapeMouseOut}
            >
                &#10006;
            </div>
            <div
                onClick={() => handleDeleteListing(hoveredShapeId, retrieveDrawings)}
                onTouchStart={() => handleDeleteListing(hoveredShapeId, retrieveDrawings)}
            >
                Delete Shape
            </div>
            <div
                style={{ marginTop: 5 }}
                onClick={() => handleExcludeListing(hoveredShapeId, retrieveDrawings)}
                onTouchStart={() => handleExcludeListing(hoveredShapeId, retrieveDrawings)}
            >
                Exclude Shape
            </div>
        </div>
    );
};
