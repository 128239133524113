import {REST} from "../rest";

export const listingFeaturesApi = {
    getList: async (filters = false, filter_key = "filter_enabled_bp_search"): Promise<IListingFeatures> => {
        const filterKey = `&filter_key=${filter_key}`;
        const [featuresMap, targetsList] = await Promise.all([
            getFeatures(filters, filterKey),
            getTargets()
        ]);

        console.log("type =", typeof(featuresMap[ListingFeature.heat_type]));

        let syndicationTargets = {};

        targetsList.forEach(t => {
            syndicationTargets["t" + t.syndication_target_id.toString()] = t.syndication_target_name
        });

        featuresMap[ListingFeature.syndication_targets] = syndicationTargets;

        return featuresMap;
    }
};

const getFeatures = (filters, filterKey) => {
    const url = `/api/listing_features?format=map${filters ? filterKey : ''}`;
    return REST
        .get(url,)
        .then(res => res.data);
}

const getTargets = () => {
    const url = '/api/syndication_targets';
    return REST
        .get(url,)
        .then(res => res.data);
}

export enum ListingFeature {
    external_structure = 'external_structure',
    property_feature = 'property_feature',
    property_structures = 'property_structures',
    property_type = 'property_type',
    ac_type = 'ac_type',
    key_code = 'key_code',
    virtual_showing_allowed = 'virtual_showing_allowed',
    furnished_type = 'furnished_type',
    pet_allowed = 'pet_allowed',
    floor_type = 'floor_type',
    unit_occupied = 'unit_occupied',
    heat_type = 'heat_type',
    utility = 'utility',
    laundry = 'laundry',
    pet_type = 'pet_type',
    unit_feature = 'unit_feature',
    service_level = 'service_level',
    move_in_costs = 'move_in_costs',
    move_in_specials_tenant = 'move_in_specials_tenant',
    rented_equipment = 'rented_equipment',
    floors_in_unit = 'floors_in_unit',
    room_types = 'room_types',
    lease_term = 'lease_term',
    syndication_targets = 'syndication_targets',
    property_owner_types = 'property_owner_types',
    contract_type = 'contract_type',
    'listings_aj_optional_fields' = 'listings_aj_optional_fields'
}

export interface IListingFeatures {
    [ListingFeature.external_structure]: any;
    [ListingFeature.property_feature]: any;
    [ListingFeature.property_structures]: any;
    [ListingFeature.property_type]: any;
    [ListingFeature.ac_type]: any;
    [ListingFeature.key_code]: any;
    [ListingFeature.virtual_showing_allowed]: any;
    [ListingFeature.furnished_type]: any;
    [ListingFeature.pet_allowed]: any;
    [ListingFeature.floor_type]: any;
    [ListingFeature.unit_occupied]: any;
    [ListingFeature.heat_type]: any;
    [ListingFeature.utility]: any;
    [ListingFeature.laundry]: any;
    [ListingFeature.pet_type]: any;
    [ListingFeature.unit_feature]: any;
    [ListingFeature.syndication_targets]?: any;
    [ListingFeature.property_owner_types]?: any;
    [ListingFeature.contract_type]?: any;
}
