import {REST} from "../../rest";

export const listingStatusTrackerApi = {
    post: async (params) => {
        const url = `/api/listing_status_tracker`;

        return await REST.post(url, params).then(res => {
            return res.data;
        });
    },
    get: async (listingIdParam: IListingIdParam) => {
        const url = `/api/listing_status_tracker/${listingIdParam.listing_id}`;
        return await REST.get(url).then(res => {
            return res.data.data;
        });
    }
};

interface IListingIdParam {
    listing_id: number;
}