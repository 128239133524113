import React, {useContext, useEffect, useState} from "react";
import Modal from 'react-modal';
import {ConsumerSearchContext} from "./consumer_search_context";
import {Direction, SortDirection, Tab, TableBody, TableCell, TableHead, TableRow, Tabs} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import moment from "moment";
import "./styles/multi_unit_modal.scss";
import {IListing} from "../../../services/api/listing";
import {getAddressLineOne, getAreaString} from "./util";
import Pluralize from 'react-pluralize'
import TableSortLabel from "@material-ui/core/TableSortLabel";

Modal.setAppElement('#react_content');

function initTabs(multiUnitListings) {
    const tabs = [{label:"All", value:"-1"}];

    multiUnitListings.forEach(listing => {
        if (listing.bedrooms == "0" && !tabs.find(tab => tab.value == "0")) {
            tabs.push({label:"Studio", value:"0"})
        } else if (listing.bedrooms == "1" && !tabs.find(tab => tab.value == "1")) {
            tabs.push({label:"1 Bed", value:"1"})
        } else if (listing.bedrooms == "2" && !tabs.find(tab => tab.value == "2")) {
            tabs.push({label:"2 Beds", value:"2"})
        } else if (listing.bedrooms == "3" && !tabs.find(tab => tab.value == "3")) {
            tabs.push({label:"3+ Beds", value:"3"})
        }
    });

    tabs.sort((prev, curr) => {
        return parseInt(prev.value) - parseInt(curr.value)
    });

    return tabs;
}

const getAvailableDateLabel = (listing: IListing) => {
    const availableYear = moment(listing.date_available).year();
    const currentYear = moment().year();
    const now = moment();
    const today = now.format('YYYY-MM-DD');

    if (moment(listing.date_available).isBefore(today)) {
        return 'Available Now'
    }
    else if (availableYear === currentYear) {
        return `Available on ${moment(listing.date_available).format("MMM DD")}`
    } else {
        return `Available on ${moment(listing.date_available).format("MMM DD YYYY")}`
    }
};

const headCells = [
    { id: 'bedrooms', numeric: false, disablePadding: false, label: 'Beds' },
    { id: 'bathrooms', numeric: false, disablePadding: false, label: 'Baths' },
    { id: 'rent', numeric: false, disablePadding: false, label: 'Rent' },
    { id: 'date_available', numeric: false, disablePadding: false, label: 'Available' },
];

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

export function MultiUnitModal({multiUnitModalOpen, multiUnitListings}) {

    const [tabs, setTabs] = useState([]);
    const [currentTab, setCurrentTab] = useState("-1");
    const [orderBy, setOrderBy] = useState("rent");
    const [order, setOrder] = useState('asc');

    const {agentLinkId, isMobile} = useContext(ConsumerSearchContext);

    useEffect( () => {
        setCurrentTab("-1");
        setTabs( initTabs(multiUnitListings) );
    },[multiUnitListings]);


    let subtitle;
    let dynContent;

    const {setMultiUnitModalOpen} = useContext(ConsumerSearchContext);

    const filterSelectedTab = (listing) => {
        if (currentTab == '-1') {
            return listing;
        } else if (currentTab == '0') {
            return listing.bedrooms === 0
        } else if (currentTab == '1') {
            return listing.bedrooms === 1
        } else if (currentTab == '2') {
            return listing.bedrooms === 2
        } else if (currentTab == '3') {
            return listing.bedrooms >= 3
        }
    };

    const openModal = () => {
        setMultiUnitModalOpen(true);
    };

    const closeModal = () => {
        setMultiUnitModalOpen(false);
    };

    const setTitle = () => {
        if(multiUnitListings.length) {
            return getAreaString(multiUnitListings[0])
        }
    };

    const setAddress = () => {
        if(multiUnitListings.length) {
            const firstListing = multiUnitListings[0];
            if(firstListing.is_agent_listing) {
                return getAddressLineOne(firstListing)
            }
        }

    };

    const goToDetails = (listing) => {
        let path = `/listings/show/${listing.apartment_id}?agentId=${agentLinkId}&isAgentBranded=true`;
        open(path);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const getSortedListings = (listings) => {
        return stableSort(listings, getComparator(order, orderBy));
    };

    const getBedroomsLabel = (listing, isMobile) => {
        const mobileLabel = 0 === listing.bedrooms ? "Studio" : listing.bedrooms;
        return isMobile ? mobileLabel : <Pluralize singular="bedroom" count={listing.bedrooms} zero={'Studio'}/>
    };

    return (
        <Modal
            isOpen={multiUnitModalOpen}
            onRequestClose={closeModal}
            className="multiunit-modal"
            overlayClassName="multiunit-modal-overlay"
        >
            <div ref={_subtitle => (subtitle = _subtitle)} className="multiunit-modal-header">
                <div className="modal-title-wrapper">
                    <div className="modal-title">{ setTitle() }</div>
                    { setAddress() }
                </div>
                <div onClick={closeModal} className="multiunit-close"><i className="far fa-window-close"/></div>
            </div>
            <div ref={_dynContent => (dynContent = _dynContent)} className="multiunit-content" >
                <Tabs
                    value={currentTab}
                    onChange={(e, val)=>setCurrentTab(val)}
                    className="multiunit-tabs"
                >
                {
                    tabs.map(tab => <Tab key={tab.label} label={tab.label} value={tab.value}/>)
                }
                </Tabs>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {headCells.map((headCell) => (
                                <TableCell
                                    key={headCell.id}
                                    align={headCell.numeric ? 'right' : 'left'}
                                    padding={(headCell.disablePadding || isMobile) ? "none" : "default"}
                                    sortDirection={orderBy === headCell.id ? order as SortDirection : false}
                                >
                                    <TableSortLabel
                                        active={orderBy === headCell.id}
                                        direction={orderBy === headCell.id ? order : 'asc'}
                                        onClick={ (event) => handleRequestSort(event, headCell.id) }
                                    >
                                        {headCell.label}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                            <TableCell align="left" />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            getSortedListings(multiUnitListings).filter(filterSelectedTab).map((listing) => (
                                <TableRow key={listing.apartment_id} onClick={ () => goToDetails(listing) }>
                                    <TableCell align="left">{ getBedroomsLabel(listing, isMobile) }</TableCell>
                                    <TableCell align="left">
                                        {isMobile ? listing.bathrooms : <Pluralize singular="bathroom" count={listing.bathrooms} />}
                                    </TableCell>
                                    <TableCell align="left">{`$${listing.rent}`}</TableCell>
                                    <TableCell align="left">{getAvailableDateLabel(listing)}</TableCell>
                                    <TableCell align="left">
                                        <div className="multiunit-see-more">See Details <i className="fas fa-arrow-right"/></div>
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </div>
        </Modal>
    )
}