import React from "react";
import {IReportInfo} from "../full_report/full_report";
import {QuickReportDescription, ReportHeader, reportStyles} from "../report";

export function QuickReport (props: IReportInfo) {
    const {agentName, agentEmail, listings, disclaimer, agentId} = props;

    const {classes} = props.jss.createStyleSheet(reportStyles).attach();
    return <div className={classes.container}>
        <ReportHeader {...{agentEmail, agentName, jss: props.jss}}/>
        {
            listings.map(listing => {
                return <QuickReportDescription {...{jss: props.jss, listing, agentId}}/>
            })
        }
        <div>
            {disclaimer}
        </div>
    </div>
}