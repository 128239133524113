import React, {useContext, useEffect} from "react";
import {ListingSearchContext} from "../../listing_search_context";

const getParsedPrice = (price: string) => {
    if (price) {
        return price === '$' ? 0 : parseInt(price.replace('$', ''));
    } else {
        return 0;
    }
}

export function FieldsetMaxRent({className, inputProps, formatDollar}: {className?: string, formatDollar?: boolean, inputProps?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>}) {
    const {criteria, setCriteria} = useContext(ListingSearchContext);

    return <fieldset className={className}>
        <label>Max Rent:</label>
        <div className="input-container">
            {
                formatDollar ? <input
                    onChange={e => setCriteria({...criteria, max_price: getParsedPrice(e.currentTarget.value)})}
                    value={`$${criteria.max_price || ''}`}
                    {...inputProps}
                /> :
                <input
                    onChange={e => setCriteria({...criteria, max_price: e.currentTarget.value != '' ? parseInt(e.currentTarget.value) : undefined})}
                    value={criteria.max_price || ''}
                    {...inputProps}
                />
            }

        </div>
    </fieldset>
}