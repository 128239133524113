exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._3abk6BPK_Gy-eZQ5cI3pj-{background-color:#f5f5f5}", "", {"version":3,"sources":["/app/app/javascript/src/features/broker_portal/listing_details/components/photos_slider/photos_slider.module.scss","/app/app/javascript/assets/styles/rb_colors.scss"],"names":[],"mappings":"AAEA,yBACE,wBC8BS,CAAA","file":"photos_slider.module.scss","sourcesContent":["@import \"../../../../../../assets/styles/rb_colors\";\n\n.photosCarousel {\n  background-color: $layoutBg;\n}","//TODO Use this instead of _variables.scss\n/* Colors */\n$red: #ED4444;\n$dark-red: darken($red, 10%);\n$yellow: #e6e38c;\n$highlight: #bbac2a;\n\n$pale-gray: #cecece;\n$dark-gray: #3F434C;\n$rbGray: #4c515b;\n$gray: #5B626E;\n$light-gray: lighten($gray, 25%);\n$lighter-gray: #E8EAEC;\n$bright-gray: #F4F4F4;\n\n$light-black: #333333;\n\n$blue: #337ab7;\n$light-blue: #6CC9EF;\n$dark-blue: #23527C;\n$pale-blue: #EEF3F6;\n$rbBlue: #0361c0;\n$rbRed: $red;\n$altBlue: #0099cc;\n$alt-dark-blue: #3F51B5;\n$cmaBlue: #20456f;\n$cmaGray: #5b616e;\n$grayTab: #666666;\n\n/* Font Family */\n$default-font: \"Work Sans\", Helvetica;\n\n/* React Layout */\n$layoutBg: #f5f5f5;\n\n/* Search Filters */\n$searchFilterColor: $dark-gray;\n\n/* Boxes */\n$boxBg: #e5e5e8;\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"photosCarousel": "_3abk6BPK_Gy-eZQ5cI3pj-"
};