import React from "react";
import "./fieldset_schools.scss";
import {FieldsetElementarySchool} from "./fieldset_elementary_school";
import {FieldsetMiddleSchool} from "./fieldset_middle_school";
import {FieldsetHighSchool} from "./fieldset_high_school";
import {FieldsetSchoolDistrict} from "./fieldset_school_district";


export function FieldsetSchools() {
    return <fieldset>
        {/*<label className="wide-only">Schools</label>*/}
        {/*<span className="separator" />*/}
        {/*<FieldsetElementarySchool/>*/}
        {/*<FieldsetMiddleSchool/>*/}
        {/*<FieldsetHighSchool/>*/}
        <FieldsetSchoolDistrict/>
    </fieldset>
}