import React, {useLayoutEffect, useState, useContext} from "react";
import "./drawer.scss";
import {isMobile} from "../../utils/device_info";

export const Drawer = React.memo(
    function DrawerComponent(props : any) {

        const {open, handleClose, width, handleClick, actionFromMap = false} = props;
        const [isMobileRes, setIsMobileRes] = useState(isMobile());

        useLayoutEffect(() => {
            const fx = () => {
                setIsMobileRes(isMobile());
            };

            window.addEventListener('resize', fx);
            return () => window.removeEventListener('resize', fx);
        }, []);

        return (
            <>
                <div
                    className={`opaque-screen ${actionFromMap === true ? "map" : ""} ${isMobileRes && open ? "mobile" : ""}`}
                    style={{display: open ? "block" : "none"}}
                    onClick={handleClose}
                >
                </div>
                <div
                    className={`drawer-container ${actionFromMap === true ? "map" : ""} ${open ? "drawer-on" : "drawer-off"} ${isMobileRes && open ? "mobile" : ""}`}
                    style={{width: isMobileRes ? "100vw" : `${width}vw` || "90vw"}}
                    onClick={handleClick}
                >
                    {props.children}
                </div>
            </>
        )
    }
)
