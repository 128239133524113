import React from "react";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { CircleDraw } from "../../../../../assets/icons/CircleDraw";
import { PolygonDraw } from "../../../../../assets/icons/PolygonDraw";
import { RectangleDraw } from "../../../../../assets/icons/RectangleDraw";
//import { FreehandDraw } from "../../../../../assets/icons/FreehandDraw";
import { ClearShapeDraw } from "../../../../../assets/icons/ClearShapeDraw";
//import { RecenterDraw } from "../../../../../assets/icons/RecenterDraw";

interface IDropdownProps {
    handleSelectShape: (
        event: React.ChangeEvent<{
            name?: string;
            value: unknown;
        }>
    ) => void;
    selectedShapeController: string | null;
}
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: "auto",
            width: 200,
            color: "#337ab7",
        },
    },
};

export const DrawDropdown: React.FC<IDropdownProps> = ({
    handleSelectShape,
    selectedShapeController,
}) => {
    return (
        <Select
            className='draw-toolbar'
            displayEmpty
            value={
                selectedShapeController !== null ? selectedShapeController : []
            }
            onChange={handleSelectShape}
            input={<Input />}
            renderValue={(selected: any) => {
                if (selected.length === 0) {
                    return <em className='draw-toolbar-em'>Draw</em>;
                }

                return selected;
            }}
            MenuProps={MenuProps}
        >
            <MenuItem value='Radius'>
                <div className='map-draw-shape'>
                    <CircleDraw />
                    <span>Radius</span>
                </div>
            </MenuItem>
            <MenuItem value='Polygon'>
                <div className='map-draw-shape'>
                    <PolygonDraw />
                    <span>Polygon</span>
                </div>
            </MenuItem>
            <MenuItem value='Rectangle'>
                <div className='map-draw-shape'>
                    <RectangleDraw />
                    <span>Rectangle</span>
                </div>
            </MenuItem>
            {/*Hide Freehand option while implementation is still in progress*/}
            {/*<MenuItem value='Freehand'>
                <div className='map-draw-shape'>
                    <FreehandDraw />
                    <span>Freehand</span>
                </div>
            </MenuItem>*/}
            <MenuItem value='Clear Shapes'>
                <div className='map-draw-shape'>
                    <ClearShapeDraw />
                    <span>Clear Shapes</span>
                </div>
            </MenuItem>
            {/*Hide Recenter option while implementation is still in progress*/}
            {/*<MenuItem value='Recenter'>
                <div className='map-draw-shape'>
                    <RecenterDraw />
                    <span>Recenter</span>
                </div>
            </MenuItem>*/}
        </Select>
    );
};
