import React, {useEffect, useState} from "react";
import Slider, {LazyLoadTypes} from "react-slick";
import {imgUrl} from "../../../services/constants";

export function ImageSlider (props: {pictures: string [], height?}) {

    const [imageBaseUrl, setImageBaseUrl] = useState("");

    useEffect(()=>{
      setImageBaseUrl(imgUrl);
    }, []);

    const lazyLoadSetting: LazyLoadTypes = "ondemand";

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        lazyLoad: lazyLoadSetting,
    };

    const picturesHeight = props.height || 275;
    const getUrl = (picture) => {
        return `url('${imageBaseUrl}/${picture}?w=500')`;
    };

    return (
      <Slider {...settings}>
        {
            props.pictures.map((picture, index) => {
            return <div key={index}>
              <div style={{backgroundImage: getUrl(picture), height: picturesHeight, backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}/>
            </div>
          })
        }
      </Slider>
    );

}