export enum POI_TYPES { 
    SUPERMARKET = 'supermarket',
    RESTAURANT = 'restaurant',
    HOSPITAL = 'hospital',
    BANK = 'bank',
    TRANSPORTATION = 'transit_station'
}

export enum POI_ICONS {
    SUPERMARKET = "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/shopping-71.png",
    RESTAURANT = "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/restaurant-71.png",
    HOSPITAL = "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/hospital-71.png",
    TRANSPORTATION = "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/bus-71.png"
}

export enum POI_BACKGROUND_COLORS {
    TRANSPORTATION = "#10BDFF"
}