import React, {useContext, useEffect, useState} from "react";
import {ListingSearchContext} from "../../listing_search_context";
import "./fieldset_bedroom_range.scss";
import {Select} from "@material-ui/core";

export function FieldsetBedroomRange(props: IFieldsetBedroomRange) {
    const {className} = props;
    const {criteria, setCriteria} = useContext(ListingSearchContext);
    const {focusedElId, setFocusedElId} = useContext(ListingSearchContext);
    const [inputError, setInputError] = useState(null);
    const errorLabel = `Max. Beds must be greater than Min. Beds.`;

    const optionChoices = [
        {label: "Any", value: ""},
        {label: "Studio", value: 0},
        {label: "1", value: 1},
        {label: "2", value: 2},
        {label: "3", value: 3},
        {label: "4", value: 4},
        {label: "5", value: 5},
        {label: "6", value: 6},
        {label: "7", value: 7},
        {label: "8", value: 8},
        {label: "9", value: 9},
        {label: "10", value: 10}
    ];

    const handleMinBedChange = (e) => {
        let minBedValue = e.currentTarget.value;
        let minBed = parseInt(minBedValue);

        if (minBed >= 0 && (!(criteria.bedroom_range.max_bedrooms === '') && criteria.bedroom_range.max_bedrooms >= 0)) {
            if (minBed > criteria.bedroom_range.max_bedrooms) {
                setInputError(errorLabel);
            } else {
                setInputError(null);
            }
        } else {
            setInputError(null);
        }

        setCriteria({...criteria, bedroom_range: {...criteria.bedroom_range, min_bedrooms: e.currentTarget.value} });

        if (setFocusedElId) {
            setFocusedElId(props.minBedInputId);
        }
    };

    const handleMaxBedChange = (e) => {
        let maxBedValue = e.currentTarget.value;
        let maxBed = parseInt(maxBedValue);

        if (maxBed >= 0 && (!(criteria.bedroom_range.min_bedrooms === '') && criteria.bedroom_range.min_bedrooms >= 0)) {
            if (maxBed < criteria.bedroom_range.min_bedrooms) {
                setInputError(errorLabel);
            } else {
                setInputError(null); 
            }
        } else {
            setInputError(null); 
        }

        setCriteria({ ...criteria, bedroom_range: { ...criteria.bedroom_range, max_bedrooms: e.currentTarget.value } });
                
        if (setFocusedElId) {
            setFocusedElId(props.maxBedInputId);
        }
    };

    useEffect(()=>{
        if(focusedElId) {
            document.getElementById(focusedElId).focus();
        }
    }, [focusedElId]);

    return (
        <fieldset className={`bedroom-range--container ${className}`}>
            <div>
                <label className="wide-only">Bedrooms</label>
            </div>
            <div className="bedroom-input--container">
                <Select
                    native
                    id={props.minBedInputId || ""}
                    value={criteria.bedroom_range.min_bedrooms}
                    onChange={handleMinBedChange} >
                        {
                            optionChoices.map((option) => (
                                <option value={option.value} key={option.value}>{option.label}</option>
                            ))
                        }
                </Select>
                 <span className={"to"}>to</span>
                 <Select
                    native
                    id={props.maxBedInputId || ""}
                    value={criteria.bedroom_range.max_bedrooms}
                    onChange={handleMaxBedChange} >
                        {
                            optionChoices.map((option) => (
                                <option value={option.value} key={option.value}>{option.label}</option>
                            ))
                        }
                </Select>
                <label className={`errorLabel ${inputError ? 'active' : ''}`}>{inputError}</label>
            </div>
        </fieldset>
    )
}

interface IFieldsetBedroomRange {
    className?: string;
    minBedInputId: string;
    maxBedInputId: string;
}