export interface ISchoolSetting {
  label: string;
  value: string;
  field?: string;
}

export const schoolSetting: ISchoolSetting[] = [
  { label: "Elementary", value: "elementary", field: 'schools'},
  { label: "Middle", value: "middle", field: 'schools'},
  { label: "High", value: "high", field: 'schools'},
  { label: "Public", value: "public", field: 'schools'},
  { label: "Private", value: "private", field: 'schools'},
  { label: "Charter", value: "charter", field: 'schools'}
];
