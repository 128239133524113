import React, { useContext, useEffect, useState } from "react";
import { ListingDetails } from "../detail_search_results/listing_details/listing_details";
import { SelectedListingsContext } from "../../selected_listings_context";
import { BookmarksContext } from "../../bookmarks_context";
import { UpgradePricingModal } from "../detail_search_results/upgrade_pricing_modal/upgrade_pricing_modal";
import { ListingSearchContext } from "../../../../components/listing_search/listing_search_context";
import "./map_view.scss";
import MapViewTableHeader from "./map_view_table_header";
import { BrokerSearchingContext } from "../../broker_search_context";
import ReactPaginate from "react-paginate";
import { MapSection } from "./map_section";
import { MapToast } from './map_toast';
import { RowsPerPagePagination } from './map_view_config/MapRowsPerPage';
import { useMapShape } from './map_view_hook/useMapShape';
import { AgentUserContext } from "../../agent_user_context";
import { isMobile } from '../../../../utils/device_info';

interface IMapView {
    listings: any[];
    isAddressDisplay: boolean;
    totalListingCount?: number;
    isEnableAllListingsTab?: boolean;
    totalResults?: number;
    isEnableMlsListingsTab?: boolean;
    isEnableRbListingsTab?: boolean;
    totalPages?: number;
    selectedSortBy: string;
    setSelectedSortBy: React.Dispatch<string>;
    listingsMapArea?: any[];
    totalPagesMap?: number;
    currentZoomLevel: number;
    setCurrentZoomLevel: React.Dispatch<number>;
}

export const MapView = React.memo(function MapViewComponent({
    listings,
    isAddressDisplay,
    totalListingCount,
    totalPages,
    setSelectedSortBy,
    selectedSortBy,
    listingsMapArea,
    totalPagesMap,
    currentZoomLevel,
    setCurrentZoomLevel
}: IMapView) {
    // context
    const { setGroupedListings, selectedListings, handleAllSelectedListings} = useContext(
        SelectedListingsContext
    );
    const {
        criteria,
        setCriteria,
        setPaginatedFromMapListingArea,
        setPaginatedFromMapArea,
        setMapAreaPageNum,
        setListingAreaPageNum,
        mapAreaPageNum,
        listingAreaPageNum,
        isPaginatedFromMapArea,
        isPaginatedFromMapListingArea,
        allCount,
        rbCount,
        mlsCount,
        tabChosen,
        loadingMapFilterSearch,
        selectedSchoolLayers,
        setSelectedSchoolLayers
    } = useContext(ListingSearchContext);
    const { bookmarks, fetchBookmarks } = useContext(BookmarksContext);
    const { isMlsAgent } = useContext(AgentUserContext)
    const { setSearching, setOrderBy, setOrder } = useContext(
        BrokerSearchingContext
    );

    // local state
    const [showShowUpgradeModal, setShowUpgradePageModal] =
        useState<boolean>(false);
    const [viewSetting, setViewSetting] = useState<string | null>(null);
    const [mapSetting, setMapSetting] = useState<string | null>(null);
    const [isSingleCheck, setIsSingleCheck] = useState<boolean>(false);
    const [isMultiCheck, setIsMultiCheck] = useState<boolean>(false);
    const [multiSelect, setMultiSelect] = useState<boolean>(false);
    const [singleSelect, setSingleSelect] = useState<boolean>(false);
    const [upgradeListingId, setUpgradeListingId] = useState<any>(null)

    const [hoveredListingId, setHoveredListingId] = useState<number | null>(
        null
    );
    const [selectedLayers, setSelectedLayers] = useState<string[]>([])
    const [schoolDistrictLayer, setSchoolDistrictLayer] = useState<boolean>(false)
    // --- new layer: Subdivision ---
    const [subdivisionLayer, setSubdivisionLayer] = useState<boolean>(false)
    // --- END ---
    // const [selectedSchoolLayers, setSelectedSchoolLayers] = useState<ISchoolSelectors[]>([])
    const [selectedShapeController, setSelectedShapeController] = useState<
        string | null
    >(null);
    const [activeModalId, setActiveModalId] = useState<number>(0);
    const [activeTooltipId, setActiveTooltipId] = useState<number>(0);
    const [multiSelectDrawManager, setMultiSelectDrawManager] = useState<any>(null);
    const [multiSelectInstance, setMultiSelectInstance] = useState<any>(null);
    const [isOpen, setIsOpen] = useState(false)
    const toggleSingleCheck = () => {
        if (multiSelectInstance !== null) {
            multiSelectInstance.setMap(null)
            setMultiSelectInstance(null)
        }
        setIsMultiCheck(false)
        setIsSingleCheck(!isSingleCheck)
    };
    const toggleMultiCheck = () => setIsMultiCheck(!isMultiCheck);
    const toggleUpgradeModal = (listingId?: any) => {
        setUpgradeListingId(listingId)
        setShowUpgradePageModal(
            (showShowUpgradeModal) => !showShowUpgradeModal
        );
    };
    const { handleClearShapes } = useMapShape()

    React.useEffect(() => {
        if (isSingleCheck) {
            setMultiSelect(false)
        }
    }, [isSingleCheck])

    React.useEffect(() => {
        if (isMultiCheck && multiSelectDrawManager !== null) {
            setIsSingleCheck(false)
            setMultiSelect(true)
            setSingleSelect(false)
            const { map, maps } = multiSelectDrawManager
                const SHAPE_OPTIONS = {
                    fillColor: "#ffffff",
                    fillOpacity: 0.5,
                    strokeWeight: 5,
                    clickable: true,
                    zIndex: 999,
                    draggable: false,
                };

                const DRAW_CONFIG = {
                    drawingControl: false,
                    draggable: false,
                    rectangleOptions: SHAPE_OPTIONS,
                };
                const drawingManager = new maps.drawing.DrawingManager(DRAW_CONFIG);
                drawingManager.setMap(map);
                drawingManager.setDrawingMode('rectangle')
                setMultiSelectInstance(drawingManager)

                maps.event.addListener(drawingManager, 'rectanglecomplete', (rectangle) => {
                    drawingManager.setDrawingMode(null)
                    const filterListings = listingsMapArea.filter(listing => {
                        return rectangle.getBounds().contains({
                            lat: listing.latitude,
                            lng: listing.longitude
                        })
                    })
                    const isAgentFilteringNotPro = filterListings.some(xListing => {
                        const isMlsListing = xListing.listing_source_type === 'MLS'
                        return isMlsAgent() && !isMlsListing
                    })

                    if (isAgentFilteringNotPro) {
                        setIsOpen(true)
                    }
                    const filterListingsIsNonProAgent = filterListings.filter(xListing => {
                        return isMlsAgent() ? xListing.listing_source_type === 'MLS' : xListing
                    })

                    rectangle.setMap(null)
                    setIsMultiCheck(false)
                    handleAllSelectedListings(filterListingsIsNonProAgent, true)
                })
        } else {
            if (multiSelectInstance !== null) {
                multiSelectInstance.setMap(null)
                setMultiSelectInstance(null)
            }
        }
    }, [isMultiCheck, multiSelectDrawManager, listingsMapArea])

    const handleSelectShape = (
        event: React.ChangeEvent<{
            name?: string;
            value: string;
        }>
    ) => {
        if (loadingMapFilterSearch) {
            return
        }
        setIsMultiCheck(false)
        if (multiSelectInstance !== null) {
            multiSelectInstance.setMap(null)
            setMultiSelectInstance(null)
        }
        if (event.target.value === 'Clear Shapes') {
            handleClearShapes()
        }
        setSelectedShapeController(event.target.value);
    };

    const handleSortClick = (selectedCOnfig) => {
        const config = JSON.parse(selectedCOnfig);
        setOrder(!config.isAsc ? "desc" : "asc");
        setOrderBy(config.value);
        setSelectedSortBy(config.label);
        setCriteria({
            ...criteria,
            sort_field: config.value,
            sort_ascending: config.isAsc,
            page_number: 1,
        });
        setSearching(true);
        setGroupedListings([]);
    };

    const handleViewSettingClick = (selectedView: any) => {
        if (loadingMapFilterSearch) {
            return
        }
        setViewSetting(JSON.parse(selectedView).value);
    };

    const handleMapSettingClick = (selectedMap: any) => {
        if (loadingMapFilterSearch) {
            return
        }
        setMapSetting(JSON.parse(selectedMap).value);
    };

    const handlePageChange = (props) => {
        setListingAreaPageNum(props.selected + 1);
        setCriteria({ ...criteria, page_number: props.selected + 1 });
        console.log("Searching");
        setSearching(true);
        setPaginatedFromMapListingArea(true);
        setPaginatedFromMapArea(false);
    };

    const handlePageChangeMap = (props) => {
        setMapAreaPageNum(props.selected + 1);
        setCriteria({ ...criteria, page_number: props.selected + 1 });
        console.log("Searching");
        setSearching(true);
        setPaginatedFromMapArea(true);
        setPaginatedFromMapListingArea(false);
    };

    const hoverListingAction = (listingId) => {
        setHoveredListingId(listingId);
    };

    return (
        <>
            {(listings.length > 0) || (listingsMapArea.length > 0) ? (
                <>
                    <MapViewTableHeader
                        handleSortClick={handleSortClick}
                        selectedSortBy={selectedSortBy}
                        handleViewSettingClick={handleViewSettingClick}
                        handleMapSettingClick={handleMapSettingClick}
                        isSingleCheck={isSingleCheck}
                        isMultiCheck={isMultiCheck}
                        toggleSingleCheck={toggleSingleCheck}
                        toggleMultiCheck={toggleMultiCheck}
                        handleSelectShape={handleSelectShape}
                        selectedShapeController={selectedShapeController}
                        listings={listingsMapArea}
                        onSetSelectedLayers={setSelectedLayers}
                        selectedLayers={selectedLayers}
                        schoolDistrictLayer={schoolDistrictLayer}
                        onSetSchoolDistrictLayer={setSchoolDistrictLayer}
                        // --- new layer: Subdivision ---
                        subdivisionLayer={subdivisionLayer}
                        onSetSubdivisionLayer={setSubdivisionLayer}
                        // --- END ---
                        selectedSchoolLayers={selectedSchoolLayers}
                        onSetSelectedSchoolLayers={setSelectedSchoolLayers}
                    />
                    <div className={`row ${isMobile() ? "map-mobile" : ""}`}>
                        {showShowUpgradeModal && (
                            <UpgradePricingModal
                                listingId={upgradeListingId}
                                showModal={showShowUpgradeModal}
                                modalAction={toggleUpgradeModal}
                                totalListingCount={totalListingCount}
                            />
                        )}
                        {(viewSetting === null ||
                            viewSetting === "tile_map") && (
                            <div className={`map-listing col-5 col-lg-4 ${isMobile() ? "map-mobile" : ""}`}>
                                {listings.map((listing) => {
                                    return (
                                        <ListingDetails
                                            pageNumber={criteria?.page_number}
                                            listing={listing}
                                            listings={listings}
                                            key={listing.apartment_id}
                                            bookmarks={bookmarks}
                                            handleBookmark={fetchBookmarks}
                                            checked={selectedListings.some(
                                                (selectedListing) =>
                                                    selectedListing.groupListingId ===
                                                    listing.apartment_id
                                            )}
                                            isAddressDisplay={isAddressDisplay}
                                            toggleUpgradeModal={
                                                toggleUpgradeModal
                                            }
                                            showShowUpgradeModal={
                                                showShowUpgradeModal
                                            }
                                            hoverListingAction={
                                                hoverListingAction
                                            }
                                        />
                                    );
                                })}
                            </div>
                        )}
                        <div
                            className={`
                                ${
                                    viewSetting === "full_map"
                                        ? "map-view col-12 col-lg-12"
                                        : "map-view col-9 col-lg-8"
                                }
                                ${isMobile() ? "map-mobile" : ""}
                            `}
                        >
                            <MapSection
                                bookmarks={bookmarks}
                                handleBookmark={fetchBookmarks}
                                listings={listingsMapArea}
                                hoveredListingId={hoveredListingId}
                                hoverListingAction={hoverListingAction}
                                isSingleCheck={isSingleCheck}
                                mapSetting={mapSetting}
                                activeTooltipId={activeTooltipId}
                                onActiveTooltipId={setActiveTooltipId}
                                selectedShapeController={
                                    selectedShapeController
                                }
                                activeModalId={activeModalId}
                                onActiveModalId={setActiveModalId}
                                isAddressDisplay={isAddressDisplay}
                                totalListingCount={totalListingCount}
                                selectedLayers={selectedLayers}
                                setSelectedShapeController={setSelectedShapeController}
                                listingsMapArea={listings}
                                schoolDistrictLayer={schoolDistrictLayer}
                                subdivisionLayer={subdivisionLayer}
                                selectedSchoolLayers={selectedSchoolLayers}
                                setMultiSelectDrawManager={setMultiSelectDrawManager}
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                multiSelect={multiSelect}
                                setMultiSelect={setMultiSelect}
                                singleSelect={singleSelect}
                                setSingleSelect={setSingleSelect}
                                currentZoomLevel={currentZoomLevel}
                                setCurrentZoomLevel={setCurrentZoomLevel}
                            />
                            <MapToast />
                            {
                                ((tabChosen === 'all' && allCount > RowsPerPagePagination.LIMIT ) ||
                                (tabChosen === 'mls' && mlsCount > RowsPerPagePagination.LIMIT) ||
                                (tabChosen === 'rb' && rbCount > RowsPerPagePagination.LIMIT))
                                && (
                                    <div className='map-view-pagination-wrapper map-pagination'>
                                        <div className='current-page'>
                                            Viewing Page {mapAreaPageNum} of{" "}
                                            {totalPagesMap}
                                        </div>

                                        <ReactPaginate
                                            previousLabel={"< "}
                                            nextLabel={" >"}
                                            breakLabel={"..."}
                                            breakClassName={"break-me"}
                                            pageCount={totalPagesMap}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={5}
                                            onPageChange={(props) =>
                                                handlePageChangeMap(props)
                                            }
                                            forcePage={
                                                ((isPaginatedFromMapArea && !isPaginatedFromMapListingArea) || (!isPaginatedFromMapArea && !isPaginatedFromMapListingArea))
                                                ? criteria.page_number - 1 : mapAreaPageNum - 1
                                            }
                                            containerClassName={"pagination"}
                                            activeClassName={"active"}
                                        />
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    {(viewSetting === null || viewSetting === "tile_map") && (
                        <div className='row listing-pagination'>
                            <div className={`${isMobile() ? "col-12 col-lg-12" : "col-5 col-lg-4"}`}>
                                <div className='map-view-pagination-wrapper'>
                                    <div className='mb-2 current-page'>
                                        Viewing Page {listingAreaPageNum} of{" "}
                                        {totalPages}
                                    </div>
                                    <ReactPaginate
                                        previousLabel={"< "}
                                        nextLabel={" >"}
                                        breakLabel={"..."}
                                        breakClassName={"break-me"}
                                        pageCount={totalPages}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={(props) =>
                                            handlePageChange(props)
                                        }
                                        forcePage={
                                            ((isPaginatedFromMapListingArea && !isPaginatedFromMapArea) || (!isPaginatedFromMapArea && !isPaginatedFromMapListingArea))
                                            ? criteria.page_number - 1 : listingAreaPageNum - 1
                                        }
                                        containerClassName={"pagination"}
                                        activeClassName={"active"}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </>
            ) : null}
        </>
    );
});
