import React, {useContext, useEffect, useRef, useState} from "react";
import "./styles/map.scss";
import GoogleMapReact from "google-map-react";
import useSupercluster from "use-supercluster";
import {Flag} from "./flag";
import { googleMapApiKey } from '../../../services/constants';
import {fitBounds} from "google-map-react";
import {getMapBoundsForPackagedResults} from "../../utils/get_map_bounds";
import {BBox} from "geojson";
import {areCoordsValid, getResultId, IResult, removeDuplicates} from "../../components/listing_search/utilities";
import {ConsumerSearchContext} from "./consumer_search_context";
import {IListingApiGetParams, listingJsonApi} from "../../../services/api/listing";
import {tileFieldsToInclude} from "./util";

function Marker({props, children}: any) {
    return children
}

const obfuscatedCoords = {};
const obfuscatedClusterCoords = {};

export function MapColumn({results, tileResults}: { results: IResult[], tileResults: IResult[] }) {

    const mapRef = useRef(null);
    const containerRef = useRef<HTMLElement>(null);
    const {agentLinkId, setMultiUnitModalOpen, setMultiUnitListings} = useContext(ConsumerSearchContext);

    const [bounds, setBounds]= useState<BBox>([0, 0, 0, 0]);
    const [zoom, setZoom] = useState(10);
    const [center, setCenter] = useState({lat: 42.43, lng: -71.01});
    const [readyToScroll, setReadyToScroll] = useState(true);

    useEffect(() => {
        getTileBounds();
    }, [tileResults]);

    const points: any = results.map(result => {

        const pointId = getResultId(result);

        return {
            type: "Feature",
            properties: {
                cluster: false,
                resultId: pointId,
                category: pointId,
                result: result,
            },
            geometry: {
                type: "Point",
                coordinates: [
                    result.location.lon,
                    result.location.lat
                ]
            }
        }
    });

    const getTileBounds = () => {
        if(!tileResults.length) {
            return;
        }

        const newBounds = getMapBoundsForPackagedResults(tileResults);
        const boundsArr = [
            newBounds.sw.lng,
            newBounds.sw.lat,
            newBounds.ne.lng,
            newBounds.ne.lat,
        ];
        setBounds(boundsArr as BBox);

        const paddingModifier = 20;

        const mapCont = containerRef.current;
        const size = {
            width: mapCont.offsetWidth - paddingModifier, // Map width in pixels
            height: mapCont.offsetHeight - paddingModifier, // Map height in pixels
        };

        let {center, zoom} = fitBounds(newBounds, size);

        setZoom(zoom);
        setCenter(center);

        logToConsole(zoom, center, boundsArr);
    };

    const logToConsole = (zoom, center, bounds) => {
        console.log(`CENTER: ${center.lat} ${center.lng}\nZOOM: ${zoom}\nBOUNDS: ${JSON.stringify(bounds)}`);
    };

    const {clusters, supercluster} = useSupercluster({
        points,
        bounds,
        zoom,
        options: {radius: 75, maxZoom: 20}
    });

    const handleMapOnchange = (zoom, center, bounds) => {
        setZoom(zoom);
        console.log(`Set zoom to ${zoom}`);

        const boundsArr = [
            bounds.nw.lng,
            bounds.se.lat,
            bounds.se.lng,
            bounds.nw.lat
        ];
        setBounds(boundsArr as BBox);

        logToConsole(zoom, center, boundsArr);
    };

    const getCoordsForListing = (resultId, latitude, longitude) => {
        let lat = latitude;
        let lon = longitude;

        if(zoom > 16) {
            lat = obfuscatedCoords[resultId] ? obfuscatedCoords[resultId][0] : getObfuscatedCoord(latitude);
            lon = obfuscatedCoords[resultId] ? obfuscatedCoords[resultId][1] : getObfuscatedCoord(longitude);

            if(!obfuscatedCoords[resultId]) {
                obfuscatedCoords[resultId] = [lat, lon];
            }
        }
        return [lat, lon];
    };

    const getCoordsForCluster = (clusterId, latitude, longitude) => {
        let lat = latitude;
        let lon = longitude;

        if(zoom > 16) {
            lat = obfuscatedClusterCoords[clusterId] ? obfuscatedClusterCoords[clusterId][0] : getObfuscatedCoord(latitude);
            lon = obfuscatedClusterCoords[clusterId] ? obfuscatedClusterCoords[clusterId][1] : getObfuscatedCoord(longitude);

            if(!obfuscatedClusterCoords[clusterId]) {
                obfuscatedClusterCoords[clusterId] = [lat, lon];
            }
        }
        return [lat, lon];
    };

    const getObfuscatedCoord = (coord) => {
        const modifier = Math.random() * 0.001;
        const rdm = Math.random();
        const add = 0 === Math.floor(rdm * 10) % 2;

        return add ? coord + modifier : coord - modifier;
    };

    const handleClusteredDotClick = (cluster) => {
        if(zoom < 16) {
            setZoom(zoom+1);
            console.log(`Set zoom to ${zoom+1}`);

            const coords = cluster.geometry.coordinates;
            const lat = coords[1];
            const lng = coords[0];
            setCenter({lat: lat, lng: lng});
        }
    };

    const loadListingsForMultiUnitModal = async (listings) => {

        const ids = listings.map(listing => listing.apartment_id);
        const criteria = {
            fields_to_include: tileFieldsToInclude,
            listing_ids: ids,
            max_record_count: 300,
        } as IListingApiGetParams;

        const response = await listingJsonApi.get(criteria);

        let results = response.data.data.filter(areCoordsValid);
        results = removeDuplicates(results);

        return results
    };

    const handleSingleDotClick = async (cluster) => {
        const listings = cluster.properties.result.listings;

        if(listings.length > 1) {
            const loadedListings = await loadListingsForMultiUnitModal(listings);
            setMultiUnitModalOpen(true);
            setMultiUnitListings(loadedListings);
        }
        else {
            const listing = listings[0];
            let path = `/listings/show/${listing.apartment_id}?agentId=${agentLinkId}&isAgentBranded=true`;
            open(path);
        }
    };

    const getUnitsInBuilding = (listings) => {
        if(listings.length > 1) {
            return listings.length;
        }
    };

    const getNonClusteredDotStyleName = (zoom, listings) => {
        if(listings.length > 1) {
            return (zoom > 16) ? "obfuscated-single-dot map-dot" : "single-dot-multiunit map-dot"
        }
        else {
            return (zoom > 16) ? "obfuscated-single-dot map-dot" : "single-dot map-dot"
        }
    };

    return <section className="map-wrapper map-split" id="map-container" ref={containerRef} >
        <GoogleMapReact
            bootstrapURLKeys={{
              key: googleMapApiKey,
            }}
            center={center}
            zoom={zoom}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({map}) => {
                mapRef.current = map;
            }}
            onChange={
                ({zoom, center, bounds}) => {handleMapOnchange(zoom, center, bounds)}
            }
            onZoomAnimationEnd={()=>{setZoom(mapRef.current.zoom)}}
        >
        {
            clusters.map((cluster, index) => {
                const [longitude, latitude] = cluster.geometry.coordinates;

                if (cluster.properties.cluster) {
                    const clusterId = `cluster__${index}`;
                    return <Marker
                        key={clusterId}
                        lat={getCoordsForCluster(clusterId, latitude, longitude)[0]}
                        lng={getCoordsForCluster(clusterId, latitude, longitude)[1]}
                    >
                        <div className={zoom > 16 ? "obfuscated-clustered-dot map-dot" : "clustered-dot map-dot"} onClick={ () => handleClusteredDotClick(cluster) } >{cluster.properties.point_count}</div>
                    </Marker>
                } else {
                    return <Marker
                        key={cluster.properties.resultId}
                        lat={getCoordsForListing(cluster.properties.resultId, latitude, longitude)[0]}
                        lng={getCoordsForListing(cluster.properties.resultId, latitude, longitude)[1]}
                    >
                        <div className={getNonClusteredDotStyleName(zoom, cluster.properties.result.listings)} onClick={ () => {handleSingleDotClick(cluster)} }>{getUnitsInBuilding(cluster.properties.result.listings)}</div>
                    </Marker>
                }
            })
        }

        {
            tileResults.map((result) => {
                let listing = result.listings[0];
                let location = result.location;
                let resultId = getResultId(result);
                return (
                    <Flag
                        lat={getCoordsForListing(resultId, location.lat, location.lon)[0]}
                        lng={getCoordsForListing(resultId, location.lat, location.lon)[1]}
                        text={`$${listing.rent}`}
                        key={resultId}
                        resultId={resultId}
                        readyToScroll={readyToScroll}
                        setReadyToScroll={setReadyToScroll}
                        zoom={zoom}
                    />
                );
            })
        }
        </GoogleMapReact>
    </section>
}