import React from "react";
import {IConsumerData} from "../../../services/api";
import {ISignInSubmitForm} from "../../components/hooks/use_sign_in_form";
import {IListing, IListingApiGetParams} from "../../../services/api/listing";

interface IConsumerSearchContext {
    tileSearching: boolean,
    setTileSearching: (newVal: boolean) => void,
    mapSearching: boolean,
    setMapSearching: (newVal: boolean) => void,
    highlightedResultId: string,
    setHighlightedResultId: (newVal: string) => void,
    userData: IConsumerData,
    submitForm: (params: ISignInSubmitForm) => void,
    isMobile: boolean,
    agentLinkId: number,
    baseTileCriteria: IListingApiGetParams,
    setBaseTileCriteria: (params: IListingApiGetParams) => void,
    totalPages: number,
    setMultiUnitListings: (newVal: IListing[]) => void,
    setMultiUnitModalOpen: (newVal: boolean) => void,
}
export const ConsumerSearchContext = React.createContext<IConsumerSearchContext>(null);
