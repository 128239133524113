import React, {useContext, useState} from "react";
import Collapsible from "react-collapsible";
import {ClientAutoComplete} from "../../../components/client_auto_complete/client_auto_complete";
import Checkbox from '@material-ui/core/Checkbox';
import {Select} from "@material-ui/core";
import {SweetAlertContext} from "../../../components/sweet_alert/sweet_alert";
import {BootstrapTooltip} from "../../../components/listing_search/search_filters/filters";

export function ClientAssociation(params) {

    const {alert, handleAssociateClient, handleDissociateClient} = params;
    const {triggerAlert} = useContext(SweetAlertContext);
    const [inputValue, setInputValue] = useState("");

    const getEmptyClientObj = () => {
        return ({
            notification_freq: "once_daily",
            send_email: false,
            client_id: 0,
            client_name: "",
        }
    )};

    const [newClient, setNewClient] = useState(getEmptyClientObj());

    const optionChoices = [
        {label: "Once a day", value: "once_daily"},
        {label: "Twice a day", value: "twice_daily"},
        {label: "Instantly", value: "instantly"},
        {label: "Unsubscribed", value: "unsubscribed"},
    ];

    const handleFrequencyChange = (e) => {
        setNewClient({...newClient, notification_freq: e.currentTarget.value});
    };

    const getTriggerMarkup = (isOpen = false) => {
        return (
            <div className="search-filters-row">
                <i className={`fas ${isOpen ? "fa-caret-down" : "fa-caret-right"}`} />
                <div className="title">Clients</div>
            </div>
        )
    };

    const handleClientChange = (selectedClient) => {
        if (selectedClient) {
            setNewClient({...newClient, client_id: selectedClient.id, client_name: selectedClient.label});
        }
    };

    const handleSendEmailChange = (event) => {
        setNewClient({...newClient, send_email: event.target.checked});
    };

    const getFrequencyLabel = (freq) => {
        const option = optionChoices.filter(opt => opt.value === freq);
        return option[0].label;
    };

    const getClientsMarkup = (alert) => {
        const clients = alert.clients;
        const noClients = <li><i>There are no clients associated to this alert</i></li>
        const clientMarkup = alert.clients.map( (client, index) => {
            return <li key={`client_${client.client_id}`} className="client-li">
                <div>
                    <span>{client.client_name}</span>
                    <BootstrapTooltip title="Dissociate client" onClick={()=>handleDissociateClient(alert, clients, index)}>
                        <i className="far fa-trash-alt dissociate-icon" />
                    </BootstrapTooltip>
                </div>
                <ul className="notification-attributes">
                    <li>Send email: {client.send_email ? "Yes" : "No"}</li>
                    <li>Frequency: {getFrequencyLabel(client.notification_freq)}</li>
                </ul>
            </li>
        });

        return clients.length ? clientMarkup : noClients
    };

    const handleSubmit = (newClient, alert) => {
        if(!newClient.client_id) {
            triggerAlert("error", "No client has been selected.");
            return;
        }

        // check that new client is not already in alert.clients list
        const match = alert.clients.filter(client => client.client_id === newClient.client_id);
        if(match.length) {
            triggerAlert("error", "This client is already associated with the alert.");
            return;
        }

        setInputValue("");
        setNewClient(getEmptyClientObj());
        handleAssociateClient(newClient, alert);
    };

    return (
        <div className="clients-row">
            <Collapsible trigger={getTriggerMarkup()} triggerWhenOpen={getTriggerMarkup(true)}>
                <ul className="clients-list">
                    { getClientsMarkup(alert) }
                </ul>
                <div className="add-client-row">
                    <div className="label">Add a client</div>
                    <ClientAutoComplete handleChange={handleClientChange}
                                        inputValue={inputValue}
                                        setInputValue={setInputValue}
                                        placeHolder="Type a client's name" />
                </div>
                <div className="client-email-row">
                    <Checkbox
                        onChange={handleSendEmailChange}
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        value={newClient.send_email}
                        checked={newClient.send_email}
                    />
                    <label>Send email</label>
                    <Select
                        native
                        value={newClient.notification_freq}
                        onChange={handleFrequencyChange}
                    >
                        {
                            optionChoices.map((option) => (
                                <option value={option.value} key={option.value}>{option.label}</option>
                            ))
                        }
                    </Select>
                    <div className="add-client-btn" onClick={() => handleSubmit(newClient, alert) }>Submit</div>
                </div>
            </Collapsible>
        </div>
    )
}