import React, {useContext, useEffect, useState} from "react";
import {FieldsetMultipleSelect} from "../multiple_select/fieldset_multiple_select";
import {ListingSearchContext} from "../../listing_search_context";

export function FieldsetExternalStructures() {
    const {getFilterOptions, filterConfig, criteria} = useContext(ListingSearchContext);
    const [options, setOptions] = useState([]);
    const [optionArr, setOptionArr] = useState(criteria.external_structures);

    useEffect(()=>{
        setOptions(getFilterOptions("external_structure"));
    },[filterConfig]);

    useEffect(()=>{
        setOptionArr(criteria.external_structures);
    }, [criteria.external_structures]);

    return <FieldsetMultipleSelect
        options={options}
        optionArr={optionArr}
        setOptionArr={setOptionArr}
        title="External Structures"
        criteriaFieldName="external_structures"
    />
}