import React from "react";
import "./checkbox_group.scss"
import {RBCheckbox} from "../rb_checkbox/rb_checkbox";

export function CheckboxGroup(props) {
    const {nonEditableFields = [], className = ""} = props;
    return props.items.map(item => {
        return <div key={item.name} className={className}>
                <RBCheckbox
                    name={item.name}
                    handleChange={props.handleChange}
                    checked={props.values[item.name] || false}
                    disabled={item.disabled || nonEditableFields.includes(item.name)}
                    label={item.label}
                />
            </div>
    })
}