import {Dialog, DialogContent, DialogTitle} from "@material-ui/core";
import {FacebookShareButton, TwitterShareButton} from "react-share";
import React, {useState} from "react";
import "./ShareDialog.scss";
import {IListing} from "../../../services/api/listing";
import xTwitterLogo from '../../../../assets/images/logo-black.png'

interface ShareDialogProps {
    handleDialogClose?: () => void;
    shareDialogOpen?: boolean;
    shareListing?: IListing;
}

export const ShareDialog = React.memo(
    function ShareDialogComponent(props: ShareDialogProps) {
        const {handleDialogClose, shareDialogOpen, shareListing} = props;
        const [craigsContent, setCraigsContent] = useState(null as string | null);

        const listingFacebookUrl = () => {
            const id = shareListing?.apartment_id || 0;
            const newlocation = location.origin + "/fb-share/" + "default" + "/" + id;
            return newlocation;
        };

        const setCraigslistContent = () => {
            setCraigsContent(`<p><b>${shareListing?.city}, ${shareListing?.state}, ${shareListing?.zip}<br/>
                            ${shareListing?.address}<br/>
                            $${shareListing?.rent}<br/>
                            ${shareListing?.bedrooms} Bedroom</b><br/>
                        </p>
                        <p>
                            ${shareListing?.comments}
                        </p>
                        <h3>Contact Agent: ${shareListing?.first_name} ${shareListing?.last_name} ${shareListing?.management_co}</h3>
                        <h4>${shareListing?.direct_phone}</h4>
                        <h4>${shareListing?.email}</h4>
                        <br/>
                        <h4> Follow us on Twitter @RentalBeast</h4>
                        <h4>Find us on Facebook: http://www.facebook.com/RentalBeast</h4>
                    `)
        }

        return React.useMemo(() => <Dialog
            onClose={() => {
                    setCraigsContent(null);
                    handleDialogClose();
                }
            }
            aria-labelledby="customized-dialog-title"
            open={shareDialogOpen}
            maxWidth={false}
        >
            <DialogTitle className="dialog-title">Share Listing</DialogTitle>
            <DialogContent className="dialog-container">
                {craigsContent ? <div className="craigslist">
                    <p>Copy HTML code to embed in other sites</p>
                    <p className="content">{craigsContent}</p>
                </div> : <>
                    <div>
                        <FacebookShareButton
                            url={listingFacebookUrl()}
                          
                            quote={""}
                            hashtag=""
                        >
                            <div className="share-dialog">
                                <i title="Facebook" className="fab fa-facebook net-icon"/>
                                Facebook
                            </div>
                        </FacebookShareButton>
                    </div>
                    <div>
                        <TwitterShareButton
                            url={listingFacebookUrl()}
                            title={"Check out this listing"}
                            hashtags={["RentalBeast"]}
                        >
                            <div className="share-dialog">
                                {/* <i title="Twitter" className="fab fa-twitter net-icon"/> */}
                                <img style={{ width: '22px', marginLeft: '4px' }} src={xTwitterLogo} />
                                <span style={{ marginLeft: '13px' }}>Twitter</span>
                            </div>
                        </TwitterShareButton>
                    </div>
                    <div>
                        <div className="share-dialog craigslist-btn" onClick={setCraigslistContent}>
                            <i title="Craigslist" className="fas fa-peace net-icon"/>
                            Craigslist
                        </div>
                    </div>
                </>}
            </DialogContent>
        </Dialog>, [craigsContent])
    }
)
