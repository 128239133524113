import React, {useContext, useEffect, useState} from "react";
import {FieldsetMultipleSelect} from "../multiple_select/fieldset_multiple_select";
import {ListingSearchContext} from "../../listing_search_context";

export function FieldsetRoomTypes() {
    const {getFilterOptions, filterConfig, criteria} = useContext(ListingSearchContext);
    const [options, setOptions] = useState([]);
    const [optionArr, setOptionArr] = useState(criteria.room_types);

    useEffect(()=>{
        setOptions(getFilterOptions("room_types"));
    },[filterConfig]);

    useEffect(()=>{
        setOptionArr(criteria.room_types);
    }, [criteria.room_types]);

    return <FieldsetMultipleSelect
        options={options}
        optionArr={optionArr}
        setOptionArr={setOptionArr}
        title="Room Type"
        criteriaFieldName="room_types"
    />
}