import React, {useContext, useEffect, useState} from "react";
import {ListingSearchContext} from "../../components/listing_search/listing_search_context";
import {listingStatsApi} from "../../../services/api/listing_stats";
import {BrokerSearchingContext} from "./broker_search_context";
import {AgentUserContext} from "./agent_user_context";
import { getAgentPartnerKey } from "../../components/listing_search/utilities";

interface ISearchWidgetProp {
 setTotalListingCount: React.Dispatch<any>
}
export function SearchWidget(props: ISearchWidgetProp) {
    const {criteria} = useContext(ListingSearchContext);
    const {searching} = useContext(BrokerSearchingContext);
    const {isFullAgent} = useContext(AgentUserContext);
    const {setTotalListingCount} = props

    const newLocal = "bp_search";
    const listingStatProps = {
        state: criteria.multiple_areas.map((c) => c.state)[0],
        city: criteria.multiple_areas.map((c) => c.city),
        zip_codes: criteria.multiple_areas.map((c) => c.zip)[0],
        gen_neighborhood: criteria.multiple_areas.map((c) => c.neighborhood),
        // unitNumber: criteria.apt_number,
        min_price: criteria.rent_range.min_price,
        max_price: criteria.rent_range.max_price,
        min_bedrooms: criteria.bedroom_range.min_bedrooms,
        max_bedrooms: criteria.bedroom_range.max_bedrooms,
        min_bathrooms: criteria.bathroom_range.min_bathrooms,
        max_bathrooms: criteria.bathroom_range.max_bathrooms,
        partner_key: criteria.partner_key,
        rb_portal: newLocal,
    };

    const handleSearch = async () => {
        if(criteria.partner_key  === null){
            const currentPartnerKey = await getAgentPartnerKey();
            listingStatProps.partner_key = currentPartnerKey;
        }
        const listingStatsResponse = await listingStatsApi.get(listingStatProps);
        const listingStatsData = listingStatsResponse.data?.data || {};
        setTotalListingCount(listingStatsData.total_count)
    };

    useEffect(() => {
        if (searching && !isFullAgent()) {
            handleSearch();
        }
    }, [searching]);

    return null;
}
