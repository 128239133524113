import React, { useContext } from "react";
import {
  IListingLocal,
  IRentalLeaseListing,
} from "../../../../../../services/api/listing";
import styles from "./main_info.module.scss";
import { numberOfBedrooms } from "../../../../../utils/bed_rooms";
import moment from "moment";
import { ListingActionButtons } from "../../../../listing_action_buttons/listing_action_buttons";
import { AgentUserContext } from "../../../../broker_search/agent_user_context";
import { useAgentShareLink } from "../../../../../components/hooks/use_agent_share_link";
import { UsePartnerConfig } from "../../../../../components/hooks/use_partner_config";
import { isMLSListing } from "../../../../../utils/listings";
import { useTranslation } from "react-i18next";

export function MainInfo(props: {
  listing: IListingLocal;
  applyNowEnabled: boolean;
  leaseData: IRentalLeaseListing;
  constructListingDetailUDFs?: any
}) {
  const {
    listing,
    leaseData,
    applyNowEnabled,
    constructListingDetailUDFs
  } = props;
  const { isFullAgent, isMlsAgent } = useContext(AgentUserContext);
  const { Dialog, setShareDialogOpen } = useAgentShareLink();
  const { hideClaim } = UsePartnerConfig();
  const { t } = useTranslation( 'translation',{useSuspense: false});

  const getAddressLine = (listing) => {
    const unit = listing.apt_number;
    return unit && "" !== unit
      ? `${listing.address}, Unit ${unit}`
      : listing.address;
  };

  const getNeighborhoodAndBuildingName = (listing) => {
    const buildingName =
      listing.building_name && "" !== listing.building_name
        ? listing.building_name
        : null;
    const neighborhood =
      listing.neighborhood && "" !== listing.neighborhood
        ? listing.neighborhood
        : null;

    if (!buildingName && !neighborhood) {
      return "";
    }
    return neighborhood && buildingName
      ? `${buildingName}, ${neighborhood}`
      : buildingName || neighborhood;
  };

  const getNeighborhoodOrNeighborhoodOverride = listing => {
    const neighborhoodToDisplay: string | number | null = listing.neighborhood_override

    if (!neighborhoodToDisplay) return ''

    return `Subdivision: ${neighborhoodToDisplay}`
  }

  const handleShareClick = (e) => {
    e.stopPropagation();
    setShareDialogOpen(true);
  };

  const showOrHideShareLinkIcon = () => {
    if (
      (isFullAgent() && listing.is_listing_free) ||
      listing.is_agent_listing
    ) {
      return (
        <i
          className={`fas fa-share ${styles.shareIcon}`}
          onClick={handleShareClick}
        />
      );
    } else if (isMlsAgent() && listing.is_agent_listing) {
      return (
        <i
          className={`fas fa-share ${styles.shareIcon}`}
          onClick={handleShareClick}
        />
      );
    }
  };

  const showOrHideMlSListingId = (listing) => {
    if (isMLSListing(listing) && listing.mls_id !== "") {
      return <div>{t('MLS Listing ID')}: {listing.mls_id}</div>;
    }
  };

  const getHiddenTabSetting = () => {
    return localStorage.getItem('hidden_tab')
  }

  const constructHTMLUdfItems = (anchorName, customClassName?: any, customStyle?: any) => {
    const udfFields = constructListingDetailUDFs(anchorName, listing)

    return udfFields.map(({ label, field_type, summaryLinkValue }, index) => (
      <div key={index} className={customClassName}>
        <span>
        {
          field_type === 'url' ? <a href={summaryLinkValue} target="_blank">{label}</a> : <>{label}: {summaryLinkValue}</>
        }
        </span>
      </div>
    ))
  }

  return (
    <div className={`${styles.main}`}>
      <div className={styles.info}>
        <div className={styles.address}>{getAddressLine(listing)}</div>
        <div className={styles.location}>
          {/* <span>Subdivision: {getNeighborhoodAndBuildingName(listing)}</span> */}
          <span>{getNeighborhoodOrNeighborhoodOverride(listing)}</span>
        </div>
        <div className={styles.location}>
          <span>
            {listing.city}, {listing.state} {listing.zip} {listing.county}
          </span>
        </div>
        {/* RB-2805: UDF values for summary_address */}
        {constructHTMLUdfItems('summary_address', styles.location)}
        {/* --- END --- */}
        <div className={styles.mainInfoContainer}>
          <span className={styles.mainInfo}>
            <i className={`fas fa-dollar-sign ${styles.money}`} />
            {listing.rent}
          </span>
          <span className={styles.mainInfo}>
            <i className={`fas fa-bed ${styles.icon}`} />
            {numberOfBedrooms[listing.bedrooms]}
          </span>
          <span className={styles.mainInfo}>
            <i className={`fas fa-bath ${styles.icon}`} />
            {listing.bathrooms} Bathroom
            {parseInt(listing.bathrooms) !== 1 ? "s" : null}
          </span>
          <span className={styles.mainInfo}>
            <i className={`far fa-calendar-alt ${styles.icon}`} />
            Available: {moment(listing.date_available).format("MMM DD, YYYY")}
          </span>
          {/* RB-2805: UDF values for summary_features */}
          {constructHTMLUdfItems('summary_features', styles.mainInfo)}
          {/* --- END --- */}
        </div>
        {showOrHideMlSListingId(listing)}
        <div>
          {listing.apartment_id !== null
            ? `Rental Beast ID: ${listing.apartment_id}`
            : null}
        </div>
        <div className={styles.status}>
          Status: {listing.status.toUpperCase()}
        </div>
        <div className={styles.status}>
          DOM: {listing.dom?.length ? listing.dom[0]?.calculated_days_on_market : "N/A"}
        </div>

        {listing.status == "Rented" && leaseData !== null && (
          <>
            <div>
              Leased Date: {moment(leaseData?.lease_date).format("MMM DD, YYYY")}
            </div>
            <div>Leased Term: {leaseData?.lease_term}</div>
            <div>Leased Rate: $ {leaseData?.monthly_rent}</div>
          </>
        )}
        {/* RB-2805: UDF values for summary_features */}
        {constructHTMLUdfItems('summary_status', styles.status)}
        {/* --- END --- */}
      </div>
      <div className={styles.applyNowContainer}>
        {
          getHiddenTabSetting() !== 'applications' && (listing.use_apply_now || applyNowEnabled)
            ? (
                <a
                  href={`/free_tenant_screening?listing_id=${listing.apartment_id}#!/`}
                  target="_blank"
                  className={styles.applyNowButton}>
                  APPLY NOW
                </a>
              )
            : null
        }
        {listing?.showing_time && (
          <div className={styles.showingTimeContainer}>
            <a href={listing?.showing_time?.showing_time_link} target='_blank'>
              <img src={listing?.showing_time?.showing_time_image_link} />
            </a>
          </div>
        )}
        {/* RB-2805: UDF values for summary_links */}
        {constructHTMLUdfItems('summary_links', styles['udf-summary_link_container'])}
        {/* --- END --- */}
        <div className={styles.shareContainer}>
          <ListingActionButtons
            selectedListingIds={[listing.apartment_id]}
            excludeBookmark={true}
            isDisabled={false}
            listing={listing}
            excludeCMA={true}
            includeFacebookShare={true}
            excludeClaim={hideClaim}
            excludeEmail={false}
            excludePrint={false}
            excludeShare={false}
            isLDPOnly={true}
          />
          <div>{showOrHideShareLinkIcon()}</div>
        </div>
      </div>
      <Dialog shareListing={listing} />
    </div>
  );
}
