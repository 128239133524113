import React, {useContext, useEffect, useLayoutEffect, useState} from "react";
import {getBpFilterConfig} from "./bp_filter_config";
import {ConsumerFilterConfig} from "./consumer_filter_config";
import {IFilterGroup} from "./types";
import {ListingSearchContext} from "../listing_search_context";
import {IListingApiGetParams, ListingType} from "../../../../services/api/listing";
import { partnerConfigApi } from "../../../../services/api/partner_config";
import { universalOptionalCompensationSectionKeys } from "../../../../services/constants";
import { getAgentPartnerKey } from "../utilities";


interface IFiltersProviderContext {
    container: HTMLElement;
    contWidth: number;
    setContWidth: Function;
    colWidth: number;
    filterGroups: any;
    setFilterGroups: ([]: IFilterGroup[]) => void;
    handleToggleAll: (any) => void;
    isConsumerPortal: boolean;
    validateSearchFilters: (criteria: IListingApiGetParams) => boolean;
}

export const FiltersContext = React.createContext<IFiltersProviderContext>(null);

interface FiltersProviderProps {
    isConsumerPortal?: boolean;
    excludedFilters?: string [];
    children: any;
    hideAgentSearch?: boolean;
    customStatusesFilters?: any[];
}

export function FiltersProvider(props: FiltersProviderProps) {
    const {excludedFilters = [], hideAgentSearch = false} = props;
    const [container, setContainer] = useState(null);
    const [contWidth, setContWidth] = useState(null);
    const [filterGroups, setFilterGroups] = useState([]);
    const {initFilterConfig} = useContext(ListingSearchContext);
    const isConsumerPortal = props.isConsumerPortal;

    const [partnerCompanyFields, setPartnerCompanyFields] = useState([]);
    const [compensationFields, setCompensationFields] = useState([])

    const initiatePartnerCompanyFields = async () => {
        const partnerKey = await getAgentPartnerKey()
        const partnerConfigRes = await partnerConfigApi.get(partnerKey, ["partner_company_optional_fields"])
        setPartnerCompanyFields(partnerConfigRes["partner_company_optional_fields"])
    }

    useEffect(() => {
        initiatePartnerCompanyFields()
    }, [])

    useEffect(() => {
        if (partnerCompanyFields && partnerCompanyFields.length > 0)
        {
            const filteredCompensationFields = partnerCompanyFields.filter((companyField) => universalOptionalCompensationSectionKeys.includes(companyField.optional_field_key));
            setCompensationFields(filteredCompensationFields);
        }
    }, [partnerCompanyFields])

    const colWidth = 400;
    const listingType = ListingType;

    const getContainerWidth = () => {
        return document.getElementById("cabinet-container")?.clientWidth;
    };

    const handleToggleAll = (newVal) => {
        const newFilterGroups = filterGroups.map(function(filter){
            return {...filter, isOpened: filter.isExpandable ? newVal : filter.isOpened };
        });
        setFilterGroups(newFilterGroups);
    };

    const validateSearchFilters = (criteria) => {
        // ensures that an agent_id is selected if listing type is agent_listings or other_agent_listings
        let isValid = true;
        if(listingType.other_agent_listings === criteria.listing_type || listingType.agent_listings === criteria.listing_type) {
            isValid = criteria.agent_id && criteria.agent_id > 0;
        }
        return isValid;
    };

    useEffect(()=>{
        const cont = document.getElementById("cabinet-container");
        setContainer(cont);

        const width = getContainerWidth();
        setContWidth(width);

        const config = isConsumerPortal ? ConsumerFilterConfig : getBpFilterConfig(excludedFilters, hideAgentSearch, props.customStatusesFilters, compensationFields);
        setFilterGroups(config);

        initFilterConfig();
    },[props.customStatusesFilters, compensationFields]);

    useEffect(()=>{
        const cont = document.getElementById("cabinet-container");
        setContainer(cont);

        const width = getContainerWidth();
        setContWidth(width);

        const config = isConsumerPortal ? ConsumerFilterConfig : getBpFilterConfig(excludedFilters, hideAgentSearch, props.customStatusesFilters, compensationFields);
        setFilterGroups(config);

        initFilterConfig();
    },[props.customStatusesFilters, compensationFields]);

    useEffect(()=>{
        if (excludedFilters.length) {
            const config = isConsumerPortal ? ConsumerFilterConfig : getBpFilterConfig(excludedFilters, hideAgentSearch, props.customStatusesFilters, compensationFields);
            setFilterGroups(config);
        }
    },[excludedFilters, props.customStatusesFilters, compensationFields]);

    useLayoutEffect(() => {
        const fx = () => {
            setContWidth(getContainerWidth());
        };

        window.addEventListener('resize', fx);
        return () => window.removeEventListener('resize', fx);
    }, []);

    return (
        <FiltersContext.Provider
            value={{
                container,
                contWidth,
                setContWidth,
                colWidth,
                filterGroups,
                setFilterGroups,
                handleToggleAll,
                isConsumerPortal,
                validateSearchFilters,
            }}
        >
            {props.children}
        </FiltersContext.Provider>
    )
}
