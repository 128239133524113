import React, {useContext, useState} from "react";
import {AgentUserContext} from "./agent_user_context";
import { IListing } from "../../../services/api/listing";
import { ListingSearchContext } from "../../components/listing_search/listing_search_context";
import {isBrokerPortal} from "../listing_management/utils";
export interface ISelectedListing {
    listingId: number;
    groupListingId: number;
    pageNumber?: number;
    listing_source_type?: string;
}

interface ISelectedListingsContext {
    selectedListings: ISelectedListing [],
    setSelectedListings: (newSelectedListings: ISelectedListing []) => any;
    handleSelectedListing: (listingIdToUpdate: number, selected: boolean, groupIdToUpdate: number, listings?: IListing[], pageNumber?: number) => any;
    clearSelectedListings?: () => any;
    handleAllSelectedListings?: (listings: any, isSelecedFromMap?: boolean) => any;
    allIsChecked?: boolean;
    allMlsIsChecked?: boolean;
    groupedListings?: ISelectedListing[];
    // setGroupedListings?: (buildingListings: ISelectedListing[]) => any;
    setGroupedListings?: (buildingListings: any[]) => any;
    handleMergeGroupListings?: (subListings: IListing[], pageNumber: number) => any;
    rowPerPageRenderer?: string;
    setRowPerPageRenderer?: (rowPerPageRenderer: string) => any;
    handlePageChange?: (listings: IListing[]) => any;
    isRBListing: (listing: any) => boolean;
    isMLSListing: (listing: any) => boolean;
    isRBGListing: (listing: any) => boolean;
    isMlsAgentAndNonMlsListing: (listing: any) => boolean;
    isGatewayAgentAndNonMlsListing: (listing: any) => boolean;
    isGatewayAccessibleListing: (listing: any) => boolean;
    isMlsAgentAndEmptyDisclaimer: (listing: any) => boolean;
    getSelectedListingIds: () => number [];
    areShowingListings: boolean;
    setAreShowingListings: (showing: boolean) => any;
    isDisclaimerEmpty: (listing: any) => boolean;
    disableCopyPaste: (e: React.ClipboardEvent<any>) => void
    isUnselectActivated: boolean;
    setIsUnselectActivated: (isUnselectActivated: boolean) => any;
}

export const SelectedListingsContext = React.createContext<ISelectedListingsContext>(null);

// This is being used in both BP and LLP
const SelectedListingsProvider = React.memo(
    function SelectedListingsProviderComponent(props) {
        const [selectedListings, setSelectedListings] = useState([] as ISelectedListing []);
        const [areShowingListings, setAreShowingListings] = useState(false);
        const [allIsChecked, setAllIsChecked] = useState(false)
        const [allMlsIsChecked, setAllMlsIsChecked] = useState(false)
        const [groupedListings, setGroupedListings] = useState([])
        const [rowPerPageRenderer, setRowPerPageRenderer] = useState<string>('')
        // Created empty functions that return false for LLP
        const {isMlsAgent, isGatewayAgent} = isBrokerPortal() ? useContext(AgentUserContext) : {isMlsAgent: () => false, isGatewayAgent: () => false};
        const { criteria } = useContext(ListingSearchContext)
        const [isUnselectActivated, setIsUnselectActivated] = useState(false);

        const handleMergeGroupListings = (subListings: IListing[], pageNumber: number) => {
            const mergedGroupedListingsIds = []

            subListings.forEach(({ apartment_id, listing_source_type }): any => {
                groupedListings.push({ listingId: apartment_id, groupListingId: apartment_id, pageNumber, listing_source_type })
            })

            groupedListings.forEach(({ listingId }) => {
                mergedGroupedListingsIds.push(listingId)
            })

            const removeDuplicateListings = groupedListings.filter(({ listingId }: any, index: number) => !mergedGroupedListingsIds.includes(listingId, index + 1))
            const filteredGroupListingsPerPage = removeDuplicateListings.filter(({ pageNumber }) => pageNumber === criteria?.page_number)
            setGroupedListings(filteredGroupListingsPerPage)
        }

        const handleSelectedListing = (listingIdToUpdate: number, selected: boolean, groupIdToUpdate: number, listings?: IListing[]) => {
            if (selected) {
                //Selected Listings Section
                const newSelectedListings = [...selectedListings, {listingId: listingIdToUpdate, groupListingId: groupIdToUpdate}]
                const { allSelectedListingsIds } = listingShaper(listings)
                const newFilteredSelectedListings = newSelectedListings.filter(({ listingId }: ISelectedListing) => allSelectedListingsIds.includes(listingId))
                setSelectedListings(newSelectedListings)
                setAllIsChecked(newFilteredSelectedListings.length === allSelectedListingsIds.length)
            } else {
                //Selected Listings Section
                const newSelectedListings = selectedListings.filter(selectedListing => selectedListing.listingId !== listingIdToUpdate);
                setSelectedListings(newSelectedListings);
                setAllIsChecked(false)
            }
        };

        const listingShaper = (listings: IListing[]) => {
            const allSelectedListings: ISelectedListing[] = listings?.map(({ apartment_id, listing_source_type }: IListing) => ({ listingId: apartment_id, groupListingId: apartment_id, listing_source_type }))
            // Get the IDs of each ALL SELECTED and GROUPED LISTINGS for that page
            // Merge the IDs ALL LISTING and the GROUPED LISTINGS for easy mapping
            // Remove duplicate ALL LISTINGS
            const mergedAllAndGroupListings = [...allSelectedListings, ...groupedListings]
            const mergedAllAndGroupListingsIds = mergedAllAndGroupListings.map((listing: ISelectedListing) => listing.listingId)
            const removeDuplicateAllListings = mergedAllAndGroupListings
                .filter(({ listingId }: any, index: number) => !mergedAllAndGroupListingsIds.includes(listingId, index + 1))
                .filter(({ listing_source_type }: any) => !isMlsAgent() || (listing_source_type !== "Off-MLS" && listing_source_type !== 'RB')) //  filter for MLS listing and MLS agent / Pro Agent
            // END

            // Get the IDs of each ALL SELECTED and the ALL-IN-ALL SELECTED Listings
            // Merge the IDs ALL LISTING and the SELECTED Listings for easy mapping
            const allSelectedListingsIds = removeDuplicateAllListings.map((listing: ISelectedListing) => listing.listingId)
            const selectedLisingsIds = selectedListings.map((listing: ISelectedListing) => listing.listingId)
            const mergedListingIds = [...selectedLisingsIds, ...allSelectedListingsIds]
            // END

            const mergedSelectedListings = [...selectedListings, ...removeDuplicateAllListings]
            const finalSelectedListings = mergedSelectedListings
                .filter(({ listingId }: ISelectedListing, index: number) => {
                    return !mergedListingIds.includes(listingId, index + 1)
                })

            const filteredAllSelectedListingsIds = allSelectedListingsIds.filter(val => selectedLisingsIds.indexOf(val) !== -1)

            const filterdFinalSelectedListings = finalSelectedListings
                .filter(({ listingId }: ISelectedListing) => {
                    return !allSelectedListingsIds.includes(listingId)
                })

            return {
                selectedLisingsIds,
                finalSelectedListings,
                allSelectedListingsIds,
                filterdFinalSelectedListings,
                filteredAllSelectedListingsIds,
                allSelectedListings
            }
        }

        const handlePageChange = (listings: IListing[]) => {
            const { allSelectedListingsIds, filteredAllSelectedListingsIds } = listingShaper(listings)
            setAllIsChecked(allSelectedListingsIds.length && filteredAllSelectedListingsIds.length === allSelectedListingsIds.length)
        }

        const handleAllSelectedListings = (listings: IListing[], isSelecedFromMap = false) => {
            const {
                finalSelectedListings,
                allSelectedListingsIds,
                filterdFinalSelectedListings,
                allSelectedListings
            } = listingShaper(listings)

            // Commented because it will still be decided
            // Disable if all listings within that page is RB Reviewed or Off-MLS
            setAllMlsIsChecked(!allIsChecked && !!allSelectedListingsIds.length)
            setAllIsChecked(!allIsChecked)
            if (isSelecedFromMap) {
                let shapeGroupListingsArr = [];
                listings.map((listing : any) => {
                    if (listing.group_listings) {
                        const selectedListingIds = new Set(selectedListings.map((selectedListing: any) => selectedListing.listingId));

                        const shapeGroupListings = listing.group_listings.map((groupedListing : any) => {
                            return {
                                listingId: parseInt(groupedListing.apartment_id),
                                groupListingId: parseInt(groupedListing.apartment_id),
                                listing_source_type : groupedListing.listing_source_type
                            }
                        })
                        shapeGroupListingsArr.push(...shapeGroupListings);
                    } else {
                        const singleListing = {
                          listingId: parseInt(listing.apartment_id),
                          groupListingId: parseInt(listing.apartment_id),
                          listing_source_type : listing.listing_source_type
                        }
                        shapeGroupListingsArr.push(singleListing);
                    }
                });
            
            const newSelectedListings = shapeGroupListingsArr.filter(listing => {
                return !selectedListings.some(item => item.listingId === listing.listingId);
            });

            const unselectedListings = selectedListings.filter(item => {
                return !shapeGroupListingsArr.some(listing => listing.listingId === item.listingId);
            });

            setSelectedListings([...newSelectedListings, ...unselectedListings]);
            } else {
                setSelectedListings(allIsChecked ? filterdFinalSelectedListings : finalSelectedListings)
            }
        }

        const clearSelectedListings = () => {
            setSelectedListings([])
            setAllIsChecked(false)
            setIsUnselectActivated(true);
        }

        const isRBListing = (listing) => {
            // return listing.disclaimer?.rb_verified === true;
            return listing.listing_source_type === "RB";
        };

        const isMLSListing = (listing) => {
        // return listing.disclaimer?.rb_verified === false;
        return listing.listing_source_type === "MLS";
        };

        const isRBGListing = (listing) => {
        return listing.listing_source_type === "RBG";
        };

        const isDisclaimerEmpty = (listing) => {
        return Object.keys(listing.disclaimer).length === 0;
        };

        const isMlsAgentAndNonMlsListing = (listing) => {
            return isMlsAgent() && isRBListing(listing)
        };

        const isGatewayAgentAndNonMlsListing = (listing) => {
            return isGatewayAgent() && isRBListing(listing)
        };

        const isGatewayAccessibleListing = (listing) => {
            return isGatewayAgent() && (isMLSListing(listing) || isRBGListing(listing))
        };

        const isMlsAgentAndEmptyDisclaimer = (listing) => {
            return isMlsAgent() && isDisclaimerEmpty(listing)
        };

        const getSelectedListingIds = () => {
            const ids = [];
            selectedListings.forEach( selectedListing => {
                ids.push(selectedListing.listingId);
            });
            return ids;
        };

        const disableCopyPaste = (e) => {
            e.preventDefault()
            return false
        }

        return (
            <SelectedListingsContext.Provider
                value={{
                    selectedListings,
                    setSelectedListings,
                    handleSelectedListing,
                    handlePageChange,
                    clearSelectedListings,
                    handleAllSelectedListings,
                    allIsChecked,
                    allMlsIsChecked,
                    groupedListings,
                    setGroupedListings,
                    handleMergeGroupListings,
                    isRBListing,
                    isMLSListing,
                    isRBGListing,
                    isMlsAgentAndNonMlsListing,
                    isGatewayAgentAndNonMlsListing,
                    isGatewayAccessibleListing,
                    isMlsAgentAndEmptyDisclaimer,
                    getSelectedListingIds,
                    areShowingListings,
                    setAreShowingListings,
                    isDisclaimerEmpty,
                    disableCopyPaste,
                    setRowPerPageRenderer,
                    rowPerPageRenderer,
                    isUnselectActivated,
                    setIsUnselectActivated
                }}
            >
                {props.children}
            </SelectedListingsContext.Provider>
        )
    }
)

export { SelectedListingsProvider }
