import React, {useContext, useEffect, useState} from "react";
import {FieldsetMultipleSelect} from "../multiple_select/fieldset_multiple_select";
import {ListingSearchContext} from "../../listing_search_context";

export function FieldsetOtherUnitFeatures() {
    const {getFilterOptions, filterConfig, criteria} = useContext(ListingSearchContext);
    const [options, setOptions] = useState([]);
    const [optionArr, setOptionArr] = useState(criteria.unit_features_list);

    useEffect(()=>{
        setOptions(getFilterOptions("unit_feature"));
    },[filterConfig]);

    useEffect(()=>{
        setOptionArr(criteria.unit_features_list);
    }, [criteria.unit_features_list]);

    return <FieldsetMultipleSelect
        options={options}
        optionArr={optionArr}
        setOptionArr={setOptionArr}
        title="Other Unit Features"
        criteriaFieldName="unit_features_list"
    />
}