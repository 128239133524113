import {REST} from "../../rest";

export const claimListingApi = {

    get: async (requestKey: string) => {
        const url = `/api/agent/listing_claim/request_key/${requestKey}`;

        return await REST.get(url).then(res => {
            return res
        });
    },

    post: async (params:IClaimListingParams) => {
        return await REST.post(`/api/agent/listing_claim/user/${params.user_id}`, {payload: params}).then(res => res);
    },

    put: async (params:IClaimListingParams) => {
        const url = `/api/agent/listing_claim/request_key/${params.request_key}`;
        const payload = {...params};

        return await REST.put(url, payload).then(res => {
            return res
        });
    },
};

export interface IClaimListingParams {
    id?: number,
    user_id: number,
    listing_id: number,
    request_action?: string,
    expiration_date?: string,
    request_key?: string,
    date_requested?: string,
    date_approved?: string,
    create_stamp?: string,
    modify_stamp?: string,
    listing_address?: string,
    first_name?: string,
    last_name?: string,
    full_name?: string,
    agent_email?: string,
}
