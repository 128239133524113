import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import { getStateAbbr } from "../../../../utils/get_state_abbr";
import { FloorPlanDetails } from "../detail_search_results/floorplan_details/floorplan_details";
import React, { useContext, useEffect } from "react";
import { SelectedListingsContext } from "../../selected_listings_context";
import {AgentUserContext} from "../../agent_user_context";

export function FloorPlanDialog(props) {
  const { 
      isExpanded, 
      setIsExpanded,
      buildingListings, 
      listing, 
      isMlsAgent, 
      isAddressDisplay, 
      isFullAgent,
      toggleUpgradeModal,
      showShowUpgradeModal
    } = props;
  const { isMlsAgentAndNonMlsListing, isMlsAgentAndEmptyDisclaimer, disableCopyPaste} =
    useContext(SelectedListingsContext);
  const {countryCode} = useContext(AgentUserContext);

  const handleDialogClose = () => {
    setIsExpanded(false);
  };

  useEffect(() => {
    if (showShowUpgradeModal) {
      handleDialogClose()
    }
  }, [showShowUpgradeModal])


  const handleToggleUpgradeModal = (listingId?: any) => {
    toggleUpgradeModal(listingId)
  }

  const showOrHideAddress = (listing) => {
    if (
      isMlsAgentAndNonMlsListing(listing) ||
      isMlsAgentAndEmptyDisclaimer(listing)
    ) {
      return (
        <div  
          onPaste={disableCopyPaste}  
          onCopy={disableCopyPaste}
          ref={listingAddress => {
              listingAddress  && listingAddress.addEventListener('selectstart', (e) => e.preventDefault()) 
          }}
          className="listing-full-address"
        >
          {listing.zip} {isAddressDisplay ?  `${listing.address}, ` : null }{listing.city}, {getStateAbbr(listing.state, countryCode)} {" "}
          {listing.apt_count == 1 && (
            <i className="fas link-icon fa-external-link-alt" />
          )}
        </div>
      );
    } else {
      return (
        <div
          onPaste={e => !isFullAgent() && disableCopyPaste(e)}  
          onCopy={e => !isFullAgent() &&  disableCopyPaste(e)}
          ref={listingAddress => {
                  !isFullAgent() &&  listingAddress  && listingAddress.addEventListener('selectstart', (e) => e.preventDefault())
          }}
          className={`${!isFullAgent() && 'listing-full-address'}`}
        >
          {listing.zip} {listing.address}, {listing.city},{" "}
          {getStateAbbr(listing.state, countryCode)}{" "}
          {listing.apt_count == 1 && (
            <i className="fas link-icon fa-external-link-alt" />
          )}
        </div>
      );
    }
  };

  return (
    <Dialog
      onClose={handleDialogClose}
      aria-labelledby="customized-dialog-title"
      open={isExpanded}
      maxWidth={false}
    >
      <DialogTitle className="dialog-title">
        <div className="row">
          <div className="col-10">
            <div>{listing.building_name}</div>
            {showOrHideAddress(listing)}
          </div>
          <div className="col-2" style={{ textAlign: "end" }}>
            <IconButton aria-label="close" onClick={handleDialogClose}>
              <i className="fas fa-times" />
            </IconButton>
          </div>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <section className="floorplans--container">
          <FloorPlanDetails
            pageNumber={props.pageNumber}
            listings={buildingListings}
            groupListingId={listing.apartment_id}
            isMlsAgent={isMlsAgent}
            handleToggleUpgradeModal={handleToggleUpgradeModal}
            isAddressDisplay={isAddressDisplay}
          />
        </section>
      </DialogContent>
    </Dialog>
  );
}
