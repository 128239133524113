import React, {useEffect, useContext, useState} from 'react'
import {Button, ButtonGroup} from "@material-ui/core";
import "./tabs_config.scss";
import {SweetAlertContext} from "../../../../../components/sweet_alert/sweet_alert";
import {SelectedListingsContext} from "../../../selected_listings_context";
import {ListingActionButtons} from "../../../../listing_action_buttons/listing_action_buttons";
import {BookmarksContext} from "../../../bookmarks_context";
import {ListingSearchContext} from "../../../../../components/listing_search/listing_search_context";
import {BrokerSearchingContext} from "../../../broker_search_context";
import { useTranslation } from "react-i18next";
interface TabsConfigProps {
    totalResults?: number;
    isEnableAllListingsTab?: boolean;
    isEnableMlsListingsTab?: boolean;
    isEnableRbListingsTab?: boolean;
}

export const TabsConfig = React.memo(
    function TabsConfigComponent(props: TabsConfigProps) {
        const {totalResults, isEnableAllListingsTab, isEnableMlsListingsTab, isEnableRbListingsTab} = props;
        const {selectedListings, getSelectedListingIds} = useContext(SelectedListingsContext);
        const {setAlertVisible, setAlertType} = useContext(SweetAlertContext);
        const {toggleBookmarks} = useContext(BookmarksContext);
        const [selectedListingIds, setSelectedListingIds] = useState(getSelectedListingIds());
        const {tabChosen, setTabChosen, criteria, setCriteria, allCount, mlsCount, rbCount, setAllCount} = useContext(ListingSearchContext);
        const {isMobileRes, setSearching} = useContext(BrokerSearchingContext);
        const { t } = useTranslation( 'translation',{useSuspense: false});

        const onSuccess = () => {
            setAlertType("success");
            setAlertVisible(true);
        };

        const getAllCountConfig = () => {
            if (isEnableAllListingsTab && !isEnableMlsListingsTab && !isEnableRbListingsTab) {
                setAllCount(totalResults);
            }
        }

        const handleActionClick = (action : string) => {
            switch (action) {
                case 'all':
                    setTabChosen('all');
                    break;
                case 'mls':
                    setTabChosen('mls');
                    break;
                case 'rb':
                    setTabChosen('rb');
                    break;
            }
            setSearching(true);
            setCriteria({...criteria, page_number : 1});
        };

        useEffect(() => {
            setSelectedListingIds(getSelectedListingIds());
        }, [selectedListings]);

        useEffect(() => {
            getAllCountConfig();
        }, []);

          return (
               <div className="tabs-config-container">
                   <div className="tabs-buttons">
                      <ButtonGroup>
                          {isEnableAllListingsTab &&
                              <Button
                                  className={`tab-config-btn all ${tabChosen === 'all' ? "active" : ""}`}
                                  onClick={ () => handleActionClick('all') }
                              >
                                  All ({allCount})
                              </Button>
                          }
                          {isEnableMlsListingsTab &&
                              <Button
                                  className={`tab-config-btn mls ${tabChosen === 'mls' ? "active" : ""}`}
                                  onClick={ () => handleActionClick('mls') }
                              >
                                  {t('MLS')} ({mlsCount})
                              </Button>
                          }
                          {isEnableRbListingsTab &&
                              <Button
                                  className={`tab-config-btn rb ${tabChosen === 'rb' ? "active" : ""}`}
                                  onClick={ () => handleActionClick('rb') }
                              >
                                  Rental Beast ({rbCount})
                              </Button>
                          }
                      </ButtonGroup>
                   </div>
                   <div className="cta-wrapper" id="cta-wrapper" style={{marginBottom: isMobileRes ? "5px" : ""}}>
                       <ListingActionButtons selectedListingIds={selectedListingIds}
                                             onSuccess={onSuccess}
                                             toggleBookmarks={toggleBookmarks}
                                             isDisabled={selectedListingIds.length <= 0}
                                             excludeCMA={false}
                                             includeFacebookShare={true}
                                             excludeClaim={true}
                                             excludeEmail={false}
                                             excludePrint={false}
                                             excludeShare={false}
                       />
                   </div>
              </div>
          )
    }
)
