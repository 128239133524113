import {REST} from "../rest";
import {IListingApiGetParams} from "./listing";

export const alertsApi = {

    index : async (agentId = null, clientId = null) => {
        return await REST.get(`/api/agent/${agentId}/alert`, {
            params: {
                client_id: clientId
            }
        }).then(res => {
            return res.data
        });
    },

    post: async (alert: IAlert): Promise<IAlert> => {
        const url = `/api/agent/${alert.user}/alert`;
        return await REST.post(url, alert).then(
        res => {
            return res.data;
        });
    },


    put : async (alert: IAlert): Promise<IAlert> => {
        console.log("Persisting Alert (PUT)");
        console.log(alert);
        const url = `/api/agent/${alert.user}/alert/${alert.id}`;
        return await REST.put(url, alert).then(
            res => {
                return res.data;
            });
    },

    delete : async (alert: IAlert) => {
        const url = `/api/agent/${alert.user}/alert/${alert.id}`;
        return await REST.delete(url).then(
            res => {
                return res;
            });
    },
};

export interface IAlert {
    id?: number;
    search_params: IListingApiGetParams,
    clients?: [],
    user: number,
    modified_at?: string,
    modified_by?: string,
    criteria_name: string,
}