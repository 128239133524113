import {getUserId} from "../session";
import {REST} from "../rest";
import {apiURL} from "../constants";
import {IListing} from "./listing";
import {ILocationObj} from "../../src/components/listing_search/listing_search_context";


const applyNowOptUrl = "rb_api/rest/applynow/available/ExternalBroker";

export const consumerApi = {
    get: async (): Promise <Consumer> => {
        const userId = await getUserId();

        if (userId !== null || userId !== 'null') {
            const consumerData = await REST.get(`${apiURL}/v1/consumer.json?id=${userId}`, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => res.data.data);

            return consumerData;
        } else {
            return null
        }
    },

    put: async (props: Consumer) => {
        const form_data = new FormData();
        for ( let key in props ) {
            form_data.append(key, props[key]);
        }
        return await REST.put(`${apiURL}/v1/consumer.json`, form_data,{
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(res => res.data);
    },

    getApplyNowOpt: async (extAgentId: number) => {
        const { data } =  await REST.get(`${apiURL}/${applyNowOptUrl}/${extAgentId}`)
        return data
    },

    toggleApplyNowOpt: async (isOptedOut: boolean, extAgentId: number) => {
        const applyNowOptEndPoint = `${apiURL}/rb_api/rest/agent/optout/${extAgentId}`
        return (
            isOptedOut ? await REST.delete(applyNowOptEndPoint) :
                        await  REST.post(applyNowOptEndPoint)
            )
    },

    getRealtorMemberInfo: async (extAgentId: number) => {
        const { data } =  await REST.get(`${apiURL}/rb_api/rest/external/agent/${extAgentId}`);

        return data;
    },

    putRealtorMemberInfo: async (extAgentId : number, realtorMemberInfoPayLoad: RealtorMemberInfo) => {

        return await REST.put(`${apiURL}/rb_api/rest/external/agent/${extAgentId}`, realtorMemberInfoPayLoad).then(res => {
            return res
        });
    },
};

export interface ConsumerAgentInfo {
    additional_info?: {
        is_visible: boolean, brokerage_name: string, office_address: {address, city, state, zip}
    },
    auto_suggestion?: boolean,
    broker_logo?: string,
    cc_settings?: {
        alert_email: { display: string, is_on: false},
        application_email: { display: string, is_on: true},
        client_questionnaire: { display: string, is_on: true},
        share_email: { display: string, is_on: true},
        welcome_email: { display: string, is_on: true}
    },
    display_tile_on_app_form?: boolean,
    email?: string,
    enable_notifications?: boolean,
    extra_emails?: string [],
    first_name?: string,
    ignore_incoming_changes?: boolean,
    last_name?: string,
    link_url_config?: {
        agent_config: {
            bookmarked_listings: {is_selected: boolean, label: string},
            is_selected: boolean,
            label: string,
            show_any_rb: {
                is_selected: boolean, label: string, filters: {
                    location: {
                        label: string, location_list: { cities: any [], neighborhoods: any [], display?: ILocationObj []}
                    },
                    owner_pay: { label: string, options: any []},
                    price_range: {label: string, max_price: number, min_price: number}
                }
            },
            show_my_listings: {is_selected: boolean, label: string},
        },
        show_rb_reviewed: {is_selected: boolean, label: string},
        new_url: string,
        old_url: string,
    },
    middle_name?: string,
    name?: string,
    notification_days?: number,
    phone?: string,
    send_accept_lead_email?: boolean,
    send_client_activity_report?: boolean,
    send_email?: boolean,
    send_msg?: boolean,
    signature_info?: string,
    super_region?: number,
    txt_phone?: string,
    ext_agent_id?:number,
    applyNowOpt?: boolean
}

interface RealtorMemberInfo {
    user_id?: number,
    license?: {
          license_id?: string,
          state_code?: string
    }[],
    realtor_member_id?: string
}

export interface Consumer {
    agent_info?: ConsumerAgentInfo,
    allow_syndication?: boolean,
    branded_apply_now?: boolean,
    email?: string,
    first_name?: string,
    full_bp_access?: boolean,
    hide_partner_cp?: boolean,
    hide_rb_cp?: boolean,
    id: string,
    is_admin?: boolean,
    is_agent?: boolean,
    is_owner?: boolean,
    last_name?: string,
    listing_mgmt_enabled?: boolean,
    phone?: string,
    renter_insurance?: string,
    saved_listings?: IListing [],
    saved_ui_props?: any,
    similar_listings?: IListing [],
    ext_agent_id?:number
    super_region: number,
    realtor_member_id?: string,
    license_id?: string,
    state_code?: string,
    realtor_member_info? : RealtorMemberInfo
}
