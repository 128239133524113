import React from "react";
import {Pagination} from "./pagination";
import {AdvancedSearch} from "./filters/advanced_search";

export function MobileToolbar() {

    return (
        <div className="mobile-toolbar-wrapper">
            <Pagination />
            <div className="advanced-search-wrapper">
                <AdvancedSearch className={"search-filter"}/>
            </div>
        </div>
    )
}