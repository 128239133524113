import {applyNowUrl} from "../constants";
import {REST} from "../rest";

export const applyNowAvailableListingApi = {
    get : async ({listingId, partnerKey}) => {

        return await REST.get(`${applyNowUrl}/available/mlslisting/${listingId}`,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                params: {
                    "partner_key": partnerKey
                }
            }).then(res => {
            return res.data
        }).catch(e => {
            console.error(e);
        });
    },

};