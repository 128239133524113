import React, {useEffect, useState} from "react";
import {getUserId, updateSessionInfo} from "../../../services/session";
import {Consumer, consumerApi} from "../../../services/api/consumer";
import i18n from "../../utils/i18n";
import {isLandlordPortal} from "../listing_management/utils";

interface ILandlordUserProviderContext {
    landLordInfo: Consumer,
    countryCode: string,
    langLocale: string,
    userId: number
}

export const LandlordUserContext = React.createContext<ILandlordUserProviderContext>(null);

export function LandlordUserProvider(props) {

    const [landLordInfo, setLandLordInfo] = useState(null);
    const [countryCode, setCountryCode] = useState('US');
    const [langLocale, setLangLocale] = useState('en-US');
    const [userId, setUserId] = useState(null);

    const initLandLordInfo = async () => {
        const consumerInfo = await consumerApi.get();
        setLandLordInfo(consumerInfo);

        const userId =  await getUserId();
        setUserId(parseInt(userId));

        const browserLocale = navigator.language;
        await i18n.changeLanguage(browserLocale)
        setLangLocale(browserLocale);
        setCountryCode(browserLocale.split('-')[1]);
    }

    useEffect(() => {
        if(isLandlordPortal()){
            updateSessionInfo();
            window.addEventListener('session-update', initLandLordInfo);
        }
    }, []);

    return (
        <LandlordUserContext.Provider
            value={{landLordInfo, countryCode, langLocale, userId}}
        >
            {props.children}
        </LandlordUserContext.Provider>
    )
}
