import React from 'react'

export const RectangleDraw = () => {
  return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="200"
        height="200"
        viewBox="0 0 200 200"
      >
        <image
          width="168"
          height="168"
          x="16"
          y="16"
          xlinkHref="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKgAAACoCAYAAAB0S6W0AAAFfklEQVR4nO2d63HbMBAGLY0LcAlpJvWkltTjZtyJMvEMM3qQIkAcDgtl96fHFgR8ywMhQPTpx89fbx24FLzkqUfD0o0hmb4HvU7Jm9/7G4Vlgci0RdAjHSh9PWUdAy7Tc0DDPbgktCG3eWZkWk2toNniKGl/svOsaq9G0FGyWE37MHJci9stEZQiiJLGQclz933sCUqTQknbmSrTZ4JSZVDS40yX6dFV/GiUtJ4px2xLUAWQbFadWxN0Fjm9iMqZNtNZp/gFJd1n6jG6F9TAZTQ3Dl7vxUfKWbLvGtXexb37TabPNOo000KNKMvvWrXZDM008h70aBWLqH5K/kjEmIzM9JtF0NbOtL4hp2geozP9djKigkbJpaQcMJm23oNGS3VqqOZO8zGQMp3+c1B5cc4Ndveakp3qx0HL9GIFFTQKKmiogjrN54Mc8xRBvz5/f2S0I3lkZfr3ySK0RdKCHxvlgsyTOsUrZz7IMXeRJGgUVNCcWz5ENVop5PA6h1hBFX8cuLFvPSwSfZq9ZYD87PSWlkM3lExD7kGteLJFsxuLoCGHSwGvITEQ8vx2MvIetGVKGX2i/xWJKDrDz+ZGL5JqO2XV5FObZ2im14ukppPPd2w9+rmHkFbPbaIyffbs+a6ZRn/teA2r5OuRlun9FD9bNbJ67jN1pmv3oIYuo3hwb+a9eC+kcqYdqy1B6R1SznqmzPRZBaV2SDmPM12me1M8rUPK2c5UmZbcg1I6pJxxTJNp6SLpNLhTyhnP6DyL2q9dxWd3avSF8eqMGN+q9o7sJGU8eFYpc8Fm2rLV2aNTijkWXKYRe/GtBweUkgcmU7/VKWgUVNAoqKBRUEGjoIJGQQWNggoaBRU0CipoFFTQKKigUVBBo6CCRkEFjYIKGgUVNAoqaBRU0CiooFFQQaOggkZBBY2CChoFFTQKKmgUVNAoqKBRUEGjoIJGQQWNggoaBRU0CipoFFTQKKigUVBBo6CCRkEFjYIKGgUVNAoqaBRU0CiooFFQQaOggkZBBY2CChoFFTQKKmgUVNAoqKBRUEGjoIJGQQWNggoaBRU0CipoFFTQKKigUVBBo6CCRkEFjYIKGgUVNAoqaBRU0CiooHkPeHOXwL8/sYfrvwGTaYugrZ149pqKOgZcpkcE7dGJrTYUNQdsprX3oBkduW8vu83/iRHjW9VeqaCjRVHSeEbnWdR+iaAUOZQ0jmky3ROUJoWStjNVps8EpcqgpMeZLtMtQekSKGk9U2Y6806SkpYz7VitCWrwMooH9+4FnU1OL6Z9ps40Yi9+j+udA4V6DdIyvRY0sqGt7az7n0e0eXFLdJOoTJ+Nbw9Z/2UavUg6VcqiWHxq8wzNNFLQo28solPeOjzSOiYtuYRJugga0ZlWrKYcCHl+OxlRQRVLtmh2o3UVHy3nqaGaO83HQMoUuZNkRR4HbuzPDXYrkpRy1JWL3+oUNAoqaKiCevuQD3LMUwT9+vz9kdGO5JGV6enHz1/URZIfG+WCzJM6xStnPsgxd5EkaFoEtcpJd87A1Zvij6PX2B9e57RO8cokXcn4ykcNLcL72ektLYduIseyqYhFLJIij/kLA0ymi6CjT7QrJ4/RmYZ/J+noE/Ai5HR6fyRiTIafze1xD1r6oFKr5hzUPHg2PNNrQZtOPq+QJaDVc5vITDMLyr9M76d4w5bR3Dg4+1anF9Q+U4/RmqCzdEg5y5k2060KaviSzapzs07xXkD1TDlmzwSldkg5jzNdpnsVlNYh5WxnqkxLpvjwJ5YdRDnjoOS5+z5q7kFHdYpygbwaI8e1uN3aRVJ2pxSzP9l5dv1fndcN9cSqmUvGeKf9t+O1Bj2R9BrgMo06zXTk2fMKyWZ8pm9vb38A9Y3p3BxYknAAAAAASUVORK5CYII="
        ></image>
      </svg>
  )
}
