import React, {useContext, useEffect, useState} from "react";
import {getUserId} from "../../../services/session";
import {userBookmarksApi} from "../../../services/rails_api/user/bookmarks";
import {EFieldsToInclude, listingApi} from "../../../services/api/listing";
import {collateByArrInclude} from "./utils/utils";
import {AgentUserContext} from "./agent_user_context";

interface IBookmarksContext {
    bookmarksCount?: number,
    fetchBookmarks: () => any,
    bookmarks: { apartment_id: number, address: string } [],
    toggleBookmarks: (e: any, listingIds: number []) => any,
}

export const BookmarksContext = React.createContext<IBookmarksContext>(null);

export function BookmarksProvider(props) {
    const [updateBookmarks, setUpdateBookmarks] = useState(false);
    const [bookmarks, setBookmarks] = useState([]);
    const {agentId} = useContext(AgentUserContext);

    useEffect(() => {
        if (updateBookmarks) {
            setUpdateBookmarks(false);
            fetchBookmarks();
        }
    },[updateBookmarks]);

    useEffect(() => {
        if (agentId) {
            fetchBookmarks();
        }
    }, [agentId]);

    const fetchBookmarks = async () => {
        const bookmarkRes = await userBookmarksApi.get(`${agentId}`);
        if (bookmarkRes && bookmarkRes.data && bookmarkRes.data.listing_ids && bookmarkRes.data.listing_ids.length > 0) {
            const bookmarkIds = bookmarkRes.data.listing_ids;
            const listingRes = await listingApi.get({
                partner_key: "default",
                fields_to_include: [
                    EFieldsToInclude.apartment_id,
                    EFieldsToInclude.square_footage,
                    EFieldsToInclude.status,
                    EFieldsToInclude.date_available,
                    EFieldsToInclude.address,
                    EFieldsToInclude.city,
                    EFieldsToInclude.state,
                    EFieldsToInclude.zip,
                    EFieldsToInclude.apt_number,
                    EFieldsToInclude.beds_count,
                    EFieldsToInclude.bathrooms,
                    EFieldsToInclude.rent,
                    EFieldsToInclude.pic_count,
                    EFieldsToInclude.pics,
                ],
                listing_ids: bookmarkIds,
                max_record_count: 999,
            });
            const bookmarksRes = listingRes.data?.data;
            if (bookmarks) {
                setBookmarks(bookmarksRes)
            }
        }
        else {
            console.log(bookmarkRes);
            setBookmarks([]);
        }
    };

    const toggleBookmarks = async (e, listingIds) => {
        e.preventDefault();
        e.stopPropagation();
        const userId = await getUserId();
        const bookmarkRes = await userBookmarksApi.get(userId);
        if (bookmarkRes && bookmarkRes.data) {
            const oldBookmarkedIds = bookmarkRes.data.listing_ids;
            let updateBookmarkIds = [];
            if (oldBookmarkedIds !== null) {
                // splits oldBookmarkedIds array to two, one having elements in listingIds array and vice versa
                let collation1 = collateByArrInclude(listingIds)(oldBookmarkedIds);
                // splits listingIds array to two, one having elements in oldBookmarkedIds array and vice versa
                let collation2 = collateByArrInclude(oldBookmarkedIds)(listingIds);
                // merging two arrays excluding common bookmark listing ids which are meant to be deleted/toggled
                updateBookmarkIds = [].concat.apply([], [collation1.get(false), collation2.get(false)].map(v => v === undefined ? [] : v));
            }
            if (oldBookmarkedIds === null) {
                updateBookmarkIds = listingIds;
            }
            const res = await userBookmarksApi.put(userId, updateBookmarkIds);
            setUpdateBookmarks(true);
            return res.data.listing_ids;
        }
    };

    return (
        <BookmarksContext.Provider
            value={{bookmarksCount: bookmarks.length, fetchBookmarks, bookmarks, toggleBookmarks}}
        >
            {props.children}
        </BookmarksContext.Provider>
    )
}