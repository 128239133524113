import {
    EFieldsToInclude,
    IListing,
    IListingApiGetParams, listingApi,
    ListingType,
    OwnershipType,
    StatusType
} from "../../../services/api/listing";
import React, {useState} from "react";
import {defaultFieldsToInclude} from "../../features/broker_search/broker_search";
import {getAgentPartnerKey} from "./utilities";
import {listingFeaturesApi} from "../../../services/rails_api/listing_features";
import { ISchoolSelectors } from "../../../services/api/school_auto_complete";

interface IOption {
    value: string,
    label: string,
}

export interface ILocationObj {
    address?: string;
    city?: string;
    matched_field?: string;
    metro?: any;
    neighborhood?: string;
    rbId?: number;
    state?: string;
    sub_neighborhood?: any;
    neighborhood_override?: any;
    total?: number;
    zip?: any;
}

interface IListingSearchCriteriaContext {
    criteria: IListingApiGetParams;
    setCriteria: (newCriteria: IListingApiGetParams) => any;
    partnerKey: string;
    filterConfig: any;
    initPartnerKey: () => void;
    initFilterConfig: () => void;
    getFilterOptions: (filterName: string, useLabelAsValue?: boolean) => IOption[],
    focusedElId?: string;
    setFocusedElId?: (string) => any;
    selectedLocations?: ILocationObj [];
    setSelectedLocations?: (newLocations: ILocationObj []) => any;
    tabChosen?: string;
    setTabChosen?: (tabChosen : string) => any;
    allCount?: number;
    setAllCount?: (allCount : number) => any;
    mlsCount?: number;
    setMlsCount?: (mlsCount : number) => any;
    rbCount?: number;
    setRbCount?: (rbCount : number) => any;
    drawShapePayload: any;
    setDrawShapePayload: React.Dispatch<any>;
    isPaginatedFromMapListingArea?: boolean;
    setPaginatedFromMapListingArea?: (isPaginatedFromMapListingArea : boolean) => any;
    isPaginatedFromMapArea?: boolean;
    setPaginatedFromMapArea?: (isPaginatedFromMapArea : boolean) => any;
    mapAreaPageNum?: number;
    setMapAreaPageNum?: (mapAreaPageNum : number) => any;
    listingAreaPageNum?: number;
    setListingAreaPageNum?: (listingAreaPageNum : number) => any;
    setShapeOptionLatLng: any;
    shapeOptionLatLng: any;
    isActionFromMap: boolean;
    setIsActionFromMap: React.Dispatch<React.SetStateAction<boolean>>
    drawShapes: any[]
    setDrawShapes: React.Dispatch<React.SetStateAction<any>>
    isClearedShape?: boolean;
    setClearedShape?: (isClearedShape : boolean) => any;
    loadingMapFilterSearch: boolean
    setLoadingMapFilterSearch : React.Dispatch<React.SetStateAction<boolean>>
    isActionExcludeOrDeleteShape: null | string
    setIsActionExcludeOrDeleteShape: React.Dispatch<React.SetStateAction<null | string>>
    selectedSchoolLayers: ISchoolSelectors[]
    setSelectedSchoolLayers: (selectedSchoolLayers: ISchoolSelectors[]) => void | null;
    isListingControlClicked?: boolean;
    setIsListingControlClicked?: (isListingControlClicked : boolean) => any;
    getGroupListingUnits: (listing: any, superRegion?: number) => any;
}

export const ListingSearchContext = React.createContext<IListingSearchCriteriaContext>(null);

export const getEmptyCriteria = (partnerKey) => {
    return {
        agent_id: null,
        attached_to_another_dwelling: "",
        available_by_date: null,
        available_from_date: null,
        bathroom_range: { min_bathrooms: "", max_bathrooms: ""},
        bedroom_range: { min_bedrooms: "", max_bedrooms: "" },
        building_type_list: [] as string[],
        cooling_type_list: [] as string[],
        date_active: { min: "", max: "" },
        days_on_market: {min: "", max: ""},
        deleaded: "",
        direct_phone: "",
        external_structures: [] as string[],
        fields_to_include: defaultFieldsToInclude,
        finished_square_footage: {min: "", max: ""},
        floor: "",
        floor_type: [] as string[],
        floors_in_unit_list: [] as string[],
        furnished: "",
        garage_spaces: -1,
        heat_type_list: [] as string[],
        include_disclaimer: false,
        laundry_type_list: [] as string[],
        lease_date: { min: "", max: "" },
        lease_rate: {min: "", max: ""},
        listing_ids: [],
        listing_type: "" as ListingType,
        lot_square_footage: {min: "", max: ""},
        max_bathrooms: 5.5,
        max_bedrooms: 10,
        max_record_count: 10,
        min_bathrooms: -1,
        min_bedrooms: -1,
        min_price: 0,
        max_price: 0,
        min_commission: "" as any,
        min_pic_count: 0,
        min_sq_ft: "" as any,
        multiple_areas: [] as ILocationObj [],
        owner_pay_list: [] as string[],
        ownership_type_list: [] as OwnershipType[],
        page_number: 1,
        parking_options_list: [] as string[],
        partner_key: partnerKey,
        pets: false,
        pet_types_list: [] as string[],
        property_features: [] as string[],
        property_structure: "",
        public_transit: {distance: -1, types: [] as string[]},
        rb_portal: "",
        rent_range: {min_price: "", max_price: ""},
        rb_mls_id: "",
        renter_insurance: "",
        room_types: [] as string[],
        school_district: "",
        state_list: [] as string[],
        statuses_list: [StatusType.ACTIVE, StatusType.APPLICATION_PENDING] as StatusType[],
        super_region: 0,
        sublet_term: "",
        sqft_range: {min_sq_ft: "", max_sq_ft: ""},
        unit_features_list: [] as string[],
        utilities_available: [],
        virtual_showing_allowed: "",
        virtual_tour_url: false,
        year_built: "",
        garage: false,
    }
};

export const getEmptyLocationObj = () => {
    return {
        address: null,
        city: null,
        metro: null,
        neighborhood: null,
        state: null,
        sub_neighborhood: null,
        neighborhood_override: null,
        zip: null,
    }
};

export const ListingSearchProvider = React.memo(function ListingSearchProvider(props) {

    const [partnerKey, setPartnerKey] = useState(null);
    const [filterConfig, setFilterConfig] = useState(null);
    const [focusedElId, setFocusedElId] = useState();

    const [criteria, setCriteria] = useState( getEmptyCriteria(partnerKey) as IListingApiGetParams);
    const [selectedLocations, setSelectedLocations] = useState([] as ILocationObj []);
    const [tabChosen, setTabChosen] = useState('all');
    const [allCount, setAllCount] = useState(0);
    const [mlsCount, setMlsCount] = useState(0);
    const [rbCount, setRbCount] = useState(0);
    const [drawShapePayload, setDrawShapePayload] = useState<any>({
        geometry: []
    })
    const [shapeOptionLatLng, setShapeOptionLatLng] = useState({
        lat: null,
        lng: null,
    });
    const [isPaginatedFromMapListingArea, setPaginatedFromMapListingArea] = useState(false);
    const [isPaginatedFromMapArea, setPaginatedFromMapArea] = useState(false);
    const [mapAreaPageNum, setMapAreaPageNum] = useState(1);
    const [listingAreaPageNum, setListingAreaPageNum] = useState(1);
    const [isActionFromMap, setIsActionFromMap] = useState<boolean>(false);
    const [drawShapes, setDrawShapes] = useState([]);
    const [isClearedShape, setClearedShape] = useState<boolean>(false);
    const [loadingMapFilterSearch, setLoadingMapFilterSearch] = useState<boolean>(false)
    const [isActionExcludeOrDeleteShape, setIsActionExcludeOrDeleteShape] = useState<string | null>(null);
    const [selectedSchoolLayers, setSelectedSchoolLayers] = useState<ISchoolSelectors[]>([])
    const [isListingControlClicked, setIsListingControlClicked] = useState<boolean>(false);

    const initPartnerKey = async () => {
        if(criteria.partner_key === null || criteria.partner_key === ''){
            const currentPartnerKey = await getAgentPartnerKey();
            setPartnerKey(currentPartnerKey);
            setCriteria({...criteria, partner_key: currentPartnerKey});
        }
    };

    const getGroupListingUnits = async (listing, superRegion?: number) => {
        const groupListingCriteria = {
            ...criteria,
            type_contact: listing.type_contact,
            addresses: [listing.address],
            group_by_address: false,
            max_record_count: 100,
            include_disclaimer: true,
            page_number: 1,
            super_region: criteria.super_region || superRegion,
            fields_to_include: [
                EFieldsToInclude.bathrooms,
                EFieldsToInclude.bedrooms,
                EFieldsToInclude.date_available,
                EFieldsToInclude.disclaimer,
                EFieldsToInclude.floor_plan_id,
                EFieldsToInclude.floor_plan_max_rent,
                EFieldsToInclude.floor_plan_min_rent,
                EFieldsToInclude.floor_plan_name,
                EFieldsToInclude.floor_plan_units_available,
                EFieldsToInclude.long_term_commission,
                EFieldsToInclude.mls_id,
                EFieldsToInclude.owner_pay,
                EFieldsToInclude.rent,
                EFieldsToInclude.status,
                EFieldsToInclude.square_footage,
                EFieldsToInclude.use_apply_now
            ],
        }
        if (tabChosen === 'mls' || tabChosen === 'rb'){
            groupListingCriteria.listing_type = (listing.listing_source_type == "MLS" || listing.listing_source_type == "RBG") ? ListingType.mls : ListingType.rb
        }
        const response = await listingApi.get(groupListingCriteria);
        return response.data.data as IListing[];
    }

    const initFilterConfig = async () => {
        const response = await listingFeaturesApi.getList(true);
        setFilterConfig(response);
    };

    const getFilterOptions = (filterName, useLabelAsValue: boolean = false) => {
        let options = [];

        if (filterConfig && Object.keys(filterConfig).length) {
            options = Object.keys(filterConfig[filterName]).map(key => {
                const label = filterConfig[filterName][key];
                return {
                    value: useLabelAsValue ? label : key,
                    label: label,
                }
            });
        }

        return options;
    };

    return (
        <ListingSearchContext.Provider
            value={{
                criteria,
                setCriteria,
                partnerKey,
                filterConfig,
                initPartnerKey,
                initFilterConfig,
                getFilterOptions,
                focusedElId,
                setFocusedElId,
                selectedLocations,
                setSelectedLocations,
                tabChosen,
                setTabChosen,
                allCount,
                setAllCount,
                mlsCount,
                setMlsCount,
                rbCount,
                setRbCount,
                drawShapePayload,
                setDrawShapePayload,
                isPaginatedFromMapListingArea,
                setPaginatedFromMapListingArea,
                isPaginatedFromMapArea,
                setPaginatedFromMapArea,
                mapAreaPageNum,
                setMapAreaPageNum,
                listingAreaPageNum,
                setListingAreaPageNum,
                shapeOptionLatLng,
                setShapeOptionLatLng,
                isActionFromMap,
                setIsActionFromMap,
                drawShapes,
                setDrawShapes,
                isClearedShape,
                setClearedShape,
                loadingMapFilterSearch,
                setLoadingMapFilterSearch,
                isActionExcludeOrDeleteShape,
                setIsActionExcludeOrDeleteShape,
                selectedSchoolLayers,
                setSelectedSchoolLayers,
                isListingControlClicked,
                setIsListingControlClicked,
                getGroupListingUnits
            }}
        >
            {props.children}
        </ListingSearchContext.Provider>
    )
})
