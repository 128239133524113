import React, {useEffect, useState} from 'react'
import Modal from "react-bootstrap/Modal";
import { Upgrade } from '../../../../../../packs/broker_portal/upgrade/upgrade';
import '../../../../../../packs/broker_portal/upgrade/upgrade.scss'
import { imgUrl } from '../../../../../../services/constants';
import '../../../../../features/rb_modern_header/rb_modern_header.scss'
import { PaywallWrapper } from './paywall_wrapper';

interface UpgradePricingModalProps {
    listingId: any
    showModal: boolean
    modalAction: () => void
    totalListingCount: number
}
const UpgradeModalContentv2 = () => {
    return (
        <div className="body-wrapper">
            <Upgrade customClass='upgrade-content-ldp' />
        </div>
    )
}

export const UpgradePricingModal: React.FC<UpgradePricingModalProps> = ({
    listingId,
    showModal,
    modalAction,
    totalListingCount,
}) => {
    const iconBackUrl =
    "https://s3.amazonaws.com/assets.mockflow.com/app/wireframepro/company/C52db143890ad4c77a5983f8edb043b60/projects/M4xlNErxLnb/images/M4369f6a99d0808e636af451336c2c7691654303696648";

    const [partnerLogoPath, setPartnerLogoPath] = useState(null)

    useEffect(() => {
        const brandingLogo = sessionStorage.getItem('branding_logo')
        setPartnerLogoPath(brandingLogo)
    }, [])

    return (
         <div className="see-pricing-container">
            {/* --- RB-1548: Upgrade Path Paywall change --- */}
            {/* <div className="see-pricing-wrapper"> */}
            {/* --- END --- */}
            <PaywallWrapper listingId={listingId}>
                <Modal
                    style={{ padding: 15 }}
                    show={showModal}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    dialogClassName='backdrop-container'
                >
                    <Modal.Header bsPrefix={"see-pricing-modal-header"}>
                        <div className="header-row" onClick={modalAction}>
                            <img className='header-img' src={iconBackUrl} />
                            <span>Back to Search results</span>
                        </div>
                        <div className='header-branding-container'>
                            <img className="upgrade-modal-logo" src={`${imgUrl}/broker_portal/bp-header-logo.png`} alt="RB Logo" />
                            <div className='upgrade-branding-divider' />
                            <img className="upgrade-modal-logo" src={`${imgUrl}/partners/${partnerLogoPath}`} alt="RB Logo" />
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <UpgradeModalContentv2 />
                    </Modal.Body>
                </Modal>
            </PaywallWrapper>
        </div>
    )
}
