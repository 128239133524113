import React, {useContext, useEffect, useState} from "react";
import "../fieldsets.scss";
import {StatusType} from "../../../../../services/api/listing";
import {FieldsetMultipleSelect} from "../multiple_select/fieldset_multiple_select";
import {ListingSearchContext} from "../../listing_search_context";

export function FieldsetStatus(props) {
    const {getFilterOptions, filterConfig, criteria} = useContext(ListingSearchContext);
    const [options, setOptions] = useState([]);
    const [optionArr, setOptionArr] = useState(criteria.statuses_list);
    const [consolidatedOptions, setConsolidatedOptions] = useState<any>([]);

    useEffect(()=>{
        setOptions(getFilterOptions("status", true));
    },[filterConfig]);

    useEffect(()=>{
        setOptionArr(criteria.statuses_list);
    }, [criteria.statuses_list]);

    useEffect(()=>{
        if ((props.customStatusesFilters || []).length) {
            mergeOptions(props.customStatusesFilters, options);
        } else {
          setConsolidatedOptions(options)
        }
    },[props.customStatusesFilters, options]);

    const mergeOptions = (customStatusesFilters : [], options : any[]) => {
        const customStatusesKeyPair = customStatusesFilters.map(keypair => ({ value: keypair, label: keypair }) )
        const optionsCombined = [...options, ...customStatusesKeyPair];

        setConsolidatedOptions(optionsCombined);
    }

    const handleValidation = (value) => {
        // ensure at least one option is selected
        let options = value;
        if(options.length < 1) {
            options = [StatusType.ACTIVE, StatusType.APPLICATION_PENDING];
        }
        return options;
    };

    return <FieldsetMultipleSelect
        options={consolidatedOptions}
        optionArr={optionArr}
        setOptionArr={setOptionArr}
        title="Status"
        criteriaFieldName="statuses_list"
        onCloseValidation={handleValidation}
        className={props.className}
    />
}
