import React, {useContext, useState} from "react";
import {ListingSearchContext} from "../../listing_search_context";
import "./fieldset_year_built.scss";

export function FieldsetYearBuilt () {
    const {criteria, setCriteria} = useContext(ListingSearchContext);
    const [inputError, setInputError] = useState(null);
    const errorLabel = `Please enter a valid year`;

    const handleOnChange = e => {
        const year = e.target.value;
        const regex = new RegExp(/^\d{4}$/);
        if (regex.test(year) || year.toString().length === 0){
            setInputError(null);
        }
        else {
            setInputError(errorLabel)
        }
        setCriteria({...criteria, year_built: e.target.value})
    };

    return (
        <fieldset className="fieldset--year-built">
            <label>Year Built</label>
            <div>
                <input onChange={handleOnChange} value={criteria.year_built} className="criteria--input" type="number" pattern="^\d{4}$"/>
                <label className={`errorLabel ${inputError ? 'active' : ''}`}>{inputError}</label>
            </div>
        </fieldset>
    )
}