import React, {useContext, useEffect, useState} from "react";
import {Checkbox, Input, ListItemText, MenuItem, Select} from "@material-ui/core";
import "../fieldsets.scss";
import {ListingSearchContext} from "../../listing_search_context";

interface ILabelValuePair {
    value: any,
    label: string,
}

interface IFieldsetMultipleSelectProps {
    options: ILabelValuePair[],
    title: string,
    criteriaFieldName: string,
    optionArr: any[],
    setOptionArr: (value: any[]) => void,
    onCloseValidation?: (value) => void;
    className?: string;
    disabled?: boolean;
}

export function FieldsetMultipleSelect(props: IFieldsetMultipleSelectProps) {

    const {options, title, criteriaFieldName, onCloseValidation,
        disabled = false, optionArr = [], setOptionArr = () => {}} = props;
    const {criteria, setCriteria, filterConfig} = useContext(ListingSearchContext);
    const [labelMap, setLabelMap] = useState({});

    const handleChange = (event) => {
        setOptionArr(event.target.value);
    };

    const handleClose = (fieldName, value) => {
        const newVal = onCloseValidation ? onCloseValidation(value) : value;
        setCriteria({...criteria, [fieldName]: newVal });
    };

    const getChecked = (option) => {
        return Boolean(optionArr.includes(option))
    };

    const renderValueFx = (selected) => {
        if ((selected as string[]).length === 0) {
            return <em>Select</em>;
        }
        return getSelectLabel(selected);
    };

    const getSelectLabel = (selected) => {
        let arr = [];
        selected.forEach((key) => {
            arr.push(labelMap[key]);
        });
        return arr.join(", ");
    };

    const initLabelMap = () => {
        const map = {};
        options.forEach((option)=>{
            map[option.value] = option.label;
        });
        setLabelMap(map);
    };

    useEffect(() => {
        initLabelMap();
    }, [filterConfig, options]);

    return (
        <div className={props.className ? props.className : "fieldset--container"}>
            <fieldset>
                <div>
                    <label>{title}</label>
                    <Select
                        disabled={disabled}
                        multiple
                        displayEmpty
                        value={optionArr}
                        onChange={handleChange}
                        onClose={() => handleClose(criteriaFieldName, optionArr) }
                        renderValue={renderValueFx}
                        input={<Input />}
                        MenuProps={{
                            getContentAnchorEl: () => null,
                        }}
                    >
                        {
                            options.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    <Checkbox checked={getChecked(option.value)} />
                                    <ListItemText primary={option.label} />
                                </MenuItem>
                            ))
                        }
                    </Select>
                </div>
            </fieldset>
        </div>
    )
}