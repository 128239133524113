import Table from "@material-ui/core/Table";
import {TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import React, {useContext} from "react";
import {BookmarksListView} from "./detail_bookmarks/bookmarks_list_view";
import {isMobile} from "../../../../utils/device_info";
import {BookmarksTileView} from "./detail_bookmarks/bookmarks_tile_view";
import {SelectedListingsContext} from "../../selected_listings_context";
import {BookmarksContext} from "../../bookmarks_context";
import { TabsConfig } from "../detail_search_results/tabs_config/tabs_config";

export function BookmarksView () {
    const mobile = isMobile();
    const {selectedListings} = useContext(SelectedListingsContext);
    const {bookmarks} = useContext(BookmarksContext);

    if (mobile) {
        return (
            <div className="row justify-content-center">
            <TabsConfig totalResults={0} isEnableAllListingsTab={false} isEnableMlsListingsTab={false} isEnableRbListingsTab={false}/>
                <div className="col-12 col-lg-11">
                    {
                        bookmarks.map((listing) => {
                            return <BookmarksTileView
                                bookmarks={bookmarks}
                                listing={listing}
                                key={listing.apartment_id}
                                checked={selectedListings.some(selectedListing => selectedListing.groupListingId === listing.apartment_id)}
                            />
                        })
                    }
                </div>
            </div>
        )
    }
    else {
        return (
            <div className="search-results-cont">
                <TabsConfig totalResults={0} isEnableAllListingsTab={false} isEnableMlsListingsTab={false} isEnableRbListingsTab={false}/>
                <Table className="search-results-table" style={{marginTop: "5px" }}>
                    <TableHead>
                        <TableRow className="detail--row container-fluid">
                            <TableCell align="left"/>
                            <TableCell align="left"/>
                            <TableCell align="left">Status</TableCell>
                            <TableCell align="left">Date Available</TableCell>
                            <TableCell align="left">Address</TableCell>
                            <TableCell align="left">Sq. Footage</TableCell>
                            <TableCell align="left">Beds</TableCell>
                            <TableCell align="left">Baths</TableCell>
                            <TableCell align="left">Rent</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody >
                        {
                            bookmarks.map(listing => {
                                return <BookmarksListView
                                    bookmarks={bookmarks}
                                    listing={listing}
                                    key={listing.apartment_id}
                                    checked={selectedListings.some(selectedListing => selectedListing.groupListingId === listing.apartment_id)}
                                />
                            })
                        }
                    </TableBody>
                </Table>
            </div>
        )
    }
}
