import React, {useEffect, useState} from "react";
import {Field, Formik} from 'formik';
import Select from 'react-select';
import "./add_showing_action.scss";
import {RbButton} from "../../../components/rb_button/rb_button";
import {getUserId} from "../../../../services/session";
import {Radio} from "@material-ui/core";
import {IShowing, showingsApi} from "../../../../services/api/showings";
import {fetchClients} from "../action_utils";


export function AddShowingAction(props: IShowingAction) {
    const {selectedListingIds} = props;
    const [clients, setClients] = useState([]);
    const [selectedClients, setSelectedClients] = useState([]);
    const [existingShowings, setExistingShowings] = useState([]);
    const [clientShowings, setClientShowings] = useState([] as IShowing []);

    const fetchExistingShowings = async () => {
        const userId = await getUserId();
        await showingsApi.getList(userId).then((res) => {
            setClientShowings(res.data)
            const showingOptions = res.data.map(showing => {
                return {
                    id: showing.id,
                    value: showing.showing_name,
                    label: showing.showing_name,
                    showingClients: showing.showing_clients
                }
            });
            setExistingShowings(showingOptions)
        });
    };

    useEffect(() => {
        fetchClients().then(r => setClients(r));
    }, []);

    const handlePost = async (agentId, values) => {
        const params = {
            agentId,
            payload: {
                client_ids: values.selected_clients_ids,
                showing_name: values.showing_name,
                listing_ids: selectedListingIds
            }
        };
        const res = await showingsApi.post(params);
        if (res.status === 201) {
            props.onSuccess();
        }
    };

    const handlePut = async (userId, values) => {
        const selectedShowingId = existingShowings.filter(showing => showing.id === values.selected_showing_id)[0].id
        const selectedShowing = clientShowings.filter(showing => showing.id === selectedShowingId)[0]
        let selectedShowingListingIds = selectedShowing.listingIds?.map(Number).concat(selectedListingIds)
        const params = {
            agentId: userId,
            showingId: values.selected_showing_id,
            payload: {
                client_ids: values.selected_clients_ids,
                showing_name: values.selected_showing_name,
                listing_ids: selectedShowingListingIds
            }
        };
        await showingsApi.put(params).then(() => {
            props.onSuccess();
        });
    };

    return <Formik
        initialValues={{
            showing_config: "new",
            showing_name: '',
            selected_showing_name: '',
            selected_showing_id: '',
            selected_clients_ids: [],
        }}
        onSubmit={async (values) => {
            const userId = await getUserId();
            if (values.showing_config == "new") {
                await handlePost(userId, values)
            } else if (values.showing_config == "existing") {
                await handlePut(userId, values)
            }
        }}>
        {({values, handleSubmit, setFieldValue}) => (
            <form onSubmit={handleSubmit} className="container-fluid add-showing-action-form">
                <h3>Showings</h3>
                <p className="selected-listings">Selected listings: {selectedListingIds.length}</p>
                <div className="row">
                    <div className="col">
                        <Radio
                            name="showing_config"
                            value="new"
                            checked={values.showing_config == "new"}
                            onChange={() => setFieldValue("showing_config", "new")}
                        />
                        <label htmlFor="showing_config" className="showing-radio-option">Create a new showing</label>
                    </div>
                </div>
                <div className="row">
                    {
                        values.showing_config == "new" ?
                        <div className="col col-10 offset-1">
                        <label htmlFor="showing_name">Showing Name</label>
                        <Field name="showing_name" type="text" className="form-control"/>
                        {/*<p className="showing-name-alt-text">Showing name is optional if you associate client(s) with*/}
                        {/*    this showing</p>*/}
                        {/*<p>- OR -</p>*/}
                    </div>
                        : null
                    }
                </div>
                <div className="row">
                    <div className="col">
                        <Radio
                            name="showing_config"
                            value="existing"
                            checked={values.showing_config == "existing"}
                            onChange={() => {
                                fetchExistingShowings();
                                setFieldValue("showing_config", "existing")
                            }}
                        />
                        <label htmlFor="showing_config" className="showing-radio-option">Add to an existing
                            showing</label>
                    </div>
                </div>
                <div className="row">
                    {
                        values.showing_config == "existing" ?
                            <div className="col col-10 offset-1">
                        <Field
                            name="selected_showing_name"
                            children={({form}) => (
                                <Select
                                    options={existingShowings}
                                    isMulti={false}
                                    onChange={(vals: any) => {
                                        if (vals) {
                                            form.setFieldValue("selected_showing_name", vals.value);
                                            form.setFieldValue("selected_showing_id", vals.id)
                                        }
                                        const selectedShowingClients = vals.showingClients.map(client => {
                                            return clients.find(clientData => clientData.id == client.clientValue)
                                        });

                                        setSelectedClients(selectedShowingClients)
                                    }}
                                />
                            )}
                        />
                    </div>
                            : null
                    }
                </div>
                <div className="row">
                    <div className="col">
                        <label htmlFor="report_format">Associate clients with showing</label>
                        <Field
                            name="selected_clients_ids"
                            children={({form}) => (
                                <Select
                                    options={clients}
                                    isMulti
                                    value={selectedClients}
                                    onChange={(vals: any) => {
                                        if (vals) {
                                            const clientsIds = vals.map(val => val.id);
                                            form.setFieldValue("selected_clients_ids", clientsIds);

                                            const selectedShowingClients = vals.map(client => {
                                                return clients.find(clientData => clientData.id == client.id)
                                            });

                                            setSelectedClients(selectedShowingClients)
                                        } else {
                                            setSelectedClients([]);
                                            form.setFieldValue("selected_clients_ids", [])
                                        }
                                    }}
                                />
                            )}
                        />
                    </div>
                </div>
                <div className="row submit-row">
                    <RbButton {...{type: "submit"}}  disabled={selectedClients.length === 0}>
                        SUBMIT
                    </RbButton>
                </div>
            </form>
        )}
    </Formik>
}

interface IShowingAction {
    selectedListingIds: number [];
    onSuccess: () => void;
}