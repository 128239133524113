import { imgUrl } from "../../../../services/constants";
import React from "react";

const colors = {
  lightgray: "rgb(246, 246, 246)",
};

export const reportStyles = {
  container: {
    padding: "20px",
    "font-family": "Arial",
  },
  reportRow: {
    padding: "15px 20px",
    border: "1px solid lightgray",
    margin: "10px",
  },
  agentInfo: {},
  headerRow: {
    extend: "reportRow",
    "background-color": colors.lightgray,
  },
  text: {
    margin: 0,
  },
  boldText: {
    extend: "text",
    "font-weight": "bold",
  },
  descriptionCol: {
    extend: "text",
    display: "inline-block",
    width: "40%",
  },
  descriptionColBlue: {
    extend: "descriptionCol",
    color: "rgb(0, 102, 153)",
    padding: "5px 0",
    "font-weight": "bold",
  },
  descriptionColCity: {
    extend: "descriptionCol",
    padding: "5px 0",
    "font-weight": "bold",
  },
  valueCol: {
    display: "inline-block",
    extend: "boldText",
    width: "55%",
  },
  valueColRb: {
    extend: "valueCol",
    "text-align": "end",
  },
  listingImage: {
    width: "40%",
    display: "inline-block",
  },
  image: {
    width: "100%",
    "max-width": "250px",
    "max-height": "300px",
  },
  listingDetails: {
    width: "55%",
    padding: "18px 0 0 18px",
    display: "inline-block",
  },
  details: {
    "margin-top": "15px",
    background: colors.lightgray,
    border: "1px solid lightgray",
    "line-height": "1.6rem",
    padding: "15px",
  },
  listingAmenities: {
    width: "40%",
    "padding-right": "20px",
  },
  listingDescription: {
    width: "60%",
  },
  columnHeader: {
    background: colors.lightgray,
    "border-bottom": "1px solid lightgray",
    padding: "12px",
  },
  checkMark: {
    color: "green",
  },
  listPics: {
    padding: "10px",
  },
};

export const fullReportStyle = {
  fullReportContainer: {
    padding: "20px",
    "font-family": "Lato, sans-serif",
  },
};

export function ReportHeader({ agentName, agentEmail, jss }) {
  const { classes } = jss.createStyleSheet(reportStyles).attach();

  return (
    <div className={classes.headerRow}>
      <div>
        <img src={`${imgUrl}/default/rentalbeast_logo.png`} alt="RB Logo" />
      </div>
      <div className={classes.agentInfo}>
        <p className={classes.boldText}>{agentName}</p>
        <p className={classes.text}>{agentEmail}</p>
      </div>
    </div>
  );
}

export function QuickReportDescription({ jss, listing, agentId }) {
  const quickReportStyles = {
    ...reportStyles,
    descriptionColBlue: {
      color: "rgb(0, 102, 153)",
      "font-weight": "bold",
    },
    propsCol: {
      width: "50%",
      display: "inline-block",
    },
  };
  const { classes } = jss.createStyleSheet(quickReportStyles).attach();
  return (
    <div className={classes.details}>
      <div>
        <div style={{ marginBottom: "10px" }}>
          <span className={classes.boldText}>{listing.city}</span>
          <span className={classes.valueColRb}>
            RB ID: {listing.apartment_id}
          </span>
        </div>
      </div>
      <div style={{ display: "flex" }}>
        <div className={classes.propsCol}>
          <p className={classes.descriptionCol}>Rent:</p>
          <p className={classes.valueCol}> $ {listing.rent}</p>
          <p className={classes.descriptionCol}>Date Available:</p>
          <p className={classes.valueCol}> {listing.date_available}</p>
        </div>
        <div className={classes.propsCol}>
          <p className={classes.descriptionCol}>Bedrooms:</p>
          <p className={classes.valueCol}> {listing.bedrooms}</p>
          <p className={classes.descriptionCol}>Bathrooms:</p>
          <p className={classes.valueCol}> {listing.bathrooms}</p>
          <p className={classes.descriptionCol}>Square Feet:</p>
          <p className={classes.valueCol}> {listing.square_footage}</p>
        </div>
      </div>
    </div>
  );
}
export function ShareReportDescription({ jss, listingId, userId }) {
  const quickReportStyles = {
    ...reportStyles,
    descriptionColBlue: {
      color: "rgb(0, 102, 153)",
      "font-weight": "bold",
    },
    propsCol: {
      width: "50%",
      display: "inline-block",
    },
  };
  const { classes } = jss.createStyleSheet(quickReportStyles).attach();
  return (
    <div className={classes.details}>
      <a
        href={`${location.origin}/default/listings/show/${listingId}?log_client_id=0&client_user_id=null&agent_id=${userId}&comm_source=email_share&open_access=true`}
      >
        Click here to see the listing full details!
      </a>
    </div>
  );
}
