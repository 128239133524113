import React from "react";
import styles from "./photos_slider.module.scss";
import {IListingLocal} from "../../../../../../services/api/listing";
import ImageGallery from "react-image-gallery";
import "./slider.scss"
import {imgUrl} from "../../../../../../services/constants";

export function PhotosSlider (props: {listing: IListingLocal}) {
    const {listing} = props;

    const getPictures = () => {
        return listing.pics.map(pic => {
            return {
                original: `${imgUrl}/${pic.path}?w=500`,
                thumbnail: `${imgUrl}/${pic.path}?w=100`
            }
        })
    }

    return <div className={styles.photosCarousel}  >
        <ImageGallery items={getPictures()} showPlayButton={false} infinite={false} showFullscreenButton={false}/>
    </div>
}