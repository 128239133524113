//=========== sorter - public ==============

export function sorter_sortAscending(sortProperty: string, arrayToSort: []) {
    sorter_sortProperty = sortProperty;
    arrayToSort.sort( sorter_generalCompare );
    sorter_sortProperty = null;
}

export function sorter_sortDescending(sortProperty: string, arrayToSort: []) {
    sorter_sortProperty = sortProperty;
    arrayToSort.sort( sorter_reverseCompare );
    sorter_sortProperty = null;
}

//=========== sorter - private =============

let sorter_sortProperty;

export function sorter_generalCompare(arg1, arg2)
{
    if(!sorter_sortProperty) {
        console.log("You must set the sort property");
    }

    let value1 = arg1[sorter_sortProperty];
    let value2 = arg2[sorter_sortProperty];

    if(value1 > value2) {
        return 1;
    }
    else if(value1 < value2) {
        return -1;
    }
    else {
        return 0;
    }
}

export function sorter_reverseCompare(arg1, arg2) {
    return  sorter_generalCompare(arg2, arg1);
}