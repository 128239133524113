import React, {useContext, useEffect, useState} from "react";
import {ListingSearchContext} from "../../listing_search_context";
import "./fieldset_schools.scss";
// import _ from "lodash";
// import Autocomplete from '@material-ui/lab/Autocomplete';
// import TextField from "@material-ui/core/TextField";
// import {districtAutoCompleteApi} from "../../../../../services/api/district_auto_complete";

// function updateOptions(loadOptions, value) {
//     loadOptions(value)
// }
// const debounced = _.debounce(updateOptions, 300)

export function FieldsetSchoolDistrict() {
    const {criteria, setCriteria} = useContext(ListingSearchContext);
    // const [options, setOptions] = useState([])
    // const [selectedOption, setSelectedOption] = useState("")
    // const [flag, setFlag] = useState(true);

    // const loadOptions = async ({ value }: { value: string, level: string }) => {
    //     await districtAutoCompleteApi.get({matchString: value}).then((res) => {
    //         if( res.length === 0){
    //             return setFlag(false);
    //         }
    //         return setOptions(res)
    //     })
    // }

    // const handleLoadOptions = (value) => {
    //     const level = 'district'
    //     if(value !== ''){
    //         debounced(loadOptions, { value, level })
    //     }
    //     else {
    //         setCriteria({...criteria, school_district: value});
    //     }
    // };
    //
    // const handleSelect = (name: string) => {
    //     setCriteria({...criteria, school_district: name});
    //     setSelectedOption(name)
    // };

    // useEffect(() => {
    //     if(["", undefined, null].includes(criteria.school_district)){
    //         setSelectedOption("")
    //     }
    // }, [criteria.school_district]);

    const handleOnChange = e => {
        setCriteria({...criteria, school_district: e.target.value});
    };

    // TODO - Uncomment all the commented lines when we change from input to autocomplete again
    return <fieldset className="fieldset--schools-container">
        <label>School District</label>
        <div>
            <input onChange={handleOnChange} value={criteria.school_district} className="criteria--input" />
        </div>
    </fieldset>
    // <fieldset className="fieldset--container">
    //     <label htmlFor="school_district">School District</label>
    //     <Autocomplete
    //         id="school_district_autocomplete"
    //         style={{ width: '100%' }}
    //         disableClearable={true}
    //         options={options}
    //         getOptionLabel={option => option ? option : ''}
    //         noOptionsText={ flag ? 'Start typing to find matches' : 'No matches found' }
    //         onChange={(e, val) => handleSelect(val)}
    //         onInputChange={(e, val) => handleLoadOptions(val)}
    //         value={selectedOption}
    //         renderInput={
    //             params => <TextField {...params} variant="outlined" />
    //         }
    //     />
    // </fieldset>
}