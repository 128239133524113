import React, { useEffect, useState } from "react";
import {
  IListingLocal,
  IRentalLeaseListing,
} from "../../../../../../services/api/listing";
import styles from "./general_info.module.scss";
import {
  ListingFeature,
  listingFeaturesApi,
} from "../../../../../../services/rails_api/listing_features";
import moment from "moment";
import { oldTechURL } from "../../../../../../services/constants";
import { general_info_tabs, TabNames } from "./general_info_tabs";
import { getContactInfo } from "./general_info_utils";
import { useTranslation } from "react-i18next";

export function GeneralInfo(props: {
  listing: IListingLocal;
  leaseData: IRentalLeaseListing;
  constructListingDetailUDFs?: any
  parcelNumRaw: string;
  filteredCompensationKeys: any[];
  universalOptionalSchoolKeyValuePair: any[];
  universalOptionalCompensationKeyValuePair: any[];
}) {
  const {
    listing,
    leaseData,
    filteredCompensationKeys = [],
    constructListingDetailUDFs,
    universalOptionalSchoolKeyValuePair,
    universalOptionalCompensationKeyValuePair
  } = props;
  const [featuresList, setFeaturesList] = useState({});

  const getLabels = (keysArr, featureName: ListingFeature) => {
    if (Object.keys(featuresList).length && keysArr) {
      return keysArr.map((key) => {
        return featuresList[featureName][key];
      });
    } else {
      return [];
    }
  };

  const isBlank = (data?: string): boolean => {
    if (data === undefined || data === null || data.trim() === "null" || data.trim().length == 0) {
        return true;
    }
    return false;
  }

  const isNotBlank = (data?: string): boolean => !isBlank(data);

  const getApplicationProcedure = (listing) => {
    let procedure = "";
    if (isNotBlank(listing.application_procedure)) {
      procedure = listing.application_procedure;
    }
    if (isNotBlank(listing.other_application_instructions)) {
      if (isNotBlank(procedure)) {
        procedure = procedure + " ";
      }
      procedure = procedure + listing.other_application_instructions;
    }
    return procedure;
  }

  const getFields = (tabName, listing, leaseData) => {
    if (tabName === TabNames.CONTACT) {
      return [
        {
          title: "Info",
          items: getContactInfo(listing).concat(constructListingDetailUDFs('info_address', listing)),
        },
      ];
    } else if (
      tabName === TabNames.LEASING_AGENT &&
      listing.status == "Rented"
    ) {
      let leasingAgentInfo = [
        {
          label: "Leasing Agent",
          value: leaseData !== null ? leaseData?.leasing_agent_name : "N/A",
        },
        {
          label: "Leasing Email",
          value: leaseData !== null ? leaseData?.leasing_agent_email : "N/A",
        },
        {
          label: "Leasing Phone",
          value: leaseData !== null ? leaseData?.leasing_agent_phone : "N/A",
        },
        {
          label: "Leasing Office",
          value: leaseData !== null ? leaseData?.leasing_brokerage_name : "N/A",
        },
        {
          label: "Leasing Office Address",
          value:
            leaseData !== null
              ? `${leaseData?.leasing_brokerage_address?.address} ${leaseData?.leasing_brokerage_address?.city}, ${leaseData?.leasing_brokerage_address?.state} ${leaseData?.leasing_brokerage_address?.zip}`
              : "N/A",
        },
      ];
      return [
        {
          title: "Leasing Agent Information",
          items: leasingAgentInfo,
        },
      ];
    } else if (tabName === TabNames.LISTING_INFO) {
      const rentChanges = listing.rent_changes
      const absoluteValue = ((rentChanges[0] || {})?.absolute || 0)
      const rentChangeAbsolute = absoluteValue > 0
      const rentChangeDifference = rentChangeAbsolute ? absoluteValue : absoluteValue * -1
      const { t } = useTranslation( 'translation',{useSuspense: false});

      let rentChange: string = absoluteValue
        ? `${absoluteValue > 0 ? '+' : '-'}$${rentChangeDifference}`
        : ''
      const rentChangeDiffColor = absoluteValue > 0 ? '#81C784' : 'red'

      let items = [
        { label: "Rent", value: `$${listing.rent}`, showFull: true, customElement: absoluteValue ? { value: rentChange, color: rentChangeDiffColor, prevRent: ((rentChanges[0] || {})?.integer_value || 0) + (absoluteValue * -1)} : null },
        { label: "Move-in Costs", value: listing.financial_reqs },
        {
          label: (listing.status === "Rented") ? "Leased Term" :"Lease Term",
          value: `${listing.sublet} ${listing.lease_term_details}`,
        },
        { label: "Move-in Specials", value: listing.move_in_specials },
        // ---- UNIVERSAL OPTIONAL > COMPENSATION SECTION ----
        ...(filteredCompensationKeys?.length === 5
          ? universalOptionalCompensationKeyValuePair?.length
            ? [
              // --- compensation available - not cofigurable as of the moment ---
              // * it's fetching the old `owner_pay` key since it's not in `listing_aj`
                {
                  label: "Compensation Available",
                  value: listing.owner_pay,
                  showFull: true,
                },
              // --- END ---
                ...universalOptionalCompensationKeyValuePair
              ] : [
                {
                  label: "Compensation Available",
                  value: listing.owner_pay,
                  showFull: true,
                },
                {
                  label: "Compensation",
                  value: listing.owner_pay_amount.includes("%")
                    ? listing.owner_pay_amount
                    : listing.owner_pay_amount.includes('$')
                      ? listing.owner_pay_amount
                      : `$${listing.owner_pay_amount}`
                },
                {
                  label: "Compensation Remarks",
                  value: listing?.compensation_remarks,
                  showFull: !!listing?.compensation_remarks,
                }
              ]
          : []
        ),
        // ---- END ----
        { label: "Contract Type", value: listing?.contract_type },
        { label: "Service Level", value: listing?.service_level },
        { label: "Renter Insurance", value: listing.renter_insurance },
        {
          label: "Additional Monthly Charge",
          value: listing.additional_monthly_charge,
        },
        {
          label: "Additional Monthly Charge Comments",
          value: listing.additional_monthly_charge_comments,
        },
        {
          label: <span>{t('MLS Entry Date')}</span>,
          value: moment(listing.mls_entry_date).format("MM/DD/YY"),
        },
        { label: "Pet Fee", value: listing?.pet_fee, showFull: true },
        { label: "HOA Fee", value: listing?.hoa_fee, showFull: true },
        {
          label: "Move-in Costs",
          value: listing?.move_in_costs,
          showFull: true,
        },
        {
          label: "Move-in Specials",
          value: listing?.move_in_specials_tenant,
          showFull: true,
        },
        ...constructListingDetailUDFs('listing_listing', listing)
      ];

      if (listing.long_term_commission) {
        const labelToRemove = "Owner Pays Amount";
        const filteredItems = items.filter(
          (item) => item.label !== labelToRemove
        );
        return [
          {
            title: "Listing Info",
            items: filteredItems,
          },
        ];
      } else {
        return [
          {
            title: "Listing Info",
            items: items,
          },
        ];
      }
    } else if (tabName === TabNames.PROPERTY_INFO) {
      return [
        {
          title: "Property",
          items: [
            {
              label: "External Structures",
              value: getLabels(
                listing.external_structures,
                ListingFeature.external_structure
              ),
            },
            {
              label: "Property Structure",
              value: listing?.property_structure,
              showFull: true,
            },
            {
              label: "Property Features",
              value: getLabels(
                listing.property_features,
                ListingFeature.property_feature
              ),
              showFull: true,
            },
            {
              label: "Parking Options",
              value: listing.parking_options,
              showFull: true,
            },
            {
              label: "Number of Garage Spaces",
              value: listing.garage_spaces,
              showFull: true,
            },
            {
              label: "Other Parking",
              value: listing.other_parking,
              showFull: true,
            },
            {
              label: "Parking Fee",
              value: listing.parking_fee,
              showFull: true,
            },
            ...constructListingDetailUDFs('property_property', listing)
          ],
        },
        {
          title: "School",
          items: [
            { label: "Middle School", value: listing.middle_school },
            { label: "High School", value: listing.high_school },
            { label: "Elementary School", value: listing.elementary_school },
            { label: "School District", value: listing.school_district },
            ...constructListingDetailUDFs('property_school', listing),
            ...((universalOptionalSchoolKeyValuePair?.length > 0) ? universalOptionalSchoolKeyValuePair : [])
          ],
        },
        {
          title: "Other",
          items: [
            {
              label: "Condo Association Name",
              value: listing.condo_association_name,
              showFull: true,
            },
            {
              label: "HOA Covenant",
              value: listing.hoa_covenant ? "Yes" : "No",
            },
            {
              label: isNotBlank(props.parcelNumRaw) ? "Assessor #" : "",
              value: isNotBlank(props.parcelNumRaw) ? props.parcelNumRaw : "",
            },
            {
              label: "Condo Association Address",
              value: listing.condo_association_address,
            },
            { label: "Lot Square Footage", value: listing.lot_square_footage },
            { label: "Restrictions", value: listing.restrictions },
            { label: "Year Built", value: listing.year_built },
            { label: "Public Transportation", value: listing.public_transit },
            ...constructListingDetailUDFs('property_other', listing)
          ],
        },
      ];
    } else if (tabName === TabNames.UNIT_INFO) {
      return [
        {
          title: "Building",
          items: [
            {
              label: "Property Type",
              value: listing.building_type,
              showFull: true,
            },
            {
              label: "Total Square Footage",
              value:
                listing.square_footage > 0
                  ? `${listing.square_footage} ft`
                  : listing.square_footage,
            },
            {
              label: "Finished Square Footage",
              value:
                listing.finished_square_footage > 0
                  ? `${listing.finished_square_footage} ft`
                  : listing.finished_square_footage,
              showFull: true,
            },
            { label: "Bedrooms", value: listing.bedrooms, showFull: true },
            {
              label: "Full Bathrooms",
              value: listing.full_bathrooms,
              showFull: true,
            },
            {
              label: "Half Bathrooms",
              value: listing.half_bathrooms,
              showFull: true,
            },
            {
              label: "Three-quarter Bathrooms",
              value: listing.three_quarter_bathrooms,
            },
            { label: "Floor", value: listing.floor },
            {
              label: "Floor Type",
              value: listing?.floor_types,
              showFull: true,
            },
            {
              label: "Floors in Unit",
              value: listing.floors_in_unit,
              showFull: true,
            },
            { label: "Furnished", value: listing.furnished, showFull: true },
            {
              label: "Room Types",
              value: getLabels(listing.room_types, ListingFeature.room_types),
            },
            ...constructListingDetailUDFs('unit_building', listing)
          ],
        },
        {
          title: "Utilities",
          items: [
            {
              label: "Utilities Available",
              value: getLabels(
                listing.utilities_available,
                ListingFeature.utility
              ),
              showFull: true,
            },
            {
              label: "Utilities Included",
              value: getLabels(
                listing.utilities_included,
                ListingFeature.utility
              ),
              showFull: true,
            },
            {
              label: "Other Utilities",
              value: listing.other_utility,
              showFull: true,
            },
            { label: "A/C Type", value: listing.ac_type, showFull: true },
            { label: "Heat Type", value: listing.heat_type, showFull: true },
            {
              label: "Unit Description",
              value: listing.comments,
              showFull: true,
            },
            ...constructListingDetailUDFs('unit_utilities', listing)
          ],
        },
        {
          title: "Description",
          items: [
            {
              label: "Unit Features",
              value: getLabels(
                listing.unit_features,
                ListingFeature.unit_feature
              ),
            },
            { label: "Deleaded", value: listing.deleaded },
            { label: "Pets Allowed", value: listing.pets, showFull: true },
            {
              label: "Pet Type",
              value: getLabels(listing.pet_types, ListingFeature.pet_type),
              showFull: true,
            },
            {
              label: "Pet Specifications",
              value: listing.pet_specs,
              showFull: true,
            },
            { label: "Laundry", value: listing.laundry, showFull: true },
            {
              label: "Attached to Another Dwelling",
              value: listing.attached_to_another_dwelling ? "Yes" : "No",
            },
            {
              label: "Rented Equipment",
              value: getLabels(
                listing.rented_equipment,
                ListingFeature.rented_equipment
              ),
            },
            ...constructListingDetailUDFs('unit_description', listing)
          ],
        },
      ];
    } else if (tabName === TabNames.SHOWINGS) {
      return [
        {
          title: "Showing Info",
          items: [
            { label: "Disclosures", value: listing.disclosure, showFull: true },
            {
              label: "Building Showing Instructions",
              value: listing.showing_instructions,
              showFull: true,
            },
            {
              label: "Unit Showing Instructions",
              value: listing.showing_instructions_listing,
              showFull: true,
            },
            { label: "Key Information", value: listing.key_info },
            { label: "Key Code", value: listing.key_code },
            {
              label: "Unit Occupied",
              value: listing.unit_occupied,
              showFull: true,
            },
            {
              label: "Open House Schedule",
              value: listing.open_house_schedule,
            },
            {
              label: "Virtual Showing",
              value: listing.virtual_showing_allowed,
              showFull: true,
            },
            {
              label: "Virtual Tour URL",
              value: listing.virtual_tour_url,
              showFull: true,
            },
            {
              label: "Virtual Showing Notes",
              value: listing.virtual_showing_notes,
              showFull: true,
            },
            ...constructListingDetailUDFs('showings_info', listing)
          ],
        },
      ];
    } else if (tabName === TabNames.NOTES_SETTINGS) {
      const hiddenFeature = localStorage.getItem('hidden_tab')

      return [
        {
          title: "Application Settings",
          items: [
            {
              label: listing.use_paper ? "Documents" : "",
              value: listing.use_paper ? listing?.docs : "" ,
              showFull: listing.use_paper,
              downloadable: true,
            },
            {
              label: "Broker Notes",
              value: listing.broker_notes,
              showFull: true,
            },
            // {label: 'Agent Private Notes', value: listing.partner_private_notes},
            {
              label: "Agent Notes",
              value: listing.agent_listing_notes?.agent_notes,
              showFull: true,
            },
            // {label: '', value: listing.hide_partner_cp},
            // {label: '', value: listing.hide_rb_cp},
            // {label: '', value: listing.hide_for_market_bp},
            // {label: 'Allow Syndication', value: listing.allow_syndication},
            // {label: 'Listing Agent Review', value: listing.la_reviewer},
            {
              label: "Application Procedure",
              value: hiddenFeature === 'applications'
                ? listing.use_apply_now
                  ? getApplicationProcedure(listing).split('[')[0]
                  : getApplicationProcedure(listing)
                : getApplicationProcedure(listing),
              showFull: true
            },
            {
                label: listing.use_third_party ? "Third Party URL" : "",
                value: listing.use_third_party ? listing.third_party_application_url : "",
                showFull: listing.use_third_party
            },
            ...constructListingDetailUDFs('notes_application', listing)
            // ---- RB-2118: commenting this one and retains the first item field "Documents" for dynamic display of downloadable file
            // {
            //   label: "Documents",
            //   value: listing.docs,
            //   showFull: true
            // }
            // ---- END -----
          ],
        },
      ];
    } else {
      return [];
    }
  };

  const getFeaturesList = async () => {
    const listingsFeaturesRes = await listingFeaturesApi.getList();
    setFeaturesList(listingsFeaturesRes);
  };

  useEffect(() => {
    getFeaturesList();
  }, []);

  const allowedTabs = [
    TabNames.CONTACT,
    TabNames.LISTING_INFO,
    TabNames.PROPERTY_INFO,
    TabNames.UNIT_INFO,
    TabNames.SHOWINGS,
    TabNames.NOTES_SETTINGS,
  ];

  const filterObject = (object) => {
    return Object.fromEntries(allowedTabs.map((k) => [k, object[k]]));
  };

  return (
    <React.Fragment>
      <div className={styles.mainContainer}>
        {Object.keys(
          listing.status == "Rented"
            ? general_info_tabs
            : filterObject(general_info_tabs)
        ).map((tab: TabNames) => {
          const tabItem = general_info_tabs[tab];
          return (
            <div key={tab} className={styles.infoSectionContainer}>
              <div className={styles.infoSectionHeader}>{tabItem.label}</div>
              <InfoSection
                fields={getFields(tab, listing, leaseData) as IField[]}
              />
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
}

function InfoSection(props: { fields: IField[] }) {
  const { fields } = props;

  const notEmptyFilter = (field) => {
    if (field.showFull) {
      return true;
    }

    return Array.isArray(field.value)
      ? field.value && field.value !== "" && field.value.length
      : field.value && field.value !== "";
  };

  return (
    <section className={styles.infoSection}>
      {fields.map((fieldsObj) => {
        return (
          <div key={fieldsObj.title} className={styles.infoSubSection}>
            <p className={styles.infoSubTitle}>{fieldsObj.title}</p>
            <div>
              {fieldsObj.items.filter(notEmptyFilter).map((item, index) => {
                return (
                  <PropertyValue
                    downloadable={item.downloadable}
                    label={item.label}
                    value={item.value}
                    customElement={item.customElement}
                    key={index}
                  />
                );
              })}
            </div>
          </div>
        );
      })}
    </section>
  );
}

function PropertyValue(props) {
  const { label, value, downloadable, customElement } = props;
  return (
    <div className={styles.dataCont}>
      <div className={styles.propertyLabel}>{label}:</div>
      {typeof value === "object" && value?.length && value?.length > 0 ? (
        <div>
          {downloadable ||  label == "Documents"
            ? value
                .map((item: any) => (
                  <DownloadableComponent key={item?.doc_id} {...item} label={label} />
                ))
                .reduce((prev?: JSX.Element, curr?: JSX.Element) => [
                  prev,
                  label !== 'Documents' && <span>, </span>,
                  curr,
                ])
            : typeof value === "object" && value.length && value.length > 0
            ? value
                .map((item) => {
                  let firstItem =
                    typeof item === "object" ? Object.keys(item)[0] : "";
                  return typeof item === "object"
                    ? `${snakeCaseToProperCase(firstItem)}: ${item[firstItem]}`
                    : snakeCaseToProperCase(item);
                })
                .join(", " + "")
            : value}
        </div>
      ) : customElement ? (
          <>
            <div dangerouslySetInnerHTML={{ __html: value }} />
            {customElement && (<div style={{ marginLeft: 10 }}>
              Chg: <span style={{ color: customElement?.color }}>{customElement?.value}</span>
              <span style={{ marginLeft: 10 }}>Prev: {customElement.prevRent}</span>
            </div>)}
          </>
        ) : value
      }
    </div>
  );
}

function DownloadableComponent({ path, doc_display_name, label }: IFileField) {
  return (
    <div style={{display:'flex' , flexDirection: label === 'Documents' ? 'column' : 'initial' }}>
      <a
        download={doc_display_name}
        target="_blank"
        href={`${oldTechURL}${path}`}
      >
        {doc_display_name}
      </a>
    </div>
  );
}

const snakeCaseToProperCase = (value: string) => {
  return value
    .split("_")
    .map(
      (text: string) => `${text.substr(0, 1).toUpperCase()}${text.substr(1)}`
    )
    .join(" ");
};

interface IField {
  title: string;
  items: IItem[];
}

interface IItem {
  label: string;
  value: string | string[];
  showFull?: boolean;
  downloadable?: boolean;
  customElement?: any;
}

interface IFileField {
  doc_id?: any;
  path: string;
  doc_display_name: string;
  label? : string
}
