function bedRooms() {
    let rooms = new Array(15);
    for (let i = 0; i < 15; i++) {
        rooms[i] = i === 0 ? "Studio" : (i === 1 ? "1 Bedroom" : i + " Bedrooms");
    }
    return rooms;
}
export const numberOfBedrooms = bedRooms();

// numberOfBedrooms should return an object like
// {
//     '0' => '0 Studio',
//     '1' => '1 Bedroom',
//     '2' => '2 Bedrooms',
//     '3' => '3 Bedrooms',
//     '4' => '4 Bedrooms',
//     '5' => '5 Bedrooms',
//     '6' => '6 Bedrooms',
//     '7' => '7 Bedrooms',
//     '8' => '8 Bedrooms',
//     '9' => '9 Bedrooms',
//     '10' => '10 Bedrooms ',
//     '11' => '11 Bedrooms',
//     '12' => '12 Bedrooms',
//     '13' => '13 Bedrooms',
//     '14' => '14 Bedrooms'
// }