import {REST} from "../rest";

export const searchAutocompletionApi = {
  /**
   * @param locationStr string inputted by user
   * @param partnerKey string agent's partner key
   * @param includeMatches comma separated list of fields to match query to. If not passed it defaults to "state,metro,city,neighborhood,sub_neighborhood,zip"
   */
  get : async (locationStr:string, partnerKey:string, includeMatches: string = "metro,city,neighborhood,sub_neighborhood,neighborhood_override") => {
    return await REST.get(`/api/search_auto_completion`,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        params: {
          partner_key: partnerKey,
          include_matches: includeMatches,
          match_string: locationStr,
          count_per_criteria: 5,
        }
      }).then(res => {
        return res.data.data;
    });
  }
};