import { getUserId } from "../../../services/session";
import { agentClientsApi } from "../../../services/rails_api/agent/clients";
import { getConsumerData } from "../../../services/api";
import {
  EFieldsToInclude,
  IListingApiGetParams,
  listingApi,
} from "../../../services/api/listing";
import ReactDOMServer from "react-dom/server";
import { bpEmailSender } from "../../../services/api/bp_email_sender";
import { getAgentPartnerKey } from "../../components/listing_search/utilities";

export const fetchClients = async () => {
  const userId = await getUserId();
  const clientsRes = await agentClientsApi.getList({ agentId: userId });
  return clientsRes
    .filter((client) => client.main_email && client.main_email !== "")
    .map((client) => {
      return {
        id: client.id,
        value: client.name_ln,
        label: client.name_ln,
        main_email: client.main_email,
      };
    });
};

export const fetchListings = async (selectedListingIds) => {
  const listingGetParams: IListingApiGetParams = {
    listing_ids: selectedListingIds,
    fields_to_include: [
      EFieldsToInclude.ac_type,
      EFieldsToInclude.address,
      EFieldsToInclude.apartment_id,
      EFieldsToInclude.apt_number,
      EFieldsToInclude.application_procedure,
      EFieldsToInclude.attached_to_another_dwelling,
      EFieldsToInclude.bathrooms,
      EFieldsToInclude.bedrooms,
      EFieldsToInclude.broker_notes,
      EFieldsToInclude.building_type,
      EFieldsToInclude.building_name,
      EFieldsToInclude.city,
      EFieldsToInclude.comments,
      EFieldsToInclude.condo_association_name,
      EFieldsToInclude.condo_association_address,
      EFieldsToInclude.county,
      EFieldsToInclude.date_available,
      EFieldsToInclude.doorman,
      EFieldsToInclude.deleaded,
      EFieldsToInclude.direct_phone,
      EFieldsToInclude.disclosure,
      EFieldsToInclude.dishwasher,
      EFieldsToInclude.elementary_school,
      EFieldsToInclude.elevator,
      EFieldsToInclude.email,
      EFieldsToInclude.fitness,
      EFieldsToInclude.financial_reqs,
      EFieldsToInclude.finished_square_footage,
      EFieldsToInclude.floor,
      EFieldsToInclude.full_bathrooms,
      EFieldsToInclude.furnished,
      EFieldsToInclude.half_bathrooms,
      EFieldsToInclude.heat_type,
      EFieldsToInclude.high_school,
      EFieldsToInclude.hoa_covenant,
      EFieldsToInclude.key_info,
      EFieldsToInclude.laundry,
      EFieldsToInclude.lease_date,
      EFieldsToInclude.lease_term_details,
      EFieldsToInclude.management_co,
      EFieldsToInclude.mls_id,
      EFieldsToInclude.middle_school,
      EFieldsToInclude.move_in_costs,
      EFieldsToInclude.mls_entry_date,
      EFieldsToInclude.neighborhood,
      EFieldsToInclude.other_parking,
      EFieldsToInclude.other_utility,
      EFieldsToInclude.parking_fee,
      EFieldsToInclude.parking_options,
      EFieldsToInclude.pic_count,
      EFieldsToInclude.pics,
      EFieldsToInclude.pool,
      EFieldsToInclude.property_features,
      EFieldsToInclude.property_structure,
      EFieldsToInclude.pet_types,
      EFieldsToInclude.pet_fee,
      // EFieldsToInclude.deleaded,
      EFieldsToInclude.pet_specs,
      EFieldsToInclude.pets,
      EFieldsToInclude.rent,
      EFieldsToInclude.renter_insurance,
      EFieldsToInclude.room_types,
      EFieldsToInclude.state,
      EFieldsToInclude.status,
      EFieldsToInclude.service_level,
      EFieldsToInclude.short_term,
      EFieldsToInclude.sundeck_grills,
      EFieldsToInclude.showing_instructions,
      EFieldsToInclude.showing_instructions_listing,
      EFieldsToInclude.square_footage,
      EFieldsToInclude.sublet,
      EFieldsToInclude.three_quarter_bathrooms,
      EFieldsToInclude.utilities_available,
      EFieldsToInclude.utilities_included,
      EFieldsToInclude.unit_features,
      EFieldsToInclude.unit_occupied,
      EFieldsToInclude.virtual_showing_allowed,
      EFieldsToInclude.virtual_tour_url,
      EFieldsToInclude.virtual_showing_notes,
      EFieldsToInclude.wheel_chair,
      EFieldsToInclude.year_built,
      EFieldsToInclude.zip,
    ],
  };
  return await listingApi.get(listingGetParams);
};

export const sendEmail = async (element, userInfo, emailValues, win, reportInfo) => {
  const ccList = [userInfo.email];
  // const listingIds = reportInfo?.listings?.map(listing => listing.apartment_id)
  const toEmailArrays = emailValues?.emails_comma?.split(',')
  const mergedToClientsEmails = emailValues?.selected_clients_emails
  const filteredToClients = mergedToClientsEmails.filter(toClientEmail => !toEmailArrays.filter(toEmail => toClientEmail === toEmail).length)

  const payload =   {
    template_id: "client-listing-full-email",
    subject: emailValues.subject,
    message: emailValues.message,
    to_emails: toEmailArrays[0] ? toEmailArrays : [],
    to_clients: filteredToClients,
    // listing_ids: listingIds,
    listing_ids: reportInfo?.listingsV2,
    agent_id: reportInfo?.agentId,
    agent_name: reportInfo?.agentName,
    agent_email: reportInfo?.agentEmail,
    agent_phone: reportInfo?.agentContact,
    agent_logo_path: reportInfo?.agentBrokerLogo,
    partner_key: await getAgentPartnerKey(),
    ...(emailValues.cc_myself && { cc_list: emailValues.cc_myself ? ccList : [] }),
    ...(emailValues.send_myself_broker_version && { bcc_list: emailValues.send_myself_broker_version ? ccList : [] }),
    is_shared: !!reportInfo.is_shared
  }
  
  return await bpEmailSender.post(payload)
};
