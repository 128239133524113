import React, { useContext, useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import {
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";

import { ListingDetails } from "../detail_search_results/listing_details/listing_details";
import { SelectedListingsContext } from "../../selected_listings_context";
import { BookmarksContext } from "../../bookmarks_context";
import { UpgradePricingModal } from "../detail_search_results/upgrade_pricing_modal/upgrade_pricing_modal";
import { RBCheckbox } from "../../../../components/rb_checkbox/rb_checkbox";
import { ListingSearchContext } from "../../../../components/listing_search/listing_search_context";
import { BrokerSearchingContext } from '../../broker_search_context';
import ReactPaginate from "react-paginate";
import "./tile_view.scss";

const CustomComponent = React.memo(
  ({ onClear, allIsChecked, onAllSelectedListings, selectedListings }: any) => {
    return (
      <div className='TableHeader-custom-header-tile_view'>
        <RBCheckbox name='' checked={allIsChecked} handleChange={onAllSelectedListings} />
        <div className='text-actions'>
          <span>{selectedListings.length} Selected</span>
          <span onClick={onClear} className='action--header'>Unselect</span>
        </div>
      </div>
    )
  }
)

const MobilePaginationComponent = React.memo(
  ({ totalPages, isMobileRes, criteria, setCriteria, setSearching }: any) => {

    const handlePageChange = (props) => {
        setCriteria({...criteria, page_number: props.selected+1});
        console.log("Searching");
        setSearching(true);
    };

    return isMobileRes && totalPages && (
      <div className="mobile-pagination-container">
          <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            breakLabel={null}
            pageCount={totalPages}
            marginPagesDisplayed={0}
            pageRangeDisplayed={0}
            containerClassName={'pagination'}
            forcePage={criteria.page_number-1}
            onPageChange={ props => handlePageChange(props)  }
          />
      </div>
    )
  }
)

export const TileView = React.memo(
  function TileViewComponent(props: ITileView) {
    const {
      allIsChecked,
      selectedListings,
      handlePageChange,
      setGroupedListings,
      clearSelectedListings,
      handleAllSelectedListings
    } = useContext(SelectedListingsContext);
    const { criteria, setCriteria } = useContext(ListingSearchContext);
    const { listings,
        isAddressDisplay,
        totalListingCount,
        totalPages } = props;
    const { bookmarks, fetchBookmarks } = useContext(BookmarksContext);
    const [showShowUpgradeModal, setShowUpgradePageModal] = useState<boolean>(false)
    const [upgradeListingId, setUpgradeListingId] = useState<any>(null)

    const toggleUpgradeModal = (listingId: any) => {
        setUpgradeListingId(listingId)
        setShowUpgradePageModal(showShowUpgradeModal => !showShowUpgradeModal)
    }
    const {isMobileRes, setSearching} = useContext(BrokerSearchingContext);

    useEffect(() => {
      handlePageChange(listings)

      // perform a cleanup
      return () => {
        setGroupedListings([])
      }
    }, [criteria?.page_number])

    // alignment - 0: left, 1: right
    const headCells = [
      { id: "checkbox", alignment: 0, label: "", component: CustomComponent },
      { id: "mobile_pagination", alignment: 0, label: "", component: MobilePaginationComponent },
    ];

    return (
      <>
        <Table className="search-results-table">
          <TableHead>
            <TableRow>
              {headCells.map((cell) => (
                <TableCell
                  align={cell.alignment === 0 ? "left" : "right"}
                  key={cell.id}
                >
                  <cell.component
                    selectedListings={selectedListings}
                    onClear={clearSelectedListings}
                    allIsChecked={allIsChecked}
                    onAllSelectedListings={() => handleAllSelectedListings(listings)}
                    totalPages={totalPages}
                    isMobileRes={isMobileRes}
                    criteria={criteria}
                    setCriteria={setCriteria}
                    setSearching={setSearching}
                  />
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        </Table>
        <div className="row justify-content-center">
          {showShowUpgradeModal && (
            <UpgradePricingModal
              listingId={upgradeListingId}
              showModal={showShowUpgradeModal}
              modalAction={toggleUpgradeModal}
              totalListingCount={totalListingCount}
            />
          )}

          <div className="col-12 col-lg-11">
            {listings.map((listing, index) => {
              return (
                <ListingDetails
                  pageNumber={criteria?.page_number}
                  listing={listing}
                  listings={listings}
                  key={listing.apartment_id}
                  bookmarks={bookmarks}
                  handleBookmark={fetchBookmarks}
                  checked={selectedListings.some(
                    (selectedListing) =>
                      selectedListing.groupListingId === listing.apartment_id
                  )}
                  isAddressDisplay={isAddressDisplay}
                  toggleUpgradeModal={toggleUpgradeModal}
                  showShowUpgradeModal={showShowUpgradeModal}
                />
              );
            })}
          </div>
        </div>
      </>
    );
  }
)

interface ITileView {
  listings: any[];
  isAddressDisplay: boolean
  totalListingCount?: number
  isEnableAllListingsTab?: boolean
  totalResults?: number
  isEnableMlsListingsTab?: boolean;
  isEnableRbListingsTab?: boolean;
  totalPages?: number;
}
