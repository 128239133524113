import React, {useContext, useState} from "react";
import {ConsumerSearchContext} from "./consumer_search_context";

export function Flag(props) {

    const {resultId, text, readyToScroll, setReadyToScroll, zoom} = props;

    const [id, setId] = useState(resultId);
    const [tag, setTag] = useState(text);
    const [selected, setSelected] = useState(false);
    const [active, setActive] = useState(false);
    const {setHighlightedResultId} = useContext(ConsumerSearchContext);

    const setSelectedListing = (id) => {};

    const getCssClassName = (selected, active) => {
        let className = "rb__flag";
        if (selected || active) {
            className = `${className} rb__flag-selected`;
        }

        return className;
    };

    const handleMouseEnter = (id) => {
        setActive(true);
        setHighlightedResultId(id);
        scrollToListing(id);
    };

    const handleMouseLeave = () => {
        setSelected(false);
        setActive(false);
        setHighlightedResultId("");
    };

    const scrollToListing = (id) => {
        if(readyToScroll) {
            const el = document.getElementById(`tile_${id}`);
            const scrollerEl = el.parentElement;

            scrollerEl.scrollTop = el.offsetTop;
            setReadyToScroll(false);

            setTimeout(()=>{
                setReadyToScroll(true)
            }, 1500);
        }
    };

    return (
        <div className={getCssClassName(selected, active)}
             onClick={() => {setSelectedListing(id)}}
             onMouseEnter={() => {handleMouseEnter(id)}}
             onMouseLeave={() => {handleMouseLeave()}}
             id={`flag_${resultId}`}
             style={{
                 left: (zoom > 16) ? "60px" : "-27px",
                 top: (zoom > 16) ? "60px" : "-40px",
             }}
        >
            <span>{tag}</span>
            <div className="rb__flag-tip" />
        </div>
    )
}