import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import {getFormattedDate} from "../../utilities";
import moment from "moment";
import React, {useContext, useEffect, useState} from "react";
import {ListingSearchContext} from "../../listing_search_context";

export function FieldsetAvailableDate(props) {
    const {criteria, setCriteria} = useContext(ListingSearchContext);
    const [dates, setDates] = useState([]);

    const setDatesCriteria = () => {
        if (dates === null || (dates[0] ==null && dates[1] === null)) {
            return
        }
        else {
            setCriteria({...criteria, available_from_date: getFormattedDate(dates[0]), available_by_date: getFormattedDate(dates[1])})
        }
    };

    useEffect(() => {
        if (dates?.length > 0 && dates[0]?.getFullYear() > 1900 && dates[1]?.getFullYear() > 1900) {
            setDatesCriteria()
        } else {
            setCriteria({...criteria, available_from_date: null, available_by_date: null})
        }
    }, [dates])

    useEffect(() => {
        setDates([
            criteria.available_from_date ? moment(criteria.available_from_date).toDate() : null,
            criteria.available_by_date ? moment(criteria.available_by_date).toDate() : null
        ])
    }, [criteria.available_by_date, criteria.available_from_date])

    return <fieldset className={props.className}>
        <div>
            <label>Date Available</label>
        </div>
        <DateRangePicker
            onChange={setDates}
            value={dates}
            className="fieldset-date--picker"
            dayPlaceholder="dd"
            monthPlaceholder="mm"
            yearPlaceholder="yyyy"
            calendarType="US"
        />
    </fieldset>
}