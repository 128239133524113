export interface ISortTableHeaderConfig {
  label: string;
  value: string;
  isAsc: boolean;
}

export const sortTableHeaderConfig: ISortTableHeaderConfig[] = [
  { label: "Availability Date (Soonest)", value: "date_available", isAsc: true },
  { label: "Cost (Low to High)", value: "rent", isAsc: true },
  { label: "Cost (High to Low)", value: "rent", isAsc: false },
  { label: "Number of Beds (Low to High)", value: "bedrooms", isAsc: true },
  { label: "Number of Beds (High to Low)", value: "bedrooms", isAsc: false },
];

export const RowsPerPage = 12;
