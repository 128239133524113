import React, {useContext, useEffect, useState} from "react";
import {ListingSearchContext} from "../../listing_search_context";
import _ from "lodash";
import "./fieldset_min_beds_arrows.scss";

export function FieldsetMinBedsArrows({className = '', onChange, disabled = false, inputProps}: {inputProps?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, className?: string, onChange?: (newBedsValue: number) => void, disabled?: boolean}) {
    const {criteria, setCriteria} = useContext(ListingSearchContext);
    const [triggerChange, setTriggerChange] = useState(false);

    const handleBedsChange = (e) => {
        if (e.target.value) {
            const val = parseInt(e.target.value);
            setCriteria({...criteria, min_bedrooms: val});
        } else {
            setCriteria({...criteria, min_bedrooms: -1});
        }
        setTriggerChange(true);
    };

    //We call onChange ONLY when the criteria was updated from this fieldset
    useEffect(() => {
        if (triggerChange) {
            onChange && onChange(criteria.min_bedrooms);
            setTriggerChange(false);
        }
    }, [criteria.min_bedrooms, triggerChange]);

    const debouncedSetTriggerChange = _.debounce(setTriggerChange, 1800);

    const onArrowChange = (arrowDirection: "up" | "down") => {
        if (disabled) {
            return;
        }

        if (arrowDirection === "up") {
            setCriteria({...criteria, min_bedrooms: criteria.min_bedrooms ? criteria.min_bedrooms + 1 : 1});
            debouncedSetTriggerChange(true);
        } else {
            if (criteria.min_bedrooms >= 0) {
                setCriteria({...criteria, min_bedrooms: criteria.min_bedrooms - 1});
                debouncedSetTriggerChange(true);
            }
        }
    };

    return <div className={`beds-arrows--container ${className || null}`}>
            <label className=""><i className="fas fa-bed"/>Beds:</label>
            <input className=""
                   onChange={handleBedsChange}
                   value={0 > criteria.min_bedrooms ? "" : criteria.min_bedrooms}
                   placeholder="Any"
                   disabled={disabled}
                   {...inputProps}
            />
            <div className="arrows-container">
                <i className="fas fa-chevron-up" onClick={() => onArrowChange("up")}/>
                <i className="fas fa-chevron-down" onClick={() => onArrowChange("down")}/>
            </div>
    </div>
}