import React, {useContext, useEffect, useState} from "react";
import {Field, Formik} from 'formik';
import Select from 'react-select';
import "./share_action.scss";
import {CheckboxGroup} from "../../../components/checkbox_group/checkbox_group";
import {RbButton} from "../../../components/rb_button/rb_button";
import {create} from "jss";
import extend from "jss-plugin-extend";
import {fetchClients, fetchListings, sendEmail} from "../action_utils";
import {ChipsInput} from "../../../components/chips_input/chips_input";
import {AgentUserContext} from "../../broker_search/agent_user_context";

export function ShareAction(props: ISendMailAction) {
    const {selectedListingIds} = props;
    const [clients, setClients] = useState([]);
    const [userInfo, setUserInfo] = useState({} as any);
    const [clientsEmails, setClientsEmails] = useState([] as string []);
    const {consumerInfo} = useContext(AgentUserContext);

    useEffect(() => {
        fetchClients().then(r => setClients(r));
        setUserInfo(consumerInfo)
    }, []);

    return <Formik
        initialValues={{
            email: '',
            send_myself_broker_version: false,
            cc_myself: false,
            report_format: 'client_full_report',
            selected_clients_emails: [],
            subject: '',
            message: '',
            emails_comma: ''
        }
        }
        onSubmit={(values, { setSubmitting }) => {
            const win = document.createElement('div');
            const styleContainer = document.createElement('div');
            styleContainer.className = 'style-container';

            win.appendChild(styleContainer);

            const jss = create();
            jss.use(extend());
            jss.setup({insertionPoint: styleContainer});

            setTimeout(() => props.onSuccess(), 500)
            setTimeout(async () => {
                fetchListings(selectedListingIds)
                    .then(async (listingsRes) => {
                        const listings = listingsRes.data.data;
                        const reportInfo = {
                            agentName: `${userInfo.first_name} ${userInfo.last_name}`,
                            agentId: userInfo.id,
                            agentEmail: userInfo.email,
                            agentContact: userInfo.phone,
                            listings: listings,
                            listingsV2: selectedListingIds,
                            is_shared: true,
                            agentBrokerLogo: userInfo?.agent_info?.broker_logo
                        }

                        const otherEmailsArr = values.emails_comma !== ""
                            ? values.emails_comma.split(",")
                            : []

                        values.selected_clients_emails = [
                            ...values.selected_clients_emails,
                            ...otherEmailsArr
                        ]

                        sendEmail(null, userInfo, values, win, reportInfo).then(async (emailRes) => {
                            if (emailRes.status === 200) {
                                // props.onSuccess()
                            }
                        })
                        setSubmitting(false)
                    })
            }, 400);
        }}>
        {formik => (
            <form onSubmit={formik.handleSubmit} className="container-fluid share-action-form">
                <h3>Email Listings</h3>
                <p className="selected-listings">Selected listings: {selectedListingIds.length}</p>
                <div className="row">
                    <div className="col">
                        <p>Select Client from List</p>
                        <Field
                            name="names"
                            children={({form})=>(
                                <Select
                                    options={clients}
                                    isMulti
                                    onChange={(vals: any) => {
                                        if (vals) {
                                            const clientsEmails = vals.map(val => val.main_email);
                                            setClientsEmails(vals);
                                            form.setFieldValue("selected_clients_emails", clientsEmails)
                                        } else {
                                            setClientsEmails([]);
                                            form.setFieldValue("selected_clients_emails", [])
                                        }
                                    }}
                                />
                            )}
                        />
                        {/*<p>and/or type emails separated by commas</p>*/}
                        {/*<Field name="email" type="text"  className="form-control"/>*/}
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <p>Or type emails</p>
                        <ChipsInput emails={formik.values.emails_comma} setFieldValue={formik.setFieldValue} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <CheckboxGroup
                            handleChange={formik.handleChange}
                            values={formik.values}
                            items={[
                                {name: "send_myself_broker_version", label: "Send a copy to myself (Cc)."},
                            ]}
                        />
                    </div>
                    <div className="col-12">
                        <label htmlFor="message">Message (optional)</label>
                        <Field name="message" type="text" as="textarea" className="form-control" rows={7}/>
                    </div>
                </div>
                <div className="row submit-row">
                    <RbButton {...{type: "submit"}} disabled={clientsEmails.length === 0 && formik.values.emails_comma === ''}>
                        Send
                    </RbButton>
                </div>
            </form>
        )}
    </Formik>
}

interface ISendMailAction {
    selectedListingIds: number [];
    onSuccess: () => void;
}