import React from "react";
import { ListingFeature } from "../../../../../services/rails_api/listing_features";

import { renderDataUi } from "./full_report_helpers/renderDataUi";
import { IComponentProps } from "./full_report_type/full_report_component_type";

interface UnitFullReport extends IComponentProps {
  featuresList?: any;
}
export const UnitFullReport: React.FC<UnitFullReport> = ({
  listing,
  featuresList,
}) => {
  const getLabels = (keysArr, featureName: ListingFeature) => {
    if (Object.keys(featuresList).length && keysArr) {
      return keysArr.map((key) => {
        return featuresList[featureName][key];
      });
    } else {
      return [];
    }
  };

  return (
    <div style={{ marginTop: 10 }}>
      <h5 style={{ fontSize: 15, fontWeight: "bold" }}>Unit</h5>
      <table
        style={{
          background: "#63a0d4",
          borderTop: "1px solid black",
          padding: "10px 20px",
          fontSize: "small",
          width: "100%",
        }}
      >
        <tr>
          <td style={{ verticalAlign: "top" }}>
            <table>
              <tr>
                <td>
                  <div style={{ width: 200 }}>Floor/Level:</div>
                </td>
                <td>
                  <span style={{ fontWeight: "bold" }}>{listing.floor}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <div style={{ width: 200 }}>Laundry:</div>{" "}
                </td>
                <td>
                  <span style={{ fontWeight: "bold" }}>{listing.laundry}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <div style={{ width: 200 }}>Heating:</div>{" "}
                </td>
                <td>
                  <span style={{ fontWeight: "bold" }}>
                    {listing.heat_type}
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <div style={{ width: 200 }}>Cooling:</div>
                </td>
                <td>
                  <span style={{ fontWeight: "bold" }}>{listing.ac_type} </span>
                </td>
              </tr>
            </table>
          </td>
          <td style={{ verticalAlign: "top" }}>
            <table style={{ marginLeft: 20 }}>
              <tr>
                <td style={{ verticalAlign: "top" }}>
                  <div style={{ width: 200 }}> Utilities Included:</div>
                </td>
                <td style={{ verticalAlign: "top" }}>
                  <div style={{ fontWeight: "bold" }}>
                    {renderDataUi(
                      getLabels(
                        listing.utilities_included,
                        ListingFeature.utility
                      )
                    )}
                  </div>
                </td>
              </tr>
              <tr>
                <td style={{ verticalAlign: "top" }}>
                  <div style={{ width: 200 }}>Unit Features:</div>
                </td>
                <td style={{ verticalAlign: "top" }}>
                  <div style={{ fontWeight: "bold" }}>
                    {renderDataUi(
                      getLabels(
                        listing.unit_features,
                        ListingFeature.unit_feature
                      )
                    )}
                  </div>
                </td>
              </tr>
              <tr>
                <td style={{ verticalAlign: "top" }}>
                  <div style={{ width: 200 }}>Rooms:</div>
                </td>
                <td style={{ verticalAlign: "top" }}>
                  <div style={{ fontWeight: "bold" }}>
                    {renderDataUi(
                      getLabels(listing.room_types, ListingFeature.room_types)
                    )}
                  </div>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </div>
  );
};
