import React, {useContext, useEffect, useState} from "react";
import {ListingSearchContext} from "../../listing_search_context";
import {Select} from "@material-ui/core";

export function FieldsetLeaseTerm() {
    const {criteria, setCriteria} = useContext(ListingSearchContext);
    const {getFilterOptions, filterConfig} = useContext(ListingSearchContext);
    const [options, setOptions] = useState([]);

    useEffect(()=>{
        setOptions(getFilterOptions("lease_term", true));
    },[filterConfig]);

    const handleChange = (e) => {
        const selected = e.currentTarget.value;
        setCriteria({...criteria, sublet_term: selected});
    };

    return (
        <fieldset className="fieldset--select-container">
            {/*Yash mentioned we should be using sublet_term key for search */}
            <label>Lease Term</label>
            <Select
                native
                value={criteria.sublet_term}
                onChange={handleChange}
            >
                <option aria-label="None" value="">Any</option>
                {
                    options.map((option) => (
                        <option value={option.value} key={option.value}>{option.label}</option>
                    ))
                }
            </Select>
        </fieldset>
    )
}