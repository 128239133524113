import React, {useEffect, useState} from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import {
    SweetAlertProps,
    SweetAlertType
} from "react-bootstrap-sweetalert/dist/components/SweetAlert";

interface ISweetAlertContext {
    setAlertContent: (newContent: string) => any;
    setAlertVisible: (newVisible: boolean) => any;
    setAlertType: (newType: SweetAlertType) => any;
    setAlertTitle?: (newTitle: string) => any;
    setSweetAlertProps?: (newProps: SweetAlertProps) => any;
    triggerAlert?: Function;
    dialogAlert?: Function;
    setCallbacks?: Function;
    callbacks?: any;
    handleCloseSweetAlert: () => void;
    showCancel?: boolean
    confirmBtnText?: string
    cancelBtnText?: string
    setConfirmBtnText?: React.Dispatch<React.SetStateAction<string>>
    setCancelBtnText?: React.Dispatch<React.SetStateAction<string>>
    setShowCancel?: React.Dispatch<React.SetStateAction<boolean>>
}

export const SweetAlertContext = React.createContext<ISweetAlertContext>(null);

export function SweetAlertsProvider (props) {

    const fallbacks = {
        "secondary": {"title": "", "content": ""},
        "info": {"title": "FYI", "content": ""},
        "success": {"title": "Success!", "content": "You are all set."},
        "warning": {"title": "Warning!", "content": ""},
        "danger": {"title": "Danger!", "content": ""},
        "error": {"title": "Oops!", "content": "Something went wrong!"},
        "input": {"title": "Input", "content": ""},
        "custom": {"title": "", "content": ""},
    };

    //Sweet Alert Context State
    const [alertVisible, setAlertVisible] = useState(false);
    const [alertType, setAlertType] = useState("success");
    const [alertTitle, setAlertTitle] = useState(fallbacks["success"].title);
    const [alertContent, setAlertContent] = useState(fallbacks["success"].content);
    const [showCancel, setShowCancel] = useState<boolean>(false)
    const [confirmBtnText, setConfirmBtnText]  = useState<string>('Ok')
    const [cancelBtnText, setCancelBtnText]  = useState<string>('Cancel')
    const [sweetAlertProps, setSweetAlertProps] = useState({} as SweetAlertProps);
    const [callbacks, setCallbacks] = useState({
        onClose: ()=>{}
    });

    const handleCloseSweetAlert = () => {
        setAlertVisible(false);
        setAlertContent("");
        callbacks.onClose(); //TODO: Rename it or use it where appropriate
    };

    useEffect(() => {
        if (!alertVisible) {
            setSweetAlertProps({title: ''} as any);
        }
    }, [alertVisible]);

    const triggerAlert = (type = "success", content, title) => {
        const fallback = fallbacks[type];
        setAlertContent(content || fallback.content);
        setAlertVisible(true);
        setAlertType(type);
        setShowCancel(false);
        setSweetAlertProps({
            title: title || fallback.title,
            onConfirm: () => {
                handleCloseSweetAlert();
            },
            onCancel: () => {
                handleCloseSweetAlert();
            },
            type: type as SweetAlertType,
        });
    };

    // new function to handle decision-prompting dialogs, like Yes/No
    const dialogAlert = (type = "warning", content, title) => {
        const fallback = fallbacks[type];
        setAlertContent(content || fallback.content);
        setAlertVisible(true);
        setAlertType(type);
        setConfirmBtnText("Yes");
        setShowCancel(true);
        setCancelBtnText("No");
        callbacks.onClose(); 
        setSweetAlertProps({
            title: title || fallback.title,
            onConfirm: () => {
                handleCloseSweetAlert();
            },
            onCancel: () => {
                handleCloseSweetAlert();
            },
            type: type as SweetAlertType,
        });
    };

    return <SweetAlertContext.Provider
        value={
            {setAlertContent,
            setAlertVisible,
            setAlertType,
            triggerAlert,
            dialogAlert,
            callbacks,
            setCallbacks,
            setSweetAlertProps,
            setAlertTitle,
            setShowCancel,
            showCancel,
            confirmBtnText,
            setConfirmBtnText,
            cancelBtnText,
            setCancelBtnText,
            handleCloseSweetAlert,
            }
        }>
        {props.children}
        <SweetAlert
            secondary={alertType === "secondary"}
            info={alertType === "info"}
            success={alertType === "success"}
            warning={alertType === "warning"}
            danger={alertType === "danger"}
            error={alertType === "error"}
            input={alertType === "input"}
            custom={alertType === "custom"}
            title={alertTitle}
            showCancel={showCancel}
            confirmBtnText={confirmBtnText}
            cancelBtnText={cancelBtnText}
            onConfirm={()=>{
                handleCloseSweetAlert();
            }}
            cancelBtnStyle={ {backgroundColor: '#ED4444', color: "white"} } //Colors taken from rb_colors.scss
            confirmBtnStyle={ {backgroundColor: '#337ab7'} } //Colors taken from rb_colors.scss
            show={alertVisible}
         
            {...sweetAlertProps}
        >
            {alertContent !== "" ? alertContent : ""}
        </SweetAlert>
    </SweetAlertContext.Provider>
}