import React, {useContext, useEffect, useState} from "react";
import "./advanced_search.scss";
import {ConsumerSearchContext} from "../consumer_search_context";
import {FilterGroup} from "../../../components/listing_search/search_filters/filter_group";
import {FiltersContext} from "../../../components/listing_search/search_filters/filters_provider";
import {Drawer} from "../../../components/drawer/drawer";
import {FieldsetClearFilters} from "../../../components/listing_search/fieldsets/clear_filters/fieldset_clear_filters";
import {isMobile} from "../../../utils/device_info";

export function AdvancedSearch({className}) {
    const {setTileSearching, setMapSearching, mapSearching, tileSearching} = useContext(ConsumerSearchContext);
    const {contWidth, colWidth, filterGroups, setFilterGroups, handleToggleAll} = useContext(FiltersContext);

    const [isOpen, setIsOpen] = useState(false);
    const [numOfCols, setNumOfCols] = useState(null);

    const toggleFilters = (isOpen) => {
        if (tileSearching || mapSearching) {
            return;
        }
        setIsOpen(!isOpen);
        if (isOpen) {
            setTileSearching(true);
            setMapSearching(true);
        }
    };

    const getTriggerClassName = (isOpen) => {
        return isOpen ? `${className} active-adv-trigger adv-trigger` : `${className} adv-trigger`;
    };

    const getTriggerText = (isOpen) => {
        return isOpen ? "Apply" : "Refine Search";
    };

    const getIcon = (isOpen) => {
        return isOpen ? "fa fa-angle-up" : "fa fa-angle-down"
    };

    const getNumOfCols = () => {
        const cols = Math.floor(contWidth / colWidth);
        return cols > 1 ? cols : "auto";
    };

    const handleSearch = () => {
        setTileSearching(true);
        setMapSearching(true);
        setIsOpen(false);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    useEffect(()=>{
        setNumOfCols(getNumOfCols());
    },[contWidth]);

    return (
        <div className="consumer-advanced-search">
            <div className={getTriggerClassName(isOpen)} id="advSearchTrigger" onClick={()=> {toggleFilters(isOpen)}}>
                {getTriggerText(isOpen)}
                <i className={getIcon(isOpen)}/>
            </div>
            <Drawer open={isOpen} width={80} handleClose={handleClose}>
                <div className="filter-header">
                    <div className="left-cont">
                        <div className="expand-control" onClick={()=>handleToggleAll(true)}>Expand</div>
                        <div className="expand-control" onClick={()=>handleToggleAll(false)}>Collapse</div>
                    </div>
                    <div className="center-cont">
                        <div className="advanced-search-btn" onClick={handleSearch}>
                            <span style={{display: isMobile() ? "none" : ""}} className="search-text" >SEARCH</span>
                            <i className="fas fa-search"/>
                        </div>
                        <FieldsetClearFilters className="clear" />
                    </div>
                    <div className="right-cont">
                        <div className="filters-close-icon" onClick={handleClose}>Close <i className="far fa-window-close" /></div>
                    </div>
                </div>
                <section className="advanced-filters" id="cabinet-container">
                    <div className="column" style={{columnCount: numOfCols}} >
                        {
                            filterGroups.map((filterData, index) => {
                                return <FilterGroup filterData={filterData}
                                                    filterGroups={filterGroups}
                                                    setFilterGroups={setFilterGroups}
                                                    groupIndex={index}
                                                    key={`filter_group_${index}`} />
                            })
                        }
                    </div>
                </section>
            </Drawer>
        </div>
    )
}