import React from "react";
import SvgIcon from "../../../../../../assets/icons/common/SvgIcon";
import { ChatBubbleIcon } from "../../../../../../assets/icons/ChatBubbleIcon";
import { IListing } from "../../../../../../services/api/listing";

interface IActiveRentPins {
    rent: number;
    aptCount?: number;
    hoveredListingId: number | null;
    listingId: number;
    hoverListingAction: (listingId : number) => void;
    isSingleCheck: boolean;
    activeTooltipId: number;
    onActiveTooltipId: (val: number) => void | null;
    isMlsListing?: boolean;
    isMlsAgent?: () => boolean;
    onOpen?: () => void | null;
    isBubbleVisible: boolean;
    removeExcludeDeleteModal: () => void;
    coords: { lat: number, lng: number };
    onCenterFocus?: (coords?: { lat: number, lng: number }) => void | null;
    onActiveModalId?: (listingId?: number) => void | null;
    onSetCurrentPage?: (pageNumber: number) => void | null;
    groupedListings?: any[];
    onIndividualListing?: (individualListing : IListing) => void;
    listing?: IListing;
    rentChanges?: any
}
export const ActiveRentPins: React.FC<IActiveRentPins> = ({
    rent,
    aptCount,
    hoveredListingId,
    listingId,
    hoverListingAction,
    isSingleCheck,
    onActiveTooltipId,
    isMlsListing,
    isMlsAgent,
    onOpen,
    isBubbleVisible,
    removeExcludeDeleteModal,
    activeTooltipId,
    coords,
    onCenterFocus,
    onActiveModalId,
    onSetCurrentPage,
    onIndividualListing,
    listing,
    rentChanges
}) => {
    const getRentChange = () => {
        const rentChangeVaue = ((rentChanges || [])[0] || {})
        const rentChangeAbsolute = rentChangeVaue?.absolute > 0
        let rentChange: any = null
        const arrowStyle: any = {
            fontWeight: 'bold',
            fontSize: '140%',
            margin: "-12px 0px 0px 18px",
            position: 'absolute',
            color: 'white'
        }

        if (rentChangeVaue?.absolute) {
            if (rentChangeAbsolute) rentChange = <span style={arrowStyle}>&uarr;</span>
            else rentChange = <span style={arrowStyle}>&darr;</span>
        }

        return listing.apt_count > 1 ? null : rentChange
    }

    const handleClickMarker = (e) => {
        e.stopPropagation()
        e.preventDefault()

        removeExcludeDeleteModal()
        if (isSingleCheck && (isMlsAgent() && !isMlsListing)) {
            onOpen()
            return
        }

        if (!isSingleCheck) {
            onCenterFocus(coords)
            onActiveTooltipId(0)
            onActiveModalId(listingId)
            onSetCurrentPage(1)
        } else {
            onIndividualListing(listing);
        }
    }

    return (
        <div
            className={`map-active-pin-container`}
            style={{
                zIndex: hoveredListingId === listingId ? 6 : 'unset',
            }}
            onClick={handleClickMarker}
        >
            <div
                className='bubble-container'
                style={{visibility: (
                    isBubbleVisible === true &&
                    (hoveredListingId !== listingId || activeTooltipId !== hoveredListingId)
                ) ? "visible" : "hidden"}}
                onMouseEnter={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    hoverListingAction(listingId)
                    onActiveTooltipId(listingId)
                }}
            >
                <SvgIcon
                    width={"70"}
                    height={"30"}
                    className={`${
                        hoveredListingId === listingId
                            ? "pin-hover"
                            : "map-active-pin"
                    }`}
                >
                    <ChatBubbleIcon />
                </SvgIcon>
                <span>{`${
                    aptCount > 1 ? `${aptCount} units` : `$ ${rent}`
                }`}{getRentChange()}</span>
            </div>

            {isMlsListing ? (
                <div
                    className={`mls-map-pin${hoveredListingId === listingId ? '-hover' : '_active'} active-pin ${hoveredListingId === listingId ? 'pin-hover' : ''}`}
                    onMouseEnter={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        hoverListingAction(listingId)
                        onActiveTooltipId(listingId)
                    }}
                    onClick={handleClickMarker}
                    onTouchStart={handleClickMarker}
                />
            ) : (
                <div
                    className={`map-pin active-pin ${
                        hoveredListingId === listingId ? "pin-hover" : ""
                    }`}
                    onMouseEnter={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        hoverListingAction(listingId)
                        onActiveTooltipId(listingId)
                    }}
                    onClick={handleClickMarker}
                    onTouchStart={handleClickMarker}
                />
            )}
        </div>
    );
};
