import React from "react";
import { IComponentProps } from "./full_report_type/full_report_component_type";

export const OtherInfoFullReport: React.FC<IComponentProps> = ({ listing }) => {
  const totalMoveInCost = (move_in_costs: any = []) => {
    let costArray = [];
    let totalCost = 0;
    if (move_in_costs.length === 0) {
      return totalCost;
    }
    move_in_costs.map((expense) => {
      for (let key in expense) {
        costArray.push(expense[key]);
      }
    });
    costArray.map((cost) => {
      totalCost += cost;
    });
    return `$ ${totalCost}`;
  };
  return (
    <div style={{ marginTop: 10 }}>
      <h5 style={{ fontSize: 15, fontWeight: "bold" }}>Other Info</h5>
      <table
        style={{
          background: "#63a0d4",
          borderTop: "1px solid black",
          padding: "10px 20px",
          fontSize: "small",
          width: "100%",
        }}
      >
        <tr>
          <td>
            <table>
              <tr>
                <td style={{ verticalAlign: "top", width: 200 }}>
                  <div>Move-in Costs:</div>
                </td>
                <td style={{ verticalAlign: "top" }} colSpan={3}>
                  <span style={{ fontWeight: "bold" }}>
                    {totalMoveInCost(listing.move_in_costs)}
                  </span>
                </td>
              </tr>
              <tr>
                <td style={{ verticalAlign: "top", width: 200 }} colSpan={1}>
                  <div style={{ width: 200 }}>Lease Term:</div>{" "}
                </td>
                <td style={{ verticalAlign: "top" }} colSpan={3}>
                  <span style={{ fontWeight: "bold" }}>{listing.sublet}</span>
                </td>
              </tr>
              <tr>
                <td style={{ verticalAlign: "top", width: 200 }} colSpan={1}>
                  <div style={{ width: 200 }}>Furnished:</div>{" "}
                </td>
                <td style={{ verticalAlign: "top" }} colSpan={3}>
                  <span style={{ fontWeight: "bold" }}>
                    {listing.furnished}
                  </span>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </div>
  );
};
