import React, {useEffect, useState} from 'react';
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {landlordClientSearchAutocompleteApi} from "../../../services/rails_api/broker_portal/landlord_client_search_autocomplete";
import _ from "lodash";

interface IClientAutoCompleteProps {
    handleChange: (val) => void;
    placeHolder?: string;
    inputValue: string;
    setInputValue: (val) => void;
}

export function ClientAutoComplete (props: IClientAutoCompleteProps) {
    const {handleChange, placeHolder = '', inputValue, setInputValue} = props;
    const [options, setOptions] = useState([]);
    const [flag, setFlag] = useState(true);

    const debounced = _.debounce(updateOptions, 300);
    function updateOptions(getData, value) {
        getData(value);
    }

    const handleInputChange = (value = inputValue) => {
        if(value !== ''){
            debounced(getData, value);
        }
    };

    const handleValueChange = (e, newValue) => {
        handleChange(newValue)
    };

    const getData = async (inputString) => {
        const autocompleteRes = await landlordClientSearchAutocompleteApi.get(inputString);
        if (autocompleteRes.length === 0){
            return setFlag(false);
        }
        else {
            return setOptions(autocompleteRes);
        }
    };

    useEffect(() => {
        handleInputChange();
    }, [inputValue]);

    return <Autocomplete
        freeSolo
        id="search"
        options={options}
        getOptionLabel={option => option.label}
        style={{ width: '100%' }}
        noOptionsText={ flag ? 'Start typing to find matches' : 'No matches found' }
        onChange={handleValueChange}
        inputValue={inputValue}
        onInputChange={
            (event, newInputValue) => {setInputValue(newInputValue)}
        }
        renderInput={
            params => <TextField {...params} variant="outlined" placeholder={placeHolder} value={inputValue} />
        }
    />
}