import React from "react";
import { IComponentProps } from "./full_report_type/full_report_component_type";

export const BasicFullReport: React.FC<IComponentProps> = ({ listing }) => {
  const getNeighborhoodAndBuildingName = (listing) => {
    const buildingName =
      listing.building_name && "" !== listing.building_name
        ? listing.building_name
        : null;
    const neighborhood =
      listing.neighborhood && "" !== listing.neighborhood
        ? listing.neighborhood
        : null;

    if (!buildingName && !neighborhood) {
      return "";
    }
    return neighborhood && buildingName
      ? `${buildingName}, ${neighborhood}`
      : buildingName || neighborhood;
  };
  return (
    <>
      <h5 style={{ fontSize: 15, fontWeight: "bold" }}>Basic</h5>
      <table
        style={{
          background: "#ececec",
          borderTop: "1px solid black",
          padding: "10px 20px",
          fontSize: "small",
          width: "100%",
        }}
      >
        <tr>
          <td>
            <table>
              <tbody>
                <tr>
                  <td>
                    <div>RB ID:</div>{" "}
                  </td>
                  <td>
                    <span style={{ fontWeight: "bold" }}>
                      {listing.apartment_id}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div style={{ width: 200 }}>Rent:</div>{" "}
                  </td>
                  <td>
                    <span style={{ fontWeight: "bold" }}>
                      {" "}
                      $ {listing.rent}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div style={{ width: 200 }}>Address:</div>{" "}
                  </td>
                  <td>
                    <span style={{ fontWeight: "bold" }}>
                      {listing.address} <br />
                      {getNeighborhoodAndBuildingName(listing)}
                      <br />
                      {listing.city}, {listing.state} {listing.zip}{" "}
                      {listing.county}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
          <td>
            <table style={{ marginLeft: 20 }}>
              <tbody>
                <tr>
                  <td>
                    <div>Date Available:</div>{" "}
                  </td>
                  <td>
                    <span style={{ fontWeight: "bold" }}>
                      {listing.date_available}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div style={{ width: 200 }}>Square Footage:</div>{" "}
                  </td>
                  <td>
                    <span style={{ fontWeight: "bold" }}>
                      {listing.square_footage}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div style={{ width: 200 }}>Bedrooms:</div>{" "}
                  </td>
                  <td>
                    <span style={{ fontWeight: "bold" }}>
                      {listing.bedrooms}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div style={{ width: 200 }}>Full Bathrooms:</div>{" "}
                  </td>
                  <td>
                    <span style={{ fontWeight: "bold" }}>
                      {listing.full_bathrooms}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div style={{ width: 200 }}>Half Bathrooms:</div>{" "}
                  </td>
                  <td>
                    <span style={{ fontWeight: "bold" }}>
                      {listing.half_bathrooms}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </table>
    </>
  );
};
