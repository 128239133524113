import  i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import Backend from "i18next-http-backend";
import XHR from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

const options = {
    order: ['querystring',  'navigator'],
    lookupLocalStorage: 'lng'
  }
i18n
.use(XHR)
.use (LanguageDetector)
.use (Backend)
.use (initReactI18next)
.init({
    fallbackLng: 'en-US',
    detection: options,
    debug: true,
    interpolation: {
        escapeValue: false
    },
    react: {
        useSuspense: false
    }
});

export default i18n;
