import React, {useContext} from "react";
import {apiURL} from "../../../../../../services/constants";
import {IListing} from "../../../../../../services/api/listing";
import {TableCell, TableRow} from "@material-ui/core";
import {SelectedListingsContext} from "../../../selected_listings_context";
import {AgentUserContext} from "../../../agent_user_context";
import "../../detail_search_results/listing_list_view/listing_list_view.scss"
import {getStateAbbr} from "../../../../../utils/get_state_abbr";
import {RBCheckbox} from "../../../../../components/rb_checkbox/rb_checkbox";
import {BookmarksContext} from "../../../bookmarks_context";

export function BookmarksListView(props: IBookmarksListView) {
    const {isFullAgent, countryCode} = useContext(AgentUserContext);
    const {handleSelectedListing, isMlsAgentAndNonMlsListing, isGatewayAccessibleListing } = useContext(SelectedListingsContext);
    const {toggleBookmarks} = useContext(BookmarksContext);
    const {listing} = props;

    const handleDialogOpen = () => {
        if (isFullAgent() || isGatewayAccessibleListing(listing)) {
            const url = `/broker_portal/listing_details/${listing.apartment_id}`;
            window.open(url, '_blank');
        }
    };

    const handleRowCheck = () => {
        handleSelectedListing(listing.apartment_id, !props.checked, listing.apartment_id, props.bookmarks);
    };

    const showOrHideAddress = (listing) => {
        if (isMlsAgentAndNonMlsListing(listing)) {
            return <TableCell align="left">
                <div>
                    <span><a href="/agent/subscriptions_and_payment">Upgrade</a> to see address, </span>
                    <span>{listing.city}, {getStateAbbr(listing.state, countryCode)} {listing.zip}</span>
                </div>
            </TableCell>
        }
        else {
            return <TableCell align="left">
                <div>
                    <span>{listing.address}, </span>
                    <span>{listing.city}, {getStateAbbr(listing.state, countryCode)} {listing.zip}</span>
                </div>
            </TableCell>
        }
    };

    return <React.Fragment>
        <TableRow key={listing.apartment_id} className="detail--row container-fluid zebra-stripes" onClick={handleDialogOpen}>
            <TableCell align="left" className="bookmark-flag-cell">
                <i  onClick={(e) => toggleBookmarks(e, [listing.apartment_id])} className="fas fa-bookmark floorplan__title--bookmark"/>
            </TableCell>
            <TableCell align="left">
                {
                    (isMlsAgentAndNonMlsListing(listing)) ? null : <RBCheckbox name={listing.apartment_id.toString()} checked={props.checked} handleChange={(changed) => handleRowCheck()}/>
                }
            </TableCell>
            <TableCell align="left">{listing.status}</TableCell>
            <TableCell align="left">{listing.date_available}</TableCell>
            { showOrHideAddress(listing) }
            <TableCell align="left">{listing.square_footage}</TableCell>
            <TableCell align="left">{listing.beds_count}</TableCell>
            {/*<TableCell align="left">{listing.min_baths === listing.max_baths ? listing.min_baths : `${listing.min_baths} - ${listing.max_baths}`}</TableCell>*/}
            <TableCell align="left">{listing.bathrooms}</TableCell>
            <TableCell align="left">{listing.rent}</TableCell>
        </TableRow>
    </React.Fragment>
}

export interface IBookmarksListView {
    listing: IListing,
    bookmarks: any,
    checked: boolean;
}