import React, {useContext, useEffect, useState} from "react";
import "../fieldsets.scss";
import {FieldsetMultipleSelect} from "../multiple_select/fieldset_multiple_select";
import {ListingSearchContext} from "../../listing_search_context";

export function FieldsetHeatType() {
    const {getFilterOptions, filterConfig, criteria} = useContext(ListingSearchContext);
    const [options, setOptions] = useState([]);
    const [optionArr, setOptionArr] = useState(criteria.heat_type);

    useEffect(()=>{
        setOptions(getFilterOptions("heat_type", true));
    },[filterConfig]);

    useEffect(()=>{
        setOptionArr(criteria.heat_type_list);
    }, [criteria.heat_type_list]);

    return <FieldsetMultipleSelect
        options={options}
        optionArr={optionArr}
        setOptionArr={setOptionArr}
        title="Heat Type"
        criteriaFieldName="heat_type_list"
    />
}