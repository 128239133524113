import {REST} from "../rest";
import { rbApiUrl } from "../constants";

export const searchAutocompleteApi = {
  /**
   * @param locationStr string inputted by user
   * @param partnerKey string agent's partner key
   * @param includeStatuses (optional) comma separated string of Status Types ie ("Active,Rented,Application Pending")
   * @param includeMatches comma separated list of fields to match query to. If not passed it defaults to "state,metro,city,neighborhood,sub_neighborhood,zip"
   */
  get : async (locationStr:string, partnerKey:string, includeStatuses?: string, includeMatches: string = "state,metro,city,neighborhood,sub_neighborhood,neighborhood_override,zip") => {
    return await REST.get(`/api/search_auto_complete`,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        params: {
          partner_key: partnerKey,
          include_matches: includeMatches,
          match_string: locationStr,
          count_per_criteria: 5,
          include_statuses: includeStatuses || 'Active',
        }
      }).then(res => {
        return res.data.data;
    });
  }
};

export const searchAutocompleteApiV2 = {
  /**
   * @param locationStr string inputted by user
   * @param partnerKey string agent's partner key
   * @param includeStatuses (optional) comma separated string of Status Types ie ("Active,Rented,Application Pending")
   * @param includeMatches comma separated list of fields to match query to. If not passed it defaults to "state,metro,city,neighborhood,sub_neighborhood,zip"
   * @param agent_id integer agent's id
   */
  get : async (agent_id: any, locationStr:string, partnerKey:string, includeStatuses?: string, includeMatches: string = "state,metro,city,neighborhood,sub_neighborhood,neighborhood_override,zip") => {
    return await REST.get(
      `${rbApiUrl}/v2/completion`,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        params: {
          match_string: locationStr,
          include_matches: includeMatches,
          count_per_criteria: 5,
          partner_key: partnerKey,
          include_statuses: includeStatuses || 'Active',
          agent_id
        }
      }).then(res => {
        return res.data.data;
    });
  }
};