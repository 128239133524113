import React, {useEffect} from "react";
import "./styles/results.scss";
import {ListingTile} from "./listing_tile";
import {MobileToolbar} from "./toolbar_mobile";
import {SearchingIcon} from "./searching_icon";
import {getResultId, IResult} from "../../components/listing_search/utilities";

export function ResultsColumnMobile({tileResults}: {tileResults: IResult[]}) {

    useEffect(() => {
    }, []);

    return (
        <section className="results-column-wrapper-mobile results-column">
            <MobileToolbar/>
            <section className="scroller">
                <SearchingIcon />
                {
                    tileResults.map((result, index) => {
                        const resultId = getResultId(result);

                        return <ListingTile key={resultId} contextListings={result.listings} listings={result.listings} />
                    })
                }
            </section>
        </section>
    )
}