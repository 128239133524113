import React, {useContext, useEffect, useState} from "react";
import { Field, Formik } from "formik";
import Select from "react-select";
import { imgUrl } from "../../../../services/constants";
import "./send_mail_action.scss";
import { CheckboxGroup } from "../../../components/checkbox_group/checkbox_group";
import { RbButton } from "../../../components/rb_button/rb_button";
import { FullReport } from "../reports/full_report/full_report";
import { create } from "jss";
import extend from "jss-plugin-extend";
import { QuickReport } from "../reports/quick_report/quick_report";
import { ClientPhotoReport } from "../reports/client_photo_report/client_photo_report";
import {
  fetchClients,
  fetchListings,
  sendEmail,
} from "../action_utils";
import { ChipsInput } from "../../../components/chips_input/chips_input";
import { listingFeaturesApi } from "../../../../services/rails_api/listing_features";
import {AgentUserContext} from "../../broker_search/agent_user_context";
import {LandlordUserContext} from "../../landlord_portal/landlord_user_context";
import {isBrokerPortal, isLandlordPortal} from "../../listing_management/utils";

const reportsMap = {
  client_full_report: FullReport,
  client_quick_report: QuickReport,
  client_photo_report: ClientPhotoReport,
};

export function SendMailAction(props: ISendMailAction) {
  const { selectedListingIds } = props;
  const [clients, setClients] = useState([]);
  const [userInfo, setUserInfo] = useState({} as any);
  const [featuresList, setFeaturesList] = useState({});
  const [clientsEmails, setClientsEmails] = useState([] as string[]);
  const [isLoading, setIsLoading] = useState(false);
  const {consumerInfo} = isBrokerPortal() && useContext(AgentUserContext);
  const {landLordInfo} = isLandlordPortal() && useContext(LandlordUserContext);

  useEffect(() => {
    fetchClients().then((r) => setClients(r));
    setUserInfo(consumerInfo || landLordInfo);
  }, []);

  useEffect(() => {
    console.log(clientsEmails);
  }, [clientsEmails]);

  const getFeaturesList = async () => {
    setIsLoading(true);
    try {
      const listingsFeaturesRes = await listingFeaturesApi.getList();
      console.log("listingsFeaturesRes", listingsFeaturesRes);

      setFeaturesList(listingsFeaturesRes);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("error listingFeaturesApi.getLisT()", error);
    }
  };

  useEffect(() => {
    getFeaturesList();
  }, []);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        email: "",
        send_myself_broker_version: false,
        cc_myself: Boolean(props.ccDefault),
        report_format: "client_full_report",
        selected_clients_emails: [],
        subject: "",
        message: "",
        emails_comma: "",
      }}
      onSubmit={(values, { setSubmitting }) => {
        const win = document.createElement("div");
        const styleContainer = document.createElement("div");
        styleContainer.className = "style-container";

        win.appendChild(styleContainer);

        const jss = create();
        jss.use(extend());
        jss.setup({ insertionPoint: styleContainer });

        if (!isLoading) {
          setTimeout(() => props.onSuccess(), 500)
          setTimeout(async () => {
            fetchListings(selectedListingIds)
              .then(async (listingsRes) => {
                const listings = listingsRes.data.data;
                const reportInfo = {
                  agentName: `${userInfo.first_name} ${userInfo.last_name}`,
                  agentId: userInfo.id,
                  agentEmail: userInfo.email,
                  agentContact: userInfo.phone,
                  agentBrokerLogo: userInfo?.agent_info?.broker_logo || "",
                  disclaimer: userInfo?.agent_info.signature_info,
                  listings: listings,
                  listingsV2: selectedListingIds,
                  featuresList: featuresList,
                  imgUrl,
                  jss,
                };

                console.log("reportInfo", reportInfo);
                const el = React.createElement(
                  reportsMap[values.report_format],
                  reportInfo
                );
                const otherEmailsArr =
                  values.emails_comma !== ""
                    ? values.emails_comma.split(",")
                    : [];
                console.log(otherEmailsArr);
                console.log(values.selected_clients_emails);
                values.selected_clients_emails = [
                  ...values.selected_clients_emails,
                  ...otherEmailsArr,
                ];
                sendEmail(el, userInfo, values, win, reportInfo).then(async (emailRes) => {
                  if (emailRes.status === 200) {
                    // props.onSuccess();
                  }
                });
                setSubmitting(false);
              })
              .catch((err) => console.log("err", err));
          }, 1000);
        }
      }}
    >
      {(formik) => (
        <form
          onSubmit={formik.handleSubmit}
          className="container-fluid email-listings--container"
        >
          <h4>Email Listings</h4>
          <p className="selected-listings">
            Selected listings: {selectedListingIds.length}
          </p>
          <div className="row">
            <div className="col-2">
              <label htmlFor="from">From</label>
            </div>
            <div className="col">
              <div>{userInfo.first_name}</div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-2">
              <label htmlFor="to">To Clients</label>
            </div>
            <div className="col">
              <Field
                name="names"
                children={({ form }) => (
                  <Select
                    options={clients}
                    isMulti
                    onChange={(vals: any) => {
                      if (vals) {
                        const clientsEmails = vals.map((val) => val.main_email);
                        setClientsEmails(vals);
                        form.setFieldValue(
                          "selected_clients_emails",
                          clientsEmails
                        );
                      } else {
                        setClientsEmails([]);
                        form.setFieldValue("selected_clients_emails", []);
                      }
                    }}
                    placeholder={"Select Clients"}
                  />
                )}
              />
              {/*<p>and/or type emails separated by commas</p>*/}
              {/*<Field name="email" type="text"  className="form-control"/>*/}
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-2">
              <label htmlFor="to">To Emails</label>
            </div>
            <div className="col">
              {/*<Field name="emails_comma" type="text"  className="form-control" placeholder={"Enter comma separated emails"}/>*/}
              <ChipsInput
                emails={formik.values.emails_comma}
                setFieldValue={formik.setFieldValue}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-2">
              <label htmlFor="report_format">Format</label>
            </div>
            <div className="col">
              <Field name="report_format" as="select" className="form-control">
                <option value="client_full_report">Client Full Report</option>
                {/* <option value="client_photo_report">Client Photo Report</option> */}
              </Field>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-2">
              <label htmlFor="subject">Subject</label>
            </div>
            <div className="col">
              <Field name="subject" type="text" className="form-control" />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-2">
              <label htmlFor="message">Message</label>
            </div>
            <div className="col">
              <Field
                name="message"
                type="text"
                as="textarea"
                className="form-control"
                rows={7}
              />
            </div>
          </div>
          <div className="row">
            <div className="col offset-md-2 offset-12">
              <CheckboxGroup
                handleChange={formik.handleChange}
                values={formik.values}
                items={[
                  {
                    name: "send_myself_broker_version",
                    label: "Send myself a broker version of the report",
                  },
                  {
                    name: "cc_myself",
                    label: "CC myself on client email & report",
                  },
                ]}
                className="mail-checkbox-group"
              />
            </div>
          </div>
          <div className="row submit-row">
            <RbButton
              onClick={formik.handleSubmit}
              disabled={
                clientsEmails.length === 0 && formik.values.emails_comma === ""
              }
            >
              Send
            </RbButton>
          </div>
        </form>
      )}
    </Formik>
  );
}

interface ISendMailAction {
  selectedListingIds: number[];
  onSuccess: () => void;
  ccDefault?: boolean;
}
