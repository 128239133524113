import React from "react";
import {IReportInfo} from "../full_report/full_report";
import {QuickReportDescription, ReportHeader, reportStyles} from "../report";
import {imgUrl} from "../../../../../services/constants";

export function ClientPhotoReport (props: IReportInfo) {
    const {agentName, agentEmail, listings, disclaimer, agentId} = props;

    const {classes} = props.jss.createStyleSheet(reportStyles).attach();
    return <div className={classes.container}>
        <ReportHeader {...{agentEmail, agentName, jss: props.jss}}/>
        {
            listings.map(listing => {
                return  <div key={listing.apartment_id}>
                        <QuickReportDescription {...{jss: props.jss, listing, agentId}}/>
                        <div>
                            {
                                listing.pic_count > 0 ?
                                    listing.pics.map(pic => {
                                        return <img className={classes.listPics} src={`${imgUrl}/${pic.path}?w=200`} alt=""/>
                                    })
                                    : null
                            }
                        </div>
                    </div>
            })
        }
        <div>
            {disclaimer}
        </div>
    </div>
}