import React, {useEffect, useState} from 'react';
import {IListing} from "../../../../services/api/listing";
import {IAgentInfoRes} from "../../../../services/api/agent_info";
import {Jss} from "jss";
import {imgUrl} from "../../../../services/constants";
import moment from "moment";
import capitalize from "@material-ui/core/utils/capitalize";
import {getCompanyTitle} from "../../../../services/session";
import {listingStatusTrackerApi} from "../../../../services/rails_api/broker_portal/listing_status_tracker";
import {getFormattedDate} from "../../../components/listing_search/utilities";
import { useTranslation } from 'react-i18next';


export function ListingsPrint(props: IPrintListingsAction) {
    const {selectedListings, agentInfo} = props;

    const {classes} = props.jss.createStyleSheet(printStyles).attach();
    const { t } = useTranslation( 'translation',{useSuspense: false});
    return <div className={classes.pdfListingsContainer}>
        {
            selectedListings.map((listing) => {
                return <PrintListing listing={listing}
                                     classes={classes}
                                     agentInfo={agentInfo}

                />
            })
        }
    </div>
}

function PrintListing(props) {
    const {listing, classes, agentInfo} = props;
    const { t } = useTranslation( 'translation',{useSuspense: false});

    const [leaseDate, setLeaseDate] = useState(null)

    const company_title = getCompanyTitle(); //in support of RB-602
    const getPictureUrl = () => {
        const picPath = agentInfo?.broker_logo
        if (picPath) {
            return `${imgUrl}/home/rb-pictures${picPath}`
        }
        else {
            return `${imgUrl}/partners/rb-agent-profile.png`
        }
    }
    const moveInCosts = (costs) => {
        let str = ''
        costs.map(obj => {
            if(str.length > 0) {
                str = str + ', '
            }
            for ( let key in obj ) {
                str = str + `${capitalize(key.replace(/[^a-zA-Z ]/g, " "))}: ${obj[key]}`

            }
        })
        return str
    }

    const fetchLeastDate = async (listing) => {
        const res = await listingStatusTrackerApi.get({listing_id: listing.apartment_id})
        const date = new Date(`${res.lease_date} 00:00`).toLocaleDateString('en-US')
        if(date !== 'Invalid Date'){
            setLeaseDate(date)
        }
    }

    useEffect(() => {
        fetchLeastDate(listing)
    }, [listing]);

    return <>
        <div className={classes.imagesRow}>
            <div className={classes.listingImageRow}>
                <div className={classes.halfRowContent}>
                    {(listing.pic_count > 0  && listing.pics.length > 0) ? <img className={classes.listingImage} src={imgUrl+'/'+listing.pics[0].path} alt=""/> : <img className={classes.listingImage} src={imgUrl+"/no-listing.rbm"} alt=""/>}
                </div>
            </div>
            <div className={classes.agentCard}>
                <div className={classes.agentCardImg}>
                    <img src={getPictureUrl()} alt="" width={180}/>
                </div>
                <div className={classes.agentCardDetails}>
                    <span style={{fontWeight: "bold", display: "block"}}>{agentInfo?.full_name}</span>
                    <span style={{fontWeight: "bold", display: "block"}}>{agentInfo?.brokerage_name}</span>
                    <span style={{fontWeight: "bold", display: "block"}}>{agentInfo?.office_phone}</span>
                    <span style={{fontWeight: "bold", display: "block"}}>{agentInfo?.email}</span>
                </div>
            </div>
        </div>

        <div className={classes.row}>
            <div className={classes.boxTitle}>
                {listing.address}{listing.apt_number.length >0 ? " Unit "+ listing.apt_number : ""}
            </div>
        </div>

        <div className={classes.listingBrief}>
            <div style={{"fontWeight": "bold"}}>
                <span style={{display: "block"}}>{listing.address}{listing.apt_number.length >0 ? " Unit "+ listing.apt_number : ""}</span>
                <span style={{display: "block"}}>{listing?.apt_count}</span>
                <span style={{display: "block"}}>{listing?.city}</span>
                {/*<span>{getStateAbbr(listing?.state)}</span>*/}
                <span style={{display: "block"}}>{listing?.zip}</span>
            </div>
            <div>
                <span style={{display: "block"}}>${listing.rent}</span>
                <span style={{display: "block"}}>
                                    {`${listing.bedrooms === "0" ? "Studio" : (listing.bedrooms === "1" ? " 1 Bedroom" : listing.bedrooms + " Bedrooms") }`}
                                </span>
                <span style={{display: "block"}}>{listing.bathrooms} Bathrooms</span>
                <span style={{display: "block"}}>
                                    Available: {moment(listing.date_available).format('MM/DD/YYYY')}
                                </span>
            </div>
            <div>
                {
                    listing.mls_id ? <span style={{display: "block"}}>{t('MLS Listing ID')}: {listing.mls_id}</span> : null
                }
                <span style={{display: "block"}}>Rental Beast ID: {listing.apartment_id}</span>
                <span style={{display: "block"}}>Status: {listing.status}</span>
            </div>
        </div>

        <div className={classes.row}>
            <div className={classes.boxTitle}>
                Listing
            </div>
        </div>

        <div className={classes.listingRow}>
            <div className={classes.halfRow}>
                <div className={classes.halfRowContent}>
                    <label className={classes.halfRowContentLabel}>Rent:</label>
                    <span style={{"fontWeight": "bold"}}>${listing.rent}</span>
                </div>
                <div className={classes.halfRowContent}>
                    <label className={classes.halfRowContentLabel}>Move-in Costs:</label>
                    <span style={{"fontWeight": "bold"}}>{moveInCosts(listing?.move_in_costs)}</span>
                </div>
                <div className={classes.halfRowContent}>
                    {
                      (listing.status === "Rented") ?
                        <label className={classes.halfRowContentLabel}>Leased Term:</label> :
                        <label className={classes.halfRowContentLabel}>Lease Term:</label>
                    }
                    <span style={{"fontWeight": "bold"}}>{listing.sublet} {listing.lease_term_details}</span>

                </div>
                <div className={classes.halfRowContent}>
                    {
                      (listing.status === "Active") ?
                      <>
                          <label className={classes.halfRowContentLabel}>Date Available:</label>
                          <span style={{"fontWeight": "bold"}}>{moment(listing.date_available).format('MM/DD/YYYY')}</span>
                      </> :
                      <>
                          {
                            (listing.status === "Rented") ?
                              <label className={classes.halfRowContentLabel}>Leased Date:</label> :
                              <label className={classes.halfRowContentLabel}>Lease Date:</label>
                          }
                          <span style={{"fontWeight": "bold"}}>{leaseDate}</span>
                      </>
                    }
                </div>
            </div>
            <div className={classes.halfRow}>
                <div className={classes.halfRowContent}>
                    <label className={classes.halfRowContentLabel}>Service Level:</label>
                    <span style={{"fontWeight": "bold"}}>{listing?.service_level}</span>
                </div>
                <div className={classes.halfRowContent}>
                    <label className={classes.halfRowContentLabel}>Renter Insurance:</label>
                    <span style={{"fontWeight": "bold"}}>{listing.renter_insurance}</span>
                </div>
                <div className={classes.halfRowContent}>
                    <label className={classes.halfRowContentLabel}>{t('MLS Entry Date')}:</label>
                    <span style={{"fontWeight": "bold"}}>{moment(listing.mls_entry_date).format('MM/DD/YY')}</span>
                </div>
            </div>
        </div>

        <div className={classes.row}>
            <div className={classes.boxTitle}>
                Property
            </div>
        </div>

        <div className={classes.listingRow}>
            <div className={classes.halfRow}>
                <div className={classes.halfRowContent}>
                    <label className={classes.boxSubTitle}>Info</label>
                </div>
                <div className={classes.halfRowContent}>
                    <label className={classes.halfRowContentLabel}>Property Features:</label>
                    <span style={{"fontWeight": "bold"}}>
                                        {listing.property_features.join().replace(/[^a-zA-Z, ]/g, " ")}
                                    </span>
                </div>
                <div className={classes.halfRowContent}>
                    <label className={classes.halfRowContentLabel}>Parking Options:</label>
                    <span style={{"fontWeight": "bold"}}>{listing?.parking_options}</span>
                </div>
            </div>
            <div className={classes.halfRow}>
                <div className={classes.halfRowContent}>
                    <label className={classes.halfRowContentLabel}>Other Parking:</label>
                    <span style={{"fontWeight": "bold"}}>{listing?.other_parking}</span>
                </div>
                <div className={classes.halfRowContent}>
                    <label className={classes.halfRowContentLabel}>Parking Fee:</label>
                    <span style={{"fontWeight": "bold"}}>{listing.parking_fee}</span>
                </div>
            </div>
        </div>

        <div className={classes.listingRowSeq}>
            <div className={classes.halfRow}>
                <div className={classes.halfRowContent}>
                    <label className={classes.boxSubTitle}>School</label>
                </div>
                <div className={classes.halfRowContent}>
                    <label className={classes.halfRowContentLabel}>Middle School:</label>
                    <span style={{"fontWeight": "bold"}}>{listing.middle_school}</span>
                </div>
                <div className={classes.halfRowContent}>
                    <label className={classes.halfRowContentLabel}>High School:</label>
                    <span style={{"fontWeight": "bold"}}>{listing.high_school}</span>
                </div>
            </div>
            <div className={classes.halfRow}>
                <div className={classes.halfRowContent}>
                    <label className={classes.halfRowContentLabel}>Elementary School:</label>
                    <span style={{"fontWeight": "bold"}}>{listing.elementary_school}</span>
                </div>
            </div>
        </div>

        <div className={classes.listingRowSeq}>
            <div className={classes.halfRow}>
                <div className={classes.halfRowContent}>
                    <label className={classes.boxSubTitle}>Other</label>
                </div>
                <div className={classes.halfRowContent}>
                    <label className={classes.halfRowContentLabel}>Condo Association:</label>
                    <span style={{"fontWeight": "bold"}}>${listing.condo_association_name}</span>
                </div>
                <div className={classes.halfRowContent}>
                    <label className={classes.halfRowContentLabel}>HOA Covenant:</label>
                    <span style={{"fontWeight": "bold"}}>{listing.hoa_covenant ? "Yes" : "No"}</span>
                </div>
            </div>
            <div className={classes.halfRow}>
                <div className={classes.halfRowContent}>
                    <label className={classes.halfRowContentLabel}>Lot Sq. Footage:</label>
                    <span style={{"fontWeight": "bold"}}>{listing.lot_square_footage}</span>
                </div>
                <div className={classes.halfRowContent}>
                    <label className={classes.halfRowContentLabel}>Year Built:</label>
                    <span style={{"fontWeight": "bold"}}>{listing.year_built}</span>
                </div>
            </div>
        </div>

        <div className={classes.row}>
            <div className={classes.boxTitle}>
                Unit
            </div>
        </div>

        <div className={classes.listingRow}>
            <div className={classes.halfRow}>
                <div className={classes.halfRowContent}>
                    <label className={classes.boxSubTitle}>Building</label>
                </div>
                <div className={classes.halfRowContent}>
                    <label className={classes.halfRowContentLabel}>Property Type:</label>
                    <span style={{"fontWeight": "bold"}}>{listing.building_type}</span>
                </div>
                <div className={classes.halfRowContent}>
                    <label className={classes.halfRowContentLabel}>Finished Sq.Footage:</label>
                    <span style={{"fontWeight": "bold"}}>{listing.finished_square_footage}</span>
                </div>
                <div className={classes.halfRowContent}>
                    <label className={classes.halfRowContentLabel}>Bedrooms:</label>
                    <span style={{"fontWeight": "bold"}}>
                                        {`${listing.bedrooms === "0" ? "Studio" : (listing.bedrooms === "1" ? "1" : listing.bedrooms) }`}
                                    </span>
                </div>
                <div className={classes.halfRowContent}>
                    <label className={classes.halfRowContentLabel}>Full Bathrooms:</label>
                    <span style={{"fontWeight": "bold"}}>{listing?.full_bathrooms}</span>
                </div>
            </div>
            <div className={classes.halfRow}>
                <div className={classes.halfRowContent}>
                    <label className={classes.halfRowContentLabel}>Half Bathrooms:</label>
                    <span style={{"fontWeight": "bold"}}>{listing?.half_bathrooms}</span>
                </div>
                <div className={classes.halfRowContent}>
                    <label className={classes.halfRowContentLabel}>3/4 Bathrooms:</label>
                    <span style={{"fontWeight": "bold"}}>{listing?.three_quarter_bathrooms}</span>
                </div>
                <div className={classes.halfRowContent}>
                    <label className={classes.halfRowContentLabel}>Floors In Unit:</label>
                    <span style={{"fontWeight": "bold"}}>{listing.floor}</span>
                </div>
                <div className={classes.halfRowContent}>
                    <label className={classes.halfRowContentLabel}>Furnished:</label>
                    <span style={{"fontWeight": "bold"}}>{listing.furnished}</span>
                </div>
            </div>
        </div>

        <div className={classes.listingRowSeq}>
            <div className={classes.halfRow}>
                <div className={classes.halfRowContent}>
                    <label className={classes.boxSubTitle}>Utilities</label>
                </div>
                <div className={classes.halfRowContent}>
                    <label className={classes.halfRowContentLabel}>Utilities Available:</label>
                    <span style={{"fontWeight": "bold"}}>
                                        {
                                            typeof(listing.utilities_available) === 'string' ? listing.utilities_available.replace(/[^a-zA-Z, ]/g, " ") : listing.utilities_available.join().replace(/[^a-zA-Z, ]/g, " ")
                                        }
                                    </span>
                </div>
                <div className={classes.halfRowContent}>
                    <label className={classes.halfRowContentLabel}>Utilities Included:</label>
                    <span style={{"fontWeight": "bold"}}>
                                        {
                                            typeof(listing.utilities_included) === 'string' ? listing.utilities_included.replace(/[^a-zA-Z, ]/g, " ") : listing.utilities_included.join().replace(/[^a-zA-Z, ]/g, " ")
                                        }
                                    </span>
                </div>
                <div className={classes.halfRowContent}>
                    <label className={classes.halfRowContentLabel}>Other Utilities:</label>
                    <span style={{"fontWeight": "bold"}}>{listing?.other_utility}</span>
                </div>
            </div>
            <div className={classes.halfRow}>
                <div className={classes.halfRowContent}>
                    <label className={classes.halfRowContentLabel}>A/C Type:</label>
                    <span style={{"fontWeight": "bold"}}>{listing?.ac_type}</span>
                </div>
                <div className={classes.halfRowContent}>
                    <label className={classes.halfRowContentLabel}>Heat Type:</label>
                    <span style={{"fontWeight": "bold"}}>{listing?.heat_type}</span>
                </div>
            </div>
        </div>

        <div className={classes.row}>
            <div className={classes.boxTitle}>
                Description
            </div>
        </div>

        <div className={classes.listingRow}>
            <div style={{width: "100%"}}>
                <div className={classes.halfRowContent}>
                    <label className={classes.halfRowContentLabel}>Unit Description:</label>
                    <span style={{"fontWeight": "bold"}}>{listing.comments}</span>
                </div>
                <div className={classes.halfRowContent}>
                    <label className={classes.halfRowContentLabel}>Unit Features:</label>
                    <span style={{"fontWeight": "bold"}}>{listing?.unit_features.join().replace(/[^a-zA-Z, ]/g, " ")}</span>
                </div>
                <div className={classes.halfRowContent}>
                    <label className={classes.halfRowContentLabel}>Deleaded:</label>
                    <span style={{"fontWeight": "bold"}}>{listing.deleaded}</span>
                </div>
                <div className={classes.halfRowContent}>
                    <label className={classes.halfRowContentLabel}>Pets Allowed:</label>
                    <span style={{"fontWeight": "bold"}}>{listing?.pets}</span>
                </div>
                <div className={classes.halfRowContent}>
                    <label className={classes.halfRowContentLabel}>Pet Type:</label>
                    <span style={{"fontWeight": "bold"}}>{listing?.pet_types}</span>
                </div>
                <div className={classes.halfRowContent}>
                    <label className={classes.halfRowContentLabel}>Pet Specifications:</label>
                    <span style={{"fontWeight": "bold"}}>{listing?.pet_specs}</span>
                </div>
                <div className={classes.halfRowContent}>
                    <label className={classes.halfRowContentLabel}>Laundry:</label>
                    <span style={{"fontWeight": "bold"}}>{listing?.laundry}</span>
                </div>
                <div className={classes.halfRowContent}>
                    <label className={classes.halfRowContentLabel}>Attached to Another Dwelling:</label>
                    <span style={{"fontWeight": "bold"}}>{listing?.attached_to_another_dwelling}</span>
                </div>
            </div>
        </div>

        <div className={classes.row}>
            <div className={classes.boxTitle}>
                Showings
            </div>
        </div>

        <div className={classes.listingRow}>
            <div className={classes.halfRow}>
                <div className={classes.halfRowContent}>
                    <label className={classes.halfRowContentLabel}>Disclosures:</label>
                    <span style={{"fontWeight": "bold"}}>{listing.disclosure}</span>
                </div>
                <div className={classes.halfRowContent}>
                    <label className={classes.halfRowContentLabel}>Building Showing Instructions:</label>
                    <span style={{"fontWeight": "bold"}}>{listing?.showing_instructions}</span>
                </div>
                <div className={classes.halfRowContent}>
                    <label className={classes.halfRowContentLabel}>Unit Showing Instructions:</label>
                    <span style={{"fontWeight": "bold"}}>{listing?.showing_instructions_listing}</span>
                </div>
                <div className={classes.halfRowContent}>
                    <label className={classes.halfRowContentLabel}>Key Information:</label>
                    <span style={{"fontWeight": "bold"}}>{listing?.key_info}</span>
                </div>
            </div>
            <div className={classes.halfRow}>
                <div className={classes.halfRowContent}>
                    <label className={classes.halfRowContentLabel}>Unit Occupied:</label>
                    <span style={{"fontWeight": "bold"}}>{listing?.unit_occupied}</span>
                </div>
                <div className={classes.halfRowContent}>
                    <label className={classes.halfRowContentLabel}>Virtual Showing:</label>
                    <span style={{"fontWeight": "bold"}}>{listing.virtual_showing_allowed}</span>
                </div>
                <div className={classes.halfRowContent}>
                    <label className={classes.halfRowContentLabel}>Virtual Tour URL:</label>
                    <span style={{"fontWeight": "bold"}}>{listing.virtual_tour_url}</span>
                </div>
                <div className={classes.halfRowContent}>
                    <label className={classes.halfRowContentLabel}>Virtual Showing Notes:</label>
                    <span style={{"fontWeight": "bold"}}>{listing.virtual_showing_notes}</span>
                </div>
            </div>
        </div>

        <div className={classes.row}>
            <div className={classes.boxTitle}>
                Listing Contact
            </div>
        </div>

        <div className={classes.listingRow}>
            <div className={classes.halfRow}>
                <div className={classes.halfRowContent}>
                    <label className={classes.halfRowContentLabel}>Listing Agent:</label>
                    <span style={{"fontWeight": "bold"}}>{listing.management_co}</span>
                </div>
                <div className={classes.halfRowContent}>
                    <label className={classes.halfRowContentLabel}>Contact Phone:</label>
                    <span style={{"fontWeight": "bold"}}>{listing?.direct_phone}</span>
                </div>
                <div className={classes.halfRowContent}>
                    <label className={classes.halfRowContentLabel}>Contact Email:</label>
                    <span style={{"fontWeight": "bold"}}>{listing?.email}</span>
                </div>
            </div>
            <div className={classes.halfRow}>
                <div className={classes.halfRowContent}>
                    <label className={classes.halfRowContentLabel}>Broker Office Name:</label>
                    <span style={{"fontWeight": "bold"}}>{listing?.agent_info?.brokerage_name}</span>
                </div>
                <div className={classes.halfRowContent}>
                    <label className={classes.halfRowContentLabel}>Broker Office Address:</label>
                    <span style={{"fontWeight": "bold"}}>{listing?.agent_info?.office_address.address} {listing?.agent_info?.office_address.city} {listing?.agent_info?.office_address.state} {listing?.agent_info?.office_address.zip}`</span>
                </div>
            </div>
        </div>

        <div className={classes.row}>
            <div className={classes.boxTitle}>
                Notes & Settings
            </div>
        </div>

        <div className={classes.listingRow}>
            <div>
                {/* commenting this section out per RB-513 - this section will be added back per RB-515*/}
                {/*<div className={classes.halfRowContent}>
                                    <label className={classes.halfRowContentLabel}>Broker Notes:</label>
                                    <span style={{"fontWeight": "bold"}}>{listing.broker_notes}</span>
                                    </div>*/}
                <div className={classes.halfRowContent}>
                    <label className={classes.halfRowContentLabel}>Application Procedure:</label>
                    <span style={{"fontWeight": "bold"}}>{listing?.application_procedure}</span>
                </div>
            </div>
        </div>

        <div className={classes.listingRow}>
            <div>
                <div className={classes.halfRowContent}>
                    <span style={{"fontWeight": "bold"}}>{listing.agent_info ?"Listing courtesy of "+ listing?.agent_info?.brokerage_name + ".": ""} Listing data provided by {company_title}. Information is deemed to be reliable but not guaranteed.</span>
                </div>
            </div>
        </div>

        <div style={{marginBottom: "15px", pageBreakAfter: "always"}}/>
    </>
}

interface IPrintListingsAction {
    selectedListings: IListing[];
    agentInfo: IAgentInfoRes;
    jss?: Jss;
}

const printStyles = {
    pdfListingsContainer: {
        "box-sizing": "border-box",
    },
    row: {
        "width": "100%",
        "display": "flex",
    },
    halfRow: {
        width: "50%",
    },
    listingImageRow: {
        width: "50%",
        marginRight: "5px",
    },
    halfRowContent: {
        marginBottom: "10px",
        display: "block",
    },
    halfRowContentLabel: {
        display: "inline-block",
        width: "150px",
        textAlign: "left",
    },
    imagesRow: {
        "width": "100%",
        "display": "flex",
        "margin": "5px",
        "flex-direction": "row",
        "justify-content": "space-between",
        "align-items": "center",
        "height": "300px",
    },
    listingImage: {
        "display": "block",
        "max-width": "100%",
        "max-height": "300px",
        "height": "300px",
    },
    agentCard: {
        width: "50%",
        border: "3px solid #555",
        margin: "0 0 10px 5px",
        height: "300px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    agentCardImg: {
        width: "50%",
        "display": "block",
        "max-width": "50%",
        margin: "5px 10px",
        "padding-top": "35px",
    },
    agentCardDetails: {
        width: "50%",
        "display": "block",
        "max-width": "50%",
        margin: "15px 10px",
        "padding-top": "35px",
        "word-break": "break-all",
    },
    listingAddress: {
        "font-weight": "bold",
        "font-size": "xx-large",
        "margin": "15px 0",
    },
    boxTitle: {
        "font-weight": "bold",
        "font-size": "xx-large",
        "margin": "15px 0 5px 0",
    },
    boxSubTitle: {
        display: "inline-block",
        width: "150px",
        textAlign: "left",
        "font-weight": "bold",
        "margin": "5px 0",
    },
    listingBrief: {
        "width": "100%",
        "display": "flex",
        "border": "2px solid #000000",
        "padding": "5px 0 5px 5px",
        "margin": "5px 0",
        "flex-direction": "row",
        "justify-content": "space-between",
        "align-items": "center",
    },
    listingRow: {
        "background-color": "rgba(222,222,222,0.56)",
        "width": "100%",
        "display": "flex",
        "border-top": "2px solid #000000",
        "padding": "5px 0 5px 5px",
        "margin": "5px 0",
        "flex-direction": "row",
        "justify-content": "space-between",
        "align-items": "center",
    },
    listingRowSeq: {
        "background-color": "rgba(222,222,222,0.56)",
        "width": "100%",
        "display": "flex",
        "padding": "10px",
        "margin": "5px 0",
        "flex-direction": "row",
        "justify-content": "space-between",
        "align-items": "center",
    }
}
