export interface IPointsOfInterestSetting {
  label: string;
  value: string;
}

export const pointsOfInterestSetting: IPointsOfInterestSetting[] = [
  { label: "Supermarkets", value: "supermarket"},
  { label: "Restaurants", value: "restaurant"},
  { label: "Hospitals", value: "hospital"},
  { label: "Banks", value: "bank"},
  { label: "Transportation", value: "transit_station"}
];
