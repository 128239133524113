import React, {useContext, useEffect, useState} from "react";
import {ListingSearchContext} from "../../listing_search_context";
import "./fieldset_bathroom_range.scss";
import {Select} from "@material-ui/core";

export function FieldsetBathroomRange(props: IFieldsetBathroomRange) {
    const {criteria, setCriteria} = useContext(ListingSearchContext);
    const {focusedElId, setFocusedElId} = useContext(ListingSearchContext);
    const [inputError, setInputError] = useState(null);
    const errorLabel = `Max. Baths must be greater than Min. Baths.`;

    const optionChoices = [
        {label: "Any", value: ""},
        {label: "1", value: 1},
        {label: "1.5", value: 1.5},
        {label: "2", value: 2},
        {label: "2.5", value: 2.5},
        {label: "3", value: 3},
        {label: "3.5", value: 3.5},
        {label: "4", value: 4},
        {label: "4.5", value: 4.5},
        {label: "5", value: 5},
        {label: "5.5", value: 5.5}
    ];

    const handleMinBathChange = (e) => {
        let minBathValue = e.currentTarget.value;
        let minBath = parseFloat(minBathValue);

        if (minBath >= 0 && (!(criteria.bathroom_range.max_bathrooms === '') && criteria.bathroom_range.max_bathrooms >= 0)) {
            if (minBath > criteria.bathroom_range.max_bathrooms) {
                setInputError(errorLabel);
            } else {
                setInputError(null);
            }
        } else {
            setInputError(null);
        }

        setCriteria({...criteria, bathroom_range: {...criteria.bathroom_range, min_bathrooms: e.currentTarget.value} });

        if (setFocusedElId) {
            setFocusedElId(props.minBathInputId);
        }
    };

    const handleMaxBathChange = (e) => {
        let maxBathValue = e.currentTarget.value;
        let maxBath = parseFloat(maxBathValue);

        if (maxBath >= 0 && (!(criteria.bathroom_range.min_bathrooms === '') && criteria.bathroom_range.min_bathrooms >= 0)) {
            if (maxBath < criteria.bathroom_range.min_bathrooms) {
                setInputError(errorLabel);
            } else {
                setInputError(null); 
            }
        } else {
            setInputError(null); 
        }

        setCriteria({ ...criteria, bathroom_range: { ...criteria.bathroom_range, max_bathrooms: e.currentTarget.value } });
                
        if (setFocusedElId) {
            setFocusedElId(props.maxBathInputId);
        }
    };

    useEffect(()=>{
        if(focusedElId) {
            document.getElementById(focusedElId).focus();
        }
    }, [focusedElId]);

    return (
        <fieldset className="bathroom-range--container">
            <div>
                <label className="wide-only">Bathrooms</label>
            </div>
            <div className="bathroom-input--container">
                <Select
                    native
                    id={props.minBathInputId || ""}
                    value={criteria.bathroom_range.min_bathrooms}
                    onChange={handleMinBathChange} >
                        {
                            optionChoices.map((option) => (
                                <option value={option.value} key={option.value}>{option.label}</option>
                            ))
                        }
                </Select>
                 <span className={"to"}>to</span>
                 <Select
                    native
                    id={props.maxBathInputId || ""}
                    value={criteria.bathroom_range.max_bathrooms}
                    onChange={handleMaxBathChange} >
                        {
                            optionChoices.map((option) => (
                                <option value={option.value} key={option.value}>{option.label}</option>
                            ))
                        }
                </Select>
                <label className={`errorLabel ${inputError ? 'active' : ''}`}>{inputError}</label>
            </div>
        </fieldset>
    )
}

interface IFieldsetBathroomRange {
    minBathInputId: string;
    maxBathInputId: string;
}