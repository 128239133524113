import React from "react";
import "./rb_checkbox.scss";

export function RBCheckbox (props: IRBCheckbox) {
    return <React.Fragment>
        <div  className={`input--checkbox-container ${props.className || ""}`} >
            <input
                type="checkbox"
                name={props.name}
                onChange={props.handleChange}
                checked={props.checked}
                className="input--checkbox"
                disabled={props.disabled}
                onClick={e => {
                    props.handleClick !== undefined && props.handleClick(e)
                    e.stopPropagation();
                }}
            />
            <span className="input--checkbox-mark"></span>
            <label htmlFor={props.name}>{props.label}</label>
        </div>
    </React.Fragment>
}

interface IRBCheckbox {
    name: string;
    label?: string;
    handleChange: (e?: any) => void;
    handleClick?: (e?: any) => void;
    checked: boolean;
    disabled?: boolean;
    className?: string;
}