import React, {useContext, useEffect, useState} from "react";
import {ListingSearchContext} from "../../listing_search_context";
import "../square_footage_range/fieldset_square_footage_range.scss"

export function FieldsetLeaseRate (props: IFieldsetLeaseRateRange) {
    const {className} = props;
    const {criteria, setCriteria} = useContext(ListingSearchContext);
    const {focusedElId, setFocusedElId} = useContext(ListingSearchContext);
    const [inputError, setInputError] = useState(null);
    const errorLabel = `Input a max rate that is higher than the min rate`;

    const handleMinChange = (e) => {
        setCriteria({...criteria, lease_rate: {...criteria.lease_rate, min: e.currentTarget.value} });
        if(setFocusedElId) {
            setFocusedElId(props.minInputId);
        }
    };

    const handleMaxChange = (e) => {
        setCriteria({...criteria, lease_rate: {...criteria.lease_rate, max: e.currentTarget.value} });
        if(setFocusedElId) {
            setFocusedElId(props.maxInputId);
        }
    };

    const handleMinBlur = (e) => {
        const newMinValue = e.currentTarget.value;
        const newRate = parseInt(newMinValue);
        if (!(criteria.lease_rate.max === null) && newRate > criteria.lease_rate.max) {
            setInputError(errorLabel);
        } else {
            setInputError(null);
        }
    };

    const handleMaxBlur = (e) => {
        const newMaxValue = e.currentTarget.value;
        const newRate = parseInt(newMaxValue);
        if (newRate < criteria.lease_rate.min) {
            setInputError(errorLabel);
        } else {
            setInputError(null);
        }
    };

    useEffect(()=>{
        if(focusedElId) {
            document.getElementById(focusedElId).focus();
        }
    }, [focusedElId]);

    return <fieldset className={`range-container ${className}`}>
        <div>
            <label className="wide-only">Lease Rate</label>
        </div>
        <div className="range-input--container">
            <input id={props.minInputId || ""}
                   className={"criteria--input"}
                   onChange={e => handleMinChange(e)}
                   onBlur={e => handleMinBlur(e)}
                   value={criteria.lease_rate.min}
                   type="number"
            />
            <span className={"to"}>to</span>
            <input id={props.maxInputId || ""}
                   className={"criteria--input"}
                   onChange={e => handleMaxChange(e)}
                   onBlur={e => handleMaxBlur(e)}
                   value={criteria.lease_rate.max}
                   type="number"
                   min={1}
            />
            <label className={`errorLabel ${inputError ? 'active' : ''}`}>{inputError}</label>
        </div>
    </fieldset>
}

interface IFieldsetLeaseRateRange {
    className?: string;
    minInputId?: string;
    maxInputId?: string;
}