import React, { useState, useCallback, useContext } from "react";
import { ListingSearchContext } from "../../../../../components/listing_search/listing_search_context";
import { SHAPE } from "../draw_utils/draw_constants";

export const useMapShape = () => {
    const {
        drawShapePayload,
        setDrawShapePayload,
        shapeOptionLatLng,
        setIsActionFromMap,
        setShapeOptionLatLng,
        drawShapes,
        setDrawShapes,
        isClearedShape,
        setClearedShape,
        setIsActionExcludeOrDeleteShape
    } = useContext(
        ListingSearchContext
    )

    const [mapDrawManger, setMapDrawManager] = useState<any>();
    const [hoveredShapeId, setHoveredShapeId] = useState<string>("");
    const [retrieveDrawings, setRetrieveDrawings] = useState<any[]>([]) 

    const polygonCenter = (poly) => {
        const vertices = poly.getPath();

        // put all latitudes and longitudes in arrays
        const longitudes = []
        const latitudes = []

        for (let i = 0; i < vertices.length; i++) {
            longitudes.push(vertices.getAt(i).lng());
            latitudes.push(vertices.getAt(i).lat())
        }

        // sort the arrays low to high
        latitudes.sort();
        longitudes.sort();

        // get the min and max of each
        const lowX = latitudes[0];
        const highX = latitudes[latitudes.length - 1];
        const lowy = longitudes[0];
        const highy = longitudes[latitudes.length - 1];

        // center of the polygon is the starting point plus the midpoint
        const centerX = lowX + ((highX - lowX) / 2);
        const centerY = lowy + ((highy - lowy) / 2);

            // return (new google.maps.LatLng(centerX, centerY));
        return {
            centerX,
            centerY
        }
    }

    const handleClearShapes = () => {
        setDrawShapePayload({
            ...drawShapePayload,
            geometry: []
        })
        drawShapes.forEach(shape => shape.setMap(null))
        setIsActionFromMap(true);
        setClearedShape(true);
        setDrawShapes([]);
    }

    const handleDeleteListing = (uniqueShapeId : any, retrieveDrawings : any) => {
        setIsActionExcludeOrDeleteShape('DELETE_SHAPE')
        const filterDeleteListings = drawShapePayload.geometry.filter(shape => shape.uniqueShapeId !== uniqueShapeId);
        setDrawShapePayload({
            ...drawShapePayload,
            geometry: filterDeleteListings
        })
        drawShapes.filter(shape => shape.uniqueShapeId == uniqueShapeId)[0].setMap(null)

        if (retrieveDrawings.length > 0) {
            retrieveDrawings.filter(shape => shape.uniqueShapeId == uniqueShapeId)[0].setMap(null)
        }

        setDrawShapes(
            drawShapes.filter(shape => shape.uniqueShapeId !== uniqueShapeId)
        )
        setIsActionFromMap(true);
        setShapeOptionLatLng({
            lat: null,
            lng: null,
        })
    }

    const handleExcludeListing = (uniqueShapeId : any, retrieveDrawings : any) => {
        const newShapePayload = drawShapePayload.geometry.map((shape) =>
            shape.uniqueShapeId === uniqueShapeId
                ? {
                      ...shape,
                      is_exclude: true,
                  }
                : shape
        );
        setIsActionExcludeOrDeleteShape('EXCLUDE_SHAPE')
        drawShapes.filter(shape => shape.uniqueShapeId == uniqueShapeId)[0]
        .setOptions({
            fillColor: '#cecece',
            fillOpacity: 0.5,
            strokeColor: '#f15952'
        })

        if (retrieveDrawings.length > 0) {
            retrieveDrawings.filter(shape => shape.uniqueShapeId == uniqueShapeId)[0]
            .setOptions({
                fillColor: '#cecece',
                fillOpacity: 0.5,
                strokeColor: '#f15952'
            })
        }

        setShapeOptionLatLng({
            lat: null,
            lng: null,
        })
        setDrawShapePayload({
            ...drawShapePayload,
            geometry: newShapePayload,
        });
    };

    const handleResizeShape =  (uniqueShapeId, newUpdatedField,  shapeAsString, currentShapePayload, drawShapes, shape) => {
            const polygonPath = [];
            if (shapeAsString == SHAPE.POLYGON) {
                const polygonBounds = newUpdatedField.getPath();

                for (let i = 0; i < polygonBounds.length; i++) {
                    const latLang = {
                        latitude: polygonBounds.getAt(i).lat(),
                        longitude: polygonBounds.getAt(i).lng(),
                    };
                    polygonPath.push(latLang);
                }

                if (polygonPath.length > 0) {
                    polygonPath.push(polygonPath[0])
                }
            }

            const newShapePayload = currentShapePayload.geometry.map((shape) =>
                shape.uniqueShapeId === uniqueShapeId
                    ? {
                        ...shape,
                        ...(shapeAsString == SHAPE.CIRCLE && {
                            radius: newUpdatedField.radius,
                            coordinates: newUpdatedField.coordinates,
                            is_exclude: shape.is_exclude}),
                        ...(shapeAsString == SHAPE.RECTANGLE && {
                             bounds: newUpdatedField.bounds,
                             is_exclude: shape.is_exclude}),
                        ...(shapeAsString == SHAPE.POLYGON && {
                            coordinates: polygonPath,
                             is_exclude: shape.is_exclude
                        }),
                    }
                    : shape
            );


            const newDrawShapes = drawShapes.map((drawShape) => {
                if (drawShape.uniqueShapeId === uniqueShapeId) {
                    return shape
                } else {
                    return drawShape;
                }
            });
    
            setDrawShapes(newDrawShapes)
            setRetrieveDrawings(newDrawShapes)
            setDrawShapePayload({
                ...drawShapePayload,
                geometry: newShapePayload
            })
    }

    const handleDrawShape = useCallback(
        (shape, shapeAsString, uniqueShapeId, existingUniqueId = undefined) => {
            const polygonPath = [];
            shape.setOptions({
                uniqueShapeId,
            });
            if (shapeAsString == SHAPE.POLYGON) {
                const polygonBounds = shape.getPath();

                for (let i = 0; i < polygonBounds.length; i++) {
                    const latLang = {
                        latitude: polygonBounds.getAt(i).lat(),
                        longitude: polygonBounds.getAt(i).lng(),
                    };
                    polygonPath.push(latLang);
                }

                if (polygonPath.length > 0) {
                    polygonPath.push(polygonPath[0])
                }
            }

            const newShapePayload = {
                shape: shapeAsString,
                is_exclude: false,
                uniqueShapeId,
                ...(shapeAsString == SHAPE.CIRCLE && {
                    radius: shape.getRadius(),
                    coordinates: [
                        {
                            latitude: shape.getCenter().lat(),
                            longitude: shape.getCenter().lng(),
                        },
                    ],
                }),
                ...(shapeAsString == SHAPE.RECTANGLE && {
                    bounds: {
                        north: shape.getBounds().toJSON().north,
                        south: shape.getBounds().toJSON().south,
                        east: shape.getBounds().toJSON().east,
                        west: shape.getBounds().toJSON().west,
                    },
                }),
                ...(shapeAsString == SHAPE.POLYGON && {
                    coordinates: polygonPath,
                }),
            };
            setDrawShapePayload((prevState) => ({
                ...prevState,
                geometry: [...prevState.geometry, newShapePayload],
            }));
        },
        [drawShapePayload]
    );

    const drawManagerHandler = (selectedShapeController) => {
        switch (selectedShapeController) {
            case "Radius":
                mapDrawManger.setDrawingMode(SHAPE.CIRCLE);
                break;
            case "Rectangle":
                mapDrawManger.setDrawingMode(SHAPE.RECTANGLE);
                break;
            case "Polygon":
                mapDrawManger.setDrawingMode(SHAPE.POLYGON);
                break;
            default:
                mapDrawManger.setDrawingMode(null);
        }
    };

    const resizeShapeManager = (drawShapes, maps, currentDrawShapePayload) => {
    
        drawShapes.map(shape => {
            if (shape instanceof maps.Circle) {
                maps.event.clearListeners(shape, "radius_changed");
                maps.event.addListener(shape, "radius_changed", () => {
                    handleResizeShape(shape.uniqueShapeId, {
                    radius: shape.getRadius(),
                    coordinates: [
                        {latitude: shape.getCenter().lat(),
                        longitude: shape.getCenter().lng()}
                    ]
                    }, SHAPE.CIRCLE, currentDrawShapePayload, drawShapes, shape)
                })
            }
            else if (shape instanceof maps.Rectangle) {
                maps.event.clearListeners(shape, "bounds_changed");
                maps.event.addListener(shape, "bounds_changed", () => {
                    handleResizeShape(shape.uniqueShapeId, {
                        bounds: {
                            north: shape.getBounds().toJSON().north,
                            south: shape.getBounds().toJSON().south,
                            east:  shape.getBounds().toJSON().east,
                            west:  shape.getBounds().toJSON().west,
                        }

                    }, SHAPE.RECTANGLE, currentDrawShapePayload, drawShapes, shape)
                })
            }
            else if (shape instanceof maps.Polygon) {
                const path = shape.getPath();
                maps.event.clearListeners(path, "set_at");
                maps.event.clearListeners(path, "insert_at");
                maps.event.clearListeners(path, "remove_at");
                path.addListener('set_at', function(index) {
                    handleResizeShape(shape.uniqueShapeId, shape, SHAPE.POLYGON, currentDrawShapePayload, drawShapes, shape)
                });
                path.addListener('insert_at', function(index) {
                    handleResizeShape(shape.uniqueShapeId, shape, SHAPE.POLYGON, currentDrawShapePayload, drawShapes,shape)
                });
                path.addListener('remove_at', function(index) {
                    handleResizeShape(shape.uniqueShapeId, shape, SHAPE.POLYGON, currentDrawShapePayload,  drawShapes, shape)
                });
            }
        })
    }
    const shapeMouseOver = (latLng, overlay, currentModalLatLang) => {
        const {lat, lng}= currentModalLatLang
        if (lat !== null && lng !== null) {
            return
        }

        setHoveredShapeId(overlay.uniqueShapeId);
        setShapeOptionLatLng(latLng);
    };

    const shapeMouseOut = () => {
        setShapeOptionLatLng({
            lat: null,
            lng: null,
        });
    };

    return {
        drawShapePayload,
        shapeOptionLatLng,
        handleDrawShape,
        drawManagerHandler,
        setMapDrawManager,
        mapDrawManger,
        shapeMouseOver,
        shapeMouseOut,
        handleExcludeListing,
        hoveredShapeId,
        handleDeleteListing,
        setDrawShapes,
        drawShapes,
        handleResizeShape,
        polygonCenter,
        handleClearShapes,
        isClearedShape,
        resizeShapeManager,
        retrieveDrawings,
        setRetrieveDrawings
    };
};
