import React from 'react';
import {Dialog, Grid, DialogTitle} from "@material-ui/core";
import { RbButton } from '../../../../../components/rb_button/rb_button';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';


interface ApplyNowBlockedPopupModalProps {
    isModalOpen?: boolean;
    setIsModalOpen?: (isModalOpen: boolean) => void;
}

export const ApplyNowBlockedPopupModal = React.memo(
    function ApplyNowBlockedPopupModalComponent(props: ApplyNowBlockedPopupModalProps) {
        const { isModalOpen, setIsModalOpen } = props;
        const onCloseModal = (e: any) => {
            e.preventDefault();
            e.stopPropagation();
            setIsModalOpen(false);
        }

        return (
            <>
                <Dialog
                    open={isModalOpen}
                    onClose={(e: any) => {
                        onCloseModal(e)
                    }}
                    fullWidth
                    maxWidth="xs"
                >
                    <DialogTitle>
                        <Grid container direction="row" justify="center" alignItems="center">
                            <ErrorOutlineOutlinedIcon style={{fontSize: '80px', color: 'red'}}/>
                        </Grid>
                    </DialogTitle>
                    <p></p>
                    <p style={{textAlign: 'center', paddingRight: '10px', paddingLeft: '10px'}}>
                        We apologize, but Apply Now tenant screening cannot be provided for this property. Local regulations and tenant screening laws in the area restrict its use, so this service is unavailable at this time.
                    </p>
                    <div style={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                    <RbButton
                        onClick={(e: any) => {
                            onCloseModal(e)
                        }}
                        style={{background: '#337ab7', color:'white'}}
                    >
                        OK
                    </RbButton>
                    </div>
                </Dialog>
            </>
        );
    }
)
