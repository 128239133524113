import React, {useContext, useState} from "react";
import {ConsumerSearchContext} from "./consumer_search_context";
import ReactPaginate from "react-paginate";
import "./styles/pagination.scss";

export function Pagination() {

    const {tileSearching, baseTileCriteria, setBaseTileCriteria, totalPages, isMobile} = useContext(ConsumerSearchContext);

    const handlePageChange = (props) => {
        setBaseTileCriteria({...baseTileCriteria, page_number: props.selected+1})
    };

    return (
        <div className="pagination-wrapper" style={{visibility: tileSearching ? "hidden" : "visible"}} >
            <ReactPaginate
                pageCount={totalPages}
                marginPagesDisplayed={isMobile ? 0 : 1}
                pageRangeDisplayed={isMobile ? 0 : 2}
                onPageChange={ props => handlePageChange(props)  }
                containerClassName={'search-pagination'}
                activeClassName={'active-page'}
                previousLabel={isMobile ? "<" : "Previous"}
                nextLabel={isMobile ? ">" : "Next"}
            />
        </div>
    )
}