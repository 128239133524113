import React, {useContext, useState} from "react";
import "./fieldset_clear_filters.scss";
import {ILocationObj, ListingSearchContext} from "../../listing_search_context";
import {ListingType, OwnershipType, StatusType} from "../../../../../services/api/listing";
import {BootstrapTooltip} from "../../search_filters/filters";
import {consumerApi} from "../../../../../services/api/consumer";
import {getFormattedDate} from "../../utilities";

export const getClearedFilters = (criteria, fieldsToSkip = []) => {
    const filters = {
        agent_id: null,
        attached_to_another_dwelling: "",
        available_by_date: null,
        available_from_date: null,
        bathroom_range: { min_bathrooms: "", max_bathrooms: ""},
        bedroom_range: { min_bedrooms: "", max_bedrooms: "" },
        building_type_list: [] as string[],
        cooling_type_list: [] as string[],
        date_active: { min: "", max: "" },
        days_on_market: {min: "", max: ""},
        direct_phone: "",
        deleaded: "",
        elementary_school: "",
        external_structures: [] as string[],
        fields_to_include: criteria.fields_to_include,
        finished_square_footage: {min: "", max: ""},
        floor: "",
        floor_type: [] as string[],
        floors_in_unit_list: [] as string[],
        furnished: "",
        garage: false,
        garage_spaces: -1,
        heat_type_list: [] as string[],
        high_school: "",
        include_disclaimer: criteria.include_disclaimer,
        laundry_type_list: [] as string[],
        lease_date: { min: null, max: null},
        lease_rate: {min: "", max: ""},
        listing_ids: [],
        listing_type: "" as ListingType,
        sublet_term: "",
        lot_square_footage: {min: "", max: ""},
        max_record_count: criteria.max_record_count,
        middle_school: "",
        min_commission: "" as any,
        min_pic_count: 0,
        min_sq_ft: "" as any,
        multiple_areas: [] as ILocationObj [],
        owner_pay_list: [] as string[],
        ownership_type_list: [] as OwnershipType[],
        page_number: 1,
        parking_options_list: [] as string[],
        partner_key: criteria.partner_key,
        pets: false,
        pet_types_list: [] as string[],
        property_features: [] as string[],
        property_structure: "",
        public_transit: {distance: -1, types: [] as string[]},
        rent_range: {min_price: "", max_price: ""},
        rb_mls_id: "",
        renter_insurance: "",
        room_types: [] as string[],
        school_district: "",
        statuses_list: [StatusType.ACTIVE, StatusType.APPLICATION_PENDING] as StatusType[],
        super_region: 0,
        sqft_range: {min_sq_ft: "", max_sq_ft: ""},
        unit_features_list: [] as string[],
        utilities_available: [],
        virtual_showing_allowed: "",
        virtual_tour_url: false,
        year_built: "",
    };

    fieldsToSkip.forEach((fieldName)=>{
       filters[fieldName] = criteria[fieldName];
    });

    return filters
};

const defaultRegion = async (clearedFilters) => {
    const userInfoRes = await consumerApi.get()
    const region = userInfoRes.agent_info.super_region
    clearedFilters.super_region = region
    return region
}

export function FieldsetClearFilters (props: IClearFilterProps) {
    const {criteria, setCriteria} = useContext(ListingSearchContext);
    const {handleDeleteLastSearch} = props;

    const [label, setLabel] = useState("Clear");

    const handleClear = async (e) => {
        const clearedFilters = getClearedFilters(criteria, props.fieldsToSkip);
        const region = await defaultRegion(clearedFilters)
        setCriteria(clearedFilters);
        setLabel("Cleared!");
        setTimeout(()=>{ setLabel("Clear")}, 1000);

        if(handleDeleteLastSearch) {
            handleDeleteLastSearch();
        }
    };

    return (
        <BootstrapTooltip title="Clear Filters">
            <div className={props.className}>
                <div onClick={(e)=>handleClear(e)} className={"clear-filters"}>{label}</div>
            </div>
        </BootstrapTooltip>
    )
}

interface IClearFilterProps {
    className: string,
    fieldsToSkip?: string[],
    handleDeleteLastSearch?: () => void,
}
