import {REST} from "../../rest";

export const userBookmarksApi = {
    get: async (userId: string): Promise<any> => {
        if (userId !== null || userId !== 'null') {
            const url = `/api/user/${userId}/bookmarks`;
            return await REST.get(url).then(res => {
                return res;
            });
        }
    },

    put: async (userId, listingIds) => {
        const url = `/api/user/${userId}/bookmarks`;
        return await REST.put(url, {listingIds}).then(res => {
            return res
        });
    },
};