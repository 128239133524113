import {useContext, useEffect, useState} from "react";
import React from "react";
import {FieldsetLocation} from "../fieldsets/location/fieldset_location";
import {FieldsetRentRange} from "../fieldsets/rent_range/fieldset_rent_range";
import {FieldsetAvailableDate} from "../fieldsets/available_date/fieldset_available_date";
import {FieldsetClearFilters} from "../fieldsets/clear_filters/fieldset_clear_filters";
import {FieldsetSearchButton} from "../fieldsets/search_button/fieldset_search_button";
import {Drawer} from "../../drawer/drawer";
import {BootstrapTooltip, Filters} from "../search_filters/filters";
import "./basic_search.scss";
import {ListingSearchContext} from "../listing_search_context";
import {BrokerSearchingContext} from "../../../features/broker_search/broker_search_context";
import {shortcutsAlertsApi} from "../../../../services/rails_api/broker_portal/shortcuts_alerts";
import {AgentUserContext} from "../../../features/broker_search/agent_user_context";
import {FieldsetStatus} from "../fieldsets/status/fieldset_status";
import {FiltersContext} from "../search_filters/filters_provider";
import {SweetAlertContext} from "../../sweet_alert/sweet_alert";
import { SelectedListingsContext } from "../../../features/broker_search/selected_listings_context";
import { FieldsetDefaultRegion } from "../fieldsets/default_region/fieldset_default_region";
import { FieldsetBedroomRange } from "../fieldsets/bedroom_range/fieldset_bedroom_range";

interface BasicSearchProps {
    filtersOpen?: boolean;
    noListingsFound: boolean;
    customStatusesFilters?: any[];
}

export function BasicSearch (props: BasicSearchProps) {
    const [filtersOpen, setFiltersOpen] = useState(false);
    const [shortcutsOpen, setShortcutsOpen] = useState(false);
    const [shortcuts, setShortcuts] = useState([]);
    const {setFocusedElId, criteria} = useContext(ListingSearchContext);
    const {searching, setSearching, drawerWidth, handleDeleteLastSearch, setPersistLastSearch} = useContext(BrokerSearchingContext);
    const { setGroupedListings } = useContext(SelectedListingsContext)
    const {isMobileRes} = useContext(BrokerSearchingContext);
    const {agentId} = useContext(AgentUserContext);
    const {validateSearchFilters} = useContext(FiltersContext);
    const {triggerAlert} = useContext(SweetAlertContext);

    const handleClose = () => {
        setFocusedElId(null);
        setFiltersOpen(false);
        setShortcutsOpen(false);
    };

    const handleOpen = () => {
        setFocusedElId(null);
        setFiltersOpen(!filtersOpen);
    };

    const handleShortcutsOpen = () => {
        setFocusedElId(null);
        loadAndSetShortcuts();
        setFiltersOpen(true);
        setShortcutsOpen(true);
    };

    const handleShortcutsToggle = () => {
        if(!shortcutsOpen) {
            loadAndSetShortcuts();
        }
        setShortcutsOpen(!shortcutsOpen);
    };

    const loadAndSetShortcuts = async () => {
        const params = {
            agent_user_id: agentId,
        };

        const res = await shortcutsAlertsApi.get(params);
        if(200 === res.status) {
            const lastSearchRemoved = res.data.filter(shortcut => "last_search" !== shortcut.criteria_name);
            setShortcuts(lastSearchRemoved);
        }
    };

    const handleValidateSearchFilters = () => {
        if (validateSearchFilters(criteria)) {
            return true;
        }
        else {
            triggerAlert("error", "Please select an agent or change the MLS/Agency filter selection");
            return false;
        }
    };

    const handleProcess = () => {
        if(!validateSearchFilters(criteria)) {
            triggerAlert("error", "Please select an agent or change the MLS/Agency filter.");
            return
        }
        setGroupedListings([])
        setPersistLastSearch(true); // This flag is to avoid unnecessarily persisting the freshly loaded last_search (shortcut) when loaded and executed on page init
        handleClose();
        setSearching(true);
    };

    useEffect(() => {
        setFiltersOpen(props.filtersOpen);
    }, [props.filtersOpen])

    return (
        <>

            <div className="search--container">
                <FieldsetLocation className="search-fieldset filter--location"/>
                <FieldsetRentRange className="search-fieldset filter--rent-range" minInputId="minRent0" maxInputId="maxRent0" />
                <FieldsetBedroomRange className="search-fieldset filter--bedroom-range" minBedInputId="minBed" maxBedInputId="maxBed" />
                <FieldsetStatus className="search-fieldset filter--multiple-status" customStatusesFilters={props.customStatusesFilters}/>
                <FieldsetAvailableDate className="search-fieldset filter--available-date"/>
                <FieldsetSearchButton className="search-fieldset action--search-button"
                                    searching={searching}
                                    setSearching={setSearching}
                                    isMobileRes={isMobileRes}
                                    noListingsFound={props.noListingsFound}
                                    handleValidateSearchFilters={handleValidateSearchFilters}
                />
                <BootstrapTooltip title="Advanced Filters">
                    <div className="search-fieldset filter-btn" onClick={handleOpen}><i className="fas fa-sliders-h"/></div>
                </BootstrapTooltip>
                <Drawer open={filtersOpen} handleClose={handleClose} width={drawerWidth}>
                    <Filters handleClose={handleClose}
                            filtersOpen={filtersOpen}
                            shortcuts={shortcuts}
                            setShortcuts={setShortcuts}
                            shortcutsOpen={shortcutsOpen}
                            setShortcutsOpen={setShortcutsOpen}
                            handleShortcutsToggle={handleShortcutsToggle}
                            loadAndSetShortcuts={loadAndSetShortcuts}
                            handleProcess={handleProcess}
                    />
                </Drawer>
                <BootstrapTooltip title="Search filters (Shortcuts)">
                    <div className="shortcuts-icon-cont" onClick={handleShortcutsOpen}>
                        <i className="fas fa-save shortcut-save-icon" />
                    </div>
                </BootstrapTooltip>
                <FieldsetClearFilters className="search-fieldset action--clear-filters" handleDeleteLastSearch={handleDeleteLastSearch} />
                <FieldsetDefaultRegion />
            </div>
         </>
    )
}
