import React, {useContext} from "react";
import {FiltersContext} from "./filters_provider";
import {FilterGroup} from "./filter_group";
import "./search_filters.scss";
import {BrokerSearchingContext} from "../../../features/broker_search/broker_search_context";
import {FieldsetClearFilters} from "../fieldsets/clear_filters/fieldset_clear_filters";
import {Theme, Tooltip, makeStyles, TooltipProps} from "@material-ui/core";
import {IShortcut, ShortcutsAlerts} from "../shortcuts_alerts/shortcuts_alerts";
import {IAlert} from "../../../../services/api/alerts";

interface IFiltersProps {
    handleClose: () => any;
    filtersOpen: boolean;
    shortcutsOpen?: boolean;
    setShortcutsOpen?: (val: boolean) => void;
    shortcuts?: IShortcut[];
    setShortcuts?: (shortcuts: IShortcut[]) => void;
    loadAndSetShortcuts?: () => void;
    handleShortcutsToggle?: () => void;
    handleProcess: () => void;
    alert?: IAlert;
    handleAlertNameChange?: (event, alert: IAlert) => void;
}

const useStylesBootstrap = makeStyles((theme: Theme) => ({
    arrow: {
        color: theme.palette.common.white,
    },
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));

export function BootstrapTooltip(props: TooltipProps) {
    const classes = useStylesBootstrap();

    return <Tooltip arrow classes={classes} {...props} />;
}

export const getNumOfCols = (contWidth, colWidth) => {
    const cols = Math.floor(contWidth / colWidth);
    return cols > 1 ? cols : "auto";
};

export function Filters(props: IFiltersProps) {

    const {contWidth, colWidth, filterGroups, setFilterGroups, handleToggleAll, validateSearchFilters} = useContext(FiltersContext);
    const {isMobileRes, handleDeleteLastSearch, searching} = useContext(BrokerSearchingContext);
    const {
        handleClose,
        filtersOpen,
        shortcutsOpen,
        setShortcutsOpen,
        shortcuts,
        setShortcuts,
        loadAndSetShortcuts,
        handleShortcutsToggle,
        handleProcess,
        alert,
        handleAlertNameChange,
    } = props;

    const handleApply = () => {
        handleProcess();
    };

    const saveShortcutCallback = () => {
        loadAndSetShortcuts();
    };

    const isSearchFeature = () => {
        return (
            "undefined" !== typeof(shortcutsOpen) &&
            "undefined" !== typeof(setShortcutsOpen) &&
            "undefined" !== typeof(shortcuts) &&
            "undefined" !== typeof(setShortcuts)
        )
    };

    const getActionContent = (alert) => {
        return isSearchFeature() ?
        <div className="center-cont">
            <div className="advanced-search-btn" onClick={handleApply}>
                <span style={{display: isMobileRes ? "none" : ""}} className="search-text" >SEARCH</span><i className="fas fa-search"/>
            </div>
            <FieldsetClearFilters className="clear" handleDeleteLastSearch={handleDeleteLastSearch} />
        </div> :
        <div className="center-cont">
            <div className={searching ? "btn-disabled advanced-search-btn" : "advanced-search-btn"} onClick={handleApply}>
                <span className="search-text" >{isMobileRes ? "SAVE" : "SAVE ALERT"}</span>
            </div>
            <input value={alert.criteria_name} onChange={(e) => handleAlertNameChange(e, alert)} className="alert-name-input" />
        </div>
    };

    return (
        <>
            <div className={`shortcuts-screen ${ filtersOpen && shortcutsOpen ? "screen-on" : "screen-off"}`} onClick={()=>{setShortcutsOpen(false)}}/>
            <div className="filter-header">
                <div className="top-row">
                    <div className="left-cont">
                        <BootstrapTooltip title="Collapse all" >
                            <div className="expand-control" onClick={()=>handleToggleAll(false)}>
                                <i className="fas fa-angle-double-up"/>
                            </div>
                        </BootstrapTooltip>
                        <BootstrapTooltip title="Expand all" >
                            <div className="expand-control" onClick={()=>handleToggleAll(true)}>
                                <i className="fas fa-angle-double-down"/>
                            </div>
                        </BootstrapTooltip>
                        {
                            isSearchFeature() ?
                            <BootstrapTooltip title="Search filters (Shortcuts)" >
                                <div className="shortcuts-trigger-cont" onClick={handleShortcutsToggle}>
                                    <i className="fas fa-save shortcut-save-icon" />
                                    <i className={shortcutsOpen ? "fas fa-caret-up" : "fas fa-caret-down"} />
                                </div>
                            </BootstrapTooltip> : null
                        }
                    </div>
                    {getActionContent(alert)}
                    <div className="right-cont">
                        <div className="filters-close-icon" onClick={handleClose}>{isSearchFeature() ? "Close" : "Cancel"} <i className="far fa-window-close" /></div>
                    </div>
                </div>
                {
                    isSearchFeature() ?
                    <ShortcutsAlerts show={shortcutsOpen}
                                     setShow={setShortcutsOpen}
                                     shortcuts={shortcuts}
                                     setShortcuts={setShortcuts}
                                     saveCallback={saveShortcutCallback}
                    /> : null
                }
            </div>
            <div id="cabinet-container" className="search--container">
                <div className="column" style={{columnCount: getNumOfCols(contWidth, colWidth)}} >
                    {
                        filterGroups.map((filterData, index) => {
                            return <FilterGroup filterData={filterData}
                                                filterGroups={filterGroups}
                                                setFilterGroups={setFilterGroups}
                                                groupIndex={index}
                                                key={`filter_group_${index}`} />
                        })
                    }
                </div>

            </div>
        </>
    )
}